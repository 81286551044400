import router from '../router/index';
import config from '../config';
import {
  login_User,
  logout_User,
  get_User_Config,
  save_User_Config,
  user_validate
} from '../api/services';

// returns the default config on first user login
function getDefaultConfig() {
  let defaultConfig = {
    dashboards: {},
    activeNodeId: null,
    breadcrumb: [],
    treeNode: {},
    node: { tabs: [] },
    activeTabId: null,
    theme: { name: 'Default', isDark: false },
    grid: 'grid-2-col-view'
  };
  return JSON.stringify(defaultConfig);
}

export default {
  namespaced: true,
  state: {
    user: {},
    isFetching: false,
    errorMessage: ''
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    LOGIN_FAILURE(state, payload) {
      state.isFetching = false;
      //state.errorMessage = payload;
      state.errorMessage = 'Ungültige Login-Daten';
    },
    LOGIN_SUCCESS(state) {
      state.isFetching = false;
      state.errorMessage = '';
    },
    LOGIN_REQUEST(state) {
      state.errorMessage = '';
      state.isFetching = true;
    },
    RESET_FETCHING(state) {
      state.isFetching = false;
    }
  },
  actions: {
    async loginUser({ dispatch }, creds) {
      if (!config.isBackend) {
        dispatch('receiveToken', 'token');
      } else {
        dispatch('requestLogin');
        if (creds.username.length > 0 && creds.password.length > 0) {
          const user_response = await login_User(creds);
          if (user_response['success'] === 1) {
            await dispatch('receiveToken', user_response['response']);
          } else {
            await dispatch('loginError', user_response['response']);
          }
        } else {
          dispatch('loginError', 'Something was wrong. Try again');
        }
      }
    },
    async receiveToken({ dispatch, commit }, userResponse) {
      let user = {};
      let success = false;
      // We check if app runs with backend mode
      if (userResponse.result == 1) {
        user = (({ userconfig, ...o }) => o)(userResponse);
        success = true;
        localStorage.setItem('user', JSON.stringify(user));
        let configData = userResponse.userconfig.value.config;
        if (configData === undefined || configData === null) {
          configData = getDefaultConfig();
        }
        localStorage.setItem('userconfig', configData);
        commit('SET_USER', user);
      }

      if (success) {
        dispatch('receiveLogin');
      } else {
        dispatch('loginError', userResponse.message);
      }
    },
    async logoutUser({ dispatch }) {
      // send config to backend
      await dispatch('saveUserConfig');
      // delete local config
      window.localStorage.clear();
      localStorage.removeItem('user');
      // user logout
      await logout_User();
    },
    loginError({ commit }, payload) {
      commit('LOGIN_FAILURE', payload);
    },
    receiveLogin({ commit }) {
      commit('LOGIN_SUCCESS');
      router.push({
        name: 'Home'
      });
    },
    requestLogin({ commit }) {
      commit('LOGIN_REQUEST');
    },
    getUserConfig() {
      let def_config = getDefaultConfig();
      return get_User_Config(def_config);
    },
    async saveUserConfig() {
      save_User_Config();
    },
    async userValidate({ dispatch }) {
      let user = JSON.parse(localStorage.getItem('user'));

      if (
        user === undefined ||
        user === null ||
        Object.entries(user).length == 0
      )
        dispatch('logoutUser');

      let data = await user_validate(user);
      if (!data.result) dispatch('logoutUser');
      else dispatch('getUserConfig');
    },
    resetFetching({ commit }) {
      commit('RESET_FETCHING');
    }
  }
};
