<template>
  <v-container fluid class="main-img">
    <div class="content-main" v-if="windowSize > 700">
      <!-- minimal resolution -->
      <v-row class="mt-1" v-if="windowSize < 1200">
        <!-- small resolution 700 - 999: chart on top -->
        <v-col :cols="12">
          <v-card class="pa-2 mt-2 chartBackground" flex="12">
            <div v-if="!showTable">
              <highcharts
                :options="chartOptions"
                ref="chart"
                :class="$vuetify.theme.isDark ? 'dark-theme' : 'light-theme'"
              ></highcharts>
            </div>
            <div v-else>
              <v-data-table
                dense
                :headers="tableHeader"
                :items="tableData"
                :footer-props="footerProps"
                :items-per-page="10"
                ><template v-slot:[`footer.page-text`]="items">
                  {{ items.pageStart }} - {{ items.pageStop }} /
                  {{ items.itemsLength }}
                </template>
                <!-- <template v-slot:[`item.timedata`]="{ item }">
                  <span>{{ new Date(item.timedata).toLocaleString() }}</span>
                </template> --></v-data-table
              >
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="mt-1">
        <v-col :cols="8">
          <v-card class="pa-2 chartBackground">
            <!-- Date Menu -->
            <v-row>
              <v-col>
                <v-menu
                  ref="datemenu1"
                  v-model="menu1"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="startDate"
                      color="teal darken-1"
                      v-bind:label="$t('analyse.startdate')"
                      prepend-icon="mdi-calendar-today"
                      v-bind="attrs"
                      v-on="on"
                      class="ml-2"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="startDate"
                    no-title
                    scrollable
                    :locale="locale"
                    @change="dateChanged()"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-menu
                  ref="datemenu2"
                  v-model="menu2"
                  :close-on-content-click="true"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="endDate"
                      color="teal darken-1"
                      v-bind:label="$t('analyse.enddate')"
                      prepend-icon="mdi-calendar"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="endDate"
                    no-title
                    scrollable
                    :locale="locale"
                    @change="dateChanged()"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col>
                <v-select
                  prepend-icon="mdi-clock"
                  color="teal darken-1"
                  item-color="teal darken-1"
                  :items="res_items"
                  item-value="text"
                  v-bind:label="$t('analyse.resolution')"
                  v-model="selected_res"
                  return-object
                  @change="dateChanged"
                  class="mr-2"
                ></v-select>
              </v-col>
            </v-row>
          </v-card>
          <v-card class="pa-2 mt-2 chartBackground" v-if="windowSize >= 1200">
            <!-- bigger resolution: show chart here -->
            <div v-if="!showTable">
              <highcharts
                :options="chartOptions"
                ref="chart"
                :class="$vuetify.theme.isDark ? 'dark-theme' : 'light-theme'"
              ></highcharts>
            </div>
            <div v-else>
              <v-data-table
                dense
                :headers="tableHeader"
                :items="tableData"
                :footer-props="footerProps"
                :items-per-page="10"
                ><template v-slot:[`footer.page-text`]="items">
                  {{ items.pageStart }} - {{ items.pageStop }} /
                  {{ items.itemsLength }}
                </template>
                <!-- <template v-slot:[`item.timedata`]="{ item }">
                  <span>{{ new Date(item.timedata).toLocaleString() }}</span>
                </template> --></v-data-table
              >
            </div>
          </v-card>
          <v-card
            class="pa-3 mt-2 chartBackground"
            v-if="available_chartData.length > 0"
          >
            <v-row>
              <v-col
                ><span class="ml-2 mt-2 componentText--text">{{
                  $t('analyse.datapoints')
                }}</span></v-col
              >
              <v-col class="text-right">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      large
                      :disabled="chartOptions.series.length == 0"
                      v-bind="attrs"
                      v-on="on"
                      @click="
                        chartOptions.legend.enabled = !chartOptions.legend
                          .enabled;
                        $refs.chart.chart.redraw();
                        $refs.chart.chart.reflow();
                      "
                      class="mr-3 mt-1"
                      >mdi-format-list-bulleted-type</v-icon
                    >
                  </template>
                  <span>{{ $t('analyse.show_legend') }}</span>
                </v-tooltip>
                <vue-json-to-csv
                  :json-data="csvData"
                  csv-title="Analyse"
                  separator=";"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- @click only for "clickable" mouse hover effect -->
                      <v-icon
                        large
                        :disabled="chartOptions.series.length == 0"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          {
                          }
                        "
                        class="mr-3 mt-1"
                        >mdi-download</v-icon
                      >
                    </template>
                    <span>{{ $t('analyse.download_csv') }}</span>
                  </v-tooltip>
                </vue-json-to-csv>
                <v-tooltip bottom v-if="!showTable">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      large
                      :disabled="chartOptions.series.length == 0"
                      @click="showTable = !showTable"
                      v-bind="attrs"
                      v-on="on"
                      class="mt-1"
                      >mdi-table</v-icon
                    >
                  </template>
                  <span>{{ $t('analyse.table_view') }}</span>
                </v-tooltip>
                <v-tooltip bottom v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      large
                      @click="showTable = !showTable"
                      v-bind="attrs"
                      v-on="on"
                      >mdi-chart-line</v-icon
                    >
                  </template>
                  <span>{{ $t('analyse.chart_view') }}</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-list dense class="mt-0 chartBackground">
              <v-list-item class="pa-0 mt-n3 mb-n2 ml-2">
                <v-checkbox
                  v-model="selectAll"
                  @click="selectAllDatapoints()"
                  color="accent"
                ></v-checkbox>
                <span>{{ $t('analyse.select_all') }}</span>
              </v-list-item>
              <v-list-item
                v-for="(timedata, i) in available_chartData"
                :key="i"
                class="pa-0 mt-0 ml-2"
              >
                <v-list-item-action>
                  <v-checkbox
                    v-model="timedata.addedToChart"
                    @click="selectChartData(i)"
                    color="accent"
                  >
                  </v-checkbox>
                </v-list-item-action>
                <v-list-item-content class="datapoint_items">
                  <v-list-item-title>
                    {{ timedata.name }} [{{ timedata.unit }}]
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ timedata.path }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-avatar
                  v-if="timedata.addedToChart"
                  :color="timedata.color"
                  class="mr-2"
                  size="24"
                >
                </v-avatar>
                <div class="select">
                  <v-select
                    :items="diagramTypes"
                    v-bind:hint="$t('analyse.chart_type')"
                    persistent-hint
                    item-text="name"
                    dense
                    return-object
                    v-model="timedata.type"
                    @change="changeDiagramType(timedata)"
                  >
                  </v-select>
                </div>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1" @click="removeDatapoint(i)"
                      >mdi-close</v-icon
                    >
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col :cols="4">
          <v-card class="pa-2 chartBackground">
            <v-menu
              offset-y
              v-model="savedAnalyseMenu"
              :close-on-content-click="false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  class="ml-2 mr-2"
                  readonly
                  v-bind:placeholder="$t('analyse.load_analysis')"
                  v-bind="attrs"
                  v-on="on"
                  v-model="loadThisAnalysis"
                >
                </v-text-field>
              </template>
              <v-list dense>
                <v-list-item-group>
                  <v-list-item
                    v-for="(item, index) in saved_analyses"
                    :key="index"
                  >
                    <v-list-item-content @click="loadAnalysis(index)">{{
                      item.name
                    }}</v-list-item-content>
                    <v-btn icon @click="deleteSavedAnalysis(index)">
                      <v-icon color="grey lighten-1">mdi-close</v-icon>
                    </v-btn>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-menu>
          </v-card>
          <v-card class="mt-2 pa-2 chartBackground">
            <v-dialog v-model="select_node_dialog" scrollable max-width="800px">
              <template v-slot:activator="{ on, attrs }">
                <div v-if="selected_node == null" class="ml-2 mr-2 mt-2">
                  <p class="componentText--text">
                    {{ $t('analyse.choose_asset') }}
                  </p>
                </div>
                <div v-else class="ml-2 mr-2 mt-2">
                  <p class="componentText--text">
                    {{ $t('analyse.current_node') }}
                    <b>{{ selected_node.name }}</b>
                  </p>
                </div>

                <v-text-field
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  v-model="selected_path_formatted"
                  class="ml-2 mr-2"
                >
                </v-text-field>
              </template>
              <v-card class="path-treeview">
                <v-card-text>
                  <v-treeview
                    dense
                    activatable
                    selection-type="independent"
                    :load-children="loadChildren"
                    :items="path_items"
                    transition
                    @update:active="onUpdate"
                  >
                    <template v-slot:prepend="{ item, open }">
                      <v-icon
                        v-if="
                          item.node != undefined && item.node.charAt(0) == 'a'
                        "
                      >
                        {{ 'mdi-home' }}
                      </v-icon>
                      <v-icon
                        v-else-if="
                          item.node != undefined && item.node.charAt(0) == 'c'
                        "
                      >
                        {{ 'mdi-gauge' }}
                      </v-icon>
                      <v-icon v-else>
                        {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
                      </v-icon>
                    </template>
                  </v-treeview>
                </v-card-text>
                <v-spacer></v-spacer>
                <v-card-actions class="justify-end">
                  <v-btn text @click="loadDatapoints">
                    Ok
                  </v-btn>
                  <v-btn
                    text
                    @click="
                      active = null;
                      select_node_dialog = false;
                    "
                  >
                    {{ $t('settings.cancel') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
          <v-card
            class="mt-2 pa-2 datapoint-box chartBackground"
            v-if="datapoints.length > 0"
          >
            <p class="mt-2 ml-2 componentText--text">
              {{ $t('analyse.available_datapoints') }}
            </p>
            <v-list dense class="ml-2 chartBackground">
              <v-list-item
                v-for="datapoint in datapoints"
                :key="datapoint.id"
                class="pa-0"
              >
                <v-list-item-action>
                  <v-checkbox v-model="datapoint.isSelected" color="accent">
                  </v-checkbox>
                </v-list-item-action>
                <v-list-item-content class="datapoint_items">
                  <v-list-item-title>
                    {{ datapoint.text }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ datapoint.unit }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
          <v-btn
            v-if="datapoints.length > 0"
            @click="requestTimeData"
            class="text-none mt-2 mr-2 chartBackground"
          >
            {{ $t('analyse.get_data') }}
          </v-btn>
          <v-dialog v-model="save_analyse_dialog" width="300">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-show="selected_node != null"
                class="text-none mt-2 chartBackground"
                v-bind="attrs"
                v-on="on"
              >
                {{ $t('analyse.save_analysis') }}
              </v-btn>
            </template>
            <v-card>
              <v-text-field
                v-bind:label="$t('analyse.choose_name')"
                v-model="save_analyse_name"
                :class="['mr-2 ml-2', $vuetify.theme.isDark ? 'forceDark' : '']"
                @keydown.enter.prevent="saveThisAnalysis()"
              >
              </v-text-field>

              <v-spacer></v-spacer>
              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  :disabled="save_analyse_name.length == 0"
                  @click="saveThisAnalysis()"
                >
                  Ok
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  @click="save_analyse_dialog = false"
                >
                  {{ $t('settings.cancel') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </div>
    <div class="content-main" v-else>
      <v-card class="pa-2 mt-2 chartBackground">
        {{ $t('main.resolution-not-supported') }}
      </v-card>
    </div>
    <v-snackbar v-model="dateAlert" color="red">
      {{ $t('chart.invalidDates') }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="dateAlert = false">
          {{ $t('chart.close') }}
        </v-btn>
      </template>
    </v-snackbar>
    <FooterComp />
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import { getRootNodes1, postSinglePanel } from '../../api/services';
import FooterComp from '@/components/Footer';
import VueJsonToCsv from 'vue-json-to-csv';
import Highcharts from 'highcharts';
import config from '../../config/index';

const dateoptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};
export default {
  name: 'AnalysePage',
  components: { FooterComp, VueJsonToCsv },
  data() {
    return {
      saved_analyses: [],
      loadThisAnalysis: null,
      save_analyse_dialog: false,
      save_analyse_name: '',
      savedAnalyseMenu: false,
      isDark: false,
      windowSize: 1001,
      /* calendar */
      menu1: false,
      menu2: false,
      startDate: null,
      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      res_items: [],
      selected_res: '',
      locale: '', //this.$root.$i18n.locale,
      dateAlert: false,
      /* treeview */
      path_items: [],
      active: null,
      open: [],
      select_node_dialog: false,
      selected_node: null,
      /* chart */
      datapoints: [],
      available_chartData: [],
      themeColors: [],
      themes: config.themes,
      selectAll: null,
      diagramTypes: [
        { name: this.$root._i18n.t('analyse.line'), type: 'spline' },
        { name: this.$root._i18n.t('analyse.column'), type: 'column' },
        { name: this.$root._i18n.t('analyse.scatter'), type: 'scatter' }
      ],
      footerProps: {
        'items-per-page-options': [10, 20, 30, 40, 50, -1],
        'items-per-page-text': this.$root._i18n.t('analyse.rows-per-page')
      },
      chartOptions: {
        chart: {
          zoomType: 'xy'
        },
        credits: {
          enabled: false
        },
        legend: {
          enabled: false
        },
        series: [],
        title: {
          text: ''
        },
        xAxis: {
          type: 'datetime'
        },
        tooltip: {},
        exporting: {
          buttons: {
            contextButton: {
              menuItems: [
                'viewFullscreen',
                'printChart',
                'separator',
                'downloadPNG',
                'downloadJPEG',
                'downloadPDF',
                'downloadSVG'
              ]
            }
          },
          menuItemDefinitions: {
            switchTable: {
              text: this.$root._i18n.t('analyse.table-view'),
              onclick: function() {}
            },
            viewFullscreen: {
              text: this.$root._i18n.t('analyse.fullscreen')
            },
            printChart: {
              text: this.$root._i18n.t('analyse.print')
            },
            downloadPNG: {
              text: this.$root._i18n.t('analyse.download_png')
            },
            downloadJPEG: {
              text: this.$root._i18n.t('analyse.download_jpeg')
            },
            downloadPDF: {
              text: this.$root._i18n.t('analyse.download_pdf')
            },
            downloadSVG: {
              text: this.$root._i18n.t('analyse.download_svg')
            }
          }
          /* buttons: {
            contextButton: {
              menuItems: [
                {
                  textKey: 'printChart',
                  onclick: function() {
                    this.print();
                  }
                },
                {
                  separator: true
                },
                {
                  textKey: 'downloadPNG',
                  onclick: function() {
                    this.exportChart();
                  }
                },
                {
                  textKey: 'downloadJPEG',
                  onclick: function() {
                    this.exportChart({
                      type: 'image/jpeg'
                    });
                  }
                },
                {
                  separator: true
                },
                {
                  textKey: 'downloadPDF',
                  onclick: function() {
                    this.exportChart({
                      type: 'application/pdf'
                    });
                  }
                },
                {
                  textKey: 'downloadSVG',
                  onclick: function() {
                    this.exportChart({
                      type: 'image/svg+xml'
                    });
                  }
                }
              ]
            }
          } */
        },
        navigation: {
          buttonOptions: {
            theme: {
              'stroke-width': 0,
              stroke: 'silver',
              r: 0,
              fill: 'white',
              states: {
                hover: {
                  fill: '#cccccc' // hover über menu icon
                },
                select: {
                  stroke: '#cccccc',
                  fill: '#808080' // menü icon gedrückt
                }
              }
            }
          },
          menuStyle: {
            background: 'white',
            border: 'none'
            /*boxShadow:
              '0 1px 5px 0 rgba(0,0,0,.1), 0 2px 2px 0 rgba(0,0,0,.06), 0 3px 1px -2px rgba(0,0,0,.06)'*/
          },
          menuItemStyle: {
            padding: '7px 10px',
            color: 'black', //text farbe im menü
            fontFamily: 'Arial'
            //fontWeight: 'normal'
          },
          menuItemHoverStyle: {
            background: '#00897B',
            color: 'black', //text farbe beim hovern
            fontFamily: 'Arial'
            //fontWeight: 'bold'
          }
        }
      },
      showTable: false
    };
  },
  beforeMount() {
    //load node tree highest level
    for (let i = 0; i < this.$store.state.nodes.nodes.nodes.length; i++) {
      this.path_items.push({
        name: this.$store.state.nodes.nodes.nodes[i].text,
        id: this.$store.state.nodes.nodes.nodes[i].id,
        parents: [{ id: 0, name: 'Global' }],
        children: [],
        node: this.$store.state.nodes.nodes.nodes[i].node
      });
    }
    this.$store.dispatch('layout/getUserConfig');
    this.$root.$i18n.locale = this.$store.state.layout.language
      ? this.$store.state.layout.language
      : 'de';
    this.locale = this.$root.$i18n.locale;
    let loc = this.locale;
    //set start date 1 month in the past
    let d = new Date(Date.now() - new Date().getTimezoneOffset() * 60000);
    d.setMonth(d.getMonth() - 1);
    this.startDate = d.toISOString().substr(0, 10);
    //load saved analyses from store
    if (this.$store.state.nodes.savedAnalyses) {
      this.saved_analyses = this.$store.getters['nodes/SAVED_ANALYSES'].slice(); // copy of array
    }

    this.chartOptions.tooltip = {
      formatter: function() {
        var options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        let unit = this.point.series.userOptions.unit;
        let timedelta = 0;
        if (this.series.processedXData.length > 1) {
          timedelta =
            this.series.processedXData[1] - this.series.processedXData[0];
          if (timedelta > 3e9) {
            options = {
              year: 'numeric'
            };
          } else if (timedelta > 9e7) {
            options = {
              year: 'numeric',
              month: 'long'
            };
          } else if (timedelta < 4e6) {
            options = {
              hour: 'numeric',
              minute: 'numeric',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            };
          }
        }
        let d = new Date(this.x);
        return `${d.toLocaleDateString(loc, options)}<br>
            <b>${this.y} ${unit}</b>`;
      }
    };
  },
  async mounted() {
    Highcharts.setOptions({
      lang: {
        resetZoom: this.$root._i18n.t('chart.reset-zoom')
      },
      time: {
        useUTC: false
      }
    });
    this.res_items = this.$root._i18n.t('chart.time-resolution-short');
    this.selected_res = this.res_items[1]; //day

    let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
    if (this.$store.state.layout.theme.chartColors) {
      this.themeColors = this.$store.state.layout.theme.chartColors;
    } else {
      if (localUserConfig.theme.isDark) {
        this.themeColors = this.themes.find(
          i => i.name == localUserConfig.theme.name
        ).dark.chartColors;
      } else {
        this.themeColors = this.themes.find(
          i => i.name == localUserConfig.theme.name
        ).light.chartColors;
      }
    }
    if (this.$store.state.layout.theme.isDark) {
      this.chartOptions.navigation.buttonOptions.theme.fill = '#cccccc';
      this.chartOptions.navigation.menuStyle.background = '#cccccc';
    }
    this.isDark = true;
    if (this.$store.state.nodes.analysisSession) {
      this.saved_analyses.push(this.$store.state.nodes.analysisSession);
      await this.loadAnalysis(this.saved_analyses.length - 1);
      this.saved_analyses.pop();
    }
    this.windowSize = window.innerWidth;
  },
  updated() {
    if (this.$refs.chart !== undefined) {
      this.$refs.chart.chart.redraw();
      this.$refs.chart.chart.reflow();
    }
  },
  beforeDestroy() {
    if (this.available_chartData.length > 0) {
      let currentSession = {};
      currentSession['availableChartData'] = this.available_chartData;
      currentSession['startDate'] = this.startDate;
      currentSession['endDate'] = this.endDate;
      currentSession['name'] = this.loadThisAnalysis;
      currentSession['res'] = this.selected_res.value;
      currentSession['selectedNode'] = this.selected_node;
      this.$store.dispatch('nodes/updateAnalysisSession', currentSession);
    }
  },
  created() {
    window.addEventListener('resize', this.windowSizeChanged);
  },
  destroyed() {
    window.removeEventListener('resize', this.windowSizeChanged);
  },
  computed: {
    ...mapState('nodes', ['node']),
    selected_path_formatted() {
      if (this.selected_node == null)
        return this.$root._i18n.t('analyse.choose_asset_short');
      else {
        let str = '';
        for (let i = 1; i < this.selected_node.parents.length; i++) {
          str += this.selected_node.parents[i].name + ' > ';
        }
        str += this.selected_node.name;
        return str;
      }
    },
    tableHeader() {
      if (this.chartOptions.series.length == 0) {
        return [];
      } else {
        let tableHeader = [];
        tableHeader.push({
          text: this.$root._i18n.t('analyse.time'),
          align: 'end',
          value: 'timedata'
        });
        for (let i = 0; i < this.chartOptions.series.length; i++) {
          tableHeader.push({
            text:
              this.chartOptions.series[i].name +
              ' [' +
              this.chartOptions.series[i].unit +
              ']',
            value: this.chartOptions.series[i].name,
            align: 'end'
          });
        }
        return tableHeader;
      }
    },
    tableData() {
      if (this.chartOptions.series.length == 0) {
        return [];
      } else {
        let tableData = [];
        var options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric'
        };
        if (this.selected_res.value == 'h') {
          options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
          };
        }
        if (this.chartOptions.series[0].data != undefined) {
          for (let i = 0; i < this.chartOptions.series[0].data.length; i++) {
            let d = new Date();
            d.setTime(this.chartOptions.series[0].data[i][0]);
            let tableobj = {
              //timedata: this.chartOptions.series[0].data[i][0]
              timedata: d.toLocaleString(this.locale, options)
            };
            for (let j = 0; j < this.chartOptions.series.length; j++) {
              if (
                this.chartOptions.series[j].data &&
                this.chartOptions.series[j].data.length > i
              )
                tableobj[
                  this.chartOptions.series[j].name
                ] = this.chartOptions.series[j].data[i][1];
            }
            tableData.push(tableobj);
          }
        }
        return tableData;
      }
    },
    csvData() {
      let result = this.tableData;
      if (this.$store.state.layout.language == 'de')
        for (let item of result) {
          for (let key of Object.keys(item)) {
            if (typeof item[key] == 'number')
              item[key] = item[key].toString(10).replace('.', ',');
          }
        }
      return result;
    }
  },
  methods: {
    windowSizeChanged(e) {
      this.windowSize = window.innerWidth;
    },
    formatDate(date) {
      if (!date) return null;
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let day = date.getDate();
      return `${day}.${month}.${year}`;
    },
    loadChildren(item) {
      item.parents = item.parents.concat([{ id: item.id, name: item.name }]);
      let tree = this.findTree(item);
      for (let i = 0; i < tree.length; i++) {
        let child_obj = {
          id: 0,
          name: '',
          parents: item.parents,
          node: null,
          children: null
        };
        child_obj.id = tree[i].id;
        child_obj.name = tree[i].text;
        child_obj.node = tree[i].node;
        if (tree[i].nodes != undefined) {
          child_obj.children = [];
        } else {
          delete child_obj.children;
        }
        item.children.push(child_obj);
      }
      item.parents = item.parents.slice(0, -1);
    },
    findTree(item) {
      let tree = this.$store.state.nodes.nodes.nodes;
      for (let i = 0; i < item.parents.length; i++) {
        for (let j = 0; j < tree.length; j++) {
          if (tree[j].id == item.parents[i].id) {
            tree = tree[j].nodes;
            break;
          }
        }
      }
      return tree;
    },
    onUpdate(s) {
      this.active = s;
    },
    searchTree() {
      this.recursive_searchTree(this.path_items, this.active);
    },
    recursive_searchTree(tree, search_id) {
      if (tree == null) {
        return;
      }
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].id == search_id) {
          this.selected_node = tree[i];
          return;
        } else this.recursive_searchTree(tree[i].children, search_id);
      }
    },
    async loadDatapoints() {
      this.select_node_dialog = false;
      this.searchTree();
      if (this.selected_node == null) {
        console.log('Error Node not found');
        return;
      }
      let o = await getRootNodes1({ node: this.selected_node.node });
      this.datapoints = o[0].vars;
      //this.datapoints.forEach(dp => (dp.isSelected = false));
      for (let i = 0; i < this.datapoints.length; i++) {
        this.datapoints[i].isSelected = false;
        this.datapoints[i].id = i + 1;
      }
    },
    //load time data for all selected datapoints of the current node
    async requestTimeData() {
      let name = 'Analyse_' + this.selected_node.name + '_' + Date.now();
      let node = this.selected_node.node;
      let dtstart = new Date(this.startDate);
      let dtend = new Date(this.endDate);
      let timezoneoffset = dtstart.getTimezoneOffset();
      let epoch_start = new Date(
        dtstart.getTime() + timezoneoffset * 60000
      ).getTime(); // add the time zone diefference
      let epoch_end = new Date(
        dtend.getTime() + timezoneoffset * 60000
      ).getTime();
      let vars = [];
      for (let i = 0; i < this.datapoints.length; i++) {
        if (this.datapoints[i].isSelected) {
          let alreadyAvailable = false;
          for (let j = 0; j < this.available_chartData.length; j++) {
            if (
              this.datapoints[i].name == this.available_chartData[j].varName &&
              this.selected_node.node == this.available_chartData[j].node
            ) {
              alreadyAvailable = true;
              break;
            }
          }
          if (!alreadyAvailable) vars.push(this.datapoints[i].name);
          this.datapoints[i].isSelected = false; // untick checkboxes
        }
      }
      this.datapoints.forEach(
        dp => (dp.id = dp.id * this.datapoints.length + 1) // strange way to re-render checkboxes, all IDs must be changed to a value that wasn't there before
      );
      if (vars.length > 0) {
        let params = {
          name: name,
          node: [node],
          dtstart: epoch_start,
          dtend: epoch_end,
          tzoffs: 0,
          res: this.selected_res.value,
          vars: vars
        };
        let o = await postSinglePanel(params);
        if (o != null) {
          let res = o.response[0];
          let req = o.request;
          if (res.success) {
            /* available Chart Data Object:
          {
            node: ...,
            varName: ...,
            name: ...,
            res: ...,
            unit: ...,
            color: ...,
            addedToChart: im Chart hinzugefügt?
            path: Pfad zum Knoten als String
            type: Linientyp
            data: [ [zeit1, data1], [zeit2, data2], ...]

          } */
            for (let i = 1; i < res.colcnt; i++) {
              let chartDataObject = {
                node: null,
                varName: null,
                name: null,
                //res: null,
                unit: null,
                color: null,
                addedToChart: true,
                path: null,
                type: this.diagramTypes[0],
                data: []
              };
              chartDataObject.node = res.columns[i].node;
              chartDataObject.varName = res.columns[i].var;
              chartDataObject.name = res.columns[i].text;
              //chartDataObject.res = req.res;
              chartDataObject.unit = res.columns[i].unit;
              chartDataObject.color = null;
              chartDataObject.path = this.selected_path_formatted;
              for (let j = 0; j < res.rowcnt; j++) {
                chartDataObject.data.push([
                  res.columns[0].data[j],
                  res.columns[i].data[j]
                ]);
              }
              this.available_chartData.push(chartDataObject);
              this.selectChartData(this.available_chartData.length - 1); // insert new data immediately into chart
            }
          } else console.log('Backend request something went wrong');
        }
      }
    },
    // add or remove time data from chart
    selectChartData(idx) {
      if (!this.available_chartData[idx].addedToChart) {
        // Remove a line from chart
        let i = 0;
        for (; i < this.chartOptions.series.length; i++) {
          if (
            this.chartOptions.series[i].node ==
              this.available_chartData[idx].node &&
            this.chartOptions.series[i].varName ==
              this.available_chartData[idx].varName
          ) {
            break;
          }
        }
        this.chartOptions.series.splice(i, 1);
      } else {
        // add a line to chart
        let path_nodes = this.available_chartData[idx].path.split('> ');
        this.chartOptions.series.push({
          name:
            this.available_chartData[idx].name +
            ' (' +
            path_nodes[path_nodes.length - 1] +
            ')',
          varName: this.available_chartData[idx].varName,
          data: this.available_chartData[idx].data,
          node: this.available_chartData[idx].node,
          unit: this.available_chartData[idx].unit,
          type: this.available_chartData[idx].type.type
        });
      }
      this.updateYAxis();
      this.updateChartColors();
    },
    // remove a dataline from available chart data
    removeDatapoint(idx) {
      if (this.available_chartData[idx].addedToChart) {
        let i = 0;
        for (; i < this.chartOptions.series.length; i++) {
          if (
            this.chartOptions.series[i].node ==
              this.available_chartData[idx].node &&
            this.chartOptions.series[i].varName ==
              this.available_chartData[idx].varName
          ) {
            break;
          }
        }
        this.chartOptions.series.splice(i, 1);
      }
      this.available_chartData.splice(idx, 1);
      this.updateYAxis();
    },
    // select or deselect all datapoints for chart
    selectAllDatapoints() {
      for (let i = 0; i < this.available_chartData.length; i++) {
        if (this.available_chartData[i].addedToChart != this.selectAll) {
          this.available_chartData[i].addedToChart = this.selectAll;
          this.selectChartData(i);
        }
      }
      if (!this.selectAll && this.chartOptions.series.length > 0) {
        this.chartOptions.series = [];
      }
    },
    // set one y-axis for each unit
    updateYAxis() {
      let units = [];
      for (let i = 0; i < this.chartOptions.series.length; i++) {
        let b = units.indexOf(this.chartOptions.series[i].unit);
        if (b == -1) units.push(this.chartOptions.series[i].unit);
      }
      this.chartOptions.yAxis = [];
      for (let i = 0; i < units.length; i++) {
        let axisobject = {
          title: {
            style: {
              fontWeigth: 'bold'
            },
            text: units[i]
          },
          opposite: i % 2 === 1
        };
        this.chartOptions.yAxis.push(axisobject);
      }
      for (let i = 0; i < this.chartOptions.series.length; i++) {
        for (let j = 0; j < this.chartOptions.yAxis.length; j++) {
          if (
            this.chartOptions.series[i].unit ==
            this.chartOptions.yAxis[j].title.text
          ) {
            this.chartOptions.series[i].yAxis = j;
            break;
          }
        }
      }
    },
    // update all chart colors
    updateChartColors() {
      for (let i = 0; i < this.chartOptions.series.length; i++) {
        // assign each series a color from color array
        this.chartOptions.series[i].color = this.themeColors[
          i % this.themeColors.length
        ];
        //copy the same color to be displayed in available chart data
        for (let j = 0; j < this.available_chartData.length; j++) {
          if (
            this.available_chartData[j].varName ===
              this.chartOptions.series[i].varName &&
            this.available_chartData[j].node ===
              this.chartOptions.series[i].node
          ) {
            this.available_chartData[j].color = this.chartOptions.series[
              i
            ].color;
            break;
          }
        }
      }
    },
    // load timedata for all currently available datapoints for the new time range
    async dateChanged() {
      let dtstart = new Date(this.startDate);
      let dtend = new Date(this.endDate);
      if (dtend < dtstart) {
        this.dateAlert = true;
        if (this.$store.state.nodes.analysisSession) {
          this.startDate = this.$store.state.nodes.analysisSession.startDate;
          this.endDate = this.$store.state.nodes.analysisSession.endDate;
        }
        return;
      }
      let timezoneoffset = dtstart.getTimezoneOffset();
      let epoch_start = new Date(
        dtstart.getTime() + timezoneoffset * 60000
      ).getTime(); // add the time zone diefference
      let epoch_end = new Date(
        dtend.getTime() + timezoneoffset * 60000
      ).getTime();
      for (let i = 0; i < this.available_chartData.length; i++) {
        let params = {
          name:
            'Analyse_' + this.available_chartData[i].node + '_' + Date.now(),
          node: [this.available_chartData[i].node],
          dtstart: epoch_start,
          dtend: epoch_end,
          tzoffs: 0,
          res: this.selected_res.value,
          vars: [this.available_chartData[i].varName]
        };
        let o = await postSinglePanel(params);
        if (o != null) {
          let res = o.response[0];
          if (res.success) {
            let newData = [];
            for (let j = 0; j < res.rowcnt; j++) {
              newData.push([res.columns[0].data[j], res.columns[1].data[j]]);
            }
            this.available_chartData[i].data = newData;
            if (this.available_chartData[i].addedToChart) {
              for (let k = 0; k < this.chartOptions.series.length; k++) {
                if (
                  this.chartOptions.series[k].node ==
                    this.available_chartData[i].node &&
                  this.chartOptions.series[k].varName ==
                    this.available_chartData[i].varName
                ) {
                  this.chartOptions.series[k].data = newData;
                }
              }
            }
          } else console.log('Request error');
        } else console.log('Request error no data');
      }
    },
    // change chart type for one data series
    changeDiagramType(timedata) {
      this.chartOptions.series.forEach(function(dataseries) {
        if (
          dataseries.node == timedata.node &&
          dataseries.varName == timedata.varName
        ) {
          dataseries.type = timedata.type.type;
        }
      });
    },
    saveThisAnalysis() {
      let idx_analysis = this.saved_analyses.findIndex(
        a => a.name === this.save_analyse_name
      );
      if (idx_analysis >= 0) {
        //there is already a saved analysis with this name
        let overwrite_text =
          this.$root._i18n.t('analyse.analysis_exists') +
          this.save_analyse_name +
          this.$root._i18n.t('analyse.overwrite');
        if (!confirm(overwrite_text)) {
          return;
        } else {
          //delete existing analysis
          this.$store.dispatch('nodes/deleteAnalysis', idx_analysis);
        }
      }
      this.save_analyse_dialog = false;
      this.loadThisAnalysis = this.save_analyse_name;
      let a = {
        startDate: this.startDate,
        endDate: this.endDate,
        res: this.selected_res.value,
        selectedNode: this.selected_node,
        availableChartData: [],
        name: this.save_analyse_name
      };
      for (let i = 0; i < this.available_chartData.length; i++) {
        a.availableChartData.push(
          (({ data, addedToChart, color, ...o }) => o)(
            this.available_chartData[i]
          )
        );
      }
      this.saved_analyses.push(a);
      this.$store.dispatch('nodes/saveAnalysis', a);
      this.$store.dispatch('nodes/updateUserConfig');
    },
    async loadAnalysis(idx) {
      this.savedAnalyseMenu = false;
      this.loadThisAnalysis = this.saved_analyses[idx].name;
      this.save_analyse_name = this.saved_analyses[idx].name;
      this.chartOptions.series = [];
      this.selected_node = this.saved_analyses[idx].selectedNode;
      this.available_chartData = JSON.parse(
        JSON.stringify(this.saved_analyses[idx].availableChartData)
      );
      this.selected_res = this.res_items.filter(
        res_obj => res_obj.value === this.saved_analyses[idx].res
      )[0];
      this.startDate = this.saved_analyses[idx].startDate;
      this.endDate = this.saved_analyses[idx].endDate;
      let dtstart = new Date(this.startDate);
      let dtend = new Date(this.endDate);
      let timezoneoffset = dtstart.getTimezoneOffset();
      let epoch_start = new Date(
        dtstart.getTime() + timezoneoffset * 60000
      ).getTime(); // add the time zone diefference
      let epoch_end = new Date(
        dtend.getTime() + timezoneoffset * 60000
      ).getTime();
      this.selectAll = false;
      this.loadDatapoints();
      for (let i = 0; i < this.available_chartData.length; i++) {
        let params = {
          name:
            'Analyse_' + this.available_chartData[i].node + '_' + Date.now(),
          node: [this.available_chartData[i].node],
          dtstart: epoch_start,
          dtend: epoch_end,
          tzoffs: 0,
          res: this.saved_analyses[idx].res,
          vars: [this.available_chartData[i].varName]
        };
        let o = await postSinglePanel(params);
        if (o != null) {
          let res = o.response[0];
          if (res.success) {
            let newData = [];
            for (let j = 0; j < res.rowcnt; j++) {
              newData.push([res.columns[0].data[j], res.columns[1].data[j]]);
            }
            this.available_chartData[i].data = newData;
            this.available_chartData[i].addedToChart = true;
            this.available_chartData[i].color = null;
            this.available_chartData[i].unit = this.available_chartData[i].unit
              ? this.available_chartData[i].unit
              : res.columns[1].unit;
            //set chart type object again in case language changed
            this.available_chartData[i].type = this.diagramTypes.filter(
              t => t.type === this.available_chartData[i].type.type
            )[0];
            this.selectChartData(i); //add to chart
          }
        }
      }
    },
    deleteSavedAnalysis(index) {
      if (this.loadThisAnalysis == this.saved_analyses[index].name) {
        this.loadThisAnalysis = '';
      }
      this.saved_analyses.splice(index, 1);
      this.$store.dispatch('nodes/deleteAnalysis', index);
      this.$store.dispatch('nodes/updateUserConfig');
    }
  }
};
</script>
<style src="./Analyse.scss" lang="scss"></style>
