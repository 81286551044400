<template>
  <div
    class="item"
    role="button"
    :class="[
      node.cssClass || '',
      expanded ? 'breadcrumbActiv breadcrumbActivText--text' : '',
      activeClass && 'breadcrumbActiv breadcrumbActivText--text'
    ]"
    :style="{
      ...(expanded &&
        theme.breadcrumbActiv && { backgroundColor: theme.breadcrumbActiv })
    }"
    :aria-expanded="node.isLeaf ? undefined : expanded"
    @mousedown="onMouseDown"
    @focus="onFocus"
  >
    <input
      v-if="selectable"
      type="checkbox"
      :checked="selected"
      :disabled="node.selectable === false"
      :aria-label="node.label"
      @change="onSelect"
    />
    <component
      :is="itemComponent"
      :class="[
        'inner-item',
        expanded || activeClass
          ? 'secondColorText--text'
          : 'componentText--text'
      ]"
      :item="node"
      :expanded="expanded"
      @click="onClickNodeText(node)"
    >
      <slot />
    </component>

    <div v-if="(typeof node.nodes !== 'undefined')">
      <div class="v-node--icon">
        <div
          v-if="!expanded"
          @click="plusClick()"
          :class="activeClass ? 'v-node--icon-open' : 'v-node--icon-close'"
        >
          <icon-base
            icon-name=""
            :width="30"
            :height="30"
            :viewBox="`${-3} ${-3} ${30} ${30}`"
          >
            <template>
              <keep-alive>
                <component
                  :strokeColor="
                    $vuetify.theme.isDark
                      ? $vuetify.theme.themes.dark['thirdColorIcon']
                      : $vuetify.theme.themes.light['thirdColorIcon']
                  "
                  :is="IconPlus"
                ></component>
              </keep-alive> </template
          ></icon-base>
        </div>
        <div v-else @click="minusClick()" class="v-node--icon-open">
          <icon-base
            icon-name=""
            :width="30"
            :height="30"
            :viewBox="`${-7} ${-14} ${30} ${30}`"
          >
            <template>
              <keep-alive>
                <component
                  :strokeColor="
                    $vuetify.theme.isDark
                      ? $vuetify.theme.themes.dark['thirdColorIcon']
                      : $vuetify.theme.themes.light['thirdColorIcon']
                  "
                  :is="IconMinus"
                ></component>
              </keep-alive> </template
          ></icon-base>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getBreadcrumbList } from './utils/breadcrumb-list';
import FinderListDropZone from './FinderListDropZone';
import IconBase from '@/components/Icons';
import IconPlus from '@/components/Icons/IconPlus';
import IconMinus from '@/components/Icons/IconMinus';

export default {
  name: 'FinderItem',
  components: {
    IconBase,
    IconPlus,
    IconMinus
  },
  mixins: [FinderListDropZone],
  data() {
    return {
      currentID: '',
      IconPlus: IconPlus,
      IconMinus: IconMinus
    };
  },
  props: {
    selectable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState('nodes', ['nodes', 'isReceiving', 'isUpdating']),
    expanded() {
      return this.treeModel.isNodeExpanded(this.node.id);
    },
    selected() {
      return this.treeModel.isNodeSelected(this.node.id);
    },
    itemComponent() {
      return this.options.itemComponent || 'div';
    },
    arrowComponent() {
      return this.options.arrowComponent;
    },
    activeClass() {
      return this.node.id == this.$store.state.nodes.activeNodeId;
    }
  },
  mounted() {},
  methods: {
    ...mapActions('nodes', ['updateNodesRequest']),
    onMouseDown() {
      this.mousedown = true;

      setTimeout(() => {
        this.mousedown = false;
      }, 100);
    },
    plusClick() {
      let activeGroupId = this.node.group;
      // const activeNodeId = this.node.id;
      if (!activeGroupId) {
        activeGroupId = this.$store.state.nodes.activeGroupId;
      }
      // this.updateNodesRequest({ activeGroupId, activeNodeId });

      this.treeModel.expandNode(this.node.id, 'click');

      this.currentID = this.node.id;
    },
    minusClick() {
      let activeGroupId = this.node.group;
      if (!activeGroupId) {
        activeGroupId = this.$store.state.nodes.activeGroupId;
      }
      this.treeModel.expandNode(this.node.parent, 'click');
      this.currentID = this.node.id;
    },
    async nodesRequest(node, expanded, tree) {
      await this.$store.dispatch('nodes/loadNodeRequest', node, { root: true });

      this.$store.dispatch(
        'nodes/updateBreadcrumbRequest',
        getBreadcrumbList(node, expanded, tree)
      );
      let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
      let tabId = localUserConfig.activeTabId;

      this.$store.dispatch('nodes/updateUserConfig').then(() => {
        this.$store.dispatch('nodes/loadPanelsRequest', {
          tabId: tabId
        });
      });
    },
    onClickNodeText(node) {
      this.nodesRequest(
        node,
        this.treeModel.expanded,
        this.treeModel.visibleTree?.nodes
      );

      this.$options.parent.clickBurger(false);
    },
    onFocus() {
      if (!this.mousedown) {
        this.treeModel.expandNode(this.node.id, 'focus');
      }
    },
    onSelect(event) {
      this.treeModel.selectNode(this.node.id, event.target.checked);
    }
  }
};
</script>

<style src="./FinderItem.scss" lang="scss"></style>
