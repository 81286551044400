<template>
  <v-container fluid class="main-img">
    <div class="content-main my-md-2">
      <div class="row">
        <div class="col-12 ">
          <h2>
            <h2 class="fourthColorText--text">
              {{ $t('sidebar.links.settings') }}
            </h2>
          </h2>
          <div class="row">
            <!-- general settings -->
            <div class="col-md-6">
              <v-card
                class="ma-1 v-sheet chartBackground"
                style="min-height: 296px;"
              >
                <v-card-title class="fourthColorText--text">
                  <v-row class="ma-0 pa-0" justify="space-between">
                    {{ $t('settings.general') }}
                    <v-switch
                      class="ma-0 pa-0"
                      dense
                      hide-details
                      v-model="isOneLineDesign"
                      label="Input Design"
                      v-if="devMode"
                    >
                    </v-switch>
                  </v-row>
                </v-card-title>
                <v-card-text class="componentText--text text-body-1 ml-2">
                  <div v-if="devMode">
                    <div v-if="isOneLineDesign" class="mb-4">
                      <v-row class="ma-0 pa-0">
                        <div class="mt-2">
                          {{ $t('settings.languages') + ':' }}
                        </div>
                        <v-col :cols="4" class="ma-0 pa-0 ml-5">
                          <v-select
                            v-model="locale"
                            :items="availableLocales"
                            dense
                            single-line
                            item-color="componentText"
                            v-on:change="switchLocale(locale)"
                            class="pt-0"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      {{ $t('settings.languages') }}
                      <v-col :cols="4" class="ma-0 pa-0 ml-5">
                        <v-select
                          v-model="locale"
                          :items="availableLocales"
                          single-line
                          item-color="componentText"
                          v-on:change="switchLocale(locale)"
                          class="pt-0"
                        ></v-select>
                      </v-col>
                    </div>
                  </div>
                  {{ $t('settings.themes') }}
                  <ThemeChanger />
                </v-card-text>
              </v-card>
            </div>
            <!-- diagram settings -->
            <div class="col-md-6">
              <v-card
                class="ma-1 v-sheet chartBackground"
                style="min-height: 296px;"
              >
                <v-card-title class="fourthColorText--text">
                  {{ $t('settings.diagrammSettings') }}
                </v-card-title>
                <v-card-text class="componentText--text text-body-1 ml-2">
                  <v-row v-if="isOneLineDesign" class="ma-0 pa-0">
                    <div class="mt-2">
                      {{ $t('settings.period') + ':' }}
                    </div>
                    <v-col :cols="1" class="ma-0 pa-0">
                      <v-text-field
                        dense
                        class="ml-5 pa-0"
                        v-model="timeRangeMonths"
                        reverse
                      ></v-text-field> </v-col
                    ><v-col>{{ $t('settings.month') }}</v-col>
                  </v-row>
                  <div v-else>
                    {{ $t('settings.period') }}
                    <v-row class="mt-1">
                      <v-col :cols="1" class="ma-0 pa-0">
                        <v-text-field
                          class="ml-5 pa-0"
                          v-model="timeRangeMonths"
                          reverse
                        ></v-text-field> </v-col
                      ><v-col>{{ $t('settings.month') }}</v-col></v-row
                    >
                  </div>
                  <v-row
                    class="mt-1"
                    v-if="
                      windowWidth > 1351 ||
                        (960 > windowWidth && windowWidth > 527)
                    "
                  >
                    <v-col
                      :cols="
                        windowWidth > 1800 ? 5 : windowWidth > 1400 ? 6 : 6
                      "
                    >
                      {{ $t('settings.colors') }}
                      <v-row class="ma-0 pa-0">
                        <v-col class="ma-0 pa-0" :cols="6">
                          <v-row
                            v-for="(color, index) in colors"
                            :key="index"
                            align="center"
                            :class="index < 5 ? 'mb-1 ml-2 mt-2' : 'ma-0'"
                          >
                            <div v-if="index < 5">
                              <v-menu
                                top
                                offset-y
                                offset-x
                                style="background-color:#ffffff"
                                :close-on-content-click="false"
                                v-model="isCpOpen[index]"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="color"
                                    fab
                                    x-small
                                    :class="
                                      windowWidth > 1400 ? 'mr-2' : 'mr-1'
                                    "
                                    elevation="2"
                                    @click="setActiveColor(index)"
                                  >
                                  </v-btn>
                                </template>
                                <v-card style="padding: 10px">
                                  <v-color-picker
                                    dot-size="18"
                                    mode="hexa"
                                    class="pa-0"
                                    v-model="colorPicker.activeColor"
                                  ></v-color-picker>
                                  <span
                                    class="text-caption componentText--text"
                                    >{{ $t('chart.lastUsedColors') }}</span
                                  >
                                  <v-row style="margin: 0px 0px 1px">
                                    <div v-for="index in 7" :key="index">
                                      <v-btn
                                        :color="
                                          $store.getters[
                                            'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                          ](index - 1)
                                        "
                                        @click="
                                          colorPicker.activeColor = $store.getters[
                                            'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                          ](index - 1)
                                        "
                                        x-small
                                        style="margin: 0px 0px 0px 5px"
                                        :disabled="
                                          $store.getters[
                                            'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                          ](index - 1) === ''
                                            ? true
                                            : false
                                        "
                                      ></v-btn>
                                    </div>
                                  </v-row>
                                  <v-row
                                    class="mt-2 mb-0 ml-0 mr-0"
                                    justify="space-between"
                                  >
                                    <v-btn
                                      x-small
                                      @click="modifyActiveColor('reset')"
                                      :disabled="
                                        colorPicker.activeColor ==
                                          colorPicker.themeColor
                                      "
                                    >
                                      {{ $t('settings.reset') }}
                                    </v-btn>
                                    <v-btn
                                      x-small
                                      @click="modifyActiveColor('cancel')"
                                      :disabled="
                                        colorPicker.activeColor ==
                                          colorPicker.savedColor
                                      "
                                    >
                                      {{ $t('settings.cancel') }}
                                    </v-btn>
                                  </v-row>
                                </v-card>
                              </v-menu>
                              {{ index + 1 }}. {{ $t('settings.color') }}
                            </div>
                          </v-row>
                        </v-col>
                        <v-col class="ma-0 pa-0" :cols="6">
                          <v-row
                            v-for="(color, index) in colors"
                            :key="index"
                            align="center"
                            :class="index >= 5 ? 'mb-1 ml-2 mt-2' : 'ma-0'"
                          >
                            <div v-if="index >= 5">
                              <v-menu
                                top
                                offset-y
                                offset-x
                                style="background-color:#ffffff"
                                :close-on-content-click="false"
                                v-model="isCpOpen[index]"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    v-bind="attrs"
                                    v-on="on"
                                    :color="color"
                                    fab
                                    x-small
                                    :class="
                                      windowWidth > 1400 ? 'mr-2' : 'mr-1'
                                    "
                                    elevation="2"
                                    @click="setActiveColor(index)"
                                  >
                                  </v-btn>
                                </template>
                                <v-card style="padding: 10px">
                                  <v-color-picker
                                    dot-size="18"
                                    mode="hexa"
                                    class="pa-0"
                                    v-model="colorPicker.activeColor"
                                  ></v-color-picker>
                                  <span
                                    class="text-caption componentText--text"
                                    >{{ $t('chart.lastUsedColors') }}</span
                                  >
                                  <v-row style="margin: 0px 0px 1px">
                                    <div v-for="index in 7" :key="index">
                                      <v-btn
                                        :color="
                                          $store.getters[
                                            'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                          ](index - 1)
                                        "
                                        @click="
                                          colorPicker.activeColor = $store.getters[
                                            'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                          ](index - 1)
                                        "
                                        x-small
                                        style="margin: 0px 0px 0px 5px"
                                        :disabled="
                                          $store.getters[
                                            'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                          ](index - 1) === ''
                                            ? true
                                            : false
                                        "
                                      ></v-btn>
                                    </div>
                                  </v-row>
                                  <v-row
                                    class="mt-2 mb-0 ml-0 mr-0"
                                    justify="space-between"
                                  >
                                    <v-btn
                                      x-small
                                      @click="modifyActiveColor('reset')"
                                      :disabled="
                                        colorPicker.activeColor ==
                                          colorPicker.themeColor
                                      "
                                    >
                                      {{ $t('settings.reset') }}
                                    </v-btn>
                                    <v-btn
                                      x-small
                                      @click="modifyActiveColor('cancel')"
                                      :disabled="
                                        colorPicker.activeColor ==
                                          colorPicker.savedColor
                                      "
                                    >
                                      {{ $t('settings.cancel') }}
                                    </v-btn>
                                  </v-row>
                                </v-card>
                              </v-menu>
                              {{ index + 1 }}. {{ $t('settings.color') }}
                            </div>
                            <!-- <div
                              v-if="
                                colors.length < 10 && index == colors.length % 5
                              "
                            >
                              <v-btn
                                x-small
                                outlined
                                fab
                                elevation="2"
                                @click="addColor()"
                                class="mr-2"
                              >
                                <v-icon>
                                  mdi-plus
                                </v-icon>
                              </v-btn>
                              Farbe hinzufügen
                            </div> -->
                          </v-row>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col
                      :cols="
                        windowWidth > 1800 ? 6 : windowWidth > 1400 ? 5 : 6
                      "
                    >
                      {{ $t('settings.diagramExample') }}
                      <v-row>
                        <highcharts
                          :options="highchartsOptions"
                          ref="chart"
                          style="position: relative;"
                        ></highcharts>
                      </v-row>
                      <v-row class="ma-0 pa-0">
                        <v-btn
                          @click="resetColors()"
                          small
                          :disabeld="false"
                          style="left: 3.8vh;position: relative;"
                        >
                          {{ $t('settings.reset') }}
                        </v-btn>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row
                    class="ml-2 mt-2 mb-2"
                    v-if="
                      windowWidth <= 527 ||
                        (960 <= windowWidth && windowWidth <= 1351)
                    "
                  >
                    {{ $t('settings.colors') }}
                    <v-row class="mt-2 mb-1 ml-0">
                      <v-col
                        v-for="(color, index) in colors"
                        :key="index"
                        class="mb-3 ml-0 mr-0 "
                        :cols="windowWidth > 1118 ? 4 : 6"
                      >
                        <v-row align="center" class="mr-0">
                          <v-menu
                            top
                            offset-y
                            offset-x
                            style="background-color:#ffffff"
                            :close-on-content-click="false"
                            v-model="isCpOpen[getIndex(index)]"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :color="colors[getIndex(index)]"
                                fab
                                x-small
                                class="mr-1"
                                elevation="2"
                                @click="setActiveColor(getIndex(index))"
                              >
                              </v-btn>
                            </template>
                            <v-card style="padding: 10px">
                              <v-color-picker
                                dot-size="18"
                                mode="hexa"
                                class="pa-0"
                                v-model="colorPicker.activeColor"
                              ></v-color-picker>
                              <span class="text-caption componentText--text">{{
                                $t('chart.lastUsedColors')
                              }}</span>
                              <v-row style="margin: 0px 0px 1px">
                                <div v-for="index in 7" :key="index">
                                  <v-btn
                                    :color="
                                      $store.getters[
                                        'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                      ](index - 1)
                                    "
                                    @click="
                                      colorPicker.activeColor = $store.getters[
                                        'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                      ](index - 1)
                                    "
                                    x-small
                                    style="margin: 0px 0px 0px 5px"
                                    :disabled="
                                      $store.getters[
                                        'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                      ](index - 1) === ''
                                        ? true
                                        : false
                                    "
                                  ></v-btn>
                                </div>
                              </v-row>
                              <v-row
                                class="mt-2 mb-0 ml-0 mr-0"
                                justify="space-between"
                              >
                                <v-btn
                                  x-small
                                  @click="modifyActiveColor('reset')"
                                  :disabled="
                                    colorPicker.activeColor ==
                                      colorPicker.themeColor
                                  "
                                >
                                  {{ $t('settings.reset') }}
                                </v-btn>
                                <v-btn
                                  x-small
                                  @click="modifyActiveColor('cancel')"
                                  :disabled="
                                    colorPicker.activeColor ==
                                      colorPicker.savedColor
                                  "
                                >
                                  {{ $t('settings.cancel') }}
                                </v-btn>
                              </v-row>
                            </v-card>
                          </v-menu>
                          {{ getIndex(index) + 1 }}. {{ $t('settings.color') }}
                        </v-row>
                      </v-col>
                      <v-btn @click="resetColors()" small :disabled="false">
                        {{ $t('settings.reset') }}
                      </v-btn>
                    </v-row>
                    <v-col :cols="12">
                      <v-row>
                        Beispieldiagram
                      </v-row>
                      <v-row>
                        <highcharts
                          :options="highchartsOptions"
                          ref="chart"
                          style="position: relative;"
                        ></highcharts>
                      </v-row>
                    </v-col>
                  </v-row>
                  <!-- following 2 v-row's as backup (disabeld via v-if) -->
                  <v-row
                    class="ml-2 mt-2"
                    v-if="
                      false &&
                        (windowWidth > 1180 ||
                          (960 > windowWidth && windowWidth > 460))
                    "
                  >
                    <v-col :cols="4" class="mt-1">
                      <v-row
                        v-for="(color, index) in colors"
                        :key="index"
                        align="center"
                        class="mb-3 ml-0"
                      >
                        <v-menu
                          top
                          offset-y
                          offset-x
                          style="background-color:#ffffff"
                          :close-on-content-click="false"
                          v-model="isCpOpen[index]"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              :color="color"
                              fab
                              x-small
                              class="mr-2"
                              elevation="2"
                              @click="setActiveColor(index)"
                            >
                            </v-btn>
                          </template>
                          <v-card style="padding: 10px">
                            <v-color-picker
                              dot-size="18"
                              mode="hexa"
                              class="pa-0"
                              v-model="colorPicker.activeColor"
                            ></v-color-picker>
                            <span class="text-caption componentText--text">{{
                              $t('chart.lastUsedColors')
                            }}</span>
                            <v-row style="margin: 0px 0px 1px">
                              <div v-for="index in 7" :key="index">
                                <v-btn
                                  :color="
                                    $store.getters[
                                      'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                    ](index - 1)
                                  "
                                  @click="
                                    colorPicker.activeColor = $store.getters[
                                      'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                    ](index - 1)
                                  "
                                  x-small
                                  style="margin: 0px 0px 0px 5px"
                                  :disabled="
                                    $store.getters[
                                      'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                    ](index - 1) === ''
                                      ? true
                                      : false
                                  "
                                ></v-btn>
                              </div>
                            </v-row>
                            <v-row
                              class="mt-2 mb-0 ml-0 mr-0"
                              justify="space-between"
                            >
                              <v-btn
                                x-small
                                @click="modifyActiveColor('reset')"
                                :disabled="
                                  colorPicker.activeColor ==
                                    colorPicker.themeColor
                                "
                              >
                                {{ $t('settings.reset') }}
                              </v-btn>
                              <v-btn
                                x-small
                                @click="modifyActiveColor('cancel')"
                                :disabled="
                                  colorPicker.activeColor ==
                                    colorPicker.savedColor
                                "
                              >
                                {{ $t('settings.cancel') }}
                              </v-btn>
                            </v-row>
                          </v-card>
                        </v-menu>
                        {{ index + 1 }}. {{ $t('settings.color') }}
                      </v-row>
                      <v-btn @click="resetColors()" small :disabeld="false">
                        {{ $t('settings.reset') }}
                      </v-btn>
                    </v-col>
                    <v-col :cols="8">
                      <v-row> {{ $t('settings.diagramExample') }}</v-row>
                      <v-row>
                        <highcharts
                          :options="highchartsOptions"
                          ref="chart"
                          style="position: relative;"
                        ></highcharts>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-row
                    class="ml-2 mt-2 mb-2"
                    v-if="
                      false &&
                        (windowWidth <= 460 ||
                          (960 <= windowWidth && windowWidth <= 1351))
                    "
                  >
                    <v-row class="mt-2 mb-1 ml-0">
                      <v-col
                        v-for="(color, index) in colors"
                        :key="index"
                        class="mb-3 ml-0 mr-0 "
                        :cols="4"
                      >
                        <v-row align="center" class="mr-0">
                          <v-menu
                            top
                            offset-y
                            offset-x
                            style="background-color:#ffffff"
                            :close-on-content-click="false"
                            v-model="isCpOpen[index]"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                v-on="on"
                                :color="color"
                                fab
                                small
                                class="mr-1"
                                elevation="2"
                                @click="setActiveColor(index)"
                              >
                              </v-btn>
                            </template>
                            <v-card style="padding: 10px">
                              <v-color-picker
                                dot-size="18"
                                mode="hexa"
                                class="pa-0"
                                v-model="colorPicker.activeColor"
                              ></v-color-picker>
                              <span class="text-caption componentText--text">{{
                                $t('chart.lastUsedColors')
                              }}</span>
                              <v-row style="margin: 0px 0px 1px">
                                <div v-for="index in 7" :key="index">
                                  <v-btn
                                    :color="
                                      $store.getters[
                                        'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                      ](index - 1)
                                    "
                                    @click="
                                      colorPicker.activeColor = $store.getters[
                                        'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                      ](index - 1)
                                    "
                                    x-small
                                    style="margin: 0px 0px 0px 5px"
                                    :disabled="
                                      $store.getters[
                                        'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                      ](index - 1) === ''
                                        ? true
                                        : false
                                    "
                                  ></v-btn>
                                </div>
                              </v-row>
                              <v-row
                                class="mt-2 mb-0 ml-0 mr-0"
                                justify="space-between"
                              >
                                <v-btn
                                  x-small
                                  @click="modifyActiveColor('reset')"
                                  :disabled="
                                    colorPicker.activeColor ==
                                      colorPicker.themeColor
                                  "
                                >
                                  {{ $t('settings.reset') }}
                                </v-btn>
                                <v-btn
                                  x-small
                                  @click="modifyActiveColor('cancel')"
                                  :disabled="
                                    colorPicker.activeColor ==
                                      colorPicker.savedColor
                                  "
                                >
                                  {{ $t('settings.cancel') }}
                                </v-btn>
                              </v-row>
                            </v-card>
                          </v-menu>
                          {{ index + 1 }}. {{ $t('settings.color') }}
                        </v-row>
                      </v-col>
                      <v-btn @click="resetColors()" small :disabled="false">
                        {{ $t('settings.reset') }}
                      </v-btn>
                    </v-row>
                    <v-col :cols="12">
                      <v-row>
                        Beispieldiagram
                      </v-row>
                      <v-row>
                        <highcharts
                          :options="highchartsOptions"
                          ref="chart"
                          style="position: relative;"
                        ></highcharts>
                      </v-row>
                    </v-col>
                  </v-row>
                  <v-btn class="mt-8" @click="saveNewValue()">
                    {{ $t('settings.save') }}
                  </v-btn>
                </v-card-text>
              </v-card>
            </div>
          </div>
          <div class="row">
            <!-- für weiteres styling siehe: https://codepen.io/pen/?&editors=101 => link führt in ein leeres codepen -->
            <!-- password settings -->
            <div class="col-md-6">
              <v-card
                class="ma-1 v-sheet chartBackground"
                style="min-height: 296px;"
              >
                <v-card-title class="fourthColorText--text">
                  {{ $t('settings.changePw') }}
                </v-card-title>
                <v-card-text class="componentText--text text-body-1 ml-2">
                  <div v-if="isOneLineDesign">
                    <v-row class="ma-0 pa-0">
                      <div class="mt-2">
                        {{ $t('settings.password') + ':' }}
                      </div>
                      <v-col :cols="7" class="ma-0 mr-4 pa-0">
                        <v-text-field
                          dense
                          class="ml-5 pa-0"
                          v-model="password"
                          required
                          :type="show1 ? 'text' : 'password'"
                          :append-icon="
                            password !== ''
                              ? show1
                                ? 'mdi-eye'
                                : 'mdi-eye-off'
                              : ''
                          "
                          @click:append="show1 = !show1"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <div class="mt-2">
                        {{ $t('settings.newPassword') + ':' }}
                      </div>
                      <v-col :cols="7" class="ma-0 mr-4 pa-0">
                        <v-text-field
                          dense
                          class="ml-5 pa-0"
                          v-model="newPassword"
                          required
                          :type="show2 ? 'text' : 'password'"
                          :append-icon="
                            newPassword !== ''
                              ? show2
                                ? 'mdi-eye'
                                : 'mdi-eye-off'
                              : ''
                          "
                          @click:append="show2 = !show2"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row class="ma-0 pa-0">
                      <div class="mt-2">
                        {{ $t('settings.confirmPassword') + ':' }}
                      </div>
                      <v-col :cols="7" class="ma-0 mr-4 pa-0">
                        <v-text-field
                          dense
                          class="ml-5 pa-0 mb-0"
                          v-model="confirmPassword"
                          required
                          :type="show3 ? 'text' : 'password'"
                          @keyup.enter="requestPasswordChange"
                          :append-icon="
                            confirmPassword !== ''
                              ? show3
                                ? 'mdi-eye'
                                : 'mdi-eye-off'
                              : ''
                          "
                          @click:append="show3 = !show3"
                          hide-details
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </div>
                  <div v-else>
                    {{ $t('settings.password') }}
                    <v-col :cols="8" class="ma-0 pa-0">
                      <v-text-field
                        class="ml-5 pa-0"
                        v-model="password"
                        required
                        :type="show1 ? 'text' : 'password'"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1"
                      ></v-text-field>
                    </v-col>
                    {{ $t('settings.newPassword') }}
                    <v-col :cols="8" class="ma-0 pa-0">
                      <v-text-field
                        class="ml-5 pa-0"
                        v-model="newPassword"
                        required
                        :type="show2 ? 'text' : 'password'"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show2 = !show2"
                      ></v-text-field>
                    </v-col>
                    {{ $t('settings.confirmPassword') }}
                    <v-col :cols="8" class="ma-0 pa-0">
                      <v-text-field
                        class="ml-5 pa-0 mb-0"
                        v-model="confirmPassword"
                        required
                        :type="show3 ? 'text' : 'password'"
                        @keyup.enter="requestPasswordChange"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show3 = !show3"
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </div>
                  <v-dialog v-model="dialog" width="400">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="mt-8"
                        @click="requestPasswordChange"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ $t('settings.save') }}
                      </v-btn>
                    </template>
                    <v-card>
                      <v-card-title>
                        {{ $t('settings.changePw') }}
                      </v-card-title>
                      <v-divider></v-divider>
                      <div v-if="passwordTooShort">
                        <v-card-text>
                          {{ $t('settings.password_too_short') }}
                        </v-card-text>
                      </div>
                      <div v-if="passwordsNotEqual">
                        <v-card-text>
                          {{ $t('settings.passwords_not_equal') }}
                        </v-card-text>
                      </div>
                      <div v-if="passwordInvalidInput">
                        <v-card-text>
                          {{ $t('settings.password_invalid_input') }}
                        </v-card-text>
                      </div>
                      <div v-if="passwordError">
                        <v-card-text>
                          {{ $t('settings.password_error') }}
                        </v-card-text>
                      </div>
                      <div v-if="passwordChangeSuccess">
                        <v-card-text>
                          {{ $t('settings.password_changed') }}
                        </v-card-text>
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary" text @click="dialog = false">
                          Ok
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card-text>
              </v-card>
              <!-- old password settings -->
              <!-- <div
                class="ma-1 v-card v-sheet chartBackground"
                style="min-height: 296px;"
              >
                <div class="v-card__title pa-5 pb-3">
                  <p class="fourthColorText--text">
                    {{ $t('settings.changePw') }}
                  </p>
                  <div class="spacer"></div>
                </div>
                <v-text-field
                  class="pwText"
                  v-model="password"
                  :label="$t('settings.password')"
                  required
                  :type="show1 ? 'text' : 'password'"
                  :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show1 = !show1"
                ></v-text-field>
                <v-text-field
                  class="pwText"
                  v-model="newPassword"
                  :label="$t('settings.newPassword')"
                  required
                  :type="show2 ? 'text' : 'password'"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show2 = !show2"
                ></v-text-field>
                <v-text-field
                  class="pwText"
                  v-model="confirmPassword"
                  :label="$t('settings.confirmPassword')"
                  required
                  :type="show3 ? 'text' : 'password'"
                  @keyup.enter="requestPasswordChange"
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  @click:append="show3 = !show3"
                ></v-text-field>
                <v-dialog v-model="dialog" width="400">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="pwSave"
                      @click="requestPasswordChange"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ $t('settings.save') }}
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title> {{ $t('settings.changePw') }} </v-card-title>
                    <v-divider></v-divider>
                    <div v-if="passwordTooShort">
                      <v-card-text>
                        {{ $t('settings.password_too_short') }}
                      </v-card-text>
                    </div>
                    <div v-if="passwordsNotEqual">
                      <v-card-text>
                        {{ $t('settings.passwords_not_equal') }}
                      </v-card-text>
                    </div>
                    <div v-if="passwordInvalidInput">
                      <v-card-text>
                        {{ $t('settings.password_invalid_input') }}
                      </v-card-text>
                    </div>
                    <div v-if="passwordError">
                      <v-card-text>
                        {{ $t('settings.password_error') }}
                      </v-card-text>
                    </div>
                    <div v-if="passwordChangeSuccess">
                      <v-card-text>
                        {{ $t('settings.password_changed') }}
                      </v-card-text>
                    </div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="primary" text @click="dialog = false">
                        Ok
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div> -->
            </div>
            <!-- old diagram settings -->
            <!-- <div class="col-md-6">
              <div
                class="ma-1 v-card v-sheet chartBackground"
                style="min-height: 296px;"
              >
                <div class="v-card__title pa-5 pb-3">
                  <p class="fourthColorText--text">
                    {{ $t('settings.diagrammSettings') }}
                  </p>
                  <div class="spacer"></div>
                </div>
                <v-row>
                  <v-col :cols="3">
                    <v-text-field
                      class="timeRangeText"
                      v-model="timeRangeMonths"
                      :label="$t('settings.month')"
                    ></v-text-field>
                  </v-col>
                  <p style="margin-top: 35px" class="componentText--text">
                    {{ $t('settings.period') }}
                  </p>
                </v-row>
                <p style="margin-top: 35px" class="componentText--text ml-6">
                  {{ $t('settings.colors') }}
                </p>
                <v-row class="ml-2">
                  <v-col :cols="6">
                    <ColorPicker
                      :colors="colors"
                      :updateColors="updateColors"
                      v-on:updateColor="updateColor"
                    />
                    <v-btn @click="deleteChartColor()">
                      {{ $t('settings.reset') }}
                    </v-btn>
                  </v-col>
                  <v-col :cols="6" ref="highchartsWidth">
                    <div class="setting__chart">
                      <highcharts
                        :options="highchartsOptions"
                        ref="chart"
                        :class="
                          $vuetify.theme.isDark ? 'dark-theme' : 'light-theme'
                        "
                      ></highcharts>
                    </div>
                  </v-col>
                </v-row>

                <v-btn class="timeRangeSave" @click="saveNewValue()">
                  {{ $t('settings.save') }}
                </v-btn>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <FooterComp />
    </div>
  </v-container>
</template>

<script>
import ThemeChanger from '@/components/ThemeChanger/ThemeChanger';
// import LanguagePicker from '@/components/LanguagePicker';
// import ColorPicker from '@/components/ColorPicker/ColorPicker';
import { changePassword } from '../../api/services';
import config from '../../config/index';
import FooterComp from '@/components/Footer';

export default {
  name: 'SettingsPage',
  components: {
    ThemeChanger,
    FooterComp
    // LanguagePicker
    // ColorPicker
  },
  data() {
    const locale = this.$root.$i18n.locale;
    const availableLocales = this.$root.$i18n.availableLocales;

    return {
      locale,
      availableLocales,
      windowWidth: window.innerWidth,
      timeRangeMonths: 0,
      show1: false,
      show2: false,
      show3: false,
      password: '',
      newPassword: '',
      confirmPassword: '',
      passwordChangeSuccess: false,
      passwordTooShort: false,
      passwordsNotEqual: false,
      passwordInvalidInput: false,
      passwordError: false,
      dialog: false,
      devMode: false,
      colors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ],
      colorPicker: {
        activeColor: '',
        activeIndex: null,
        savedColor: '',
        themeColor: ''
      },
      isCpOpen: [false, false, false, false, false],
      themes: config.themes,
      themeColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ],
      // updateColors: false,
      highchartsOptions: {},
      isOneLineDesign: false
    };
  },
  beforeMount() {
    this.timeRangeMonths = this.$store.state.nodes.defaultTime
      ? this.$store.state.nodes.defaultTime
      : 1;
    this.devMode = this.$store.state.layout.dev;
    this.highchartsOptions = {
      credits: { enabled: false },
      exporting: { enabled: false },
      chart: {
        type: 'pie',
        height: 230,
        width: 230,
        backgroundColor: 'rgba(0,0,0,0)'
      },
      title: { text: null },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          }
        }
      },
      series: [
        {
          data: [
            {
              y: 1,
              name: 'test1',
              color: '#02858D',
              sliced: false
            },
            {
              y: 1,
              name: 'test2',
              color: '#8BB059',
              sliced: false
            },
            {
              y: 1,
              name: 'test3',
              color: '#C4B735',
              sliced: false
            },
            {
              y: 1,
              name: 'test4',
              color: '#CEA125',
              sliced: false
            },
            {
              y: 1,
              name: 'test5',
              color: '#215F75',
              sliced: false
            },
            {
              y: 1,
              name: 'test6',
              color: '#215F75',
              sliced: false
            },
            {
              y: 1,
              name: 'test7',
              color: '#215F75',
              sliced: false
            },
            {
              y: 1,
              name: 'test8',
              color: '#215F75',
              sliced: false
            },
            {
              y: 1,
              name: 'test9',
              color: '#215F75',
              sliced: false
            },
            {
              y: 1,
              name: 'test10',
              color: '#215F75',
              sliced: false
            }
          ]
        }
      ],
      xAxis: {
        labels: { style: { color: '#333333' } },
        maxPadding: 0,
        minPadding: 0,
        title: null,
        visible: true,
        categories: ['1', '2']
      },
      yAxis: [
        {
          labels: { style: { color: '#333333' } },
          title: {
            style: { color: '#333333', fontWeight: 'bold' },
            text: null
          }
        }
      ]
    };
  },
  mounted() {
    let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
    if (localUserConfig.theme.isDark) {
      this.themeColors = JSON.parse(
        JSON.stringify(
          this.themes.find(i => i.name == localUserConfig.theme.name).dark
            .chartColors
        )
      );
    } else {
      this.themeColors = JSON.parse(
        JSON.stringify(
          this.themes.find(i => i.name == localUserConfig.theme.name).light
            .chartColors
        )
      );
    }
    if (typeof this.$store.state.layout.theme.chartColors != 'undefined') {
      this.colors = this.$store.state.layout.theme.chartColors;
    } else {
      this.colors = this.themeColors;
    }
    this.updateColors = !this.updateColors;
    for (let index in this.colors) {
      // highcharts muss noch angepasst werden auf die 10 Datenspuren #####################################################
      this.highchartsOptions.series[0].data[index].color = this.colors[index];
      if (typeof this.$refs.chart != 'undefined') {
        this.$refs.chart.options.series[0].data[index].color = this.colors[
          index
        ];
      }
    }
  },
  watch: {
    isCpOpen: {
      handler: function(newValue, oldValue) {
        if (!newValue.includes(true)) {
          this.saveAndResetColorPicker();
        }
      },
      deep: true
    },
    colorPicker: {
      handler: function(newValue, oldValue) {
        if (newValue.activeIndex != null) {
          // console.log(newValue.activeColor);
          this.highchartsOptions.series[0].data[newValue.activeIndex].color =
            newValue.activeColor;
        }
      },
      deep: true
    }
  },
  methods: {
    switchLocale(locale) {
      if (this.$root.$i18n.locale !== locale) {
        this.$root.$i18n.locale = locale;
        this.$store.dispatch('layout/setLanguage', locale);
      }
    },
    async requestPasswordChange() {
      this.dialog = true;
      this.passwordChangeSuccess = false;
      this.passwordTooShort = false;
      this.passwordsNotEqual = false;
      this.passwordInvalidInput = false;
      this.passwordError = false;
      if (
        this.newPassword.length == 0 ||
        this.password.length == 0 ||
        this.confirmPassword.length == 0
      ) {
        this.passwordInvalidInput = true;
        return;
      }
      if (this.newPassword.length < 8) {
        //confirm('Passwort zu kurz');
        this.passwordTooShort = true;
        return;
      }
      if (this.newPassword != this.confirmPassword) {
        //confirm('Ungleiches Passwort');
        this.passwordsNotEqual = true;
        return;
      }
      let params = {
        username: this.$store.state.auth.user.login,
        oldPassword: this.password,
        newPassword: this.newPassword
      };
      let response = await changePassword(params);
      this.passwordChangeSuccess = response.data.success;
      if (!this.passwordChangeSuccess) {
        this.passwordError = true;
        return;
      }
      this.password = '';
      this.newPassword = '';
      this.confirmPassword = '';
    },
    saveNewValue() {
      let m = parseInt(this.timeRangeMonths);
      if (typeof m == 'number' && m > 0) {
        this.$store.dispatch('nodes/saveDefaultTimerangeRequest', m);
      }
      this.$store.dispatch('layout/requestChartColor', this.colors);
    },
    resetColors() {
      this.colors = this.themeColors;
      for (let index in this.colors) {
        this.$refs.chart.options.series[0].data[index].color = this.colors[
          index
        ];
      }
    },
    saveAndResetColorPicker() {
      if (this.colorPicker.activeColor != this.colorPicker.savedColor) {
        this.$store.dispatch(
          'layout/addLastUsedColors',
          this.colorPicker.activeColor
        );
        this.colors[
          this.colorPicker.activeIndex
        ] = this.colorPicker.activeColor;
        this.highchartsOptions.series[0].data[
          this.colorPicker.activeIndex
        ].color = this.colorPicker.activeColor;
      }
      this.highchartsOptions.series[0].data[
        this.colorPicker.activeIndex
      ].sliced = false;
      this.colorPicker.activeColor = '';
      this.colorPicker.activeIndex = null;
      this.colorPicker.savedColor = '';
      this.colorPicker.themeColor = '';
    },
    setActiveColor(index) {
      if (this.colorPicker.activeIndex != null) this.saveAndResetColorPicker();
      this.colorPicker.activeColor = this.colors[index];
      this.colorPicker.activeIndex = index;
      this.colorPicker.savedColor = (' ' + this.colorPicker.activeColor).slice(
        1
      );
      this.colorPicker.themeColor = this.themeColors[index];
      this.highchartsOptions.series[0].data[index].sliced = true;
    },
    modifyActiveColor(type) {
      if (type == 'reset') {
        let themeColor = this.themeColors[this.colorPicker.activeIndex];
        this.colorPicker.activeColor = themeColor;
        this.colors[this.colorPicker.activeIndex] = themeColor;
        this.highchartsOptions.series[0].data[
          this.colorPicker.activeIndex
        ].color = themeColor;
      } else if (type == 'cancel') {
        this.colorPicker.activeColor = this.colorPicker.savedColor;
        this.colors[this.colorPicker.activeIndex] = this.colorPicker.savedColor;
        this.isCpOpen[this.colorPicker.activeIndex] = false;
        console.log(this.isCpOpen);
      }
    },
    addColor() {
      this.colors.push('#ff0000');
    },
    getIndex(index) {
      if (this.windowWidth > 1118)
        switch (index % 3) {
          case 0:
            return index / 3 + 0;
          case 1:
            return (index - 1) / 3 + 4;
          case 2:
            return (index - 2) / 3 + 7;
        }
      else
        switch (index % 2) {
          case 0:
            return index / 2;
          case 1:
            return (index - 1) / 2 + 5;
        }
    },
    handleResize: function() {
      this.windowWidth = window.innerWidth;
    }
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.addEventListener('resize', this.handleResize);
  }
};
</script>

<style lang="scss" scoped>
.content-main {
  padding-top: 70px !important;
}
.main-img {
  background-image: url('../../assets/GSP_Kacheln_background.svg');
  background-size: cover;
  min-height: 100%;
}

.v-card {
  height: 100%;
}

.setting__chart {
  position: relative;
  display: block;
}
</style>
