<template>
  <g>
    <path
      d="M17.6747 13.2417C17.1445 14.4954 16.3153 15.6002 15.2596 16.4594C14.2038 17.3187 12.9537 17.9062 11.6184 18.1707C10.2831 18.4351 8.90337 18.3685 7.59981 17.9765C6.29624 17.5845 5.10854 16.8792 4.14053 15.9222C3.17253 14.9652 2.4537 13.7856 2.04688 12.4866C1.64006 11.1876 1.55765 9.80874 1.80685 8.47053C2.05605 7.13232 2.62926 5.87553 3.47639 4.81003C4.32351 3.74453 5.41875 2.90277 6.66635 2.35834"
      :stroke="strokeColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M18.3333 10C18.3333 8.90567 18.1178 7.82204 17.699 6.81099C17.2802 5.79994 16.6664 4.88129 15.8926 4.10746C15.1187 3.33364 14.2001 2.71981 13.189 2.30102C12.178 1.88224 11.0943 1.66669 10 1.66669V10H18.3333Z"
      :stroke="strokeColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script>
export default {
  props: {
    strokeColor: {
      type: String,
      default: '#143857',
      required: false
    }
  }
};
</script>
