<template>
  <!--<div v-if="Object.entries(chartData.chartData).length === 0">
    <p>Content not found</p>
  </div>-->

  <div class="v-chart">
    <!-- header -->
    <v-hover v-slot:default="{ hover }">
      <div
        :class="
          `${
            hover || showChartMenu
              ? 'v-chart__header secondColor'
              : 'v-chart__header'
          }`
        "
      >
        <!-- title -->
        <div class="d-flex align-center justify-space-between">
          <v-hover v-slot="{ hover }">
            <div
              v-if="!showTitleEdit"
              class="d-inline-flex"
              @dblclick="
                () => {
                  showTitleEdit = true;
                  editedTitle = title;
                }
              "
            >
              <h3 class="px-1 componentText--text">
                {{ title ? title : $t('chart.noTitle') }}
              </h3>
              <div
                v-if="hover"
                class="ml-1 pointer"
                @click="
                  () => {
                    showTitleEdit = true;
                    editedTitle = title;
                  }
                "
              >
                <icon-base
                  :icon-name="$t('chart.edit')"
                  :width="18"
                  :height="21"
                  :viewBox="`${0} ${0} ${25} ${20}`"
                >
                  <template>
                    <keep-alive>
                      <component
                        :strokeColor="
                          $vuetify.theme.isDark
                            ? $vuetify.theme.themes.dark['thirdColorIcon']
                            : $vuetify.theme.themes.light['thirdColorIcon']
                        "
                        :is="iconEdit"
                      ></component>
                    </keep-alive>
                  </template>
                </icon-base>
              </div>
            </div>
            <div v-else class="d-flex">
              <v-text-field
                v-model="editedTitle"
                v-on:blur="
                  editTitle();
                  $emit('update');
                "
                @keyup.enter="
                  editTitle();
                  $emit('update');
                "
                autofocus
              ></v-text-field>
            </div>
          </v-hover>
          <div class="dragArea justify-center" style="display: flex">
            <v-hover v-slot:default="{ hover }"
              ><v-btn icon class="custom dragArea">
                <v-icon
                  color="greyTint"
                  :class="
                    `${
                      hover
                        ? 'thirdColorText--text active dragArea'
                        : 'dragArea'
                    }`
                  "
                >
                  mdi-cursor-move
                </v-icon>
              </v-btn>
            </v-hover>
          </div>
        </div>

        <div class="d-flex align-center justify-space-between">
          <!-- data menu (time and resolution) -->
          <v-menu
            class="custom "
            v-model="showDateMenu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            :max-width="!$vuetify.breakpoint.mdAndDown ? '600px' : '100%'"
            :min-height="!$vuetify.breakpoint.mdAndDown ? '300px' : '100%'"
            :fullscreen="$vuetify.breakpoint.mdAndDown"
          >
            <template v-slot:activator="{ on }">
              <div
                class="
                  v-chart__calendar
                  d-flex
                  align-center
                  justify-space-between
                "
              >
                <v-hover v-slot:default="{ hover }">
                  <div
                    :class="
                      `${
                        hover
                          ? 'd-flex align-center py-1 px-1'
                          : 'd-flex align-center py-1 px-1'
                      }`
                    "
                  >
                    <div class="mr-2 d-flex align-center justify-center">
                      <icon-base icon-name="" :width="25" :height="19">
                        <template slot-scope="{ strokeColor }">
                          <IconCalendar :strokeColor="strokeColor" /> </template
                      ></icon-base>
                    </div>
                    <div
                      v-on="on"
                      :class="`${hover ? 'thirdColorText--text' : ''}`"
                    >
                      <span class="componentText--text">
                        {{ fromDateDisp }} - {{ toDateDisp }}</span
                      >
                    </div>
                  </div>
                </v-hover>
              </div>
            </template>

            <v-card v-if="$vuetify.breakpoint.mdAndUp">
              <v-card-text>
                <!-- data resolution -->
                <v-row>
                  <v-col :cols="6" align-self="center">
                    <v-row
                      justify="center"
                      class="font-weight-bold componentText--text"
                      style="font-size:1rem"
                      >{{ $t('chart.data-resolution') }}:</v-row
                    >
                  </v-col>
                  <v-col :cols="6">
                    <v-select
                      :items="$t(timeResolution)"
                      :disabled="
                        diagramType == 'pie' || diagramType == 'area'
                          ? true
                          : false
                      "
                      item-text="text"
                      item-value="value"
                      v-model="datResPicker"
                      dense
                      solo
                      hide-details
                    ></v-select>
                  </v-col>
                </v-row>
                <!-- calender -->
                <v-row>
                  <v-tabs fixed-tabs v-model="dateTab">
                    <v-tab
                      v-for="(item, index) in [
                        'absoluter Zeitraum',
                        'relativer Zeitraum'
                      ]"
                      :key="index"
                      class="font-weight-bold componentText--text"
                      style="
                        font-size: 1rem;
                        letter-spacing: 0;
                        text-transform: inherit;
                      "
                    >
                      {{ item }}
                    </v-tab>
                  </v-tabs>
                </v-row>
                <v-row v-if="dateTab == 0">
                  <v-col :cols="6">
                    <!-- from -->
                    <v-row justify="center">
                      <v-date-picker
                        class="custom"
                        v-model="fromDateVal"
                        first-day-of-week="1"
                        :locale="locale"
                        no-title
                      ></v-date-picker>
                    </v-row>
                  </v-col>
                  <v-col :cols="6">
                    <!-- to -->
                    <v-row justify="center">
                      <v-date-picker
                        class="custom"
                        v-model="toDateVal"
                        first-day-of-week="1"
                        :locale="locale"
                        no-title
                      ></v-date-picker>
                    </v-row>
                  </v-col>
                </v-row>
                <v-row v-if="dateTab == 1">
                  <v-col :cols="6">
                    <v-text-field
                      type="number"
                      label="Anzahl"
                      v-model="relativeDate.count"
                      :rules="[
                        v => !!v || 'Feld darf nicht leer sein',
                        v => (v && v >= 1) || 'Wert muss mindestens 1 betragen'
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col :cols="6">
                    <v-select
                      :items="$t('chart.relative-time')"
                      item-text="text"
                      item-value="value"
                      v-model="relativeDate.timespan"
                      label="Zeitraum"
                    ></v-select>
                  </v-col>
                </v-row>
                <!-- submit buttons -->
                <v-row>
                  <v-col :cols="6" class="v-calendar__action">
                    <v-btn
                      class="custom font-weight-bold firstColor firstColorText--text"
                      @click="
                        applyAll();
                        $emit('reload_all');
                      "
                      depressed
                      block
                      style="
                        letter-spacing: 0;
                        text-transform: inherit;
                      "
                      :disabled="
                        dateTab == 0 || panel.relativeDate.count >= 1
                          ? false
                          : true
                      "
                    >
                      {{ $t('chart.apply-all-action') }}
                    </v-btn>
                  </v-col>
                  <v-col :cols="6">
                    <v-btn
                      class="custom font-weight-bold firstColor firstColorText--text"
                      @click="
                        applyCurrent();
                        $emit('reload_all');
                      "
                      depressed
                      block
                      style="
                        letter-spacing: 0;
                        text-transform: inherit;
                      "
                      :disabled="
                        dateTab == 0 || panel.relativeDate.count >= 1
                          ? false
                          : true
                      "
                    >
                      {{ $t('chart.apply-action') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <div v-else class="d-flex align-center flex-column v-calendar">
              <div class="d-flex align-center flex-column">
                <v-date-picker
                  class="custom"
                  v-model="fromDateVal"
                  first-day-of-week="1"
                  :locale="locale"
                  no-title
                ></v-date-picker>
                <v-date-picker
                  class="custom"
                  v-model="toDateVal"
                  first-day-of-week="1"
                  :locale="locale"
                  no-title
                ></v-date-picker>
              </div>
              <div class="v-calendar__action d-flex align-center flex-column">
                <v-btn
                  class="custom secondColor mb-4"
                  @click="
                    applyAll();
                    $emit('reload_all');
                  "
                  depressed
                >
                  {{ $t('chart.apply-all-action') }}
                </v-btn>

                <v-btn
                  class="custom firstColor firstColorText--text"
                  @click="
                    applyCurrent();
                    $emit('reload_all');
                  "
                  depressed
                >
                  {{ $t('chart.apply-action') }}
                </v-btn>
              </div>
            </div>
          </v-menu>
          <!-- info for series without data -->
          <div v-if="seriesWithoutData != '' && hasData">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-row v-on="on" v-bind="attrs" style="margin: 0px 5px">
                  <span class="warning--text text-caption">
                    <v-icon small color="warning">mdi-alert</v-icon
                    >{{ seriesWithoutData }}</span
                  >
                </v-row>
              </template>
              <span>{{ $t('chart.noSeriesHelp') }}</span>
            </v-tooltip>
          </div>
          <!-- design menu -->
          <v-menu
            v-model="showChartMenu"
            :close-on-content-click="false"
            :nudge-left="180"
            transition="scale-transition"
            offset-y
            max-width="216px"
            min-width="100px"
          >
            <template v-slot:activator="{ on }">
              <div class="d-flex align-center justify-space-between">
                <v-hover v-slot:default="{ hover }">
                  <div
                    :class="
                      `${
                        hover || showChartMenu
                          ? 'd-flex align-center'
                          : 'd-flex align-center '
                      }`
                    "
                  >
                    <v-btn icon v-on="on" class="custom">
                      <v-icon
                        color="greyTint"
                        :class="
                          `${
                            hover || showChartMenu
                              ? 'thirdColorText--text active'
                              : ''
                          }`
                        "
                        >mdi-dots-horizontal</v-icon
                      >
                    </v-btn>
                  </div>
                </v-hover>
              </div>
            </template>
            <!-- diagram types -->
            <v-sheet>
              <v-container
                fluid
                grid-list-sm
                class="pt-6 pb-5 pl-6 chartBackground"
              >
                <p class="diagramHeading componentText--text">
                  {{ $t('chart.chart-types') }}
                </p>
                <v-layout row wrap>
                  <v-flex
                    v-for="item in chartItemsDiagrams"
                    :key="item.id"
                    :index="item.id"
                    xs4
                  >
                    <v-hover v-slot:default="{ hover }">
                      <div
                        @click="
                          setChartTypeView(item.type);
                          $emit('update');
                        "
                        :class="
                          `${
                            hover ? 'sidebarActiv active divHover' : 'divHover'
                          }`
                        "
                      >
                        <icon-base
                          v-if="item.type == 'table' || item.type == 'pie'"
                          :icon-name="$t(item.text)"
                          :width="30"
                          :height="30"
                          :viewBox="`${0} ${0} ${25} ${25}`"
                        >
                          <template slot-scope="{ strokeColor }">
                            <keep-alive>
                              <component
                                :strokeColor="strokeColor"
                                :is="item.component"
                              ></component>
                            </keep-alive> </template
                        ></icon-base>
                        <icon-base v-else :icon-name="$t(item.text)">
                          <template slot-scope="{ strokeColor }">
                            <keep-alive>
                              <component
                                :strokeColor="strokeColor"
                                :is="item.component"
                              ></component>
                            </keep-alive> </template
                        ></icon-base>
                      </div>
                    </v-hover>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-sheet>
            <div class="dividerBackground" v-if="diagramType != 'heatmap'">
              <v-divider class="diagramLine"></v-divider>
            </div>
            <!-- Settings -->
            <v-sheet v-if="diagramType != 'heatmap'">
              <v-container fluid class="pt-5 pb-5 chartBackground">
                <p class="diagramHeading componentText--text pl-6">
                  {{ $t('chart.chart-settings') }}
                </p>
                <!-- stack DataPoints for colums type -->
                <div
                  class="stackedDiv"
                  v-if="
                    diagramType == 'column' &&
                      tableHeader.length > 2 &&
                      comparedUnit
                  "
                >
                  <v-switch
                    v-model="stackedColumn"
                    class="stacked"
                    :label="$t('dashboard.switch')"
                    @change="
                      prepareChartOptions(datResPicker);
                      $store.dispatch('nodes/updatePanelSettingRequest', {
                        id: panel.id,
                        stacking: stackedColumn
                      });
                    "
                  ></v-switch>
                </div>
                <!-- stack DataPoints for bar type -->
                <div
                  class="stackedDiv"
                  v-if="
                    diagramType == 'bar' &&
                      tableHeader.length > 2 &&
                      comparedUnit
                  "
                >
                  <v-switch
                    v-model="stackedBar"
                    class="stacked"
                    :label="$t('dashboard.switch')"
                    @change="
                      prepareChartOptions(datResPicker);
                      $store.dispatch('nodes/updatePanelSettingRequest', {
                        id: panel.id,
                        stacking: stackedBar
                      });
                    "
                  ></v-switch>
                </div>
                <!-- stack DataPoints for area type -->
                <div
                  class="stackedDiv"
                  v-if="diagramType == 'area' && tableHeader.length > 2"
                >
                  <v-switch
                    v-model="stackedArea"
                    class="stacked"
                    :label="$t('dashboard.switch')"
                    @change="
                      prepareChartOptions(datResPicker);
                      $store.dispatch('nodes/updatePanelSettingRequest', {
                        id: panel.id,
                        stacking: stackedArea
                      });
                    "
                  ></v-switch>
                </div>
                <!-- sort series -->
                <div
                  class="stackedDiv"
                  v-if="
                    diagramType != 'area' &&
                      diagramType != 'scatter' &&
                      diagramType != 'pie' &&
                      diagramType != 'spline' &&
                      diagramType != 'heatmap'
                  "
                >
                  <v-switch
                    v-model="sortBySize"
                    class="stacked"
                    :label="$t('chart.sort')"
                    @change="
                      if (!panel.sortBySize) topFlop.isActive = 0;
                      prepareChartOptions(datResPicker);
                      $emit('update');
                    "
                  ></v-switch>
                </div>
                <!-- trendline -->
                <div
                  class="stackedDiv"
                  v-if="
                    (diagramType == 'scatter' ||
                      diagramType == 'column' ||
                      diagramType == 'spline') &&
                      tableHeader.length == 2
                  "
                >
                  <v-switch
                    v-model="displayTrendLine"
                    class="stacked"
                    :label="$t('chart.trendline')"
                    @change="
                      $emit('update');
                      addRegression();
                    "
                  ></v-switch>
                </div>
                <!-- top / flop -->
                <div
                  v-if="
                    panel.compareNodes &&
                      panel.vars.length == 1 &&
                      (diagramType == 'bar' || diagramType == 'column')
                  "
                >
                  <v-select
                    class="topFlop"
                    v-model="topFlop.isActive"
                    :items="topFlopConfig"
                    label="Top / Flop"
                    hide-details
                    dense
                    @change="
                      cutData(topFlop.isActive, topFlop.n);
                      topFlop = topFlop;
                    "
                  ></v-select>
                  <v-slider
                    v-if="topFlop.isActive != 0"
                    v-model="topFlop.n"
                    class="align-center"
                    :max="panel.tableData.length"
                    :min="1"
                    hide-details
                    dense
                    @change="
                      cutData(topFlop.isActive, topFlop.n);
                      topFlop = topFlop;
                    "
                  >
                    <template v-slot:append>
                      <v-text-field
                        v-model="topFlop.n"
                        class="mt-0 pt-0"
                        hide-details
                        single-line
                        type="number"
                        style="width: 60px"
                        @input="
                          cutData(topFlop.isActive, topFlop.n);
                          topFlop = topFlop;
                        "
                      ></v-text-field>
                    </template>
                  </v-slider>
                </div>
                <!-- custom colors -->
                <div v-if="diagramType != 'heatmap'">
                  <v-expansion-panels flat>
                    <v-expansion-panel class="chartBackground">
                      <v-expansion-panel-header>
                        {{ $t('chart.chartColors') }}
                      </v-expansion-panel-header>
                      <v-expansion-panel-content id="customColorContent">
                        <v-row
                          v-for="(datapoint, index) in panel.chartData
                            ? panel.chartData[0].series
                            : [{ name: 'bla bla' }]"
                          :key="index"
                        >
                          <v-col
                            :cols="3"
                            style="padding: 0px"
                            align-self="center"
                          >
                            <v-menu
                              offset-y
                              offset-x
                              style="background-color:#ffffff"
                              :close-on-content-click="false"
                              v-model="cPIsMenuOpen[index]"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  v-bind="attrs"
                                  v-on="on"
                                  :color="
                                    panel.chartColors[index]
                                      ? panel.chartColors[index].color
                                      : themeColors[index % themeColors.length]
                                  "
                                  @click="setActiveColorPicker(index)"
                                  elevation="2"
                                  fab
                                  x-small
                                ></v-btn>
                              </template>
                              <v-card style="padding: 10px">
                                <v-color-picker
                                  dot-size="18"
                                  mode="hexa"
                                  class="pa-0"
                                  v-model="colorPicker.activeColor"
                                ></v-color-picker>
                                <span
                                  class="text-caption componentText--text"
                                  >{{ $t('chart.themeColors') }}</span
                                >
                                <v-row style="margin: 0px 0px 5px">
                                  <v-btn
                                    v-for="(color, index) in themeColors"
                                    :key="index"
                                    :color="color"
                                    x-small
                                    style="margin: 0px 0px 0px 5px"
                                    @click="colorPicker.activeColor = color"
                                  ></v-btn>
                                </v-row>
                                <span
                                  class="text-caption componentText--text"
                                  >{{ $t('chart.lastUsedColors') }}</span
                                >
                                <v-row style="margin: 0px 0px 1px">
                                  <div v-for="index in 7" :key="index">
                                    <v-btn
                                      :color="
                                        $store.getters[
                                          'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                        ](index - 1)
                                      "
                                      @click="
                                        colorPicker.activeColor = $store.getters[
                                          'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                        ](index - 1)
                                      "
                                      x-small
                                      style="margin: 0px 0px 0px 5px"
                                      :disabled="
                                        $store.getters[
                                          'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                        ](index - 1) === ''
                                          ? true
                                          : false
                                      "
                                    ></v-btn>
                                  </div>
                                </v-row>
                              </v-card>
                            </v-menu>
                          </v-col>
                          <v-col style="padding: 0px">
                            <span class="text-caption">{{
                              datapoint.name
                            }}</span>
                          </v-col>
                        </v-row>
                        <v-row
                          justify="end"
                          v-if="
                            !(
                              panel.chartColors.find(x => x.isCustom) ===
                              undefined
                            )
                          "
                        >
                          <v-btn
                            style="margin: 3px 0px 0px 0px"
                            x-small
                            @click="resetChartColors()"
                          >
                            {{ $t('settings.reset') }}
                          </v-btn>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <div
                    v-if="
                      comparedUnit &&
                        diagramType != 'pie' &&
                        diagramType != 'scatter'
                    "
                  >
                    <v-expansion-panels flat>
                      <v-expansion-panel class="chartBackground">
                        <v-expansion-panel-header>
                          {{ $t('chart.tolerancelimit') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content id="customColorContent">
                          <v-text-field
                            class="toleranceInput"
                            :label="$t('chart.upperLimit')"
                            clearable
                            type="number"
                            v-model="upperLimit"
                            @change="
                              $store.dispatch(
                                'nodes/updatePanelSettingRequest',
                                {
                                  id: panel.id,
                                  upperLimit: upperLimit
                                }
                              );
                              prepareChartOptions();
                            "
                            @click:clear="
                              $store.dispatch(
                                'nodes/updatePanelSettingRequest',
                                {
                                  id: panel.id,
                                  upperLimit: undefined
                                }
                              );
                              prepareChartOptions();
                            "
                          >
                          </v-text-field>
                          <v-text-field
                            class="toleranceInput"
                            :label="$t('chart.lowerLimit')"
                            clearable
                            type="number"
                            v-model="lowerLimit"
                            @change="
                              $store.dispatch(
                                'nodes/updatePanelSettingRequest',
                                {
                                  id: panel.id,
                                  lowerLimit: lowerLimit
                                }
                              );
                              prepareChartOptions();
                            "
                            @click:clear="
                              $store.dispatch(
                                'nodes/updatePanelSettingRequest',
                                {
                                  id: panel.id,
                                  lowerLimit: undefined
                                }
                              );
                              prepareChartOptions();
                            "
                          >
                          </v-text-field>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </div>
                </div>
              </v-container>
            </v-sheet>
            <div class="dividerBackground">
              <v-divider class="diagramLine"></v-divider>
            </div>
            <v-sheet>
              <v-list class="chartBackground">
                <!-- fullscreen -->
                <v-list-item
                  link
                  class="v-list-item--chart"
                  @click="showChartMenu = false"
                >
                  <v-list-item-content>
                    <v-dialog
                      v-model="showDialog"
                      fullscreen
                      hide-overlay
                      transition="dialog-bottom-transition"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-list-item-title
                          v-bind="attrs"
                          v-on="on"
                          class="d-flex align-center justify-content-between"
                        >
                          <icon-base
                            :icon-name="$t('chart.full-screen')"
                            class="mr-3"
                          >
                            <template slot-scope="{ strokeColor }">
                              <keep-alive>
                                <component
                                  :strokeColor="strokeColor"
                                  :is="chartItemsFuntions[0].component"
                                ></component>
                              </keep-alive>
                            </template>
                          </icon-base>
                          {{ $t(chartItemsFuntions[0].text) }}
                        </v-list-item-title>
                      </template>
                      <v-card class="py-10 px-10">
                        <v-card-title
                          class="align-center justify-space-between"
                        >
                          <div></div>
                          <div class="dialog__close" @click="close_fullscreen">
                            <icon-base icon-name="" :width="16" :height="16">
                              <template slot-scope="{ strokeColor }">
                                <IconClose
                                  :strokeColor="strokeColor"
                                /> </template
                            ></icon-base>
                          </div>
                        </v-card-title>

                        <v-spacer></v-spacer>
                        <div>
                          <v-simple-table
                            v-if="showTableData"
                            fixed-header
                            :height="windowsHeight - 58"
                          >
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th
                                    v-for="(title, i) in tableHeader"
                                    :key="i"
                                    class="text-left"
                                  >
                                    {{ title.text }}
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(row, i) in tableContent" :key="i">
                                  <td
                                    v-for="(title, i) in tableHeader"
                                    :key="i"
                                  >
                                    {{ row[title.value] }}
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>

                          <highcharts
                            v-if="!showTableData"
                            :options="chartOptions"
                            ref="bigChart"
                            :class="
                              $vuetify.theme.isDark
                                ? 'dark-theme'
                                : 'light-theme'
                            "
                          ></highcharts>
                        </div>
                      </v-card>
                    </v-dialog>
                  </v-list-item-content>
                </v-list-item>
                <!-- edit datapoints -->
                <v-list-item
                  link
                  class="v-list-item--chart"
                  @click="$emit('wizardDialog'), $emit('toggleWizard')"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="d-flex align-center justify-content-between"
                    >
                      <icon-base :icon-name="$t('chart.edit')" class="mr-3">
                        <template slot-scope="{ strokeColor }">
                          <keep-alive>
                            <component
                              :strokeColor="strokeColor"
                              :is="chartItemsFuntions[4].component"
                            ></component>
                          </keep-alive> </template
                      ></icon-base>
                      {{ $t(chartItemsFuntions[4].text) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- edit axis min/max -->
                <v-dialog v-model="editAxisDialog" width="500">
                  <template v-slot:activator="{ on, attrs }">
                    <v-list-item
                      link
                      class="v-list-item--chart"
                      v-on="on"
                      v-bind="attrs"
                      v-if="diagramType != 'pie'"
                    >
                      <v-list-item-content>
                        <v-list-item-title
                          class="d-flex align-center justify-content-between"
                        >
                          <icon-base
                            :icon-name="$t('chart.export-csv')"
                            class="mr-3"
                          >
                            <template slot-scope="{ strokeColor }">
                              <keep-alive>
                                <component
                                  :strokeColor="strokeColor"
                                  :is="chartItemsFuntions[5].component"
                                ></component>
                              </keep-alive>
                            </template>
                          </icon-base>
                          {{ $t(chartItemsFuntions[5].text) }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>
                  <v-card v-if="axisRange.length > 0">
                    <div v-if="diagramType != 'heatmap'">
                      <v-row
                        class="mt-1 ml-2 mr-2"
                        v-for="(axis, i) in chartOptions.yAxis"
                        :key="i"
                        align="center"
                      >
                        <v-col cols="2">
                          <span class="componentText--text">
                            {{ axis.title.text }} :</span
                          >
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            :class="$vuetify.theme.isDark ? 'forceDark' : ''"
                            hide-details
                            label="Min"
                            outlined
                            dense
                            v-model="axisRange[i][0]"
                            :rules="[numberRule]"
                            @keydown.enter="
                              editAxisDialog = false;
                              prepareChartOptions(datResPicker);
                              showChartMenu = false;
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            :class="$vuetify.theme.isDark ? 'forceDark' : ''"
                            hide-details
                            label="Max"
                            outlined
                            dense
                            v-model="axisRange[i][1]"
                            :rules="[numberRule]"
                            @keydown.enter="
                              editAxisDialog = false;
                              prepareChartOptions(datResPicker);
                              showChartMenu = false;
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <div v-else>
                      <!-- heatmap -->
                      <v-row class="mt-1 ml-2 mr-2" align="center">
                        <v-col cols="2">
                          <span style="color:black">
                            {{ chartOptions.yAxis.unit }} :</span
                          >
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            hide-details
                            label="Min"
                            outlined
                            dense
                            v-model="axisRange[0][0]"
                            :rules="[numberRule]"
                            @keydown.enter="
                              editAxisDialog = false;
                              prepareChartOptions(datResPicker);
                              showChartMenu = false;
                            "
                          ></v-text-field>
                        </v-col>
                        <v-col cols="5">
                          <v-text-field
                            hide-details
                            label="Max"
                            outlined
                            dense
                            v-model="axisRange[0][1]"
                            :rules="[numberRule]"
                            @keydown.enter="
                              editAxisDialog = false;
                              prepareChartOptions(datResPicker);
                              showChartMenu = false;
                            "
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="
                          editAxisDialog = false;
                          prepareChartOptions(datResPicker);
                          showChartMenu = false;
                        "
                      >
                        OK
                      </v-btn>
                      <v-btn
                        color="primary"
                        text
                        @click="editAxisDialog = false"
                      >
                        {{ $t('settings.cancel') }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
                <!-- export as csv -->
                <v-list-item
                  v-if="hasTableData"
                  link
                  class="v-list-item--chart"
                  @click="showChartMenu = false"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="d-flex align-center justify-content-between"
                    >
                      <icon-base
                        :icon-name="$t('chart.export-csv')"
                        class="mr-3"
                      >
                        <template slot-scope="{ strokeColor }">
                          <keep-alive>
                            <component
                              :strokeColor="strokeColor"
                              :is="chartItemsFuntions[1].component"
                            ></component>
                          </keep-alive>
                        </template>
                      </icon-base>

                      <vue-json-to-csv
                        :json-data="csv.content"
                        :csv-title="csv.title"
                        separator=";"
                      >
                        {{ $t(chartItemsFuntions[1].text) }}
                      </vue-json-to-csv>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- export as png -->
                <v-list-item
                  link
                  class="v-list-item--chart"
                  @click="exportPNG()"
                  v-if="panel.type != 'table'"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="d-flex align-center justify-content-between"
                    >
                      <icon-base
                        :icon-name="$t('chart.export-csv')"
                        class="mr-3"
                      >
                        <template slot-scope="{ strokeColor }">
                          <keep-alive>
                            <component
                              :strokeColor="strokeColor"
                              :is="chartItemsFuntions[2].component"
                            ></component>
                          </keep-alive> </template
                      ></icon-base>
                      {{ $t(chartItemsFuntions[2].text) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <!-- delete panel -->
                <v-list-item
                  link
                  class="v-list-item--chart"
                  @click="deletePanel()"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      class="d-flex align-center justify-content-between"
                    >
                      <icon-base :icon-name="$t('chart.delete')" class="mr-3">
                        <template slot-scope="{ strokeColor }">
                          <keep-alive>
                            <component
                              :strokeColor="strokeColor"
                              :is="chartItemsFuntions[3].component"
                            ></component>
                          </keep-alive> </template
                      ></icon-base>
                      {{ $t(chartItemsFuntions[3].text) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-menu>
        </div>
      </div>
    </v-hover>
    <div class="separator"></div>
    <!-- body -->
    <div class="v-chart__content">
      <div class="v-chart__chart" ref="v_chart_chart">
        <!-- table view -->
        <v-simple-table
          v-if="showTableData"
          fixed-header
          :height="this.$refs.v_chart_chart.clientHeight"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(title, i) in tableHeader"
                  :key="i"
                  class="text-right"
                >
                  {{ title.text }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, i) in tableContent" :key="i">
                <td
                  v-for="(title, i) in tableHeader"
                  :key="i"
                  class="text-right"
                >
                  {{ row[title.value] }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <!-- other diagram types -->
        <highcharts
          v-if="!showTableData && hasData && mounted"
          :options="chartOptions"
          ref="chart"
          :class="$vuetify.theme.isDark ? 'dark-theme' : 'light-theme'"
        ></highcharts>
        <!-- no data from backend -->
        <div v-if="!hasData && mounted">
          {{ $t('chart.noData') }}
        </div>
      </div>
    </div>
    <v-snackbar v-model="dateAlert" color="red">
      {{ $t('chart.invalidDates') }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="dateAlert = false">
          {{ $t('chart.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { format } from 'date-fns';
import * as locale from 'date-fns/locale';
import VueJsonToCsv from 'vue-json-to-csv';
import IconBase from '@/components/Icons/';
import IconTableView from '@/components/Icons/IconTableView';
import IconPieView from '@/components/Icons/IconPieView';
import IconBarView from '@/components/Icons/IconBarView';
import IconSplineView from '@/components/Icons/IconSplineView';
import IconExportCSV from '@/components/Icons/IconExportCSV';
import IconFullScreen from '@/components/Icons/IconFullScreen';
import IconDelete from '@/components/Icons/IconDelete';
import IconHeatmap from '../Icons/IconHeatmap.vue';
import IconScatter from '../Icons/IconScatter.vue';
import IconColumnView from '../Icons/IconColumnView.vue';
import IconEdit from '@/components/Icons/IconEdit';
import IconCalendar from '@/components/Icons/IconCalendar';
import IconClose from '@/components/Icons/IconClose';
import IconDurationCurve from '@/components/Icons/IconDurationCurve';
import config from '../../config/index';
import ColorPicker from '@/components/ColorPicker/ColorPicker';

export default {
  name: 'ChartComponent',
  components: {
    IconBase,
    VueJsonToCsv,
    IconCalendar,
    IconClose,
    IconEdit,
    ColorPicker
  },
  props: {
    panel: {},
    update: {
      type: Boolean,
      default: false
    },
    settings: {}
  },
  data() {
    return {
      // rerenderKey: true,
      // today: '',
      // tomorrow: '',
      dateAlert: false,
      iconEdit: IconEdit,
      themes: config.themes,
      themeColors: ['#02858D', '#8BB059', '#C4B735', '#CEA125', '#215F75'],
      colorPicker: {
        activeColor: '',
        activeIndex: null,
        savedColor: ''
      },
      cPIsMenuOpen: [false, false, false, false, false, false],
      locale: this.$root.$i18n.locale,
      stackedColumn: false,
      stackedBar: false,
      stackedArea: true,
      displayTrendLine: false,
      showTitleEdit: false,
      editedTitle: '',
      showDialog: false,
      editAxisDialog: false,
      axisRange: [],
      numberRule: v => {
        if (v == null) return true;
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v))) return true;
        return 'Ungültige Zahl';
      },
      showDateMenu: false,
      showChartMenu: false,
      chartOptions: {},
      title: '...',
      fromDateVal: null,
      toDateVal: null,
      datResPicker: this.$props.panel ? this.$props.panel.res : 'd',
      comparedUnit: true,
      csv: { title: '', content: [] },
      chartItemsFuntions: [
        {
          id: 1,
          icon: 'full-screen',
          type: '',
          text: 'chart.full-screen',
          component: IconFullScreen
        },
        {
          id: 2,
          text: 'chart.export-csv',
          icon: 'export-csv',
          type: '',
          component: IconExportCSV
        },
        {
          id: 3,
          text: 'chart.export-png',
          icon: 'export-csv',
          type: '',
          component: IconExportCSV
        },
        {
          id: 4,
          icon: 'delete',
          type: '',
          text: 'chart.delete',
          component: IconDelete
        },
        {
          id: 5,
          icon: 'edit',
          type: '',
          text: 'chart.edit',
          component: IconEdit
        },
        {
          id: 6,
          icon: 'export-csv',
          type: '',
          text: 'chart.editAxis',
          component: IconEdit
        }
      ],
      hasTableData: false,
      showTableData: false,
      tableHeader: [],
      tableContent: [],
      hasChartData: false,
      diagramType: '',
      updateChart: false,
      hasData: false,
      seriesWithoutData: '',
      mounted: false,
      timeResolution: 'chart.time-resolution',
      hourlyResolution: true,
      dateFormat: 'dd.MM.yyyy',
      topFlopConfig: [
        {
          text: 'off',
          value: 0
        },
        {
          text: 'Top N',
          value: 1
        },
        {
          text: 'Flop N',
          value: 2
        }
      ],
      windowsHeight: window.innerHeight,
      upperLimit: null,
      dateTab: 0,
      lowerLimit: null
    };
  },

  computed: {
    chartItemsDiagrams() {
      let chartItemsDiagrams = [
        {
          id: 1,
          text: 'chart.table-view',
          //icon: 'table-view',
          type: 'table',
          component: IconTableView
        },
        {
          id: 2,
          text: 'chart.pie-view',
          //icon: 'pie-view',
          type: 'pie',
          component: IconPieView
        },
        {
          id: 3,
          text: 'chart.heatmap-view',
          //icon: 'bar-view',
          type: 'heatmap',
          component: IconHeatmap
        },
        {
          id: 4,
          text: 'chart.spline-view',
          //icon: 'spline-view',
          type: 'spline',
          component: IconSplineView
        },
        {
          id: 5,
          text: 'chart.bar-view',
          //icon: 'bar-view',
          type: 'bar',
          component: IconBarView
        },
        {
          id: 6,
          text: 'chart.column-view',
          //icon: 'column-view',
          type: 'column',
          component: IconColumnView
        },
        {
          id: 7,
          text: 'chart.sorted-area',
          //icon: 'duration-curve',
          type: 'area',
          component: IconDurationCurve
        },
        {
          id: 8,
          text: 'chart.scatter',
          //icon: 'scatter-view',
          type: 'scatter',
          component: IconScatter
        }
      ];
      var result = [];
      if (this.tableHeader.length < 3) {
        result = chartItemsDiagrams.filter(diagram => diagram.type != 'pie');
      }
      if (this.tableHeader.length > 2) {
        result = chartItemsDiagrams.filter(
          diagram => diagram.type != 'heatmap'
        );
      }
      if (this.comparedUnit == false) {
        result = chartItemsDiagrams.filter(
          diagram => diagram.type != 'pie' && diagram.type != 'heatmap'
        );
      }
      if (this.diagramType == 'heatmap') {
        result = chartItemsDiagrams.filter(
          diagram => diagram.type != 'pie' && diagram.type != 'area'
        );
      }
      // if panel look at the nodes of the current node
      if (this.$props.panel.compareNodes) {
        result = chartItemsDiagrams.filter(
          diagram =>
            diagram.type == 'bar' ||
            diagram.type == 'column' ||
            diagram.type == 'table'
        );
      }
      return result;
    },
    setLabelColor() {
      let labelColor = '';
      if (this.$vuetify.theme.isDark) {
        labelColor = this.$vuetify.theme.themes.dark['chartLabes'];
      } else {
        labelColor = this.$vuetify.theme.themes.light['chartLabes'];
      }
      return labelColor;
    },
    fromDateDisp() {
      if (this.fromDateVal === null) {
        let begginingOfTheLastYear = new Date(
          new Date().getFullYear() - 1, // Year
          0, // Month
          1, // Day
          2 // Hour
        );

        return format(begginingOfTheLastYear, this.dateFormat, {
          // FFS: get browser locale (shows 'us' if not defined)
          locale: locale[this.lcoale]
        });
      } else {
        return format(new Date(this.fromDateVal), this.dateFormat, {
          locale: locale[this.locale]
        });
      }
    },
    toDateDisp() {
      if (this.toDateVal === null) {
        let endOfTheLastYear = new Date(
          new Date().getFullYear() - 1, // Year
          11, // Month
          31, // Day
          23 // Hour
        );
        return format(endOfTheLastYear, this.dateFormat, {
          locale: locale[this.locale]
        });
      } else {
        return format(new Date(this.toDateVal), this.dateFormat, {
          locale: locale[this.locale]
        });
      }
    },
    hasSpecialSettings() {
      return (
        this.diagramType == 'bar' ||
        this.diagramType == 'column' ||
        this.diagramType == 'area' ||
        (this.diagramType == 'scatter' && this.tableHeader.length == 2) ||
        (this.diagramType == 'spline' && this.tableHeader.length == 2) ||
        this.diagramType == 'pie'
      );
    },
    relativeDate: {
      get() {
        return this.panel.relativeDate;
      },
      set(value) {
        if (this.relativeDate != value) {
          this.$store.dispatch('nodes/updatePanelSettingRequest', {
            id: this.panel.id,
            relativeDate: value
          });
        }
      }
    },
    sortBySize: {
      get() {
        return this.panel.sortBySize;
      },
      set(value) {
        if (this.sortBySize != value) {
          this.$store.dispatch('nodes/updatePanelSettingRequest', {
            id: this.panel.id,
            sortBySize: value
          });
        }
      }
    },
    topFlop: {
      get() {
        return this.panel.topFlop;
      },
      set(value) {
        this.$store.dispatch('nodes/updatePanelSettingRequest', {
          id: this.panel.id,
          topFlop: value
        });
      }
    }
  },
  async beforeMount() {
    let panel = this.$props.panel;
    if (panel.stacking) {
      this.stackedColumn = true;
      this.stackedBar = true;
      this.stackedArea = true;
    } else {
      this.stackedColumn = false;
      this.stackedBar = false;
      this.stackedArea = false;
    }
    if (panel.hasTrendLine) {
      this.displayTrendLine = true;
    }
    let startDate;
    let endDate;
    if (panel.relativeDate !== undefined)
      if (panel.relativeDate.active) {
        this.dateTab = 1;
        let today = new Date(new Date().setHours(0, 0, 0, 0));
        let weekday = today.getDay() == 0 ? 6 : today.getDay() - 1;
        switch (this.panel.relativeDate.timespan) {
          case 'w':
            startDate = new Date(today).setDate(
              today.getDate() - weekday - 7 * this.panel.relativeDate.count
            );
            endDate = new Date(
              today.setDate(today.getDate() - weekday - 1)
            ).setHours(23, 0, 0, 0);
            break;
          case 'm':
            startDate = Date.parse(
              new Date(
                today.getFullYear(),
                today.getMonth() - this.panel.relativeDate.count,
                1
              )
            );
            endDate = new Date(today.getFullYear(), today.getMonth());
            endDate = endDate.setHours(-1);
            break;
          case 'y':
            startDate = Date.parse(
              new Date(
                today.getFullYear() - this.panel.relativeDate.count,
                0,
                1
              )
            );
            endDate = new Date(today.getFullYear() - 1, 11, 31);
            endDate = endDate.setHours(23, 0, 0, 0);
            break;
        }
        if (startDate != panel.startdate || endDate != panel.endDate) {
          panel.startDate = startDate;
          panel.endDate = endDate;
        }
      }
    await this.getData(panel.startDate, panel.endDate, panel.res);
    this.updateChart = this.$props.update;
    if (
      panel.node.length == 1 &&
      (panel.node[0].charAt(0) == 'G' ||
        panel.node[0].charAt(0) == 'g' ||
        panel.node[0].charAt(0) == 'a')
    ) {
      this.hourlyResolution = false;
    }
  },
  mounted() {
    let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
    if (this.$store.state.layout.theme.chartColors) {
      this.themeColors = this.$store.state.layout.theme.chartColors;
    } else {
      if (localUserConfig.theme.isDark) {
        this.themeColors = this.themes.find(
          i => i.name == localUserConfig.theme.name
        ).dark.chartColors;
      } else {
        this.themeColors = this.themes.find(
          i => i.name == localUserConfig.theme.name
        ).light.chartColors;
      }
    }
  },
  updated() {
    if (this.$refs.chart !== undefined && this.panel.type != 'table') {
      if (this.$refs.chart.chart != undefined) {
        this.$refs.chart.chart.redraw();
        this.$refs.chart.chart.reflow();
        return;
      }
    }
  },
  watch: {
    panel: {
      handler: async function(newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          if (newValue.tableData == null && oldValue.tableData != null) {
            this.$props.panel.tableData = oldValue.tableData;
            this.$props.panel.chartData = oldValue.chartData;
            this.$props.panel.tableHeader = oldValue.tableHeader;
          } else {
            await this.getData(
              newValue.startDate,
              newValue.endDate,
              newValue.res
            );
            if (this.$refs.chart !== undefined && newValue.type != 'table') {
              this.$refs.chart.chart.reflow();
            }
            if (newValue.id != oldValue.id) {
              if (newValue.type == 'table') {
                this.showTableData = true;
              } else if (oldValue.type == 'table') {
                this.showTableData = false;
              }
            }
          }
        }
      },
      deep: true
    },
    settings: {
      handler: async function(newValue, oldValue) {
        if (JSON.stringify(newValue) != JSON.stringify(oldValue)) {
          if (newValue.res != oldValue.res) {
            this.datResPicker = newValue.res;
          }
          if (this.panel.relativeDate.active) this.dateTab = 1;
          await this.getData(
            newValue.startDate,
            newValue.endDate,
            newValue.res
          );
        }
      },
      deep: true
    },
    cPIsMenuOpen: {
      handler: async function(newValue, oldValue) {
        if (!newValue.includes(true)) {
          this.saveAndResetColorPicker();
        }
      },
      deep: true
    },
    updateChart(newValue, oldValue) {
      if (newValue != oldValue) {
        if (this.$refs.chart !== undefined && this.panel.type != 'table') {
          if (this.$refs.chart.chart != undefined) {
            this.$refs.chart.chart.redraw();
            this.$refs.chart.chart.reflow();
            return;
          }
        }
      }
      console.log('Chart ref error');
    },
    update(newValue, oldValue) {
      if (newValue != oldValue) {
        this.toggleUpdate();
      }
    },
    showDialog(newValue, oldValue) {
      if (newValue) {
        this.showChartMenu = false;
        this.chartOptions.chart.height = this.windowsHeight - 58;
      } else {
        this.chartOptions.chart.height = 280;
      }
      if (this.$refs.bigChart !== undefined) {
        this.$refs.bigChart.chart.redraw();
      }
    },
    showDateMenu(newValue, oldValue) {
      if (newValue == true) {
        if (
          this.diagramType == 'pie' ||
          this.diagramType == 'bar' ||
          this.diagramType == 'column'
        ) {
          this.timeResolution = this.hourlyResolution
            ? 'chart.time-resolution'
            : 'chart.time-resolution-asset';
        } else {
          this.timeResolution = this.hourlyResolution
            ? 'chart.time-resolution-short'
            : 'chart.time-resolution-asset-short';
        }
      }
    }
  },
  methods: {
    // TODO
    // Confirmation Dialog if the User really wants to delete this Panel
    close_fullscreen() {
      this.showDialog = false;
      //this.getData(this.fromDateVal, this.toDateVal);
      this.prepareChartOptions(this.datResPicker);
    },
    async deletePanel() {
      await this.$store.dispatch(
        'nodes/deletePanelRequest',
        this.$props.panel.id
      );
      this.showChartMenu = false;
    },
    editTitle() {
      this.title = this.editedTitle;
      this.editedTitle = '';
      this.showTitleEdit = false;

      let panel = this.$props.panel;
      this.$store.dispatch('nodes/updatePanelSettingRequest', {
        id: panel.id,
        title: this.title
      });

      //this.$store.dispatch('nodes/updateUserConfig');
      this.$store.dispatch('nodes/updateStore');
    },
    async fetchData(from, to, res) {
      let panel = this.$props.panel;

      if (typeof panel == 'undefined') {
        return;
      }
      // get timezone offeset in minutes
      let timeZoneStart = new Date(panel.startDate).getTimezoneOffset();
      let timeZoneEnd = new Date(panel.endDate).getTimezoneOffset();

      // convert ISO 8601 time string to timestamp
      // (this assume time still in UTC and not local time, we need to correct the time as user enters local time)
      let tsFrom = panel.startDate;
      let tsTo = panel.endDate; // time by user
      //let tsToEndofDay = panel.endDate; // end of day for data request
      if (from !== null) {
        // get time of current timezone and set hours to 0:00:00
        tsFrom = new Date(from).setHours(0, 0, 0, 0);
        // prepare time for backend request with offset
        tsFrom = tsFrom - new Date(tsFrom).getTimezoneOffset() * 60000;
      }
      if (to !== null) {
        // get time of current timezone
        tsTo = new Date(to);
        // the backend knows that the endDate is exclusiv
        tsTo.setDate(tsTo.getDate() + 1);
        // set to the last hour, to get all datas of the day
        tsTo = tsTo.setHours(0, 0, 0, 0);
        // prepare time for backen request with offset
        tsTo = tsTo - new Date(tsTo).getTimezoneOffset() * 60000;
      }
      let chartType = panel.type;

      // pie charts needs the resolution 'a'
      if (chartType == 'pie' && res != 'a') {
        res = 'a';
      }
      // resolution 'a' is just permitted for pie, bar & column. area has to be 'd'
      if (
        (chartType != 'pie' &&
          chartType != 'bar' &&
          chartType != 'column' &&
          res == 'a' &&
          !panel.compareNodes) ||
        chartType == 'area'
      )
        res = 'd';
      // heatmap needs resolution 'd' or 'h'
      if (chartType == 'heatmap') {
        if (!(res == 'd' || res == 'h')) {
          res = 'd';
        }
      }

      // set some params for request
      let dtStart = tsFrom;
      let dtEnd = tsTo;
      let vars = panel.vars;
      let dataRes = res ? res : 'd';
      let panelId = panel.id;
      let node = [];
      if (
        panel.compareNodes &&
        this.$store.state.nodes.treeNode.nodes !== undefined
      ) {
        this.$store.state.nodes.treeNode.nodes.forEach(item =>
          node.push(item.node)
        );
      } else {
        node = [this.$store.state.nodes.treeNode.node];
      }
      // sends request to get required data
      await this.$store.dispatch('nodes/updatePanelRequest', {
        name: panel.title,
        node: node,
        dtstart: dtStart,
        dtend: dtEnd,
        tzoffs: timeZoneEnd, // can remove when the backend is updated
        tzoffstart: timeZoneStart,
        tzoffend: timeZoneEnd,
        res: dataRes,
        vars: vars,
        diagram: chartType,
        currentPanel: panelId
      });

      // the modul highcharts just displayed when mounted is true
      this.mounted = true;
    },
    async addRegression() {
      let panel = this.$props.panel;
      if (panel.id) {
        this.$store.dispatch('nodes/updatePanelSettingRequest', {
          id: panel.id,
          hasTrendLine: this.displayTrendLine
        });
      }
    },
    /**
     * adjusts all data related to highcharts
     * @param {String} res the resolution of panel that will displayed
     * @return is only used to stop the function earlier
     */
    prepareChartOptions(res) {
      // set main references as varibles
      let panel = this.$props.panel;
      let _chartData = panel.chartData;
      let chartYAxis = panel.chartYAxis;

      // set time varibles
      let tsFrom = panel.startDate;
      let tsTo = panel.endDate;

      // main part of the adjustments
      if (
        _chartData !== undefined &&
        _chartData !== null &&
        Object.entries(_chartData).length > 0 &&
        panel.tableData !== undefined &&
        panel.tableData !== null
      ) {
        // fill the data of the component
        this.title = panel.title ? panel.title : null;
        this.diagramType = _chartData[0].type;
        this.hasTableData = panel.tableData.length < 0 ? false : true;
        this.tableHeader = this.hasTableData ? [...panel.tableHeader] : [];
        this.tableContent = this.hasTableData ? [...panel.tableData] : [];

        this.hasChartData = _chartData.length < 0 ? false : true;
        this.hasData = false;
        if (tsFrom != null) {
          this.fromDateVal = new Date(
            tsFrom - new Date(tsFrom).getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
        }
        if (tsTo != null) {
          this.toDateVal = new Date(
            tsTo - new Date(tsTo).getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
        }
        // check if this panel has any data to display
        for (let i = 0; i < this.tableContent.length; i++) {
          if (typeof this.tableContent[i] != 'undefined') {
            let filteredTableContent = [];
            if (this.diagramType == 'pie') {
              filteredTableContent = Object.values(this.tableContent[i]).filter(
                val => val != null && val != 0
              );
            } else {
              filteredTableContent = Object.values(this.tableContent[i]).filter(
                val => val != null
              );
            }
            if (filteredTableContent.length > 1) {
              this.hasData = true;
              break;
            }
          } else {
            break;
          }
        }
        if (!_chartData[0].hasData || !this.hasData) {
          return;
        }
        if (_chartData[0].type != 'heatmap') {
          // check if one or more series has no data
          let tempSeriesWithoutData = [];
          for (let index in _chartData[0].data) {
            if (
              _chartData[0].series[index] &&
              _chartData[0].data[index].filter(arr => arr[1] != null).length ==
                0
            )
              tempSeriesWithoutData.push(_chartData[0].series[index].name);
          }
          this.seriesWithoutData = '';
          for (let seriesStr of tempSeriesWithoutData) {
            if (tempSeriesWithoutData.length == 1)
              this.seriesWithoutData =
                seriesStr + ' ' + this.$t('chart.serieNoData');
            else if (tempSeriesWithoutData.indexOf(seriesStr) == 0)
              this.seriesWithoutData = seriesStr;
            else if (
              tempSeriesWithoutData.indexOf(seriesStr) !=
              tempSeriesWithoutData.length - 1
            )
              this.seriesWithoutData += ', ' + seriesStr;
            else
              this.seriesWithoutData +=
                ', ' + seriesStr + ' ' + this.$t('chart.seriesNoData');
          }

          // check if this panel has the property chartColors
          if (panel.chartColors[0] === undefined) {
            panel['chartColors'] = [];
            for (let index in panel.vars) {
              panel.chartColors.push({
                color: this.themeColors[index % this.themeColors.length],
                isCustom: false,
                datapoint: panel.vars[index]
              });
            }
          }
          // new property .datapoint when someone hasn't this, it will be added
          if (panel.chartColors[0].datapoint === undefined) {
            for (let index in panel.chartColors) {
              panel.chartColors[index]['datapoint'] = panel.vars[index];
            }
          }
          // checks if each data point is present and in the right place
          for (let index in panel.vars) {
            if (
              panel.chartColors.filter(x => x.datapoint == panel.vars[index])
                .length == 0
            ) {
              panel.chartColors.splice(index, 0, {
                color: this.themeColors[index % this.themeColors.length],
                isCustom: false,
                datapoint: panel.vars[index]
              });
            } else {
              let chartColorsIndex = panel.chartColors.findIndex(
                x => x.datapoint == panel.vars[index]
              );
              if (chartColorsIndex != index) {
                panel.chartColors.splice(
                  index,
                  0,
                  panel.chartColors.splice(chartColorsIndex, 1)[0]
                );
              }
            }
          }
          // checks if the color is custom and if there are too many objects in it
          for (let index in panel.chartColors) {
            if (!panel.chartColors[index]['isCustom']) {
              panel.chartColors[index]['color'] = this.themeColors[
                index % this.themeColors.length
              ];
            }
            if (panel.vars[index] === undefined) {
              panel.chartColors.splice(index, panel.chartColors.length);
            }
          }
        }
        // create an initial object for highcharts options
        const chartOptions = {
          exporting: {
            chartOptions: {
              plotOptions: {
                series: {
                  dataLabels: {
                    enabled: this.diagramType == 'pie' ? true : false
                  }
                }
              }
            },
            enabled: false,
            fallbackToExportServer: false
          },
          chart: {
            type: _chartData[0].type ? _chartData[0].type : '',
            events: {
              load() {
                setTimeout(this.reflow.bind(this));
              }
            },
            height: 280,
            zoomType: 'xy'
          },
          title: {
            text: null
          },
          series: [
            {
              data: _chartData[0].data,
              name: _chartData[0].series[0].name
            }
          ],
          colorAxis: {
            visible: false
          },
          yAxis: {
            minPadding: 0,
            maxPadding: 0,
            title: {
              text: chartYAxis[0].unit ? chartYAxis[0].unit : ' ',
              style: {
                fontWeight: 'bold'
              },
              rotation: 0
            }
          },
          xAxis: {
            labels: {
              style: {
                color: this.setLabelColor
              }
            },
            type: panel.compareNodes ? 'LINEAR' : 'datetime',
            title: null,
            minPadding: 0,
            maxPadding: 0,
            visible: res == 'a' && !panel.compareNodes ? false : true
          },
          legend: {
            enabled: true,
            itemStyle: {
              color: this.setLabelColor,
              fontWeight: 'bold'
            }
          },
          credits: {
            enabled: false
          },
          time: {
            //timezoneOffset: tzOffset,
            useUTC: false
          },

          plotOptions: {
            column: {
              stacking: '',
              zones: []
            },
            bar: {
              stacking: '',
              zones: []
            },
            spline: {
              zones: []
            },
            series: {
              borderWidth: 0
            },
            area: {
              stacking: 'normal'
            },
            scatter: {
              tooltip: {
                headerFormat: '<b>{series.name}</b><br>',
                pointFormatter: function() {
                  var options = {
                    weekday: 'long',
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                  };
                  let d = new Date(this.x);
                  d.toLocaleDateString(this.locale, options);
                  return (
                    d.toLocaleDateString(this.locale, options) +
                    ': <b> ' +
                    this.y +
                    ' ' +
                    chartYAxis[0].unit[0] +
                    '</b>'
                  );
                }
              }
            }
          }
        };
        let used_colors = [];
        let data_arr = [];
        let y_axis = [];

        switch (_chartData[0].type) {
          case 'heatmap':
            chartOptions['colorAxis'] = {
              stops: [
                [0.0, '#02858D'],
                [0.5, '#C4B735'],
                [1, '#BB5A01']
              ],
              min: null,
              max: null, // Maximum der Farbachse unten, null = automatisch,
              labels: {
                style: {
                  color: this.setLabelColor
                },
                format: '{value} ' + chartYAxis[0].unit[0]
              },
              tickAmount: 3
            };
            chartOptions['tooltip'] = {
              formatter: function() {
                var options = {
                  weekday: 'long',
                  year: 'numeric',
                  month: 'long',
                  day: 'numeric'
                };
                if (res == 'd') {
                  let t = this.point.x;
                  let d = new Date(t);
                  d.setDate(d.getDate() + this.point.y + 1);
                  return `${d.toLocaleDateString(
                    this.locale,
                    options
                  )} <br><b> ${this.point.value} ${chartYAxis[0].unit[0]}
                 </b>`;
                } else {
                  let t = this.point.x;
                  let d = new Date(t);
                  return `${d.toLocaleDateString(this.locale, options)} ${
                    this.point.y
                  }:00 <br><b> ${this.point.value} ${chartYAxis[0].unit[0]}
                 </b>`;
                }
              }
            };
            if (res == 'd') {
              chartOptions['yAxis'] = {
                //...chartOptions.yAxis,
                categories: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
                tickPositions: null,
                labels: {
                  format: null,
                  style: {
                    color: this.setLabelColor
                  }
                },
                reversed: true,
                title: {
                  text: 'Wochentag',
                  style: {
                    fontWeight: 'bold',
                    color: this.setLabelColor
                  },
                  rotation: 270
                },
                unit: chartYAxis[0].unit[0]
              };
            }
            if (res == 'h') {
              chartOptions['yAxis'] = {
                //...chartOptions.yAxis,
                tickPositions: [0, 6, 12, 18, 24],
                labels: {
                  format: '{value}:00'
                },
                categories: null,
                reversed: true,
                title: {
                  text: 'Uhrzeit',
                  style: {
                    fontWeight: 'bold',
                    color: this.setLabelColor
                  },
                  rotation: 270
                },
                unit: chartYAxis[0].unit[0]
              };
            }
            if (chartOptions.series[0].colsize != null) {
              if (res == 'h') {
                chartOptions.series[0].colsize = 36e5 * 24; // 36e5 = one hour
              } else {
                chartOptions.series[0].colsize = 36e5 * 24 * 7;
              }
            } else {
              chartOptions['series'] = [
                {
                  ...chartOptions.series[0],
                  colsize: res == 'h' ? 36e5 * 24 : 36e5 * 24 * 7
                }
              ];
            }

            if (this.axisRange.length == 0) {
              this.axisRange.push([null, null]);
            }
            chartOptions['colorAxis']['min'] =
              this.axisRange[0][0] == null || this.axisRange[0][0].length == 0
                ? null
                : parseFloat(this.axisRange[0][0]);
            chartOptions['colorAxis']['max'] =
              this.axisRange[0][1] == null || this.axisRange[0][1].length == 0
                ? null
                : parseFloat(this.axisRange[0][1]);
            chartOptions['colorAxis']['startOnTick'] =
              chartOptions['colorAxis']['min'] != null ? false : true;
            chartOptions['colorAxis']['endOnTick'] =
              chartOptions['colorAxis']['max'] != null ? false : true;
            /*if (res == 'h') {
              chartOptions.series[0].colsize = 36e5 * 24; // 36e5 = one hour
            } else {
              chartOptions.series[0].colsize = 36e5 * 24 * 7;
            }
            chartOptions.series[0].data = _chartData[0].data;
            chartOptions.series[0].name = _chartData[0].series[0].name;*/
            break;
          case 'pie':
            var vars_data = [];

            var dataTotalAmount = 0;
            for (let i = 0; i < panel.vars.length; i++) {
              dataTotalAmount += panel.tableData[0][panel.vars[i]];
            }

            for (let i = 0; i < panel.vars.length; i++) {
              vars_data.push({
                name:
                  _chartData[0].series[i].name.slice(
                    0,
                    _chartData[0].series[i].name.indexOf('[')
                  ) +
                  ' - ' +
                  (
                    (_chartData[0].data[i][0][1] / dataTotalAmount) *
                    100
                  ).toFixed(1) +
                  '%',
                y: _chartData[0].data[i][0][1],
                color: panel.chartColors[i].color
              });
              used_colors.push(this.themeColors[i % this.themeColors.length]);
            }

            chartOptions.colorAxis = null;

            chartOptions['series'] = [
              {
                data: vars_data,
                tooltip: {
                  pointFormat:
                    '<b>{point.y} ' +
                    chartYAxis[0].unit[0] +
                    '</b><br>' +
                    '{point.percentage:.1f}%'
                }
              }
            ];
            chartOptions['plotOptions'] = {
              pie: {
                allowPointSelect: true,
                cursor: 'pointer'
              }
            };
            break;
          default:
            for (let j = 0; j < _chartData[0].series.length; j++) {
              /*console.log(
                y_axis.filter(ele => ele.title.text == chartYAxis[0].unit[j])
              );*/
              // Push a new y-Axis if unit isn't in chart already
              if (
                y_axis.filter(ele => ele.title.text == chartYAxis[0].unit[j])
                  .length == 0
              ) {
                y_axis.push({
                  labels: {
                    style: {
                      color: this.setLabelColor
                    }
                  },
                  title: {
                    text: chartYAxis[0].unit[j] ? chartYAxis[0].unit[j] : ' ',
                    style: {
                      fontWeight: 'bold',
                      color: this.setLabelColor
                    }
                    //rotation: 0,
                  },
                  startOnTick: true,
                  endOnTick: true,
                  opposite: 1 === y_axis.length % 2
                });
              }

              let data = [];

              if (
                // Jahresdauerlinie
                (((this.diagramType == 'bar' || this.diagramType == 'column') &&
                  panel.sortBySize == true) ||
                  this.diagramType == 'area') &&
                !panel.compareNodes
              ) {
                data = JSON.parse(JSON.stringify(_chartData[0].data[j]));
                data.sort((a, b) => {
                  if (a[1] < b[1]) return 1;
                  if (a[1] > b[1]) return -1;
                  return 0;
                });
                data = data.map((arr, i) => {
                  if (this.diagramType == 'area' && res == 'd') {
                    arr[0] = (i + 1) * 24;
                    switch (chartYAxis[0].unit[j]) {
                      case 'kWh':
                        arr[1] = Math.round(arr[1] / 24);
                        break;
                      case '°C':
                        break;
                      default:
                        arr[1] = Math.round((arr[1] / 24) * 100) / 100;
                        break;
                    }
                  } else arr[0] = i + 1;
                  return arr;
                });

                let xAxisTitle = '';
                if (res == 'h' || (res == 'd' && this.diagramType == 'area'))
                  xAxisTitle = 'Anzahl Stunden';
                else if (res == 'd') xAxisTitle = 'Anzahl Tage';
                else if (res == 'm') xAxisTitle = 'Anzahl Monate';
                else if (res == 'y') xAxisTitle = 'Anzahl Jahre';

                chartOptions['xAxis'].type = '';
                chartOptions['xAxis'].title = {
                  text: xAxisTitle,
                  style: {
                    color: '#333333',
                    fontWeight: 'bold'
                  }
                };
              } else {
                data = _chartData[0].data[j];
                chartOptions['xAxis'].type = 'datetime';
                if (chartOptions['xAxis'].title != null)
                  delete chartOptions['xAxis'].title;
              }

              data_arr.push({
                color: panel.chartColors[j]
                  ? panel.chartColors[j].color
                  : this.themeColors[j % this.themeColors.length],
                showInLegend: data.filter(arr => arr[1] != null).length,
                data: data,
                name: _chartData[0].series[j].name,
                yAxis: y_axis.indexOf(
                  y_axis.filter(
                    ele => ele.title.text == chartYAxis[0].unit[j]
                  )[0]
                )
              });

              used_colors.push(this.themeColors[j % this.themeColors.length]);
            }

            // add a trend line if option is set
            if (
              this.displayTrendLine &&
              (this.diagramType == 'scatter' ||
                this.diagramType == 'column' ||
                this.diagramType == 'spline')
            ) {
              for (let i = 0; i < panel.chartData[0].series.length; i++) {
                if (panel.chartData[0].series[i].trend) {
                  data_arr[i].type = 'line';
                }
              }
            }
            if (y_axis.length != this.axisRange.length) {
              // chart data was edited (datapoint added or removed) -> reset the axis settings
              this.axisRange = [];
              for (let a_idx = 0; a_idx < y_axis.length; a_idx++) {
                this.axisRange.push([null, null]);
              }
            }
            // set the yAxis range if option is set
            for (let i = 0; i < this.axisRange.length; i++) {
              y_axis[i].min =
                this.axisRange[i][0] == null || this.axisRange[i][0].length == 0
                  ? null
                  : parseFloat(this.axisRange[i][0]);
              y_axis[i].max =
                this.axisRange[i][1] == null || this.axisRange[i][1].length == 0
                  ? null
                  : parseFloat(this.axisRange[i][1]);
              y_axis[i].startOnTick =
                y_axis[i].min != null || y_axis[i].max != null ? false : true;
              y_axis[i].endOnTick = y_axis[i].startOnTick;
            }

            chartOptions.colorAxis = null;
            chartOptions['series'] = data_arr;
            chartOptions['yAxis'] = y_axis;
            chartOptions['colors'] = used_colors;
            break;
        }

        for (let i = 0; i < chartYAxis[0].unit.length; i++) {
          if (chartYAxis[0].unit[0] != chartYAxis[0].unit[i]) {
            this.comparedUnit = false;
            break;
          } else {
            this.comparedUnit = true;
          }
        }

        if (_chartData[0].type == 'table') {
          this.setTableView();
        }
        // sets stacked option for column, bar and area charts
        if (this.stackedColumn == true && panel.type == 'column') {
          chartOptions['plotOptions'] = {
            column: {
              stacking: 'normal'
            }
          };
        }

        if (this.stackedBar == true && panel.type == 'bar') {
          chartOptions['plotOptions'] = {
            bar: {
              stacking: 'normal'
            }
          };
        }

        if (this.stackedArea == false && panel.type == 'area') {
          chartOptions['plotOptions'] = {
            area: {
              stacking: ''
            }
          };
        }

        this.upperLimit = panel.upperLimit ? panel.upperLimit : this.upperLimit;
        this.lowerLimit = panel.lowerLimit ? panel.lowerLimit : this.lowerLimit;
        // sets tolerancelimit  for column,spline and bar charts
        if (
          panel.type == 'column' ||
          panel.type == 'bar' ||
          panel.type == 'spline'
        ) {
          if (this.lowerLimit && this.upperLimit) {
            chartOptions['plotOptions'][panel.type]['zones'] = [
              {
                value: this.lowerLimit,
                color: 'red'
              },
              {
                value: this.upperLimit
              },
              {
                color: 'red'
              }
            ];
          } else if (this.upperLimit) {
            this.lowerLimit = undefined;
            chartOptions['plotOptions'][panel.type]['zones'] = [
              {
                value: this.upperLimit
              },
              {
                color: 'red'
              },
              {
                value: this.lowerLimit
              }
            ];
          } else if (this.lowerLimit) {
            this.upperLimit = undefined;
            chartOptions['plotOptions'][panel.type]['zones'] = [
              {
                value: this.lowerLimit,
                color: 'red'
              },
              {
                value: this.upperLimit
              }
            ];
          } else {
            chartOptions['plotOptions'][panel.type]['zones'] = [
              {
                value: undefined
              }
            ];
          }
        }

        if (panel.compareNodes) {
          if (panel.sortBySize) {
            let series = JSON.parse(JSON.stringify(chartOptions.series));
            let sum = [];
            for (let item of series) {
              item.data.forEach((arr, i) => {
                if (series.indexOf(item) == 0) {
                  sum.push(arr);
                } else {
                  sum[i][1] += arr[1];
                }
              });
            }
            sum.sort((a, b) => {
              if (a[1] < b[1]) return 1;
              if (a[1] > b[1]) return -1;
              return 0;
            });
            let ranking = [];
            sum.forEach(item => ranking.push(item[0]));
            for (let item of chartOptions.series) {
              item.data.sort((a, b) => {
                return ranking.indexOf(a[0]) - ranking.indexOf(b[0]);
              });
            }
            panel.tableData.sort((a, b) => {
              return (
                ranking.indexOf(a[panel.tableHeader[0].value]) -
                ranking.indexOf(b[panel.tableHeader[0].value])
              );
            });
            this.tableContent = panel.tableData;
            chartOptions.xAxis['categories'] = ranking;
          } else {
            let categories = [];
            panel.tableData.forEach(item => {
              categories.push(item[panel.tableHeader[0].value]);
            });
            chartOptions.xAxis['categories'] = categories;
          }
        }

        this.chartOptions = chartOptions;
        if (panel.topFlop.isActive != 0)
          this.cutData(panel.topFlop.isActive, panel.topFlop.n);
        this.prepareCsv();
      }

      if (panel.startDate === null) {
        let begginingOfTheLastYear = new Date(
          new Date().getFullYear() - 1,
          0,
          1,
          2
        );
        this.fromDateVal = new Date(begginingOfTheLastYear)
          .toISOString()
          .substr(0, 10);
      } else if (panel.startDate != new Date(this.fromDateVal).getTime()) {
        this.fromDateVal = new Date(
          tsFrom - new Date(tsFrom).getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }

      if (panel.endDate === null) {
        this.toDateVal = new Date(new Date().getFullYear() - 1, 11, 32, 1)
          .toISOString()
          .substr(0, 10);
      } else if (panel.endDate != new Date(this.toDateVal).getTime()) {
        this.toDateVal = new Date(
          tsTo - new Date(tsTo).getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10);
      }
    },
    async getData(from, to, res) {
      await this.fetchData(from, to, res);
      this.prepareChartOptions(res);
      //this.$store.dispatch('nodes/updateUserConfig');
      this.$store.dispatch('nodes/updateStore');
    },
    async applyAll() {
      this.showDateMenu = false;
      let startDate;
      let endDate;
      let res = this.datResPicker;
      if (this.dateTab == 0) {
        startDate = new Date(this.fromDateVal).setHours(0, 0, 0, 0);
        endDate = new Date(this.toDateVal).setHours(23, 0, 0, 0);
        if (endDate < startDate) {
          this.dateAlert = true;
          this.fromDateVal = this.settings.startDate;
          this.toDateVal = this.settings.endDate;
          return;
        }
        this.$store.dispatch('nodes/updateAllPanelSettingRequest', {
          startDate: startDate,
          endDate: endDate,
          res: res
        });
      } else {
        let today = new Date(new Date().setHours(0, 0, 0, 0));
        let weekday = today.getDay() == 0 ? 6 : today.getDay() - 1;
        switch (this.relativeDate.timespan) {
          case 'w':
            startDate = new Date(today).setDate(
              today.getDate() - weekday - 7 * this.relativeDate.count
            );
            endDate = new Date(
              today.setDate(today.getDate() - weekday - 1)
            ).setHours(23, 0, 0, 0);
            break;
          case 'm':
            startDate = Date.parse(
              new Date(
                today.getFullYear(),
                today.getMonth() - this.relativeDate.count,
                1
              )
            );
            endDate = new Date(today.getFullYear(), today.getMonth());
            endDate = endDate.setHours(-1);
            break;
          case 'y':
            startDate = Date.parse(
              new Date(today.getFullYear() - this.relativeDate.count, 0, 1)
            );
            endDate = new Date(today.getFullYear() - 1, 11, 31);
            endDate = endDate.setHours(23, 0, 0, 0);
            break;
          default:
            break;
        }
        this.$store.dispatch('nodes/updateAllPanelSettingRequest', {
          startDate: startDate,
          endDate: endDate,
          res: res,
          relativeDate: this.relativeDate
        });
      }
    },
    async applyCurrent() {
      this.showDateMenu = false;
      //console.log('datres', this.datResPicker);
      //console.log(this.$refs.datResPicker.selectedItems[0].value)
      //console.log('applied date');
      //console.log(this.fromDateVal, this.toDateVal);
      //await this.getData(this.fromDateVal, this.toDateVal, this.datResPicker);
      let startDate;
      let endDate;
      let relativeDate = JSON.parse(JSON.stringify(this.panel.relativeDate));
      if (this.dateTab == 0) {
        relativeDate.active = false;
        startDate = new Date(this.fromDateVal).setHours(0, 0, 0, 0);
        endDate = new Date(this.toDateVal).setHours(23, 0, 0, 0);
        if (endDate < startDate) {
          this.dateAlert = true;
          this.fromDateVal = this.settings.startDate;
          this.toDateVal = this.settings.endDate;
          return;
        }
      } else {
        relativeDate.active = true;
        let today = new Date(new Date().setHours(0, 0, 0, 0));
        let weekday = today.getDay() == 0 ? 6 : today.getDay() - 1;
        switch (relativeDate.timespan) {
          case 'w':
            startDate = new Date(today).setDate(
              today.getDate() - weekday - 7 * relativeDate.count
            );
            endDate = new Date(
              today.setDate(today.getDate() - weekday - 1)
            ).setHours(23, 0, 0, 0);
            break;
          case 'm':
            startDate = Date.parse(
              new Date(
                today.getFullYear(),
                today.getMonth() - relativeDate.count,
                1
              )
            );
            endDate = new Date(today.getFullYear(), today.getMonth());
            endDate = endDate.setHours(-1);
            break;
          case 'y':
            startDate = Date.parse(
              new Date(today.getFullYear() - relativeDate.count, 0, 1)
            );
            endDate = new Date(today.getFullYear() - 1, 11, 31);
            endDate = endDate.setHours(23, 0, 0, 0);
            break;
        }
      }
      let panel = this.$props.panel;
      this.$store.dispatch('nodes/updatePanelSettingRequest', {
        id: panel.id,
        startDate: startDate,
        endDate: endDate,
        res: this.datResPicker,
        relativeDate: relativeDate
      });
    },
    setTableView() {
      if (this.$props.panel.type == 'pie') {
        this.$props.panel.type = 'table';
        this.getData(this.fromDateVal, this.toDateVal, this.datResPicker);
      } else {
        this.$props.panel.type = 'table';
      }
      this.diagramType = 'table';
      this.showTableData = true;
      this.tableContent = [...this.$props.panel.tableData];
      this.tableHeader = [...this.$props.panel.tableHeader];
      this.showChartMenu = false;
    },
    async setChartTypeView(type) {
      if (
        type == 'pie' ||
        this.$props.panel.type == 'pie' ||
        this.$props.panel.type == 'heatmap' ||
        type == 'heatmap'
      ) {
        this.$props.panel.type = type;
        this.$props.panel.chartData[0].type = type;
        await this.getData(this.fromDateVal, this.toDateVal, this.datResPicker);
      } else {
        this.$props.panel.type = type;
        this.$props.panel.chartData[0].type = type;
      }
      this.showTableData = false;
      this.chartOptions = {
        ...this.chartOptions,
        chart: {
          type: type
        }
      };
      let panel = this.$props.panel;
      this.$store.dispatch('nodes/updatePanelSettingRequest', {
        id: panel.id,
        type: type
      });
      this.diagramType = type;
      this.toggleUpdate();
      this.showChartMenu = false;
      this.prepareChartOptions(this.datResPicker);
    },
    toggleUpdate() {
      this.updateChart = !this.updateChart;
    },
    exportPNG() {
      this.showChartMenu = false;
      this.$refs.chart.chart.exportChart();
    },

    cutData(topFlop, n) {
      let panel = this.$props.panel;
      if (n > panel.tableData.length) {
        n = panel.tableData.length;
        panel.topFlop.n = panel.tableData.length;
      }
      if (!panel.sortBySize && topFlop != 0) {
        panel.sortBySize = true;
        this.prepareChartOptions(panel.res);
      }
      if (topFlop == 0) {
        this.tableContent = panel.tableData;
        for (let i in this.chartOptions.series) {
          this.chartOptions.series[i].data = panel.chartData[0].data[i];
        }
      } else if (topFlop == 1) {
        this.tableContent = panel.tableData.slice(0, n);
        for (let i in this.chartOptions.series) {
          this.chartOptions.series[i].data = panel.chartData[0].data[i].slice(
            0,
            n
          );
        }
      } else {
        this.tableContent = panel.tableData.slice(panel.tableData.length - n);
        for (let i in this.chartOptions.series) {
          this.chartOptions.series[i].data = panel.chartData[0].data[i].slice(
            panel.tableData.length - n
          );
        }
      }
      let newCategories = [];
      for (let title of this.tableContent) {
        newCategories.push(title['NodeName']);
      }
      this.chartOptions.xAxis.categories = newCategories;
    },
    saveAndResetColorPicker() {
      if (this.colorPicker.activeColor != this.colorPicker.savedColor) {
        this.$store.dispatch(
          'layout/addLastUsedColors',
          this.colorPicker.activeColor
        );
        let color = {};
        if (
          this.themeColors[
            this.colorPicker.activeIndex % this.themeColors.length
          ] == this.colorPicker.activeColor
        )
          //this.panel.chartColors[this.colorPicker.activeIndex]
          color = {
            color: (' ' + this.colorPicker.activeColor).slice(1),
            isCustom: false,
            datapoint: this.panel.vars[this.colorPicker.activeIndex]
          };
        //this.panel.chartColors[this.colorPicker.activeIndex]
        else
          color = {
            color: (' ' + this.colorPicker.activeColor).slice(1),
            isCustom: true,
            datapoint: this.panel.vars[this.colorPicker.activeIndex]
          };
        if (this.panel.type == 'pie')
          this.chartOptions.series.data[
            this.colorPicker.activeIndex
          ].color = this.colorPicker.activeColor;
        else
          this.chartOptions.series[
            this.colorPicker.activeIndex
          ].color = this.colorPicker.activeColor;
        this.$store.dispatch('nodes/updatePanelSettingRequest', {
          id: this.panel.id,
          colorIndex: this.colorPicker.activeIndex,
          color: color
        });
      }
      this.colorPicker.activeColor = '';
      this.colorPicker.savedColor = '';
      this.colorPicker.activeIndex = null;
    },
    setActiveColorPicker(index) {
      if (this.colorPicker.activeIndex != null) {
        this.saveAndResetColorPicker();
      }
      this.colorPicker.activeIndex = index;
      this.colorPicker.activeColor = this.panel.chartColors[index].color;
      this.colorPicker.savedColor = (' ' + this.colorPicker.activeColor).slice(
        1
      );
    },
    resetChartColors() {
      let colors = [];
      for (let index in this.panel.chartColors) {
        //this.panel.chartColors[index] =
        colors.push({
          color: this.themeColors[index % this.themeColors.length],
          isCustom: false
        });
        if (this.panel.type == 'pie')
          this.chartOptions.series.data[index].color = this.themeColors[
            index % this.themeColors.length
          ];
        else
          this.chartOptions.series[index].color = this.themeColors[
            index % this.themeColors.length
          ];
      }
      this.$store.dispatch('nodes/updatePanelSettingRequest', {
        id: this.panel.id,
        chartColors: colors
      });
    },
    prepareCsv() {
      let arr = [];
      let title = '';
      let breadcrumb = this.$store.state.nodes.breadcrumb;
      let node = breadcrumb[breadcrumb.length - 1];

      for (let index in this.tableContent) {
        let obj = {};
        for (let head of this.tableHeader) {
          obj[head.text] = this.tableContent[index][head.value];
          if (this.locale == 'de' && typeof obj[head.text] == 'number')
            obj[head.text] = obj[head.text].toString(10).replace('.', ',');
        }
        arr.push(obj);
      }

      if (node.type == 'a' || node.level < 2) {
        title = node.text;
      } else {
        for (let item of breadcrumb) {
          if (item.type == 'a' || item.level >= 2) {
            if (title == '') {
              title = title + item.text;
            } else {
              title = title + '-' + item.text;
            }
          }
        }
      }
      this.csv.content = arr;
      this.csv.title = title.replace(/[/\\?%*:|"<>]/g, '_');
    }
  }
};
</script>

<style src="./Chart.scss" lang="scss" />
