<script>
import TreeModel from './utils/tree-model';
import FinderList from './FinderList';

import { mapActions, mapState } from 'vuex';

import functionalityStyle from './mixins/functionalityStyle.mixin';
import panelControl from './mixins/panelControl.mixin';
import contentControl from './mixins/contentControl.mixin';

import NodeTreeBurger from './NodeTreeBurger.vue';
import NodeTreeShadow from './NodeTreeShadow.vue';

/**
 * Render the tree of an item and its selected children.
 *
 * @param {Object} h          `createElement` object
 * @param {Object} context    Context component
 * @param {Object} item       Item to render
 * @return Rendering object
 */
function renderTree(h, context, item, width) {
  if (!item || !item.nodes || item.nodes.length === 0) {
    return null;
  }

  const expandedChild = item.nodes.find(child => {
    return context.treeModel.isNodeExpanded(child.id);
  });

  const options = {
    sortBy: context.sortBy,
    itemComponent: context.itemComponent,
    arrowComponent: context.arrowComponent,
    dragImageComponent: context.dragImageComponent,
    dropZoneComponent: context.dropZoneComponent,
    theme: context.theme,
    hasDragHandle: context.hasDragHandle,
    canDrop: context.canDrop
  };

  const itemList = (
    <FinderList
      ref="rootList"
      tree-model={context.treeModel}
      parent={item}
      items={item.nodes}
      selectable={context.selectable}
      drag-enabled={context.dragEnabled}
      options={options}
      has-expanded-item={!expandedChild}
      context={context}
    />
  );

  let counter = 0;

  let pushContainer = 0;

  let style = {};
  let activeListContainer = false;

  if (counter < context.treeModel.expanded.length) {
    let visibleTree = context.treeModel.expanded;
    if (width > 1264 && visibleTree.length - 4 > 0) {
      visibleTree = visibleTree.slice(-4);

      visibleTree.filter(item => {
        return (
          item === expandedChild?.parent ||
          item === visibleTree[visibleTree.length - 1]
        );
      });

      if (visibleTree[0] === expandedChild?.parent) {
        style = {
          position: 'absolute',
          left: '70px'
        };

        activeListContainer = true;
      }
    } else if (width <= 1264 && width > 960 && visibleTree.length - 3 > 0) {
      visibleTree = visibleTree.slice(-3);

      visibleTree.filter(item => {
        return (
          item === expandedChild?.parent ||
          item === visibleTree[visibleTree.length - 1]
        );
      });

      if (visibleTree[0] === expandedChild?.parent) {
        style = {
          position: 'absolute',
          left: '70px'
        };

        activeListContainer = true;
      }
    } else if (width <= 960 && width > 600 && visibleTree.length - 2 > 0) {
      visibleTree = visibleTree.slice(-2);

      visibleTree.filter(item => {
        return (
          item === expandedChild?.parent ||
          item === visibleTree[visibleTree.length - 1]
        );
      });

      if (visibleTree[0] === expandedChild?.parent) {
        style = {
          position: 'absolute',
          left: '70px'
        };

        activeListContainer = true;
      }

      if (expandedChild == undefined) {
        style = {
          position: 'relative',
          left: 'initial'
        };

        activeListContainer = true;
      }
    } else {
      if (width <= 600 && visibleTree.length > 1) {
        visibleTree = visibleTree.slice(-1);

        if (visibleTree[0]) {
          style = {
            position: 'absolute',
            left: '0'
          };

          activeListContainer = true;
        }
      }
    }
  }

  return (
    <div
      class={
        activeListContainer
          ? 'list-container--active list-container'
          : ' list-container'
      }
      style={{
        marginLeft: `${pushContainer}px`,
        ...style
      }}
    >
      {itemList}
      {expandedChild && renderTree(h, context, expandedChild, width)}
    </div>
  );
}
/**
 * Get a value animated by a ease out Bezier curve.
 */
function easeOutQuad(elapsedTime, start, end, duration) {
  return -end * (elapsedTime /= duration) * (elapsedTime - 2) + start;
}

export default {
  name: 'FinderComponent',
  components: {
    NodeTreeBurger,
    NodeTreeShadow,
    FinderList
  },
  mixins: [functionalityStyle, panelControl, contentControl],

  props: {
    tree: {
      type: Object,
      required: true
    },
    /**
     * Enable the selection of items.
     *
     * ::: tip
     * You can disable the selection on some items by setting them `selectable: false`.
     * :::
     *
     * ::: tip
     * You can set some items selected by default by setting them `selected: true`.
     * :::
     */
    selectable: {
      type: Boolean,
      default: false
    },
    /**
     * Whether all its descendants should be automatically selected when an item is selected.
     */
    autoSelectDescendants: {
      type: Boolean,
      default: false
    },
    /**
     * Whether all its descendants should be automatically deselected when an item is deselected.
     */
    autoDeselectDescendants: {
      type: Boolean,
      default: false
    },
    /**
     * Function to filter displayed items.
     *
     * ```js
     * const filter = item => /^$myitem/.test(item.label);
     * ```
     */
    filter: {
      type: Function,
      default: undefined
    },
    /**
     * Function to sort displayed items.
     *
     * ```js
     * const sortBy = (item1, item2) => item1.id > item2.id ? -1 : item1.id < item2.id ? 1 : 0;
     * ```
     */
    sortBy: {
      type: Function,
      default: undefined
    },
    /**
     * ID of the item expanded when loaded.
     */
    defaultExpanded: {
      type: String,
      default: undefined
    },
    /**
     * Custom component to render items.
     */
    itemComponent: {
      type: [String, Object],
      default: undefined
    },
    /**
     * Custom component to render arrows (on items with children).
     */
    arrowComponent: {
      type: [String, Object],
      default: undefined
    },
    /**
     * Styling options.
     */
    theme: {
      type: Object,
      default: () => ({})
    },
    /**
     * Duration of the scroll animation (in milliseconds).
     * When an item is expanded, the finder is scrolled to the right,
     * using an animation. This parameter defines the duration of this animation.
     *
     * Set `0` for no animation.
     */
    scrollAnimationDuration: {
      type: Number,
      default: 200
    }
  },
  setup() {
    let width = window.innerWidth;
    return {
      width
    };
  },
  data() {
    return {
      treeModel: {},
      rootLevel: 0,
      breadcrumb: [{ id: '0', text: 'Anlage auswählen' }]
    };
  },

  computed: {
    ...mapState('nodes', {
      nodes: state => state.nodes
    }),
    ...mapState('nodes', ['isBreadcrumbActive']),
    ROOT_NODES_STATE() {
      return this.nodes;
    },
    listStyle() {
      return {
        width: `${this.width}px`
      };
    }
  },
  watch: {
    tree(newTree) {
      this.treeModel.root = newTree;
    },
    filter(newFilter) {
      this.treeModel.filter = newFilter;
    },
    autoSelectDescendants(autoSelectDescendants) {
      this.treeModel.autoSelectDescendants = autoSelectDescendants;
    },
    autoDeselectDescendants(autoDeselectDescendants) {
      this.treeModel.autoDeselectDescendants = autoDeselectDescendants;
    }
  },
  beforeCreate() {
    Object.defineProperty(this.$options.propsData, 'tree', {
      configurable: false
    });
  },

  destroyed() {
    window.removeEventListener('resize', this.isResizeNavigation);
  },
  created() {
    window.addEventListener('resize', this.isResizeNavigation);

    this.treeModel = new TreeModel(this.tree, {
      filter: this.filter,
      defaultExpanded: this.defaultExpanded,
      autoSelectDescendants: this.autoSelectDescendants,
      autoDeselectDescendants: this.autoDeselectDescendants,
      rootLevel: this.rootLevel
    });
    this.treeModel.on('expand', (expanded, sourceEvent) => {
      if (sourceEvent !== 'dragover') {
        this.$nextTick(() => {
          this._scrollToRight(this.scrollAnimationDuration);
        });
      }

      /**
       * This event is triggered when an item has been expanded.
       *
       * ```html
       * <Finder :tree="tree" @expand="onExpand"/>
       * ```
       *
       * ```js
       * onExpand({ expanded, sourceEvent }) {
       *   console.log(
       *     `Items with ${expanded.join()} IDs are now expanded`
       *   );
       * }
       * ```
       *
       * @event expand
       * @type {object}
       * @property {Array<string>} expanded    IDs of expanded items
       * @property {string}        sourceEvent Name of the event that triggered the action
       *                                       (`"click"`, `"focus"`, `"drop"`, `"dragover"` or `undefined`)
       */
      this.$emit('expand', {
        expanded,
        sourceEvent
      });
    });
    this.treeModel.on('select', selected => {
      /**
       * This event is triggered when an item has been selected.
       *
       * ```html
       * <Finder :tree="tree" @select="onSelect"/>
       * ```
       *
       * ```js
       * onSelect({ selected }) {
       *   console.log(
       *     `Items with ${selected.join()} IDs are now selected`
       *   );
       * }
       * ```
       *
       * @event select
       * @type {object}
       * @property {Array<string>} selected IDs of selected items
       */
      this.$emit('select', {
        selected
      });
    });
    this.treeModel.on('move', ({ moved, to, index }) => {
      /**
       * This event is triggered when an item has been moved by drag and drop.
       * When an item is dropped on a dropzone between two elements, a `index` is also provided.
       *
       * ```html
       * <Finder :tree="tree" @move="onMove"/>
       * ```
       *
       * ```js
       * onMove({ moved, to, index }) {
       *   console.log(
       *     `Item with ${moved} ID has been moved
       *     to its new parent with ${to} ID`
       *   );
       * }
       * ```
       *
       * @event move
       * @type {object}
       * @property {string} moved ID of the moved item
       * @property {string} to    ID of the parent on which the item has been moved to
       * @property {number} index Index of the dropzone
       */
      this.$emit('move', {
        moved,
        to,
        index
      });
    });
  },

  methods: {
    clickBurger() {
      let breadcrumb = this.$store.state.nodes.breadcrumb;
      let root = document.getElementsByTagName('html')[0];
      if (
        breadcrumb !== undefined &&
        breadcrumb !== null &&
        breadcrumb.length > 1
      ) {
        this.expand(breadcrumb[breadcrumb.length - 2].id);
      } else {
        this.expand('root');
      }

      this.$store.dispatch('nodes/changeIsBreadcrumbActive');

      if (this.isBreadcrumbActive) {
        root.classList.add('v-tree-view--open');
      } else {
        if (
          breadcrumb !== undefined &&
          breadcrumb !== null &&
          breadcrumb.length == 1
        ) {
          this.expand(breadcrumb[breadcrumb.length - 1].id);
        }

        root.classList.remove('v-tree-view--open');
      }
    },
    /*clickShadow() {
      //this.$store.dispatch('nodes/changeIsBreadcrumbActive', false);
      this.clickBurger();
    },*/
    isResizeNavigation() {
      let width = window.innerWidth;
      return width;
    },
    /**
     * Set a given item expanded.
     *
     * ```html
     * <Finder :tree="tree" ref="myFinder" />
     * ```
     *
     * ```js
     * this.$refs.myFinder.expand('item111');
     * ```
     *
     * @param {string} itemId      ID of the item to expand
     * @param {string} sourceEvent Source event that will appear in `expand` event
     *                             (`api` by default)
     */
    expand(itemId, sourceEvent = 'api') {
      this.treeModel.expandNode(itemId, sourceEvent);
    },
    _scrollToRight(scrollDuration) {
      const { scrollLeft, scrollWidth, offsetWidth } = this.$el;

      if (scrollDuration === 0) {
        this.$el.scrollLeft = scrollWidth;
        return;
      }

      const scrollDistance = scrollWidth - offsetWidth - scrollLeft;
      if (scrollDistance <= 0) {
        return;
      }

      let oldTimestamp = performance.now();
      let duration = 0;
      const step = newTimestamp => {
        const stepDuration = newTimestamp - oldTimestamp;
        duration += stepDuration;

        if (duration >= scrollDuration) {
          this.$el.scrollLeft = this.$el.scrollWidth;
          return;
        }

        oldTimestamp = newTimestamp;

        this.$el.scrollLeft = easeOutQuad(
          duration,
          scrollLeft,
          scrollDistance,
          scrollDuration
        );
        window.requestAnimationFrame(step);
      };
      window.requestAnimationFrame(step);
    }
  },
  render(h) {
    return (
      <div class="v-tree-view">
        <NodeTreeBurger
          handleBurgerClicked={item => this.clickBurger(item)}
          list={this.breadcrumb}
        />

        <NodeTreeShadow
          isActive={this.isBreadcrumbActive}
          handleShadowClicked={this.clickBurger}
        />
        <div
          class={
            this.isBreadcrumbActive
              ? 'v-tree-view--wrapper breadcrumbBackground v-tree-view--wrapper-active'
              : 'v-tree-view--wrapper breadcrumbBackground'
          }
        >
          <div class="v-tree-overlap">
            <div class="v-tree-view--finder breadcrumbBackground">
              <div class="v-finder breadcrumbBackground">
                {this.treeModel &&
                  renderTree(
                    h,
                    this,
                    this.tree, //Model.visibleTree,
                    this.isResizeNavigation()
                  )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
</script>

<style src="./Finder.scss" lang="scss"></style>
