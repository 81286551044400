<template>
  <path
    d="M1 1L8.16962 7L15.3392 1"
    :stroke="strokeColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  />
</template>

<script>
export default {
  props: {
    strokeColor: {
      type: String,
      default: '#143857',
      required: false
    }
  }
};
</script>
