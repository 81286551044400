<template>
  <path
    :fill="strokeColor"
    :stroke="strokeColor"
    stroke-width="0.1"
    stroke-linecap="round"
    stroke-linejoin="round"
    d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12M8.8,14L10,12.8V4H14V12.8L15.2,14H8.8Z"
  />
</template>

<script>
export default {
  props: {
    strokeColor: {
      type: String,
      default: '#143857',
      required: false
    }
  }
};
</script>
