<template>
  <v-app>
    <v-container fluid class="error-page">
      <v-row class="d-flex justify-center">
        <v-col cols="8">
          <div class="card">
            <span class="error-logo"> {{ $t('error.status') }}</span>
            <p class="error-text">
              {{ $t('error.messageText') }}
            </p>
            <p class="error-subtext">
              {{ $t('error.messageSubText') }}
            </p>
            <v-btn
              class="text-capitalize"
              x-large
              color="firstColor"
              to="/dashboard"
            >
              {{ $t('error.back') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import config from '@/config';

export default {
  name: 'ErrorPage',
  data() {
    return {
      config
    };
  }
};
</script>

<style src="./Error.scss" lang="scss"></style>
