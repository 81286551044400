import config from '../config/index';
import { save_User_Config } from '../api/services';

export default {
  namespaced: true,
  state: {
    drawer: false,
    node: true,
    dev: false,
    theme: {
      name: config.defaultTheme,
      isDark: false
    },
    grid: 'grid-view-2cols',
    nodeWidth: 300,
    themeconfig: [
      {
        name: 'Default',
        dark: {
          logo: 'nexerion_weiss.svg',
          tabText: true // true for the white text color / false for the black text color
        },
        light: {
          logo: 'nexerion_blau.svg',
          tabText: false // true for the white text color / false for the black text color
        }
      },
      {
        name: 'Nexerion',
        dark: {
          logo: 'nexerion_weiss.svg',
          tabText: true // true for the white text color / false for the black text color
        },
        light: {
          logo: 'nexerion_weiss.svg',
          tabText: true // true for the white text color / false for the black text color
        }
      },
      {
        name: 'Gegenbauer',
        dark: {
          logo: 'gegenbauer-dark-logo.svg',
          tabText: true // true for the white text color / false for the black text color
        },
        light: {
          logo: 'gegenbauer-light-logo.svg',
          tabText: false // true for the white text color / false for the black text color
        }
      },
      {
        name: 'Evo',
        dark: {
          logo: 'evo-logo.svg',
          tabText: true // true for the white text color / false for the black text color
        },
        light: {
          logo: 'evo-logo.svg',
          tabText: false // true for the white text color / false for the black text color
        }
      },
      {
        name: 'Wiro',
        dark: {
          logo: 'wiro-logo.svg',
          tabText: true // true for the white text color / false for the black text color
        },
        light: {
          logo: 'wiro-logo.svg',
          tabText: false // true for the white text color / false for the black text color
        }
      },
      {
        name: 'IVL Langer',
        dark: {
          logo: 'IVL_LangerLogo.svg',
          tabText: true // true for the white text color / false for the black text color
        },
        light: {
          logo: 'IVL_LangerLogo.svg',
          tabText: false // true for the white text color / false for the black text color
        }
      },
      {
        name: 'SWB Bonn',
        dark: {
          logo: 'SWB_Logo.svg',
          tabText: true // true for the white text color / false for the black text color
        },
        light: {
          logo: 'SWB_Logo.svg',
          tabText: false // true for the white text color / false for the black text color
        }
      },
      {
        name: 'GASAG',
        dark: {
          logo: 'dark-logo.svg',
          tabText: true // true for the white text color / false for the black text color
        },
        light: {
          logo: 'dark-logo.svg',
          tabText: false // true for the white text color / false for the black text color
        }
      }
    ]
  },
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },
    toggleNode(state) {
      state.node = !state.node;
    },
    toggleGrid(state, payload) {
      state.grid = payload;
    },
    handleNodeWidth(state, payload) {
      state.nodeWidth = payload;
    },
    SET_THEME(state, payload) {
      state.theme = { ...payload };
    },
    SET_DEV(state, payload) {
      state.dev = payload;
    },
    SET_LANGUAGE(state, payload) {
      state.language = payload;
    },
    SET_CHARTCOLORS(state, payload) {
      state.theme['chartColors'] = payload;
    },
    SET_LASTUSEDCOLORS(state, payload) {
      state['lastUsedColors'] = payload;
    },
    ADD_LASTUSEDCOLORS(state, payload) {
      if (state['lastUsedColors'].indexOf(payload) == -1) {
        state['lastUsedColors'].unshift(payload);
        if (state['lastUsedColors'].length > 7) state['lastUsedColors'].pop();
      } else {
        state['lastUsedColors'].sort(function(x, y) {
          return x == payload ? -1 : y == payload ? 1 : 0;
        });
      }
    }
  },
  actions: {
    async setLanguage({ commit, dispatch }, payload) {
      if (payload.language === undefined) commit('SET_LANGUAGE', payload);
      else commit('SET_LANGUAGE', payload.language);
      if (payload.gotConfig === undefined || !payload.gotConfig)
        await dispatch('saveUserConfig');
    },
    TOGGLE_DRAWER({ commit }) {
      commit('toggleDrawer');
    },
    TOGGLE_NODE({ commit }) {
      commit('toggleNode');
    },
    async TOGGLE_GRID({ commit, dispatch }, payload) {
      if (payload.grid === undefined) commit('toggleGrid', payload);
      else commit('toggleGrid', payload.grid);
      if (payload.grid === undefined || !payload.grid)
        await dispatch('saveUserConfig');
    },
    HANDLE_NODE_WIDTH({ commit }, payload) {
      commit('handleNodeWidth', payload);
    },
    async setTheme({ commit, dispatch }, payload) {
      if (payload.theme === undefined) commit('SET_THEME', payload);
      else commit('SET_THEME', payload.theme);
      if (!payload.gotConfig || payload.gotConfig === undefined) {
        await dispatch('saveUserConfig');
      }
    },
    async setDev({ commit, dispatch }, payload) {
      commit('SET_DEV', payload);
      await dispatch('saveUserConfig');
    },
    async setLastUsedColors({ commit, dispatch }, payload) {
      if (payload.lastUsedColors === undefined)
        commit('SET_LASTUSEDCOLORS', payload);
      else commit('SET_LASTUSEDCOLORS', payload.lastUsedColors);
      if (payload.gotConfig === undefined || !payload.gotConfig) {
        await dispatch('saveUserConfig');
      }
    },
    async addLastUsedColors({ commit, dispatch }, payload) {
      commit('ADD_LASTUSEDCOLORS', payload);
      await dispatch('saveUserConfig');
    },
    getUserConfig({ dispatch }) {
      let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));

      if (
        localUserConfig.theme !== undefined &&
        localUserConfig.grid !== undefined
      ) {
        dispatch('setTheme', { theme: localUserConfig.theme, gotConfig: true });
        dispatch('TOGGLE_GRID', {
          grid: localUserConfig.grid,
          gotConfig: true
        });
      }
      if (localUserConfig.language !== undefined) {
        dispatch('setLanguage', {
          language: localUserConfig.language,
          gotConfig: true
        });
      }
      if (localUserConfig.lastUsedColors !== undefined) {
        dispatch('setLastUsedColors', {
          lastUsedColors: localUserConfig.lastUsedColors,
          gotConfig: true
        });
      } else {
        dispatch('setLastUsedColors', {
          lastUsedColors: [],
          gotConfig: true
        });
      }
    },
    async saveUserConfig() {
      let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));

      localUserConfig.theme = this.state.layout.theme;
      localUserConfig.grid = this.state.layout.grid;
      localUserConfig.language = this.state.layout.language;
      localUserConfig.lastUsedColors = this.state.layout.lastUsedColors;

      localStorage.setItem('userconfig', JSON.stringify(localUserConfig));
      save_User_Config();
    },
    async requestChartColor({ dispatch, commit }, payload) {
      commit('SET_CHARTCOLORS', payload);
      await dispatch('saveUserConfig');
    }
  },
  getters: {
    DRAWER_STATE(state) {
      return state.drawer;
    },
    NODE_STATE(state) {
      return state.node;
    },
    GRID_STATE(state) {
      return state.grid;
    },
    NODE_WIDTH_STATE(state) {
      return state.nodeWidth;
    },
    GET_LAST_USED_COLOR_BY_INDEX: state => index => {
      if (index >= state.lastUsedColors.length) return '';
      else return state.lastUsedColors[index];
    },
    GET_THEME_RESSOURCE: state => ressource => {
      let currentTheme = state.themeconfig.find(
        theme => theme.name == state.theme.name
      );
      if (state.theme.isDark) currentTheme = currentTheme.dark;
      else currentTheme = currentTheme.light;
      if (currentTheme[ressource] !== undefined) return currentTheme[ressource];
      else return 'no ressource with name ' + ressource + ' found.';
    },
    GET_LANGUAGE: state => {
      if (state.language !== undefined) return state.language;
      else return 'de';
    }
  }
};
