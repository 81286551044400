<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="`${x} ${y} ${width} ${height}`"
    :aria-labelledby="iconName"
    role="presentation"
  >
    <title :id="iconName" lang="en">{{ iconName }}</title>
    <g fill="transparent">
      <slot :strokeColor="strokeColor" />
    </g>
  </svg>
</template>

<script>
import config from '../../config';
export default {
  name: 'IconComponent',
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 24
    },
    height: {
      type: [Number, String],
      default: 24
    }
  },
  data: () => ({
    x: 0,
    y: 0,
    themes: config.themes,
    strokeColor: '#143857'
  }),
  methods: {
    getIconColor: function() {
      return this.$vuetify.theme.isDark
        ? this.$vuetify.theme.themes.dark['firstColorIcon']
        : this.$vuetify.theme.themes.light['firstColorIcon'];
    }
  },
  mounted: function() {
    this.strokeColor = this.getIconColor();
  },
  watch: {
    strokeColor: function() {
      this.strokeColor = this.getIconColor();
    },
    '$store.state.layout.theme': function() {
      this.strokeColor = this.getIconColor();
    }
  }
};
</script>
