<template>
  <v-navigation-drawer
    app
    clipped
    v-model="DRAWER_STATE"
    :mini-variant="!DRAWER_STATE"
    :width="sidebarWidth"
    :permanent="$vuetify.breakpoint.mdAndUp"
    :mini-variant-width="sidebarMinWidth"
    :class="{ 'drawer-mini': !DRAWER_STATE }"
    color="sidebar"
  >
    <v-col>
      <v-list>
        <template v-for="(item, i) in items">
          <v-row v-if="item.heading" :key="item.heading">
            <v-col cols="6">
              <span
                style
                class="subheader sidebarText--text"
                :class="item.heading && DRAWER_STATE ? 'show ' : 'hide'"
              >
                {{ $t(item.heading) }}
              </span>
            </v-col>
            <v-col cols="6"> </v-col>
          </v-row>
          <v-divider
            v-else-if="item.divider"
            :key="i"
            dark
            class="my-4"
          ></v-divider>

          <v-list-item
            v-else
            :key="item.text"
            :to="item.link === '#' ? '' : item.link"
            @click="item.action ? item.action() : null"
            link
            color="sidebarActiv"
            :class="item.link === $route.path ? 'sidebarActiv' : ''"
          >
            <v-tooltip v-if="!DRAWER_STATE" right>
              <template v-slot:activator="{ on, attrs }">
                <v-list-item-action v-on="on" v-bind="attrs">
                  <icon-base :icon-name="item.icon">
                    <template>
                      <keep-alive>
                        <component
                          :strokeColor="
                            $vuetify.theme.isDark
                              ? $vuetify.theme.themes.dark[IconColor]
                              : $vuetify.theme.themes.light[IconColor]
                          "
                          :is="item.iconComponent"
                        ></component>
                      </keep-alive>
                    </template>
                  </icon-base>
                </v-list-item-action>
              </template>
              <span>{{ $t(item.title) }}</span>
            </v-tooltip>

            <v-list-item-action v-if="DRAWER_STATE">
              <icon-base :icon-name="item.icon">
                <template>
                  <keep-alive>
                    <component
                      :strokeColor="
                        $vuetify.theme.isDark
                          ? $vuetify.theme.themes.dark[IconColor]
                          : $vuetify.theme.themes.light[IconColor]
                      "
                      :is="item.iconComponent"
                    ></component>
                  </keep-alive>
                </template>
              </icon-base>
            </v-list-item-action>

            <v-list-item-content
              :class="
                item.link === $route.path
                  ? 'text-left sidebarActivText--text'
                  : 'text-left sidebarText--text'
              "
            >
              <v-list-item-title link>
                {{ $t(item.title) }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-col>

    <div>
      <v-btn
        icon
        fab
        class="v-btn-drawer secondColor"
        @click.stop="TOGGLE_DRAWER"
        width="34px"
        height="34px"
      >
        <v-icon v-if="DRAWER_STATE" color="firstColorIcon">
          mdi-chevron-left
        </v-icon>

        <v-icon v-else color="firstColorIcon">
          mdi-chevron-right
        </v-icon>
      </v-btn>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IconBase from '@/components/Icons';
import IconHome from '@/components/Icons/IconHome';
import IconDashboard from '@/components/Icons/IconDashboard';
import IconAnalyse from '@/components/Icons/IconAnalyse';
import IconSettings from '@/components/Icons/IconSettings';
import IconReport from '@/components/Icons/IconReport';
import IconLogout from '@/components/Icons/IconLogout';
import IconArrowRight from '@/components/Icons/IconArrowRight';
import IconArrowLeft from '@/components/Icons/IconArrowLeft';

export default {
  name: 'SidebarComponent',
  props: {
    source: String
  },
  components: { IconBase, IconArrowRight, IconArrowLeft },
  data() {
    let width = window.innerWidth;

    return {
      items: [
        { heading: 'sidebar.sections.navigation' },
        { divider: true },
        {
          title: 'sidebar.links.home',
          icon: 'home',
          iconComponent: IconHome,
          link: '/'
        },
        {
          title: 'sidebar.links.dashboard',
          icon: 'dashboard',
          iconComponent: IconDashboard,
          link: '/dashboard'
        },
        {
          title: 'sidebar.links.analyze',
          icon: 'analyze',
          iconComponent: IconAnalyse,
          link: '/analyze'
        } /*
        {
          title: 'sidebar.links.report',
          icon: 'report',
          iconComponent: IconReport,
          link: '/report'
        }, */,
        { heading: 'sidebar.sections.profile' },
        { divider: true },
        {
          title: 'sidebar.links.settings',
          iocn: 'settings',
          iconComponent: IconSettings,
          link: '/settings'
        },
        {
          title: 'sidebar.links.logout',
          icon: 'logout',
          iconComponent: IconLogout,
          link: '#',
          action: () => this.logoutUser()
        }
      ],
      sidebarWidth: width >= 960 ? 260 : '100%',
      sidebarMinWidth: 78,
      dialog: false,
      windowWidth: width,
      IconColor: 'siderbarIcon'
    };
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  beforeMount() {
    if (this.$store.state.layout.dev) {
      this.items.splice(4, 0, {
        title: 'sidebar.links.report',
        icon: 'report',
        iconComponent: IconReport,
        link: '/report'
      });
    }
  },
  computed: {
    ...mapState('layout', {
      drawer: state => state.drawer
    }),
    DRAWER_STATE: {
      get() {
        return this.drawer;
      },
      set(newValue) {
        if (newValue === this.drawer) return;
        this.TOGGLE_DRAWER();
      }
    },
    mini() {
      return !(this.mdAndUp || this.menuOpen);
    }
  },
  methods: {
    ...mapActions('layout', ['TOGGLE_DRAWER']),
    ...mapActions('auth', ['logoutUser']),
    handleResize: function() {
      let width = window.innerWidth;
      this.windowWidth = width;
      this.sidebarWidth = width >= 960 ? 260 : '100%';
    }
  }
};
</script>

<style src="./Sidebar.scss" lang="scss" />
