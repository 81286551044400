<template>
  <g>
    <path
      d="M6.77781 12.5556C9.96881 12.5556 12.5556 9.96881 12.5556 6.77781C12.5556 3.58681 9.96881 1 6.77781 1C3.58681 1 1 3.58681 1 6.77781C1 9.96881 3.58681 12.5556 6.77781 12.5556Z"
      :stroke="strokeColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.0001 13.999L10.8584 10.8573"
      :stroke="strokeColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </g>
</template>

<script>
export default {
  props: {
    strokeColor: {
      type: String,
      default: '#143857',
      required: false
    }
  }
};
</script>
