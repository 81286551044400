import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import auth from './auth';
import layout from './layout';
import nodes from './nodes';

Vue.use(Vuex);

const vuexPersist = new VuexPersist({
  key: 'energyNode-app',
  storage: window.localStorage
});

export default new Vuex.Store({
  modules: {
    auth,
    layout,
    nodes
  },
  plugins: [vuexPersist.plugin]
});
