/**
 * creates an array of all possible nodes that the user could selected
 * @param {Array} nodes an array of nodes
 * @returns {Array} all nodes in a list that can be selected
 */
export function flatVisibleTree(nodes) {
  let result = [];
  // for each node on this level in that previous node
  nodes.forEach(function(node) {
    // add the node to the result
    result.push(node);
    // if the node is expanded
    if (Array.isArray(node.nodes)) {
      // give the array of all nodes in the node
      result = result.concat(flatVisibleTree(node.nodes));
    }
  });
  return result;
}

/**
 * creates an array of nodes, except the root, up to the selected node from the tree menu
 * @param {Object} node first: selected item from the tree node menu, after: the parent of the previous node
 * @param {Array} flatTree list of visible items
 * @returns {Array} path of nodes to the selected node
 */
export function getNodesAfterRoot(currentNode, flatTree) {
  let node = flatTree.find(treeNode => treeNode.id == currentNode.id);
  let result = [currentNode];
  // if node.level == 0 we already at the last/lowes possible node
  while (node.level != 0) {
    // find the nodeObject of the parent from given node
    let pNode = flatTree.find(treeNode => treeNode.id == node.parent);
    // save founded node
    result.unshift(pNode);
    // navigate to the parent node (e.g. from node level 2 to node level 1)
    node = pNode;
  }
  return result;
}

/**
 * finds out the path of the selected node
 * @param {Object} node selected item from the tree node menu
 * @param {Array} tree list of visible items as a tree structure
 * @return {Array} path to the selected node [{id:'0',text:'...'},{..}..]
 */
export function getBreadcrumbList(node, expanded, tree) {
  let flatTree = flatVisibleTree(tree);
  let breadcrumbList = [...getNodesAfterRoot(node, flatTree)];
  return breadcrumbList;
}
