var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app-bar',{staticClass:"main-header hidden-sm-and-down",attrs:{"elevation":"0","height":"70","fixed":"","color":"header"}},[_c('div',{staticClass:"v-left"},[_c('SidebarToggleBtn'),(!!_vm.logo)?[_c('v-img',{attrs:{"src":require(("@/assets/logos/" + _vm.logo)),"contain":"","width":"222","height":"15","alt":"Gasag Solution Plus"}})]:_vm._e(),_c('NodeTreeView',{directives:[{name:"show",rawName:"v-show",value:(_vm.renderTreeNodeNav),expression:"renderTreeNodeNav"}]})],2),_c('SearchComponent')],1),(_vm.windowWidth < 960 && _vm.windowWidth >= 580)?_c('v-app-bar',{staticClass:"main-headerTwo",attrs:{"elevation":"0","height":"70","fixed":"","color":"header"}},[(_vm.showSearch == false)?_c('div',{staticClass:"v-left"},[_c('SidebarToggleBtn'),(!!_vm.logo)?[_c('v-img',{attrs:{"src":require(("@/assets/logos/" + _vm.logo)),"contain":"","width":"222","height":"15","alt":"Gasag Solution Plus"}})]:_vm._e(),_c('NodeTreeView',{directives:[{name:"show",rawName:"v-show",value:(_vm.renderTreeNodeNav),expression:"renderTreeNodeNav"}]}),_c('v-btn',{staticClass:"secondColor mt-8 ml-4 searchbtn",attrs:{"icon":"","fab":"","small":"","width":"40px","height":"40px"},on:{"click":function($event){return _vm.toggleShowSearch()}},model:{value:(_vm.showSearch),callback:function ($$v) {_vm.showSearch=$$v},expression:"showSearch"}},[[_c('icon-base',{attrs:{"icon-name":"Suche","width":20,"height":15,"viewBox":((-3) + " " + (0) + " " + (20) + " " + (15))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var strokeColor = ref.strokeColor;
return [_c('IconSearch',{attrs:{"strokeColor":strokeColor}})]}}],null,false,2905166185)})]],2)],2):_c('div',{staticClass:"v-left"},[(!!_vm.logo)?[_c('v-img',{attrs:{"src":require(("@/assets/logos/" + _vm.logo)),"contain":"","width":"222","height":"15","alt":"Gasag Solution Plus"}})]:_vm._e(),_c('v-btn',{staticClass:"secondColor mt-7",attrs:{"icon":"","fab":"","small":"","width":"40px","height":"40px"},on:{"click":function($event){return _vm.toggleShowSearch()}},model:{value:(_vm.showSearch),callback:function ($$v) {_vm.showSearch=$$v},expression:"showSearch"}},[[_c('icon-base',{attrs:{"icon-name":"Suche","width":12,"height":18,"viewBox":((0) + " " + (0) + " " + (12) + " " + (18))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var strokeColor = ref.strokeColor;
return [_c('IconArrowLeft',{attrs:{"strokeColor":strokeColor}})]}}],null,false,2483000357)})]],2),_c('SearchComponent')],2)]):(_vm.windowWidth < 580)?_c('v-app-bar',{staticClass:"main-header",attrs:{"elevation":"0","height":"70","fixed":"","color":"header"}},[(_vm.showSearch == false)?_c('div',{staticClass:"v-left"},[_c('SidebarToggleBtn'),(!!_vm.logo)?[_c('v-img',{attrs:{"src":require(("@/assets/logos/" + _vm.logo)),"contain":"","width":"222","height":"15","alt":"Gasag Solution Plus"}})]:_vm._e(),_c('div',{staticClass:"headerContent"},[_c('v-btn',{staticClass:"secondColor ml-3  searchbtn",attrs:{"icon":"","fab":"","small":"","width":"40px","height":"40px"},on:{"click":function($event){return _vm.toggleShowSearch()}},model:{value:(_vm.showSearch),callback:function ($$v) {_vm.showSearch=$$v},expression:"showSearch"}},[[_c('icon-base',{attrs:{"icon-name":"Suche","width":20,"height":15,"viewBox":((0) + " " + (0) + " " + (20) + " " + (15))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var strokeColor = ref.strokeColor;
return [_c('IconSearch',{attrs:{"strokeColor":strokeColor}})]}}],null,false,2905166185)})]],2)],1)],2):_c('div',{staticClass:"v-left"},[_c('v-btn',{staticClass:"secondColor mt-4",attrs:{"icon":"","fab":"","small":"","width":"40px","height":"40px"},on:{"click":function($event){return _vm.toggleShowSearch()}},model:{value:(_vm.showSearch),callback:function ($$v) {_vm.showSearch=$$v},expression:"showSearch"}},[[_c('icon-base',{attrs:{"icon-name":"Suche","width":12,"height":18,"viewBox":((0) + " " + (0) + " " + (12) + " " + (18))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var strokeColor = ref.strokeColor;
return [_c('IconArrowLeft',{attrs:{"strokeColor":strokeColor}})]}}])})]],2),_c('SearchComponent')],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }