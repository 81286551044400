<template>
  <div style="margin-left: 10px">
    <v-row>
      <v-col
        v-for="(item, index) in colors"
        :key="index"
        style="padding-right: 0px"
      >
        <v-row class="center">
          {{ $t('settings.color') }} {{ index + 1 }}
        </v-row>
        <v-row class="center">
          <v-btn :color="item" @click="changecolor(index)"></v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="center">
      <v-color-picker
        v-model="activeColor"
        class="ma-2"
        :update:color="sendUpdate()"
      ></v-color-picker>
    </v-row>
  </div>
</template>
<script>
import config from '../../config/index';

export default {
  name: 'ColorPicker',
  props: {
    colors: {
      type: Array
    },
    updateColors: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    activeIndex: 0,
    activeColor: null,
    themes: config.themes
  }),
  watch: {
    updateColors() {
      this.activeColor = JSON.parse(
        JSON.stringify(this.colors[this.activeIndex])
      );
    }
  },
  mounted() {
    this.activeColor = JSON.parse(
      JSON.stringify(this.colors[this.activeIndex])
    );
  },
  methods: {
    changecolor(index) {
      this.activeColor = JSON.parse(JSON.stringify(this.colors[index]));

      this.activeIndex = index;
    },
    sendUpdate() {
      this.$emit('updateColor', this.activeColor, this.activeIndex);
    }
  }
};
</script>
<style lang="scss" scoped>
.center {
  justify-content: center;
  align-items: center;
}
.v-btn {
  border-radius: 25%;
  min-width: 36px !important;
  margin-right: 5px;
  margin-bottom: 5px;
}

.col-3 {
  margin-right: 30px;
}

.col-8 {
  max-width: 58%;
}
</style>
