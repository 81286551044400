<template>
  <router-view @update="changeEvent()" class="red" />
</template>

<script>
export default {
  name: 'App',
  mounted() {
    document.title = 'Nexerion';
    if (localStorage.getItem('user')) this.$store.dispatch('auth/userValidate');
  },
  methods: {
    changeEvent() {
      //console.log('Change Event');
    }
  }
};
</script>

<style src="./styles/app.scss" lang="scss" />
