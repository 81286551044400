import Vue from 'vue';
import Router from 'vue-router';

// import store from "../store";

import LayoutComponent from '@/components/Layout/Layout';

// Pages
import HomePage from '@/pages/Home/Home';
import DashboardPage from '@/pages/Dashboard/Dashboard';
import SettingsPage from '@/pages/Settings/Settings';
import UI from '@/pages/UI/index';
import ReportPage from '@/pages/Report/Report';
import AnalysePage from '@/pages/Analyse/Analyse';

import ErrorPage from '@/pages/Error/Error';
import LoginPage from '@/pages/Login/Login';

// Documentation
import { isAuthenticated } from '../mixins/auth';

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: '/login',
      name: 'Login',
      component: LoginPage
    },

    {
      path: '/',
      redirect: 'login',
      name: 'Layout',
      component: LayoutComponent,
      // beforeEnter: (to, from, next) => {
      //   let token = localStorage.getItem('token');
      //   isAuthenticated(token) ? next() : next({path: '/login'})
      // },
      beforeEnter: (to, from, next) => {
        //let token = localStorage.getItem('token');
        //isAuthenticated(token) ? next() : next({path: '/login'})
        let user = JSON.parse(localStorage.getItem('user'));
        if (user == null || Object.entries(user).length == 0) {
          next({ path: '/login' });
        } else {
          next();
        }
      },
      children: [
        {
          path: '/',
          name: 'Home',
          component: HomePage
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: DashboardPage
        },
        {
          path: '/settings',
          name: 'Settings',
          component: SettingsPage
        },
        {
          path: '/report',
          name: 'Report',
          component: ReportPage
        },
        {
          path: '/analyze',
          name: 'Analyse',
          component: AnalysePage
        },
        {
          path: '/ui',
          name: 'UI',
          component: UI
        }
      ]
    },
    {
      path: '*',
      name: 'Error',
      component: ErrorPage
    }
  ]
});
