<template>
  <div class="pl-5">
    <div
      class="v-node v-breadcrumb breadcrumbBurgerBackground"
      ref="breadcrumb"
    >
      <template v-if="list.length > 0">
        <div
          class="v-breadcrumb__item"
          v-for="(item, index) in list"
          :key="item.id"
          @click="handleBurgerClicked(item.id)"
        >
          <!-- Dekstop node tree view -->
          <div v-if="windowWidth >= 1400" class="d-flex align-center">
            <template v-if="index === 0">
              <icon-base
                :icon-name="icons[0].name"
                :width="icons[0].width"
                :height="icons[0].height"
                class="mr-3"
              >
                <template>
                  <keep-alive>
                    <component
                      :strokeColor="
                        $vuetify.theme.isDark
                          ? $vuetify.theme.themes.dark[breadcrumbIconColor]
                          : $vuetify.theme.themes.light[breadcrumbIconColor]
                      "
                      :is="icons[0].component"
                    ></component>
                  </keep-alive> </template
              ></icon-base>
            </template>

            <div class="v-breadcrumb__text">{{ item.text }}</div>

            <template v-if="index > -1 && index != list.length - 1">
              <v-icon :class="breadcrumbIconColor + '--text custom'"
                >mdi-chevron-right</v-icon
              >
            </template>

            <template v-if="index == list.length - 1">
              <v-icon :class="breadcrumbIconColor + '--text custom'"
                >mdi-chevron-down</v-icon
              >
            </template>
          </div>

          <!-- Tablet node tree view -->
          <template v-else-if="windowWidth < 1400 && windowWidth >= 905">
            <template v-if="index === 0">
              <div class="d-flex align-center">
                <icon-base
                  :icon-name="icons[0].name"
                  :width="icons[0].width"
                  :height="icons[0].height"
                  class="mr-3"
                >
                  <template>
                    <keep-alive>
                      <component
                        :strokeColor="
                          $vuetify.theme.isDark
                            ? $vuetify.theme.themes.dark[breadcrumbIconColor]
                            : $vuetify.theme.themes.light[breadcrumbIconColor]
                        "
                        :is="icons[0].component"
                      ></component>
                    </keep-alive> </template
                ></icon-base>
                <template v-if="list.length <= 3">
                  <div class="v-breadcrumb__text">
                    {{ item.text }}
                  </div>
                  <v-icon :class="breadcrumbIconColor + '--text custom'"
                    >mdi-chevron-right</v-icon
                  >
                </template>
                <template v-else><div>...</div></template>
              </div>
            </template>

            <template
              v-if="index != 0 && list.length <= 3 && index != list.length - 1"
            >
              <div class="v-breadcrumb__text">{{ item.text }}</div>
              <v-icon :class="breadcrumbIconColor + '--text custom'"
                >mdi-chevron-right</v-icon
              >
            </template>

            <template v-if="index >= list.length / 2">
              <div class="d-flex align-center">
                <div class="v-breadcrumb__text">{{ item.text }}</div>
                <template v-if="index > -1 && index != list.length - 1">
                  <v-icon :class="breadcrumbIconColor + '--text custom'"
                    >mdi-chevron-right</v-icon
                  >
                </template>

                <template v-if="index == list.length - 1">
                  <v-icon :class="breadcrumbIconColor + '--text custom'"
                    >mdi-chevron-down</v-icon
                  >
                </template>
              </div>
            </template>
          </template>

          <!-- Mobile node tree view -->
          <template v-else>
            <div
              v-if="windowWidth < 905 && list.length - 1 === index"
              class="d-flex align-center"
            >
              <template>
                <icon-base
                  :icon-name="icons[0].name"
                  :width="icons[0].width"
                  :height="icons[0].height"
                  class="mr-3"
                >
                  <template>
                    <keep-alive>
                      <component
                        :strokeColor="
                          $vuetify.theme.isDark
                            ? $vuetify.theme.themes.dark[breadcrumbIconColor]
                            : $vuetify.theme.themes.light[breadcrumbIconColor]
                        "
                        :is="icons[0].component"
                      ></component>
                    </keep-alive> </template
                ></icon-base>
              </template>

              <div class="v-breadcrumb__text">{{ item.text }}</div>

              <template v-if="index > -1 && index != list.length - 1">
                <v-icon :class="breadcrumbIconColor + '--text custom'"
                  >mdi-chevron-right</v-icon
                >
              </template>

              <template v-if="index == list.length - 1">
                <v-icon :class="breadcrumbIconColor + '--text custom'"
                  >mdi-chevron-down</v-icon
                >
              </template>
            </div>
          </template>
        </div>
      </template>
      <!-- Empty node tree -->
      <template v-else>
        <div @click="handleBurgerClicked('')" class="d-flex align-center">
          <template>
            <icon-base
              :icon-name="icons[0].name"
              :width="icons[0].width"
              :height="icons[0].height"
              class="mr-3"
            >
              <template>
                <keep-alive>
                  <component
                    :strokeColor="
                      $vuetify.theme.isDark
                        ? $vuetify.theme.themes.dark[breadcrumbIconColor]
                        : $vuetify.theme.themes.light[breadcrumbIconColor]
                    "
                    :is="icons[0].component"
                  ></component>
                </keep-alive>
              </template>
            </icon-base>
          </template>

          <div class="v-breadcrumb__text secondColorText--text">
            {{ $t('main.global-tree') }}
          </div>

          <div class="d-flex align-center">
            <v-icon :class="breadcrumbIconColor + '--text custom'"
              >mdi-chevron-down</v-icon
            >
          </div>
        </div>
      </template>
      <div
        class="pin"
        style="margin-left: 10px; margin-top: 7px;"
        v-show="renderTreeNodeNav"
        @click="pin_as_favourite"
        v-if="!as_favourite"
      >
        <icon-base
          :icon-name="$t('main.pinNotActiv')"
          :width="30"
          :height="30"
          :viewBox="`${0} ${0} ${30} ${30}`"
        >
          <template slot-scope="{ strokeColor }">
            <IconPin :strokeColor="strokeColor" />
          </template>
        </icon-base>
      </div>
      <div
        class="pin"
        style="margin-left: 10px; margin-top: 7px;"
        v-show="renderTreeNodeNav"
        @click="pin_as_favourite"
        v-if="as_favourite"
      >
        <icon-base
          :icon-name="$t('main.pinActiv')"
          :width="30"
          :height="30"
          :viewBox="`${0} ${0} ${30} ${30}`"
        >
          <template slot-scope="{ strokeColor }">
            <IconPinFilled :strokeColor="strokeColor" />
          </template>
        </icon-base>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from '@/components/Icons';
import IconNodeList from '@/components/Icons/IconNodeList';
import themes from '../../config/themes';
import IconPin from '../Icons/IconPin.vue';
import IconPinFilled from '../Icons/IconPinFilled.vue';
import local from '@/locales/de.json';

export default {
  components: { IconBase, IconPin, IconPinFilled },
  props: {
    handleBurgerClicked: {
      type: Function,
      required: true
    }
  },
  data: () => ({
    windowWidth: 0,
    breadcrumbWidth: 0,
    elipsis: [],
    icons: [
      {
        id: 1,
        src: require('@/components/Icons/IconNodeList.vue'),
        name: 'node-list',
        component: IconNodeList,
        width: '24',
        height: '24'
      }
    ],
    breadcrumbIconColor: 'breadcrumbIcon',
    list: [],
    as_favourite: false,
    viewsList: ['/dashboard']
  }),
  computed: {
    renderTreeNodeNav() {
      if (this.viewsList.includes(this.$route.fullPath)) {
        this.check_if_favourite();
        return true;
      } else {
        return false;
      }
    }
  },
  mounted() {
    let width = window.innerWidth;
    this.windowWidth = width;
    this.breadcrumbWidth = this.$refs.breadcrumb?.clientWidth;
    this.list = [...this.getBreadcrumb()];
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    getBreadcrumb: function() {
      const unique = [
        ...new Map(
          this.$store.state.nodes.breadcrumb.map(item => [item['id'], item])
        ).values()
      ];

      return unique;
    },
    handleResize: function() {
      let width = window.innerWidth;
      this.breadcrumbWidth = this.$refs.breadcrumb?.clientWidth;
      this.windowWidth = width;
      let t = this.elipsis.find(i => console.log(i));
      this.elipsis = [];
    },
    pin_as_favourite() {
      this.as_favourite = !this.as_favourite;
      let current_breadcrumb = this.$store.state.nodes.breadcrumb;
      if (this.as_favourite) {
        this.$store.dispatch(
          'nodes/addBreadcrumbToFavourites',
          current_breadcrumb
        );
      } else {
        this.$store.dispatch(
          'nodes/removeBreadcrumbFromFavourites',
          current_breadcrumb[current_breadcrumb.length - 1]
        );
      }
    },
    check_if_favourite() {
      if (this.$store.state.nodes.breadcrumb.length > 0) {
        let cur_name = this.$store.state.nodes.breadcrumb[
          this.$store.state.nodes.breadcrumb.length - 1
        ].text;
        let ret = this.$store.state.nodes.start_favourite_breadcrumbs.filter(
          e => e[e.length - 1].text == cur_name
        );
        this.as_favourite = ret.length > 0;
      }
    }
  },
  watch: {
    '$store.state.nodes.breadcrumb': function() {
      this.list = [...this.getBreadcrumb()];
    }
  }
};
</script>

<style src="./NodeTreeBurger.scss" lang="scss"></style>
