export const themes = [
  {
    name: 'Default',
    dark: {
      firstColor: '#CE792B', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#ffffff', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#2C2D31', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#333333', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ffffff', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#ffffff', // never used
      thirdColorText: '#ffffff', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#ffffff', // icons sidebar & Grid icons

      fourthColorText: '#ffffff', // createPanel add-diagram, Settings Headline

      //primary: '#2C2D31', // all vuetify component that has an active state or highlighting state

      //still never used
      accent: '#143857',
      success: '#8BB059',
      info: '#6156d8',
      warning: '#1565C0',
      error: '#CE792B',

      thirdBackground: '#ffffff',

      componentText: '#ffffff', // Copyright, Settings, createPanel dialog, chart menu

      // Layout
      background: '#575961', // Main Background

      header: '#2C2D31',
      sidebar: '#2C2D31',
      sidebarText: '#ffffff',
      sidebarActiv: '#575961', // Element which is selected
      sidebarActivText: '#ffffff', // Text of the Element which is selected
      siderbarIcon: '#ffffff', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#ffffff', // searchfield border

      // Dashboard
      tabBackground: '#2C2D31',
      tabBackgroundActiv: '#575961',
      tabTextActiv: '#ffffff',
      gridIconActiv: '#ffffff',

      // Breadcrumb
      breadcrumbBurgerBackground: '#575961', // breadcrumb in header
      breadcrumbBackground: '#2C2D31', // Background in BreadcrumbMenu
      breadcrumbActiv: '#ffffff', // selected & expanded Breadcrumb
      breadcrumbActivText: '#2C2D31', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#ffffff', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#2C2D31', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#ffffff' //color of the chart labels
    },
    light: {
      firstColor: '#143857', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#143857', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#F3F3F3', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#ffffff', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ce792b', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#F3F3F3', // never used
      thirdColorText: '#143857', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#143857', // icons sidebar & Grid icons

      fourthColorText: '#123759', // createPanel add-diagram, Settings Headline

      componentText: '#333333', // Copyright, Settings

      // still never used
      primary: '#1976d2',
      accent: '#143857',
      success: '#8BB059',
      info: '#ff53d0',
      warning: '#ff8e00',
      error: '#CE792B',

      // Layout
      background: '#f3f3f3', // Background behind the Panels

      header: '#ffffff',
      sidebar: '#ffffff',
      sidebarText: '#333333',
      sidebarActiv: '#F3F3F3', // Element which is selected
      sidebarActivText: '#333333', // Text of the Element which is selected
      siderbarIcon: '#143857', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#143857', // searchfield border

      // Dashboard
      tabBackground: '#ffffff',
      tabBackgroundActiv: '#F3F3F3',
      tabTextActiv: '#333333',
      gridIconActiv: '#143857',

      // Breadcrumb
      breadcrumbBurgerBackground: '#F3F3F3', // breadcrumb in header
      breadcrumbBackground: '#ffffff', // Background in BreadcrumbMenu
      breadcrumbActiv: '#215F75', // selected & expanded Breadcrumb
      breadcrumbActivText: '#ffffff', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#215F75', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#ffffff', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#333333' //color of the chart labels
    }
  },
  {
    name: 'Nexerion',
    dark: {
      firstColor: '#CE792B', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#ffffff', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#2C2D31', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#333333', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ffffff', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#ffffff', // never used
      thirdColorText: '#ffffff', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#ffffff', // icons sidebar & Grid icons

      fourthColorText: '#ffffff', // createPanel add-diagram, Settings Headline

      //primary: '#2C2D31', // all vuetify component that has an active state or highlighting state

      //still never used
      accent: '#143857',
      success: '#8BB059',
      info: '#6156d8',
      warning: '#1565C0',
      error: '#CE792B',

      thirdBackground: '#ffffff',

      componentText: '#ffffff', // Copyright, Settings, createPanel dialog, chart menu

      // Layout
      background: '#575961', // Main Background

      header: '#2C2D31',
      sidebar: '#2C2D31',
      sidebarText: '#ffffff',
      sidebarActiv: '#575961', // Element which is selected
      sidebarActivText: '#ffffff', // Text of the Element which is selected
      siderbarIcon: '#ffffff', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#ffffff', // searchfield border

      // Dashboard
      tabBackground: '#2C2D31',
      tabBackgroundActiv: '#575961',
      tabTextActiv: '#ffffff',
      gridIconActiv: '#ffffff',

      // Breadcrumb
      breadcrumbBurgerBackground: '#575961', // breadcrumb in header
      breadcrumbBackground: '#2C2D31', // Background in BreadcrumbMenu
      breadcrumbActiv: '#ffffff', // selected & expanded Breadcrumb
      breadcrumbActivText: '#2C2D31', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#ffffff', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#2C2D31', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#ffffff' //color of the chart labels
    },
    light: {
      firstColor: '#215F75', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#215F75', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#ffffff', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#ffffff', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ce792b', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#123759', // never used
      thirdColorText: '#215F75', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#215F75', // icons sidebar & Grid icons

      fourthColorText: '#123759', // createPanel add-diagram, Settings Headline

      componentText: '#333333', // Copyright, Settings, createPanel dialog, chart menu

      // still never used
      primary: '#1976d2',
      accent: '#8bb059',
      success: '#86af3f',
      info: '#f34fc6',
      warning: '#FB8C00',
      error: '#FF5252',

      // Layout
      background: '#f3f3f3', // Main Background

      header: '#215F75',
      sidebar: '#215f75',
      sidebarText: '#ffffff',
      sidebarActiv: '#123759', // Element which is selected
      sidebarActivText: '#ffffff', // Text of the Element which is selected
      siderbarIcon: '#ffffff', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#025581', // searchfield border

      // Dashboard
      tabBackground: '#215F75',
      tabBackgroundActiv: '#123759',
      tabTextActiv: '#ffffff',
      gridIconActiv: '#ffffff',

      // Breadcrumb
      breadcrumbBurgerBackground: '#ffffff', // breadcrumb in header
      breadcrumbBackground: '#ffffff', // Background in BreadcrumbMenu
      breadcrumbActiv: '#ce792b', // selected & expanded Breadcrumb
      breadcrumbActivText: '#ffffff', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#215F75', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#ffffff', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#333333' //color of the chart labels
    }
  },
  {
    name: 'GASAG',
    dark: {
      firstColor: '#CE792B', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#ffffff', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#2C2D31', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#333333', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ffffff', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#ffffff', // never used
      thirdColorText: '#ffffff', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#ffffff', // icons sidebar & Grid icons

      fourthColorText: '#ffffff', // createPanel add-diagram, Settings Headline

      //primary: '#2C2D31', // all vuetify component that has an active state or highlighting state

      //still never used
      accent: '#143857',
      success: '#8BB059',
      info: '#6156d8',
      warning: '#1565C0',
      error: '#CE792B',

      thirdBackground: '#ffffff',

      componentText: '#ffffff', // Copyright, Settings, createPanel dialog, chart menu

      // Layout
      background: '#575961', // Main Background

      header: '#2C2D31',
      sidebar: '#2C2D31',
      sidebarText: '#ffffff',
      sidebarActiv: '#575961', // Element which is selected
      sidebarActivText: '#ffffff', // Text of the Element which is selected
      siderbarIcon: '#ffffff', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#ffffff', // searchfield border

      // Dashboard
      tabBackground: '#2C2D31',
      tabBackgroundActiv: '#575961',
      tabTextActiv: '#ffffff',
      gridIconActiv: '#ffffff',

      // Breadcrumb
      breadcrumbBurgerBackground: '#575961', // breadcrumb in header
      breadcrumbBackground: '#2C2D31', // Background in BreadcrumbMenu
      breadcrumbActiv: '#ffffff', // selected & expanded Breadcrumb
      breadcrumbActivText: '#2C2D31', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#ffffff', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#2C2D31', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#ffffff' //color of the chart labels
    },
    light: {
      firstColor: '#215F75', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#215F75', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#ffffff', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#ffffff', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ce792b', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#123759', // never used
      thirdColorText: '#215F75', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#215F75', // icons sidebar & Grid icons

      fourthColorText: '#123759', // createPanel add-diagram, Settings Headline

      componentText: '#333333', // Copyright, Settings, createPanel dialog, chart menu

      // still never used
      primary: '#1976d2',
      accent: '#8bb059',
      success: '#86af3f',
      info: '#f34fc6',
      warning: '#FB8C00',
      error: '#FF5252',

      // Layout
      background: '#f3f3f3', // Main Background

      header: '#215F75',
      sidebar: '#215f75',
      sidebarText: '#ffffff',
      sidebarActiv: '#123759', // Element which is selected
      sidebarActivText: '#ffffff', // Text of the Element which is selected
      siderbarIcon: '#ffffff', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#025581', // searchfield border

      // Dashboard
      tabBackground: '#215F75',
      tabBackgroundActiv: '#123759',
      tabTextActiv: '#ffffff',
      gridIconActiv: '#ffffff',

      // Breadcrumb
      breadcrumbBurgerBackground: '#ffffff', // breadcrumb in header
      breadcrumbBackground: '#ffffff', // Background in BreadcrumbMenu
      breadcrumbActiv: '#ce792b', // selected & expanded Breadcrumb
      breadcrumbActivText: '#ffffff', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#215F75', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#ffffff', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#333333' //color of the chart labels
    }
  },
  {
    name: 'Gegenbauer',
    dark: {
      firstColor: '#CE792B', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#ffffff', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#2C2D31', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#333333', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ffffff', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#ffffff', // never used
      thirdColorText: '#ffffff', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#ffffff', // icons sidebar & Grid icons

      fourthColorText: '#ffffff', // createPanel add-diagram, Settings Headline

      //primary: '#2C2D31', // all vuetify component that has an active state or highlighting state

      //still never used
      accent: '#143857',
      success: '#8BB059',
      info: '#6156d8',
      warning: '#1565C0',
      error: '#CE792B',

      thirdBackground: '#ffffff',

      componentText: '#ffffff', // Copyright, Settings, createPanel dialog, chart menu

      // Layout
      background: '#575961', // Main Background

      header: '#2C2D31',
      sidebar: '#2C2D31',
      sidebarText: '#ffffff',
      sidebarActiv: '#575961', // Element which is selected
      sidebarActivText: '#ffffff', // Text of the Element which is selected
      siderbarIcon: '#ffffff', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#ffffff', // searchfield border

      // Dashboard
      tabBackground: '#2C2D31',
      tabBackgroundActiv: '#575961',
      tabTextActiv: '#ffffff',
      gridIconActiv: '#ffffff',

      // Breadcrumb
      breadcrumbBurgerBackground: '#575961', // breadcrumb in header
      breadcrumbBackground: '#2C2D31', // Background in BreadcrumbMenu
      breadcrumbActiv: '#ffffff', // selected & expanded Breadcrumb
      breadcrumbActivText: '#2C2D31', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#ffffff', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#2C2D31', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#ffffff' //color of the chart labels
    },
    light: {
      firstColor: '#0082c9', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#0082c9', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#F3F3F3', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#ffffff', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#0082c9', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#F3F3F3', // never used
      thirdColorText: '#143857', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#143857', // icons sidebar & Grid icons

      fourthColorText: '#0082c9', // createPanel add-diagram, Settings Headline

      componentText: '#333333', // Copyright, Settings

      // still never used
      primary: '#1976d2',
      accent: '#143857',
      success: '#a5d64c',
      info: '#ff53d0',
      warning: '#ff8e00',
      error: '#ff5252',

      // Layout
      background: '#f3f3f3', // Background behind the Panels

      header: '#ffffff',
      sidebar: '#0082c9',
      sidebarText: '#ffffff',
      sidebarActiv: '#F3F3F3', // Element which is selected
      sidebarActivText: '#333333', // Text of the Element which is selected
      siderbarIcon: '#143857', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#D8D8D8', // searchfield border

      // Dashboard
      tabBackground: '#ffffff',
      tabBackgroundActiv: '#F3F3F3',
      tabTextActiv: '#333333',
      gridIconActiv: '#143857',

      // Breadcrumb
      breadcrumbBurgerBackground: '#F3F3F3', // breadcrumb in header
      breadcrumbBackground: '#ffffff', // Background in BreadcrumbMenu
      breadcrumbActiv: '#0082c9', // selected & expanded Breadcrumb
      breadcrumbActivText: '#ri', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#0082c9', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#ffffff', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#333333' //color of the chart labels
    }
  },
  {
    name: 'Evo',
    dark: {
      firstColor: '#CE792B', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#ffffff', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#2C2D31', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#333333', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ffffff', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#ffffff', // never used
      thirdColorText: '#ffffff', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#ffffff', // icons sidebar & Grid icons

      fourthColorText: '#ffffff', // createPanel add-diagram, Settings Headline

      //primary: '#2C2D31', // all vuetify component that has an active state or highlighting state

      //still never used
      accent: '#143857',
      success: '#8BB059',
      info: '#6156d8',
      warning: '#1565C0',
      error: '#CE792B',

      thirdBackground: '#ffffff',

      componentText: '#ffffff', // Copyright, Settings, createPanel dialog, chart menu

      // Layout
      background: '#575961', // Main Background

      header: '#2C2D31',
      sidebar: '#2C2D31',
      sidebarText: '#ffffff',
      sidebarActiv: '#575961', // Element which is selected
      sidebarActivText: '#ffffff', // Text of the Element which is selected
      siderbarIcon: '#ffffff', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#ffffff', // searchfield border

      // Dashboard
      tabBackground: '#2C2D31',
      tabBackgroundActiv: '#575961',
      tabTextActiv: '#ffffff',
      gridIconActiv: '#ffffff',

      // Breadcrumb
      breadcrumbBurgerBackground: '#575961', // breadcrumb in header
      breadcrumbBackground: '#2C2D31', // Background in BreadcrumbMenu
      breadcrumbActiv: '#ffffff', // selected & expanded Breadcrumb
      breadcrumbActivText: '#2C2D31', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#ffffff', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#2C2D31', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#ffffff' //color of the chart labels
    },
    light: {
      firstColor: '#ffffff', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#333333', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#143857', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#F3F3F3', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#ffffff', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ce792b', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#F3F3F3', // never used
      thirdColorText: '#143857', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#143857', // icons sidebar & Grid icons

      fourthColorText: '#123759', // createPanel add-diagram, Settings Headline

      componentText: '#333333', // Copyright, Settings

      // still never used
      primary: '#1976d2',
      accent: '#143857',
      success: '#a5d64c',
      info: '#ff53d0',
      warning: '#ff8e00',
      error: '#ff5252',

      // Layout
      background: '#f3f3f3', // Background behind the Panels

      header: '#ffffff',
      sidebar: '#ffffff',
      sidebarText: '#333333',
      sidebarActiv: '#F3F3F3', // Element which is selected
      sidebarActivText: '#333333', // Text of the Element which is selected
      siderbarIcon: '#143857', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#143857', // searchfield border

      // Dashboard
      tabBackground: '#ffffff',
      tabBackgroundActiv: '#F3F3F3',
      tabTextActiv: '#333333',
      gridIconActiv: '#143857',

      // Breadcrumb
      breadcrumbBurgerBackground: '#F3F3F3', // breadcrumb in header
      breadcrumbBackground: '#ffffff', // Background in BreadcrumbMenu
      breadcrumbActiv: '#215F75', // selected & expanded Breadcrumb
      breadcrumbActivText: '#ffffff', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#215F75', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#ffffff', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#333333' //color of the chart labels
    }
  },
  {
    name: 'Wiro',
    dark: {
      firstColor: '#CE792B', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#ffffff', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#2C2D31', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#333333', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ffffff', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#ffffff', // never used
      thirdColorText: '#ffffff', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#ffffff', // icons sidebar & Grid icons

      fourthColorText: '#ffffff', // createPanel add-diagram, Settings Headline

      //primary: '#2C2D31', // all vuetify component that has an active state or highlighting state

      //still never used
      accent: '#143857',
      success: '#8BB059',
      info: '#6156d8',
      warning: '#1565C0',
      error: '#CE792B',

      thirdBackground: '#ffffff',

      componentText: '#ffffff', // Copyright, Settings, createPanel dialog, chart menu

      // Layout
      background: '#575961', // Main Background

      header: '#2C2D31',
      sidebar: '#2C2D31',
      sidebarText: '#ffffff',
      sidebarActiv: '#575961', // Element which is selected
      sidebarActivText: '#ffffff', // Text of the Element which is selected
      siderbarIcon: '#ffffff', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#ffffff', // searchfield border

      // Dashboard
      tabBackground: '#2C2D31',
      tabBackgroundActiv: '#575961',
      tabTextActiv: '#ffffff',
      gridIconActiv: '#ffffff',

      // Breadcrumb
      breadcrumbBurgerBackground: '#575961', // breadcrumb in header
      breadcrumbBackground: '#2C2D31', // Background in BreadcrumbMenu
      breadcrumbActiv: '#ffffff', // selected & expanded Breadcrumb
      breadcrumbActivText: '#2C2D31', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#ffffff', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#2C2D31', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#ffffff' //color of the chart labels
    },
    light: {
      firstColor: '#ffffff', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#333333', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#143857', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#F3F3F3', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#ffffff', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ce792b', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#F3F3F3', // never used
      thirdColorText: '#143857', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#143857', // icons sidebar & Grid icons

      fourthColorText: '#123759', // createPanel add-diagram, Settings Headline

      componentText: '#333333', // Copyright, Settings

      // still never used
      primary: '#1976d2',
      accent: '#143857',
      success: '#a5d64c',
      info: '#ff53d0',
      warning: '#ff8e00',
      error: '#ff5252',

      // Layout
      background: '#f3f3f3', // Background behind the Panels

      header: '#ffffff',
      sidebar: '#ffffff',
      sidebarText: '#333333',
      sidebarActiv: '#F3F3F3', // Element which is selected
      sidebarActivText: '#333333', // Text of the Element which is selected
      siderbarIcon: '#143857', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#143857', // searchfield border

      // Dashboard
      tabBackground: '#ffffff',
      tabBackgroundActiv: '#F3F3F3',
      tabTextActiv: '#333333',
      gridIconActiv: '#143857',

      // Breadcrumb
      breadcrumbBurgerBackground: '#F3F3F3', // breadcrumb in header
      breadcrumbBackground: '#ffffff', // Background in BreadcrumbMenu
      breadcrumbActiv: '#215F75', // selected & expanded Breadcrumb
      breadcrumbActivText: '#ffffff', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#215F75', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#ffffff', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#333333' //color of the chart labels
    }
  },
  {
    name: 'IVL Langer',
    dark: {
      firstColor: '#CE792B', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#ffffff', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#2C2D31', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#333333', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ffffff', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#ffffff', // never used
      thirdColorText: '#ffffff', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#ffffff', // icons sidebar & Grid icons

      fourthColorText: '#ffffff', // createPanel add-diagram, Settings Headline

      //primary: '#2C2D31', // all vuetify component that has an active state or highlighting state

      //still never used
      accent: '#143857',
      success: '#8BB059',
      info: '#6156d8',
      warning: '#1565C0',
      error: '#CE792B',

      thirdBackground: '#ffffff',

      componentText: '#ffffff', // Copyright, Settings, createPanel dialog, chart menu

      // Layout
      background: '#575961', // Main Background

      header: '#2C2D31',
      sidebar: '#2C2D31',
      sidebarText: '#ffffff',
      sidebarActiv: '#575961', // Element which is selected
      sidebarActivText: '#ffffff', // Text of the Element which is selected
      siderbarIcon: '#ffffff', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#ffffff', // searchfield border

      // Dashboard
      tabBackground: '#2C2D31',
      tabBackgroundActiv: '#575961',
      tabTextActiv: '#ffffff',
      gridIconActiv: '#ffffff',

      // Breadcrumb
      breadcrumbBurgerBackground: '#575961', // breadcrumb in header
      breadcrumbBackground: '#2C2D31', // Background in BreadcrumbMenu
      breadcrumbActiv: '#ffffff', // selected & expanded Breadcrumb
      breadcrumbActivText: '#2C2D31', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#ffffff', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#2C2D31', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#ffffff' //color of the chart labels
    },
    light: {
      firstColor: '#ffffff', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#333333', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#143857', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#F3F3F3', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#ffffff', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ce792b', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#F3F3F3', // never used
      thirdColorText: '#143857', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#143857', // icons sidebar & Grid icons

      fourthColorText: '#123759', // createPanel add-diagram, Settings Headline

      componentText: '#333333', // Copyright, Settings

      // still never used
      primary: '#1976d2',
      accent: '#143857',
      success: '#a5d64c',
      info: '#ff53d0',
      warning: '#ff8e00',
      error: '#ff5252',

      // Layout
      background: '#f3f3f3', // Background behind the Panels

      header: '#ffffff',
      sidebar: '#ffffff',
      sidebarText: '#333333',
      sidebarActiv: '#F3F3F3', // Element which is selected
      sidebarActivText: '#333333', // Text of the Element which is selected
      siderbarIcon: '#143857', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#143857', // searchfield border

      // Dashboard
      tabBackground: '#ffffff',
      tabBackgroundActiv: '#F3F3F3',
      tabTextActiv: '#333333',
      gridIconActiv: '#143857',

      // Breadcrumb
      breadcrumbBurgerBackground: '#F3F3F3', // breadcrumb in header
      breadcrumbBackground: '#ffffff', // Background in BreadcrumbMenu
      breadcrumbActiv: '#215F75', // selected & expanded Breadcrumb
      breadcrumbActivText: '#ffffff', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#215F75', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#ffffff', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#333333' //color of the chart labels
    }
  },
  {
    name: 'SWB Bonn',
    dark: {
      firstColor: '#CE792B', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#ffffff', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#ffffff', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#2C2D31', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#333333', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ffffff', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#ffffff', // never used
      thirdColorText: '#ffffff', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#ffffff', // icons sidebar & Grid icons

      fourthColorText: '#ffffff', // createPanel add-diagram, Settings Headline

      //primary: '#2C2D31', // all vuetify component that has an active state or highlighting state

      //still never used
      accent: '#143857',
      success: '#8BB059',
      info: '#6156d8',
      warning: '#1565C0',
      error: '#CE792B',

      thirdBackground: '#ffffff',

      componentText: '#ffffff', // Copyright, Settings, createPanel dialog, chart menu

      // Layout
      background: '#575961', // Main Background

      header: '#2C2D31',
      sidebar: '#2C2D31',
      sidebarText: '#ffffff',
      sidebarActiv: '#575961', // Element which is selected
      sidebarActivText: '#ffffff', // Text of the Element which is selected
      siderbarIcon: '#ffffff', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#ffffff', // searchfield border

      // Dashboard
      tabBackground: '#2C2D31',
      tabBackgroundActiv: '#575961',
      tabTextActiv: '#ffffff',
      gridIconActiv: '#ffffff',

      // Breadcrumb
      breadcrumbBurgerBackground: '#575961', // breadcrumb in header
      breadcrumbBackground: '#2C2D31', // Background in BreadcrumbMenu
      breadcrumbActiv: '#ffffff', // selected & expanded Breadcrumb
      breadcrumbActivText: '#2C2D31', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#ffffff', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#2C2D31', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#ffffff' //color of the chart labels
    },
    light: {
      firstColor: '#ffffff', // Btn on Error.vue, chart btn apply, Breadcrumb btn Back
      firstColorText: '#333333', // chart btn apply, CreatePanel btn back & next
      firstColorIcon: '#143857', // each icon with strokeColor & sidebar toggle icon

      secondColor: '#F3F3F3', // Chart Hover Top, btn apply all, CreatePanel activ List-Item, sidebar toggle btn background
      secondColorText: '#ffffff', // Breadcrumb text if breadcrumb is empty
      secondColorIcon: '#ce792b', // Hover close & check icon & btn next on create Panel dialog, check item on create panel, radio in /Settings

      thirdColor: '#F3F3F3', // never used
      thirdColorText: '#143857', // Chart Hover Top dates & menu donts, Dashboard create dialog hover close
      thirdColorIcon: '#143857', // icons sidebar & Grid icons

      fourthColorText: '#123759', // createPanel add-diagram, Settings Headline

      componentText: '#333333', // Copyright, Settings

      // still never used
      primary: '#1976d2',
      accent: '#143857',
      success: '#a5d64c',
      info: '#ff53d0',
      warning: '#ff8e00',
      error: '#ff5252',

      // Layout
      background: '#f3f3f3', // Background behind the Panels

      header: '#ffffff',
      sidebar: '#ffffff',
      sidebarText: '#333333',
      sidebarActiv: '#F3F3F3', // Element which is selected
      sidebarActivText: '#333333', // Text of the Element which is selected
      siderbarIcon: '#143857', //Icons in Sidebar
      searchBackground: '#ffffff', // background of the search in the top right corner
      searchBorder: '#143857', // searchfield border

      // Dashboard
      tabBackground: '#ffffff',
      tabBackgroundActiv: '#F3F3F3',
      tabTextActiv: '#333333',
      gridIconActiv: '#143857',

      // Breadcrumb
      breadcrumbBurgerBackground: '#F3F3F3', // breadcrumb in header
      breadcrumbBackground: '#ffffff', // Background in BreadcrumbMenu
      breadcrumbActiv: '#215F75', // selected & expanded Breadcrumb
      breadcrumbActivText: '#ffffff', // Text of selected & expanded Breadcrumb
      breadcrumbIcon: '#215F75', // Breadcrumbicons im Header

      // Chart
      chartBackground: '#ffffff', // Panel hintergrund
      chartColors: [
        '#02858D',
        '#C2654A',
        '#8BB059',
        '#49C499',
        '#C4B735',
        '#6BA1B0',
        '#CEA125',
        '#E69C1E',
        '#215F75',
        '#8C410F'
      ], // Colors on diagrams
      chartLabes: '#333333' //color of the chart labels
    }
  }
];
