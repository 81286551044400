<template>
  <div class="searchbar">
    <v-text-field
      class="autocomplete"
      background-color="breadcrumbBurgerBackground"
      @input="onChange"
      v-model="search"
      @keyup.down="onArrowDown"
      @keyup.up="onArrowUp"
      @keyup.enter="onEnter"
      :placeholder="$t('main.search')"
      outlined
      dense
    ></v-text-field>
    <div v-if="isOpen" class="searchlist">
      <v-list dense>
        <v-list-item-group v-model="selectedItem" color="primary">
          <v-list-item
            v-for="(item, i) in results"
            :key="i"
            @click="setResult(item, i)"
            :class="{ 'is-active': i === arrowCounter }"
          >
            <v-list-item-content>
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
  </div>
</template>

<script>
import config from '../../config';

function sleep(ms) {
  return new Promise(resolve => {
    setTimeout(resolve, ms);
  });
}

export default {
  name: 'SearchComponent',

  data() {
    return {
      config,
      treePathArray: [],
      treePathStrings: [],
      isOpen: false,
      results: [],
      search: '',
      isLoading: false,
      arrowCounter: -1,
      selectedItem: null
    };
  },
  methods: {
    recursive_find_paths(tree, depth, current_path) {
      if (tree == null) {
        return;
      }
      if (tree[0] != null && tree[0].type == 'C') {
        //stop at component level
        return;
      }
      for (let i = 0; i < tree.length; i++) {
        current_path.push(tree[i].text);
        //console.log(current_path);
        let a = current_path.slice(0);
        this.treePathArray.push(a);
        this.recursive_find_paths(tree[i].nodes, depth + 1, current_path);
        current_path.pop();
      }
    },
    getNodeTreeArray() {
      let tree = this.$store.state.nodes.nodes.nodes;
      let current_path = [];
      //console.log('getnodetree length', tree.length);
      this.recursive_find_paths(tree, 0, current_path);

      // turn each list element into a path-string so it can be searched
      for (let i = 0; i < this.treePathArray.length; i++) {
        let pathstring = '';
        for (let j = 0; j < this.treePathArray[i].length; j++) {
          pathstring += this.treePathArray[i][j] + ' > ';
        }
        pathstring = pathstring.slice(0, -3);
        this.treePathStrings.push(pathstring);
      }
    },
    onChange() {
      this.filterResults();
      this.isOpen = true;
    },

    filterResults() {
      this.results = this.treePathStrings.filter(item => {
        return item.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    setResult(item, idx) {
      this.selectedItem = item;
      this.search = '';
      this.isOpen = false;
      this.jumpToAsset();
    },
    onArrowDown() {
      if (this.arrowCounter < this.results.length - 1) {
        this.arrowCounter++;
      }
    },
    onArrowUp() {
      if (this.arrowCounter > 0) {
        this.arrowCounter--;
      }
    },
    onEnter() {
      this.selectedItem = this.results[this.arrowCounter];
      this.search = '';
      this.arrowCounter = -1;
      this.isOpen = false;
      this.jumpToAsset();
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
        this.arrowCounter = -1;
      }
    },
    async jumpToAsset() {
      const path_arr = this.selectedItem.split(' > ');
      let tree_arr = this.$store.state.nodes.nodes.nodes;
      let breadcrumb = [{ id: '0', text: 'Global' }];
      let j;
      for (let i = 0; i < path_arr.length; i++) {
        for (j = 0; j < tree_arr.length; j++) {
          if (path_arr[i] == tree_arr[j].text) {
            breadcrumb.push(tree_arr[j]);
            break;
          }
        }
        tree_arr = tree_arr[j].nodes;
      }
      this.selectedItem = '';
      this.search = '';
      await this.$store.dispatch(
        'nodes/loadNodeRequest',
        breadcrumb[breadcrumb.length - 1],
        { root: true }
      );
      this.$store.dispatch('nodes/updateBreadcrumbRequest', breadcrumb);
      let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
      let tabId = localUserConfig.activeTabId;
      await this.$store.dispatch('nodes/updateUserConfig');
      this.$store.dispatch('nodes/loadPanelsRequest', {
        tabId: tabId
      });
      if (this.$route.name != 'Dashboard') {
        this.$router.push('/dashboard');
      }
    }
  },
  async mounted() {
    document.addEventListener('click', this.handleClickOutside);
    await sleep(5000); // wait for the nodetree to load (on login)
    this.$nextTick(function() {
      this.getNodeTreeArray();
    });
  },
  destroyed() {
    document.removeEventListener('click', this.handleClickOutside);
  }
};
</script>

<style src="./Search.scss" lang="scss"></style>
