var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"v-chart"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{class:("" + (hover || _vm.showChartMenu
            ? 'v-chart__header secondColor'
            : 'v-chart__header'))},[_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [(!_vm.showTitleEdit)?_c('div',{staticClass:"d-inline-flex",on:{"dblclick":function () {
                _vm.showTitleEdit = true;
                _vm.editedTitle = _vm.title;
              }}},[_c('h3',{staticClass:"px-1 componentText--text"},[_vm._v(" "+_vm._s(_vm.title ? _vm.title : _vm.$t('chart.noTitle'))+" ")]),(hover)?_c('div',{staticClass:"ml-1 pointer",on:{"click":function () {
                  _vm.showTitleEdit = true;
                  _vm.editedTitle = _vm.title;
                }}},[_c('icon-base',{attrs:{"icon-name":_vm.$t('chart.edit'),"width":18,"height":21,"viewBox":((0) + " " + (0) + " " + (25) + " " + (20))}},[[_c('keep-alive',[_c(_vm.iconEdit,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                          ? _vm.$vuetify.theme.themes.dark['thirdColorIcon']
                          : _vm.$vuetify.theme.themes.light['thirdColorIcon']}})],1)]],2)],1):_vm._e()]):_c('div',{staticClass:"d-flex"},[_c('v-text-field',{attrs:{"autofocus":""},on:{"blur":function($event){_vm.editTitle();
                _vm.$emit('update');},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.editTitle();
                _vm.$emit('update');}},model:{value:(_vm.editedTitle),callback:function ($$v) {_vm.editedTitle=$$v},expression:"editedTitle"}})],1)]}}],null,true)}),_c('div',{staticClass:"dragArea justify-center",staticStyle:{"display":"flex"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-btn',{staticClass:"custom dragArea",attrs:{"icon":""}},[_c('v-icon',{class:("" + (hover
                      ? 'thirdColorText--text active dragArea'
                      : 'dragArea')),attrs:{"color":"greyTint"}},[_vm._v(" mdi-cursor-move ")])],1)]}}],null,true)})],1)],1),_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-menu',{staticClass:"custom ",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":!_vm.$vuetify.breakpoint.mdAndDown ? '600px' : '100%',"min-height":!_vm.$vuetify.breakpoint.mdAndDown ? '300px' : '100%',"fullscreen":_vm.$vuetify.breakpoint.mdAndDown},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('div',{staticClass:"\n                v-chart__calendar\n                d-flex\n                align-center\n                justify-space-between\n              "},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('div',{class:("" + (hover
                        ? 'd-flex align-center py-1 px-1'
                        : 'd-flex align-center py-1 px-1'))},[_c('div',{staticClass:"mr-2 d-flex align-center justify-center"},[_c('icon-base',{attrs:{"icon-name":"","width":25,"height":19},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var strokeColor = ref.strokeColor;
return [_c('IconCalendar',{attrs:{"strokeColor":strokeColor}})]}}],null,true)})],1),_c('div',_vm._g({class:("" + (hover ? 'thirdColorText--text' : ''))},on),[_c('span',{staticClass:"componentText--text"},[_vm._v(" "+_vm._s(_vm.fromDateDisp)+" - "+_vm._s(_vm.toDateDisp))])])])]}}],null,true)})],1)]}}],null,true),model:{value:(_vm.showDateMenu),callback:function ($$v) {_vm.showDateMenu=$$v},expression:"showDateMenu"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":6,"align-self":"center"}},[_c('v-row',{staticClass:"font-weight-bold componentText--text",staticStyle:{"font-size":"1rem"},attrs:{"justify":"center"}},[_vm._v(_vm._s(_vm.$t('chart.data-resolution'))+":")])],1),_c('v-col',{attrs:{"cols":6}},[_c('v-select',{attrs:{"items":_vm.$t(_vm.timeResolution),"disabled":_vm.diagramType == 'pie' || _vm.diagramType == 'area'
                        ? true
                        : false,"item-text":"text","item-value":"value","dense":"","solo":"","hide-details":""},model:{value:(_vm.datResPicker),callback:function ($$v) {_vm.datResPicker=$$v},expression:"datResPicker"}})],1)],1),_c('v-row',[_c('v-tabs',{attrs:{"fixed-tabs":""},model:{value:(_vm.dateTab),callback:function ($$v) {_vm.dateTab=$$v},expression:"dateTab"}},_vm._l(([
                      'absoluter Zeitraum',
                      'relativer Zeitraum'
                    ]),function(item,index){return _c('v-tab',{key:index,staticClass:"font-weight-bold componentText--text",staticStyle:{"font-size":"1rem","letter-spacing":"0","text-transform":"inherit"}},[_vm._v(" "+_vm._s(item)+" ")])}),1)],1),(_vm.dateTab == 0)?_c('v-row',[_c('v-col',{attrs:{"cols":6}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-date-picker',{staticClass:"custom",attrs:{"first-day-of-week":"1","locale":_vm.locale,"no-title":""},model:{value:(_vm.fromDateVal),callback:function ($$v) {_vm.fromDateVal=$$v},expression:"fromDateVal"}})],1)],1),_c('v-col',{attrs:{"cols":6}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-date-picker',{staticClass:"custom",attrs:{"first-day-of-week":"1","locale":_vm.locale,"no-title":""},model:{value:(_vm.toDateVal),callback:function ($$v) {_vm.toDateVal=$$v},expression:"toDateVal"}})],1)],1)],1):_vm._e(),(_vm.dateTab == 1)?_c('v-row',[_c('v-col',{attrs:{"cols":6}},[_c('v-text-field',{attrs:{"type":"number","label":"Anzahl","rules":[
                      function (v) { return !!v || 'Feld darf nicht leer sein'; },
                      function (v) { return (v && v >= 1) || 'Wert muss mindestens 1 betragen'; }
                    ]},model:{value:(_vm.relativeDate.count),callback:function ($$v) {_vm.$set(_vm.relativeDate, "count", $$v)},expression:"relativeDate.count"}})],1),_c('v-col',{attrs:{"cols":6}},[_c('v-select',{attrs:{"items":_vm.$t('chart.relative-time'),"item-text":"text","item-value":"value","label":"Zeitraum"},model:{value:(_vm.relativeDate.timespan),callback:function ($$v) {_vm.$set(_vm.relativeDate, "timespan", $$v)},expression:"relativeDate.timespan"}})],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"v-calendar__action",attrs:{"cols":6}},[_c('v-btn',{staticClass:"custom font-weight-bold firstColor firstColorText--text",staticStyle:{"letter-spacing":"0","text-transform":"inherit"},attrs:{"depressed":"","block":"","disabled":_vm.dateTab == 0 || _vm.panel.relativeDate.count >= 1
                        ? false
                        : true},on:{"click":function($event){_vm.applyAll();
                      _vm.$emit('reload_all');}}},[_vm._v(" "+_vm._s(_vm.$t('chart.apply-all-action'))+" ")])],1),_c('v-col',{attrs:{"cols":6}},[_c('v-btn',{staticClass:"custom font-weight-bold firstColor firstColorText--text",staticStyle:{"letter-spacing":"0","text-transform":"inherit"},attrs:{"depressed":"","block":"","disabled":_vm.dateTab == 0 || _vm.panel.relativeDate.count >= 1
                        ? false
                        : true},on:{"click":function($event){_vm.applyCurrent();
                      _vm.$emit('reload_all');}}},[_vm._v(" "+_vm._s(_vm.$t('chart.apply-action'))+" ")])],1)],1)],1)],1):_c('div',{staticClass:"d-flex align-center flex-column v-calendar"},[_c('div',{staticClass:"d-flex align-center flex-column"},[_c('v-date-picker',{staticClass:"custom",attrs:{"first-day-of-week":"1","locale":_vm.locale,"no-title":""},model:{value:(_vm.fromDateVal),callback:function ($$v) {_vm.fromDateVal=$$v},expression:"fromDateVal"}}),_c('v-date-picker',{staticClass:"custom",attrs:{"first-day-of-week":"1","locale":_vm.locale,"no-title":""},model:{value:(_vm.toDateVal),callback:function ($$v) {_vm.toDateVal=$$v},expression:"toDateVal"}})],1),_c('div',{staticClass:"v-calendar__action d-flex align-center flex-column"},[_c('v-btn',{staticClass:"custom secondColor mb-4",attrs:{"depressed":""},on:{"click":function($event){_vm.applyAll();
                  _vm.$emit('reload_all');}}},[_vm._v(" "+_vm._s(_vm.$t('chart.apply-all-action'))+" ")]),_c('v-btn',{staticClass:"custom firstColor firstColorText--text",attrs:{"depressed":""},on:{"click":function($event){_vm.applyCurrent();
                  _vm.$emit('reload_all');}}},[_vm._v(" "+_vm._s(_vm.$t('chart.apply-action'))+" ")])],1)])],1),(_vm.seriesWithoutData != '' && _vm.hasData)?_c('div',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({staticStyle:{"margin":"0px 5px"}},'v-row',attrs,false),on),[_c('span',{staticClass:"warning--text text-caption"},[_c('v-icon',{attrs:{"small":"","color":"warning"}},[_vm._v("mdi-alert")]),_vm._v(_vm._s(_vm.seriesWithoutData))],1)])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('chart.noSeriesHelp')))])])],1):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-left":180,"transition":"scale-transition","offset-y":"","max-width":"216px","min-width":"100px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('div',{staticClass:"d-flex align-center justify-space-between"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var hover = ref.hover;
return [_c('div',{class:("" + (hover || _vm.showChartMenu
                        ? 'd-flex align-center'
                        : 'd-flex align-center '))},[_c('v-btn',_vm._g({staticClass:"custom",attrs:{"icon":""}},on),[_c('v-icon',{class:("" + (hover || _vm.showChartMenu
                            ? 'thirdColorText--text active'
                            : '')),attrs:{"color":"greyTint"}},[_vm._v("mdi-dots-horizontal")])],1)],1)]}}],null,true)})],1)]}}],null,true),model:{value:(_vm.showChartMenu),callback:function ($$v) {_vm.showChartMenu=$$v},expression:"showChartMenu"}},[_c('v-sheet',[_c('v-container',{staticClass:"pt-6 pb-5 pl-6 chartBackground",attrs:{"fluid":"","grid-list-sm":""}},[_c('p',{staticClass:"diagramHeading componentText--text"},[_vm._v(" "+_vm._s(_vm.$t('chart.chart-types'))+" ")]),_c('v-layout',{attrs:{"row":"","wrap":""}},_vm._l((_vm.chartItemsDiagrams),function(item){return _c('v-flex',{key:item.id,attrs:{"index":item.id,"xs4":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var hover = ref.hover;
return [_c('div',{class:("" + (hover ? 'sidebarActiv active divHover' : 'divHover')),on:{"click":function($event){_vm.setChartTypeView(item.type);
                        _vm.$emit('update');}}},[(item.type == 'table' || item.type == 'pie')?_c('icon-base',{attrs:{"icon-name":_vm.$t(item.text),"width":30,"height":30,"viewBox":((0) + " " + (0) + " " + (25) + " " + (25))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var strokeColor = ref.strokeColor;
return [_c('keep-alive',[_c(item.component,{tag:"component",attrs:{"strokeColor":strokeColor}})],1)]}}],null,true)}):_c('icon-base',{attrs:{"icon-name":_vm.$t(item.text)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var strokeColor = ref.strokeColor;
return [_c('keep-alive',[_c(item.component,{tag:"component",attrs:{"strokeColor":strokeColor}})],1)]}}],null,true)})],1)]}}],null,true)})],1)}),1)],1)],1),(_vm.diagramType != 'heatmap')?_c('div',{staticClass:"dividerBackground"},[_c('v-divider',{staticClass:"diagramLine"})],1):_vm._e(),(_vm.diagramType != 'heatmap')?_c('v-sheet',[_c('v-container',{staticClass:"pt-5 pb-5 chartBackground",attrs:{"fluid":""}},[_c('p',{staticClass:"diagramHeading componentText--text pl-6"},[_vm._v(" "+_vm._s(_vm.$t('chart.chart-settings'))+" ")]),(
                  _vm.diagramType == 'column' &&
                    _vm.tableHeader.length > 2 &&
                    _vm.comparedUnit
                )?_c('div',{staticClass:"stackedDiv"},[_c('v-switch',{staticClass:"stacked",attrs:{"label":_vm.$t('dashboard.switch')},on:{"change":function($event){_vm.prepareChartOptions(_vm.datResPicker);
                    _vm.$store.dispatch('nodes/updatePanelSettingRequest', {
                      id: _vm.panel.id,
                      stacking: _vm.stackedColumn
                    });}},model:{value:(_vm.stackedColumn),callback:function ($$v) {_vm.stackedColumn=$$v},expression:"stackedColumn"}})],1):_vm._e(),(
                  _vm.diagramType == 'bar' &&
                    _vm.tableHeader.length > 2 &&
                    _vm.comparedUnit
                )?_c('div',{staticClass:"stackedDiv"},[_c('v-switch',{staticClass:"stacked",attrs:{"label":_vm.$t('dashboard.switch')},on:{"change":function($event){_vm.prepareChartOptions(_vm.datResPicker);
                    _vm.$store.dispatch('nodes/updatePanelSettingRequest', {
                      id: _vm.panel.id,
                      stacking: _vm.stackedBar
                    });}},model:{value:(_vm.stackedBar),callback:function ($$v) {_vm.stackedBar=$$v},expression:"stackedBar"}})],1):_vm._e(),(_vm.diagramType == 'area' && _vm.tableHeader.length > 2)?_c('div',{staticClass:"stackedDiv"},[_c('v-switch',{staticClass:"stacked",attrs:{"label":_vm.$t('dashboard.switch')},on:{"change":function($event){_vm.prepareChartOptions(_vm.datResPicker);
                    _vm.$store.dispatch('nodes/updatePanelSettingRequest', {
                      id: _vm.panel.id,
                      stacking: _vm.stackedArea
                    });}},model:{value:(_vm.stackedArea),callback:function ($$v) {_vm.stackedArea=$$v},expression:"stackedArea"}})],1):_vm._e(),(
                  _vm.diagramType != 'area' &&
                    _vm.diagramType != 'scatter' &&
                    _vm.diagramType != 'pie' &&
                    _vm.diagramType != 'spline' &&
                    _vm.diagramType != 'heatmap'
                )?_c('div',{staticClass:"stackedDiv"},[_c('v-switch',{staticClass:"stacked",attrs:{"label":_vm.$t('chart.sort')},on:{"change":function($event){if (!_vm.panel.sortBySize) { _vm.topFlop.isActive = 0; }
                    _vm.prepareChartOptions(_vm.datResPicker);
                    _vm.$emit('update');}},model:{value:(_vm.sortBySize),callback:function ($$v) {_vm.sortBySize=$$v},expression:"sortBySize"}})],1):_vm._e(),(
                  (_vm.diagramType == 'scatter' ||
                    _vm.diagramType == 'column' ||
                    _vm.diagramType == 'spline') &&
                    _vm.tableHeader.length == 2
                )?_c('div',{staticClass:"stackedDiv"},[_c('v-switch',{staticClass:"stacked",attrs:{"label":_vm.$t('chart.trendline')},on:{"change":function($event){_vm.$emit('update');
                    _vm.addRegression();}},model:{value:(_vm.displayTrendLine),callback:function ($$v) {_vm.displayTrendLine=$$v},expression:"displayTrendLine"}})],1):_vm._e(),(
                  _vm.panel.compareNodes &&
                    _vm.panel.vars.length == 1 &&
                    (_vm.diagramType == 'bar' || _vm.diagramType == 'column')
                )?_c('div',[_c('v-select',{staticClass:"topFlop",attrs:{"items":_vm.topFlopConfig,"label":"Top / Flop","hide-details":"","dense":""},on:{"change":function($event){_vm.cutData(_vm.topFlop.isActive, _vm.topFlop.n);
                    _vm.topFlop = _vm.topFlop;}},model:{value:(_vm.topFlop.isActive),callback:function ($$v) {_vm.$set(_vm.topFlop, "isActive", $$v)},expression:"topFlop.isActive"}}),(_vm.topFlop.isActive != 0)?_c('v-slider',{staticClass:"align-center",attrs:{"max":_vm.panel.tableData.length,"min":1,"hide-details":"","dense":""},on:{"change":function($event){_vm.cutData(_vm.topFlop.isActive, _vm.topFlop.n);
                    _vm.topFlop = _vm.topFlop;}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-text-field',{staticClass:"mt-0 pt-0",staticStyle:{"width":"60px"},attrs:{"hide-details":"","single-line":"","type":"number"},on:{"input":function($event){_vm.cutData(_vm.topFlop.isActive, _vm.topFlop.n);
                        _vm.topFlop = _vm.topFlop;}},model:{value:(_vm.topFlop.n),callback:function ($$v) {_vm.$set(_vm.topFlop, "n", $$v)},expression:"topFlop.n"}})]},proxy:true}],null,true),model:{value:(_vm.topFlop.n),callback:function ($$v) {_vm.$set(_vm.topFlop, "n", $$v)},expression:"topFlop.n"}}):_vm._e()],1):_vm._e(),(_vm.diagramType != 'heatmap')?_c('div',[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',{staticClass:"chartBackground"},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t('chart.chartColors'))+" ")]),_c('v-expansion-panel-content',{attrs:{"id":"customColorContent"}},[_vm._l((_vm.panel.chartData
                          ? _vm.panel.chartData[0].series
                          : [{ name: 'bla bla' }]),function(datapoint,index){return _c('v-row',{key:index},[_c('v-col',{staticStyle:{"padding":"0px"},attrs:{"cols":3,"align-self":"center"}},[_c('v-menu',{staticStyle:{"background-color":"#ffffff"},attrs:{"offset-y":"","offset-x":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var on = ref.on;
                          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.panel.chartColors[index]
                                    ? _vm.panel.chartColors[index].color
                                    : _vm.themeColors[index % _vm.themeColors.length],"elevation":"2","fab":"","x-small":""},on:{"click":function($event){return _vm.setActiveColorPicker(index)}}},'v-btn',attrs,false),on))]}}],null,true),model:{value:(_vm.cPIsMenuOpen[index]),callback:function ($$v) {_vm.$set(_vm.cPIsMenuOpen, index, $$v)},expression:"cPIsMenuOpen[index]"}},[_c('v-card',{staticStyle:{"padding":"10px"}},[_c('v-color-picker',{staticClass:"pa-0",attrs:{"dot-size":"18","mode":"hexa"},model:{value:(_vm.colorPicker.activeColor),callback:function ($$v) {_vm.$set(_vm.colorPicker, "activeColor", $$v)},expression:"colorPicker.activeColor"}}),_c('span',{staticClass:"text-caption componentText--text"},[_vm._v(_vm._s(_vm.$t('chart.themeColors')))]),_c('v-row',{staticStyle:{"margin":"0px 0px 5px"}},_vm._l((_vm.themeColors),function(color,index){return _c('v-btn',{key:index,staticStyle:{"margin":"0px 0px 0px 5px"},attrs:{"color":color,"x-small":""},on:{"click":function($event){_vm.colorPicker.activeColor = color}}})}),1),_c('span',{staticClass:"text-caption componentText--text"},[_vm._v(_vm._s(_vm.$t('chart.lastUsedColors')))]),_c('v-row',{staticStyle:{"margin":"0px 0px 1px"}},_vm._l((7),function(index){return _c('div',{key:index},[_c('v-btn',{staticStyle:{"margin":"0px 0px 0px 5px"},attrs:{"color":_vm.$store.getters[
                                        'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                      ](index - 1),"x-small":"","disabled":_vm.$store.getters[
                                        'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                      ](index - 1) === ''
                                        ? true
                                        : false},on:{"click":function($event){_vm.colorPicker.activeColor = _vm.$store.getters[
                                        'layout/GET_LAST_USED_COLOR_BY_INDEX'
                                      ](index - 1)}}})],1)}),0)],1)],1)],1),_c('v-col',{staticStyle:{"padding":"0px"}},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(datapoint.name))])])],1)}),(
                          !(
                            _vm.panel.chartColors.find(function (x) { return x.isCustom; }) ===
                            undefined
                          )
                        )?_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticStyle:{"margin":"3px 0px 0px 0px"},attrs:{"x-small":""},on:{"click":function($event){return _vm.resetChartColors()}}},[_vm._v(" "+_vm._s(_vm.$t('settings.reset'))+" ")])],1):_vm._e()],2)],1)],1),(
                    _vm.comparedUnit &&
                      _vm.diagramType != 'pie' &&
                      _vm.diagramType != 'scatter'
                  )?_c('div',[_c('v-expansion-panels',{attrs:{"flat":""}},[_c('v-expansion-panel',{staticClass:"chartBackground"},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(_vm.$t('chart.tolerancelimit'))+" ")]),_c('v-expansion-panel-content',{attrs:{"id":"customColorContent"}},[_c('v-text-field',{staticClass:"toleranceInput",attrs:{"label":_vm.$t('chart.upperLimit'),"clearable":"","type":"number"},on:{"change":function($event){_vm.$store.dispatch(
                              'nodes/updatePanelSettingRequest',
                              {
                                id: _vm.panel.id,
                                upperLimit: _vm.upperLimit
                              }
                            );
                            _vm.prepareChartOptions();},"click:clear":function($event){_vm.$store.dispatch(
                              'nodes/updatePanelSettingRequest',
                              {
                                id: _vm.panel.id,
                                upperLimit: undefined
                              }
                            );
                            _vm.prepareChartOptions();}},model:{value:(_vm.upperLimit),callback:function ($$v) {_vm.upperLimit=$$v},expression:"upperLimit"}}),_c('v-text-field',{staticClass:"toleranceInput",attrs:{"label":_vm.$t('chart.lowerLimit'),"clearable":"","type":"number"},on:{"change":function($event){_vm.$store.dispatch(
                              'nodes/updatePanelSettingRequest',
                              {
                                id: _vm.panel.id,
                                lowerLimit: _vm.lowerLimit
                              }
                            );
                            _vm.prepareChartOptions();},"click:clear":function($event){_vm.$store.dispatch(
                              'nodes/updatePanelSettingRequest',
                              {
                                id: _vm.panel.id,
                                lowerLimit: undefined
                              }
                            );
                            _vm.prepareChartOptions();}},model:{value:(_vm.lowerLimit),callback:function ($$v) {_vm.lowerLimit=$$v},expression:"lowerLimit"}})],1)],1)],1)],1):_vm._e()],1):_vm._e()])],1):_vm._e(),_c('div',{staticClass:"dividerBackground"},[_c('v-divider',{staticClass:"diagramLine"})],1),_c('v-sheet',[_c('v-list',{staticClass:"chartBackground"},[_c('v-list-item',{staticClass:"v-list-item--chart",attrs:{"link":""},on:{"click":function($event){_vm.showChartMenu = false}}},[_c('v-list-item-content',[_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({staticClass:"d-flex align-center justify-content-between"},'v-list-item-title',attrs,false),on),[_c('icon-base',{staticClass:"mr-3",attrs:{"icon-name":_vm.$t('chart.full-screen')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var strokeColor = ref.strokeColor;
return [_c('keep-alive',[_c(_vm.chartItemsFuntions[0].component,{tag:"component",attrs:{"strokeColor":strokeColor}})],1)]}}],null,true)}),_vm._v(" "+_vm._s(_vm.$t(_vm.chartItemsFuntions[0].text))+" ")],1)]}}],null,true),model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c('v-card',{staticClass:"py-10 px-10"},[_c('v-card-title',{staticClass:"align-center justify-space-between"},[_c('div'),_c('div',{staticClass:"dialog__close",on:{"click":_vm.close_fullscreen}},[_c('icon-base',{attrs:{"icon-name":"","width":16,"height":16},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var strokeColor = ref.strokeColor;
return [_c('IconClose',{attrs:{"strokeColor":strokeColor}})]}}],null,true)})],1)]),_c('v-spacer'),_c('div',[(_vm.showTableData)?_c('v-simple-table',{attrs:{"fixed-header":"","height":_vm.windowsHeight - 58},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.tableHeader),function(title,i){return _c('th',{key:i,staticClass:"text-left"},[_vm._v(" "+_vm._s(title.text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.tableContent),function(row,i){return _c('tr',{key:i},_vm._l((_vm.tableHeader),function(title,i){return _c('td',{key:i},[_vm._v(" "+_vm._s(row[title.value])+" ")])}),0)}),0)]},proxy:true}],null,true)}):_vm._e(),(!_vm.showTableData)?_c('highcharts',{ref:"bigChart",class:_vm.$vuetify.theme.isDark
                              ? 'dark-theme'
                              : 'light-theme',attrs:{"options":_vm.chartOptions}}):_vm._e()],1)],1)],1)],1)],1),_c('v-list-item',{staticClass:"v-list-item--chart",attrs:{"link":""},on:{"click":function($event){_vm.$emit('wizardDialog'), _vm.$emit('toggleWizard')}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center justify-content-between"},[_c('icon-base',{staticClass:"mr-3",attrs:{"icon-name":_vm.$t('chart.edit')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var strokeColor = ref.strokeColor;
return [_c('keep-alive',[_c(_vm.chartItemsFuntions[4].component,{tag:"component",attrs:{"strokeColor":strokeColor}})],1)]}}],null,true)}),_vm._v(" "+_vm._s(_vm.$t(_vm.chartItemsFuntions[4].text))+" ")],1)],1)],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                              var on = ref.on;
                              var attrs = ref.attrs;
return [(_vm.diagramType != 'pie')?_c('v-list-item',_vm._g(_vm._b({staticClass:"v-list-item--chart",attrs:{"link":""}},'v-list-item',attrs,false),on),[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center justify-content-between"},[_c('icon-base',{staticClass:"mr-3",attrs:{"icon-name":_vm.$t('chart.export-csv')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                              var strokeColor = ref.strokeColor;
return [_c('keep-alive',[_c(_vm.chartItemsFuntions[5].component,{tag:"component",attrs:{"strokeColor":strokeColor}})],1)]}}],null,true)}),_vm._v(" "+_vm._s(_vm.$t(_vm.chartItemsFuntions[5].text))+" ")],1)],1)],1):_vm._e()]}}],null,true),model:{value:(_vm.editAxisDialog),callback:function ($$v) {_vm.editAxisDialog=$$v},expression:"editAxisDialog"}},[(_vm.axisRange.length > 0)?_c('v-card',[(_vm.diagramType != 'heatmap')?_c('div',_vm._l((_vm.chartOptions.yAxis),function(axis,i){return _c('v-row',{key:i,staticClass:"mt-1 ml-2 mr-2",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticClass:"componentText--text"},[_vm._v(" "+_vm._s(axis.title.text)+" :")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{class:_vm.$vuetify.theme.isDark ? 'forceDark' : '',attrs:{"hide-details":"","label":"Min","outlined":"","dense":"","rules":[_vm.numberRule]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.editAxisDialog = false;
                            _vm.prepareChartOptions(_vm.datResPicker);
                            _vm.showChartMenu = false;}},model:{value:(_vm.axisRange[i][0]),callback:function ($$v) {_vm.$set(_vm.axisRange[i], 0, $$v)},expression:"axisRange[i][0]"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{class:_vm.$vuetify.theme.isDark ? 'forceDark' : '',attrs:{"hide-details":"","label":"Max","outlined":"","dense":"","rules":[_vm.numberRule]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.editAxisDialog = false;
                            _vm.prepareChartOptions(_vm.datResPicker);
                            _vm.showChartMenu = false;}},model:{value:(_vm.axisRange[i][1]),callback:function ($$v) {_vm.$set(_vm.axisRange[i], 1, $$v)},expression:"axisRange[i][1]"}})],1)],1)}),1):_c('div',[_c('v-row',{staticClass:"mt-1 ml-2 mr-2",attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('span',{staticStyle:{"color":"black"}},[_vm._v(" "+_vm._s(_vm.chartOptions.yAxis.unit)+" :")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Min","outlined":"","dense":"","rules":[_vm.numberRule]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.editAxisDialog = false;
                            _vm.prepareChartOptions(_vm.datResPicker);
                            _vm.showChartMenu = false;}},model:{value:(_vm.axisRange[0][0]),callback:function ($$v) {_vm.$set(_vm.axisRange[0], 0, $$v)},expression:"axisRange[0][0]"}})],1),_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{attrs:{"hide-details":"","label":"Max","outlined":"","dense":"","rules":[_vm.numberRule]},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.editAxisDialog = false;
                            _vm.prepareChartOptions(_vm.datResPicker);
                            _vm.showChartMenu = false;}},model:{value:(_vm.axisRange[0][1]),callback:function ($$v) {_vm.$set(_vm.axisRange[0], 1, $$v)},expression:"axisRange[0][1]"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.editAxisDialog = false;
                        _vm.prepareChartOptions(_vm.datResPicker);
                        _vm.showChartMenu = false;}}},[_vm._v(" OK ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.editAxisDialog = false}}},[_vm._v(" "+_vm._s(_vm.$t('settings.cancel'))+" ")])],1)],1):_vm._e()],1),(_vm.hasTableData)?_c('v-list-item',{staticClass:"v-list-item--chart",attrs:{"link":""},on:{"click":function($event){_vm.showChartMenu = false}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center justify-content-between"},[_c('icon-base',{staticClass:"mr-3",attrs:{"icon-name":_vm.$t('chart.export-csv')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var strokeColor = ref.strokeColor;
return [_c('keep-alive',[_c(_vm.chartItemsFuntions[1].component,{tag:"component",attrs:{"strokeColor":strokeColor}})],1)]}}],null,true)}),_c('vue-json-to-csv',{attrs:{"json-data":_vm.csv.content,"csv-title":_vm.csv.title,"separator":";"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.chartItemsFuntions[1].text))+" ")])],1)],1)],1):_vm._e(),(_vm.panel.type != 'table')?_c('v-list-item',{staticClass:"v-list-item--chart",attrs:{"link":""},on:{"click":function($event){return _vm.exportPNG()}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center justify-content-between"},[_c('icon-base',{staticClass:"mr-3",attrs:{"icon-name":_vm.$t('chart.export-csv')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var strokeColor = ref.strokeColor;
return [_c('keep-alive',[_c(_vm.chartItemsFuntions[2].component,{tag:"component",attrs:{"strokeColor":strokeColor}})],1)]}}],null,true)}),_vm._v(" "+_vm._s(_vm.$t(_vm.chartItemsFuntions[2].text))+" ")],1)],1)],1):_vm._e(),_c('v-list-item',{staticClass:"v-list-item--chart",attrs:{"link":""},on:{"click":function($event){return _vm.deletePanel()}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"d-flex align-center justify-content-between"},[_c('icon-base',{staticClass:"mr-3",attrs:{"icon-name":_vm.$t('chart.delete')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                        var strokeColor = ref.strokeColor;
return [_c('keep-alive',[_c(_vm.chartItemsFuntions[3].component,{tag:"component",attrs:{"strokeColor":strokeColor}})],1)]}}],null,true)}),_vm._v(" "+_vm._s(_vm.$t(_vm.chartItemsFuntions[3].text))+" ")],1)],1)],1)],1)],1)],1)],1)])]}}])}),_c('div',{staticClass:"separator"}),_c('div',{staticClass:"v-chart__content"},[_c('div',{ref:"v_chart_chart",staticClass:"v-chart__chart"},[(_vm.showTableData)?_c('v-simple-table',{attrs:{"fixed-header":"","height":this.$refs.v_chart_chart.clientHeight},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',_vm._l((_vm.tableHeader),function(title,i){return _c('th',{key:i,staticClass:"text-right"},[_vm._v(" "+_vm._s(title.text)+" ")])}),0)]),_c('tbody',_vm._l((_vm.tableContent),function(row,i){return _c('tr',{key:i},_vm._l((_vm.tableHeader),function(title,i){return _c('td',{key:i,staticClass:"text-right"},[_vm._v(" "+_vm._s(row[title.value])+" ")])}),0)}),0)]},proxy:true}],null,false,2592115744)}):_vm._e(),(!_vm.showTableData && _vm.hasData && _vm.mounted)?_c('highcharts',{ref:"chart",class:_vm.$vuetify.theme.isDark ? 'dark-theme' : 'light-theme',attrs:{"options":_vm.chartOptions}}):_vm._e(),(!_vm.hasData && _vm.mounted)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('chart.noData'))+" ")]):_vm._e()],1)]),_c('v-snackbar',{attrs:{"color":"red"},scopedSlots:_vm._u([{key:"action",fn:function(ref){
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":""},on:{"click":function($event){_vm.dateAlert = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('chart.close'))+" ")])]}}]),model:{value:(_vm.dateAlert),callback:function ($$v) {_vm.dateAlert=$$v},expression:"dateAlert"}},[_vm._v(" "+_vm._s(_vm.$t('chart.invalidDates'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }