<template>
  <v-row class="ml-1 mt-1">
    <v-col :cols="5" class="pr-1">
      <div>
        <v-radio-group v-model="radio" class="mt-0">
          <v-radio
            v-for="(theme, index) in filteredThemes"
            :key="index"
            :value="theme.name"
            @click="setTheme(theme)"
            color="secondColorIcon"
            light
          >
            <template v-slot:label>
              <span class="componentText--text">
                {{
                  theme.name == 'Default' ? $t('settings.default') : theme.name
                }}</span
              >
            </template>
          </v-radio>
        </v-radio-group>
      </div>
    </v-col>
    <v-col :cols="7" class="pr-1 componentText--text">
      <v-row justify="center" align="center" class="mt-0 mb-1">
        {{ $t('main.dark-mode') }}
        <v-switch
          v-model="isDark_switch"
          @click="darkMode"
          class="ml-3 mt-1 pt-0"
          dense
          hide-details
        />
      </v-row>
      <v-row
        v-if="this.users.includes(this.username)"
        justify="center"
        align="center"
        class="mt-0"
      >
        {{ $t('main.dev-mode') }}
        <v-switch
          v-if="this.users.includes(this.username)"
          v-model="devmode"
          @click="devMode()"
          class="ml-3 mt-1 pt-0"
          dense
          hide-details
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex';
import config from '../../config/index';

export default {
  name: 'ThemeChanger',
  data: () => ({
    radio: 'Default',
    themes: config.themes,
    defaultThemes: ['Default', 'Nexerion', 'GASAG'],
    themeMapper: [
      {
        key: '00',
        theme: 'all'
      },
      {
        key: '02',
        theme: 'Evo'
      },
      {
        key: '03',
        theme: '' // GSP theme is in defaultThemes
      },
      {
        key: '04',
        theme: '' // GSP theme is in defaultThemes
      },
      {
        key: '05',
        theme: 'Wiro'
      },
      {
        key: '06',
        theme: 'Gegenbauer' // G2Plus = Gegenbauer theme
      },
      {
        key: '07',
        theme: '' // no theme definded (ecoworks GmbH)
      },
      {
        key: '08',
        theme: 'IVL Langer'
      },
      {
        key: '09',
        theme: '' // GASAG theme is in defaultThemes
      }
    ],
    devmode: null,
    username: null,
    users: [
      'hray',
      'mvantreek',
      'kuehnel',
      'alisa',
      'jneumann',
      'mdemtschueck',
      'mr',
      'msg',
      'ska'
    ]
  }),
  computed: {
    ...mapState('layout', ['theme']),
    ...mapState('auth', ['user']),
    filteredThemes() {
      let filter = this.themeMapper.filter(obj => obj.key == this.user.line)[0];
      let filteredThemes = [];

      if (filter.theme == 'all') {
        filteredThemes = this.themes;
      } else {
        this.defaultThemes.forEach(defaultTheme => {
          let theme = this.themes.filter(theme => theme.name == defaultTheme);
          filteredThemes.push(...theme);
        });
        let theme = this.themes.filter(theme => theme.name == filter.theme);
        filteredThemes.push(...theme);
      }
      return filteredThemes;
    },
    isDark_switch: {
      get: function() {
        return this.$vuetify.theme.isDark;
      },
      set: function() {}
    }
  },
  mounted() {
    // set default theme
    let defaultTheme = this.themes.find(
      item => item.name === this.$store.state.layout.theme.name
    );
    //this.setTheme(defaultTheme);
    this.radio = this.$store.state.layout.theme.name
      ? this.$store.state.layout.theme.name
      : 'Default';

    this.username = this.$store.state.auth.user.login;
    this.devmode = this.$store.state.layout.dev;
  },
  methods: {
    darkMode() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

      let payload = {
        name: this.$store.state.layout.theme.name,
        isDark: this.isDark_switch
      };

      this.$store.dispatch('layout/setTheme', payload);
    },
    devMode() {
      this.$store.dispatch('layout/setDev', this.devmode);
      this.$router.go(0);
    },
    setTheme(theme) {
      const name = theme.name;
      const dark = theme.dark;
      const light = theme.light;
      // set themes
      Object.keys(dark).forEach(i => {
        this.$vuetify.theme.themes.dark[i] = dark[i];
      });
      Object.keys(light).forEach(i => {
        this.$vuetify.theme.themes.light[i] = light[i];
      });
      // also save theme name to disable selection
      this.$vuetify.theme.themes.name = name;

      let payload = {
        name: name,
        isDark: this.$vuetify.theme.isDark
      };
      this.radio = name;
      this.$store.dispatch('layout/setTheme', payload);
    }
  }
};
</script>
