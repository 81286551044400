<template>
  <v-card
    v-if="toggleWizard"
    class="v-panel pt-3 pr-3 pb-3 pl-3 d-flex align-center justify-center"
    outlined
    tile
    color="chartBackground"
  >
    <div @click="$emit('wizardDialog')">
      <div class="d-flex align-center justify-center flex-column">
        <icon-base :width="67" :height="67" :viewBox="`${2} ${0} ${20} ${20}`">
          <template slot-scope="{ strokeColor }">
            <IconPlus :strokeColor="strokeColor" /> </template
        ></icon-base>
        <span class="mt-3 componentText--text">{{
          $t('dashboard.add-diagram')
        }}</span>
      </div>
    </div>
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="align-center justify-space-between">
          <h3 class="componentText--text">
            {{ $t('dashboard.add-diagram') }}
          </h3>
          <div class="dialog__close" @click="closeDialog()">
            <v-hover v-slot:default="{ hover }">
              <v-icon :class="`${hover ? 'secondColorIcon--text active' : ''}`"
                >mdi-close</v-icon
              >
            </v-hover>
          </div>
        </v-card-title>
        <div>
          <v-row
            v-if="treeNode.type !== 'a' && treeNode.type !== 'c'"
            class="componentText--text"
            align="center"
          >
            <v-switch
              class="select_all_elements ml-3 pr-1"
              v-model="compareNodes"
            ></v-switch>
            <span class="mb-2">
              {{ $t('createPanel.compareNodes') }}
            </span>
          </v-row>
          <form-wizard ref="wizard" :start-index="isHome ? 0 : 1">
            <div slot="title"></div>
            <tab-content>
              <v-container>
                <v-label>{{ $t('createPanel.chooseLocation') }}</v-label>
                <v-text-field
                  v-model="search"
                  ref="search"
                  class="custom"
                  solo
                  :placeholder="$t('main.search')"
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <icon-base :width="32" :height="16">
                      <template slot-scope="{ strokeColor }">
                        <IconSearch :strokeColor="strokeColor" /> </template
                    ></icon-base>
                  </template>
                </v-text-field>
                <div
                  v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
                  class="v-checkbox__container"
                  v-if="items.length > 0"
                >
                  <template v-if="search !== null && search.length > 0">
                    <v-treeview
                      v-model="selection"
                      :items="items"
                      :selection-type="selectionType"
                      selectable
                      return-object
                      :search="search"
                      :filter="filter"
                      expand-icon="mdi-chevron-down"
                      :open="items"
                    ></v-treeview>
                  </template>
                  <template v-else>
                    <v-treeview
                      v-model="selection"
                      :items="items"
                      :selection-type="selectionType"
                      selectable
                      return-object
                      :search="search"
                      :filter="filter"
                      expand-icon="mdi-chevron-down"
                    ></v-treeview>
                  </template>
                </div>
              </v-container>
            </tab-content>
            <tab-content>
              <v-container v-show="isDatapoint">
                <v-label>
                  <span class="componentText--text">
                    {{ $t('createPanel.chooseDatapoints') }}
                  </span>
                </v-label>
                <v-text-field
                  v-model="searchDatapoint"
                  ref="searchDatapoint"
                  class="custom"
                  solo
                  :placeholder="$t('main.search')"
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <icon-base :width="32" :height="16">
                      <template slot-scope="{ strokeColor }">
                        <IconSearch :strokeColor="strokeColor" /> </template
                    ></icon-base>
                  </template>
                </v-text-field>
                <div
                  class="v-diagram__container"
                  v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
                >
                  <v-list flat>
                    <v-list-item-group
                      v-model="selectedDatapoint"
                      active-class="secondColor"
                      ref="dataPointList"
                      multiple
                    >
                      <v-list-item
                        v-for="(item, i) in filteredDatapoint"
                        :key="i"
                        @click="selectDatapoint(item)"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title class="componentText--text"
                              >{{ item.text }} [{{
                                item.unit
                              }}]</v-list-item-title
                            >
                          </v-list-item-content>

                          <v-list-item-action v-if="active">
                            <v-icon class="secondColorIcon--text"
                              >mdi-check</v-icon
                            >
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-container>
            </tab-content>
            <!-- diagram type -->
            <tab-content>
              <v-container>
                <v-label>
                  <span class="componentText--text">
                    {{ $t('createPanel.chooseDiagramType') }}
                  </span>
                </v-label>
                <v-text-field
                  v-model="searchDiagram"
                  ref="searchDiagram"
                  class="custom"
                  solo
                  :placeholder="$t('main.search')"
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <icon-base :width="32" :height="16">
                      <template slot-scope="{ strokeColor }">
                        <IconSearch :strokeColor="strokeColor" /> </template
                    ></icon-base>
                  </template>
                </v-text-field>
                <div
                  class="v-diagram__container"
                  v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
                >
                  <v-list flat>
                    <v-list-item-group
                      v-model="selectedDiagram"
                      active-class="secondColor"
                      ref="diagramList"
                    >
                      <v-list-item
                        v-for="(item, i) in filteredDiagram"
                        :key="i"
                        @click="selectDiagram(item, i)"
                      >
                        <v-list-item-content>
                          <v-list-item-title>
                            <div
                              class="d-flex align-center justify-space-between"
                              style="width: 100%"
                            >
                              <span class="componentText--text">
                                {{ $t(item.name) }}
                              </span>
                              <icon-base :icon-name="item.icon" class="mr-3">
                                <template slot-scope="{ strokeColor }">
                                  <keep-alive>
                                    <component
                                      :strokeColor="strokeColor"
                                      :is="item.component"
                                    ></component>
                                  </keep-alive> </template
                              ></icon-base></div
                          ></v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-container>
            </tab-content>
            <!-- diagram title -->
            <tab-content>
              <v-container>
                <v-label>
                  <span class="componentText--text">
                    {{ $t('createPanel.chooseDiagramTitle') }}
                  </span>
                </v-label>
                <div
                  class="v-diagram__container"
                  v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
                >
                  <v-text-field
                    :class="$vuetify.theme.isDark ? 'forceDark' : ''"
                    :label="$t('createPanel.title')"
                    :rules="rules"
                    v-model="diagramTitle"
                    hide-details="auto"
                  ></v-text-field>
                </div>
              </v-container>
            </tab-content>
            <template slot="footer" slot-scope="props">
              <div class="wizard-footer-left">
                <v-btn
                  class="mr-2 mb-2 custom firstColor firstColorText--text"
                  v-if="
                    props.activeTabIndex > (isHome ? 0 : 1) || props.isLastStep
                  "
                  @click.native="props.prevTab()"
                >
                  {{ $t('controlls.back') }}
                </v-btn>
              </div>
              <div class="wizard-footer-right">
                <v-btn
                  :disabled="
                    (!selection.length && isHome) ||
                      (selectedDatapoint === null &&
                        props.activeTabIndex > (!isHome ? 0 : 1)) ||
                      (selectedDiagram === null &&
                        props.activeTabIndex > (!isHome ? 1 : 2)) ||
                      selectedDatapoint.length == 0
                  "
                  class="mr-2 mb-2 custom firstColor firstColorText--text"
                  v-if="!props.isLastStep"
                  @click.native="props.nextTab()"
                >
                  {{ $t('controlls.next') }}
                </v-btn>

                <v-btn
                  v-bind:class="
                    `${
                      selectedDatapoint === null || selectedDiagram === null
                        ? 'mr-2 mb-2'
                        : ' mr-2 mb-2 custom firstColor firstColorText--text'
                    }`
                  "
                  :disabled="
                    selectedDatapoint === null ||
                      selectedDiagram === null ||
                      diagramTitle === ''
                  "
                  v-else
                  @click.native="createPanel(), $emit('wizardDialog')"
                >
                  {{
                    props.isLastStep
                      ? $t('dashboard.add-diagram')
                      : $t('controlls.next')
                  }}
                </v-btn>
              </div>
            </template>
          </form-wizard>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
  <v-card
    v-else
    class="v-panel pt-3 pr-3 pb-3 pl-3 d-flex align-center justify-center"
    outlined
    tile
    color="chartBackground"
  >
    <v-dialog v-model="dialog" persistent max-width="500">
      <v-card>
        <v-card-title class="align-center justify-space-between">
          <h3 class="componentText--text">
            {{ $t('dashboard.add-diagram') }}
          </h3>
          <div class="dialog__close" @click="closeDialog()">
            <v-hover v-slot:default="{ hover }">
              <v-icon :class="`${hover ? 'secondColorIcon--text active' : ''}`"
                >mdi-close</v-icon
              >
            </v-hover>
          </div>
        </v-card-title>
        <div>
          <div v-if="treeNode.type !== 'a' && treeNode.type !== 'c'">
            <v-switch
              class="select_all_elements"
              v-model="compareNodes"
              :label="$t('createPanel.compareNodes')"
            ></v-switch>
          </div>
          <form-wizard ref="wizard" :start-index="isHome ? 0 : 1">
            <div slot="title"></div>
            <tab-content>
              <v-container>
                <v-label>{{ $t('createPanel.chooseLocation') }}</v-label>
                <v-text-field
                  v-model="search"
                  ref="search"
                  class="custom"
                  solo
                  :placeholder="$t('main.search')"
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <icon-base :width="32" :height="16">
                      <template slot-scope="{ strokeColor }">
                        <IconSearch :strokeColor="strokeColor" /> </template
                    ></icon-base>
                  </template>
                </v-text-field>
                <div
                  v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
                  class="v-checkbox__container"
                  v-if="items.length > 0"
                >
                  <template v-if="search !== null && search.length > 0">
                    <v-treeview
                      v-model="selection"
                      :items="items"
                      :selection-type="selectionType"
                      selectable
                      return-object
                      :search="search"
                      :filter="filter"
                      expand-icon="mdi-chevron-down"
                      :open="items"
                    ></v-treeview>
                  </template>
                  <template v-else>
                    <v-treeview
                      v-model="selection"
                      :items="items"
                      :selection-type="selectionType"
                      selectable
                      return-object
                      :search="search"
                      :filter="filter"
                      expand-icon="mdi-chevron-down"
                    ></v-treeview>
                  </template>
                </div>
              </v-container>
            </tab-content>
            <tab-content>
              <v-container v-show="isDatapoint">
                <v-label>
                  <span class="componentText--text">
                    {{ $t('createPanel.chooseDatapoints') }}
                  </span>
                </v-label>
                <v-text-field
                  v-model="searchDatapoint"
                  ref="searchDatapoint"
                  class="custom"
                  solo
                  :placeholder="$t('main.search')"
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <icon-base :width="32" :height="16">
                      <template slot-scope="{ strokeColor }">
                        <IconSearch :strokeColor="strokeColor" /> </template
                    ></icon-base>
                  </template>
                </v-text-field>
                <div
                  class="v-diagram__container"
                  v-bar="{ preventParentScroll: true, scrollThrottle: 30 }"
                >
                  <v-list flat>
                    <v-list-item-group
                      v-model="selectedDatapoint"
                      active-class="secondColor"
                      ref="dataPointList"
                      multiple
                    >
                      <v-list-item
                        v-for="(item, i) in filteredDatapoint"
                        :key="i"
                        @click="selectDatapoint(item)"
                      >
                        <template v-slot:default="{ active }">
                          <v-list-item-content>
                            <v-list-item-title class="componentText--text"
                              >{{ item.text }} [{{
                                item.unit
                              }}]</v-list-item-title
                            >
                          </v-list-item-content>

                          <v-list-item-action v-if="active">
                            <v-icon class="secondColorIcon--text"
                              >mdi-check</v-icon
                            >
                          </v-list-item-action>
                        </template>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                </div>
              </v-container>
            </tab-content>
            <template slot="footer" slot-scope="props">
              <div class="wizard-footer-right">
                <v-btn
                  :disabled="
                    (!selection.length && isHome) ||
                      (selectedDatapoint === null &&
                        props.activeTabIndex > (!isHome ? 0 : 1)) ||
                      (selectedDiagram === null &&
                        props.activeTabIndex > (!isHome ? 1 : 2)) ||
                      selectedDatapoint.length == 0
                  "
                  class="mr-2 mb-2 custom firstColor firstColorText--text"
                  v-if="!props.isLastStep"
                  @click.native="props.nextTab()"
                >
                  {{ $t('controlls.next') }}
                </v-btn>

                <v-btn
                  v-bind:class="
                    `${
                      selectedDatapoint === null || selectedDiagram === null
                        ? 'mr-2 mb-2'
                        : ' mr-2 mb-2 custom firstColor firstColorText--text'
                    }`
                  "
                  :disabled="
                    selectedDatapoint === null ||
                      selectedDiagram === null ||
                      diagramTitle === ''
                  "
                  v-else
                  @click.native="createPanel(), $emit('wizardDialog')"
                >
                  {{ $t('chart.apply-action') }}
                </v-btn>
              </div>
            </template>
          </form-wizard>
        </div>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapState } from 'vuex';
import { FormWizard, TabContent } from 'vue-form-wizard';
import 'vue-form-wizard/dist/vue-form-wizard.min.css';
import IconBase from '@/components/Icons/';
import IconTableView from '@/components/Icons/IconTableView';
import IconPieView from '@/components/Icons/IconPieView';
import IconBarView from '@/components/Icons/IconBarView';
import IconSplineView from '../Icons/IconSplineView.vue';
import IconHeatmap from '../Icons/IconHeatmap.vue';
import IconColumnView from '../Icons/IconColumnView.vue';
import IconPlus from '../Icons/IconPlus.vue';
import IconSearch from '../Icons/IconSearch.vue';
import IconDurationCurve from '@/components/Icons/IconDurationCurve';
import IconScatter from '@/components/Icons/IconScatter';
import local from '@/locales/de.json';
//import messages from '@/plugins/i18n.js';

export default {
  name: 'CreatePanel',
  components: {
    FormWizard,
    TabContent,
    IconBase,
    IconPlus,
    IconSearch
  },
  props: {
    parentDialog: {
      type: Boolean,
      default: false
    },
    selectPanelId: {
      type: Number,
      default: null
    },
    toggleWizard: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      rules: [value => !!value || 'Required.'],
      diagramTitle: '',
      search: null,
      caseSensitive: false,
      selectionType: 'independent',
      nodes: [],
      initItems: [],
      items: [],
      itemsFiltered: [],
      form: {},
      activeStep: 0,
      searchDatapoint: '',
      tempDatapoint: [],
      selectedDatapoint: [],
      isDatapoint: true,
      datapointItems: [],
      panelVars: null,
      panelTitle: null,
      panelType: null,
      /*
      datapointItems: [
        {
          id: 122,
          text: 'CO2'
        },
        {
          id: 133,
          text: 'kWh'
        },
        {
          id: 134,
          text: '€'
        }
      ],
      */
      searchDiagram: '',
      tempDiagram: {},
      selectedDiagram: [],
      comparedUnit: true,
      compareNodes: false
      /* diagramItems: [
        {
          id: 1,
          name: 'createPanel.tableName',
          type: 'table',
          icon: 'table-view',
          component: IconTableView
        },
        {
          id: 2,
          name: 'createPanel.pieChartName',
          type: 'pie',
          icon: 'pie-view',
          component: IconPieView
        },
        {
          id: 3,
          name: 'createPanel.splineChartName',
          type: 'spline',
          icon: 'spline-view',
          component: IconSplineView
        },
        {
          id: 4,
          name: 'createPanel.barChartName',
          type: 'bar',
          icon: 'bar-view',
          component: IconBarView
        },
        {
          id: 5,
          name: 'createPanel.heatmapName',
          type: 'heatmap',
          icon: 'bar-view',
          component: IconHeatmap
        },
        {
          id: 6,
          name: 'createPanel.columnChartName',
          type: 'column',
          icon: 'column-view',
          component: IconColumnView
        }
      ] */
    };
  },
  /* beforeMount(){
    this.loadElements();
  }, */
  computed: {
    dialog: {
      get() {
        return this.parentDialog == true;
      },
      set(value) {
        this.$emit('setDialog', value);
      }
    },
    diagramItems() {
      let diagramItems = [
        {
          id: 1,
          name: 'createPanel.tableName',
          type: 'table',
          //icon: 'table-view',
          component: IconTableView
        },
        {
          id: 2,
          name: 'createPanel.pieChartName',
          type: 'pie',
          //icon: 'pie-view',
          component: IconPieView
        },
        {
          id: 3,
          name: 'createPanel.splineChartName',
          type: 'spline',
          //icon: 'spline-view',
          component: IconSplineView
        },
        {
          id: 4,
          name: 'createPanel.barChartName',
          type: 'bar',
          //icon: 'bar-view',
          component: IconBarView
        },
        {
          id: 5,
          name: 'createPanel.heatmapName',
          type: 'heatmap',
          //icon: 'bar-view',
          component: IconHeatmap
        },
        {
          id: 6,
          name: 'createPanel.columnChartName',
          type: 'column',
          //icon: 'column-view',
          component: IconColumnView
        },
        {
          id: 7,
          name: 'createPanel.sortedArea',
          //icon: 'duration-curve',
          type: 'area',
          component: IconDurationCurve
        },
        {
          id: 8,
          name: 'createPanel.scatter',
          //icon: 'duration-curve',
          type: 'scatter',
          component: IconScatter
        }
      ];

      for (let i = 0; i < diagramItems.length; i++) {
        diagramItems[i].translatedName = this.$root._i18n.t(
          diagramItems[i].name
        );
      }

      var result = [];
      if (this.tempDatapoint.length < 2) {
        result = diagramItems.filter(diagram => diagram.type != 'pie');
      }
      if (this.tempDatapoint.length > 1) {
        result = diagramItems.filter(diagram => diagram.type != 'heatmap');
      }
      if (this.compareunits() == false) {
        result = diagramItems.filter(
          diagram => diagram.type != 'pie' && diagram.type != 'heatmap'
        );
      }
      result = result.sort(function(a, b) {
        var nameA = a.translatedName;
        var nameB = b.translatedName;
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return result;
    },
    ...mapState('nodes', ['panels', 'isReceiving', 'isUpdating', 'treeNode']),
    filter() {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined;
    },
    isHome() {
      //return true;
      return this.$route.name === 'Home';
    },
    selection: {
      get: function() {
        if (!this.isHome) {
          // set current node from bread crumb in dashboard mode
          this.setSelectedNode();
        }
        return this.nodes;
      },
      set: function(newVal) {
        // TODO: Check if the last selected nodes(groups) from the tree is a datapoint level
        // IF datapoint level is selected set this.isDatapoint = false
        // IF datapoint level is not selected set this.isDatapoint = true
        // PS Backend respond should contain data property for handling the type - content type
        this.$store.state.nodes.isCreatingPanel = { nodes: [...newVal] };
        this.nodes = [...newVal];
      }
    },
    filteredDatapoint() {
      if (!this.searchDatapoint) {
        return this.datapointItems;
      } else {
        return this.datapointItems.filter(item => {
          return (
            item.text
              .toLowerCase()
              .indexOf(this.searchDatapoint.toLowerCase()) > -1 ||
            item.unit
              .toLowerCase()
              .indexOf(this.searchDatapoint.toLowerCase()) > -1
          );
        });
      }
    },
    filteredDiagram() {
      if (!this.searchDiagram) {
        if (this.compareNodes) {
          return this.diagramItems.filter(
            diagram =>
              diagram.type == 'bar' ||
              diagram.type == 'column' ||
              diagram.type == 'table'
          );
        } else {
          return this.diagramItems;
        }
      } else {
        return this.diagramItems.filter(item => {
          if (this.compareNodes) {
            if (
              item.type == 'bar' ||
              item.type == 'column' ||
              item.type == 'table'
            ) {
              return (
                item.translatedName
                  .toLowerCase()
                  .indexOf(this.searchDiagram.toLowerCase()) > -1
              );
            }
          } else {
            return (
              item.translatedName
                .toLowerCase()
                .indexOf(this.searchDiagram.toLowerCase()) > -1
            );
          }
        });
      }
    }
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        document.body.classList.add('v-dialog--open');
        if (this.selectPanelId != null) {
          this.loadElements();
        }
      } else {
        document.body.classList.remove('v-dialog--open');
      }
    }
  },
  methods: {
    assignDepth(arr, depth = 0) {
      let newArr = [...arr];
      newArr.forEach(obj => {
        obj.depth = depth;
        this.assignDepth(obj.children, depth + 1);
      });

      return newArr;
    },
    singleDepth(values) {
      let seen = new Set();
      var hasDuplicates = values.some(function(currentObject) {
        return seen.size === seen.add(currentObject.depth).size;
      });

      return hasDuplicates;
    },
    selectDiagram(item, i) {
      this.selectedDiagram = i;
      this.tempDiagram = { ...item };
    },
    selectDatapoint(item) {
      // Prüfung ob tempDatapoint.title vorhanden in diagramTitle
      let isCustomName = false;
      for (let datapoint of this.tempDatapoint) {
        if (!this.diagramTitle.includes(datapoint.text)) {
          isCustomName = true;
        }
      }
      let index = this.tempDatapoint.indexOf(item);
      index === -1
        ? this.tempDatapoint.push(item)
        : this.tempDatapoint.splice(index, 1);

      if (!isCustomName) {
        var title = '';
        this.tempDatapoint.forEach(t => (title += t.text + ', '));
        this.diagramTitle = title.slice(0, -2);
      }
    },
    async createPanel() {
      // TODO
      // get name | DTStart | DTEnd | TomeZone | DataResolution

      //let name = 'Test Request';
      let startdate = new Date();
      let default_months = this.$store.state.nodes.defaultTime;
      if (default_months) {
        startdate.setMonth(startdate.getMonth() - default_months);
      } else {
        startdate.setMonth(startdate.getMonth() - 1);
      } // one month back
      let dtStart = startdate.setHours(0, 0, 0, 0);
      let dtEnd = new Date().setHours(23, 0, 0, 0);
      // let timeZone = new Date().getTimezoneOffset(); // timezone offset in minutes -> previous day 23:00 for us
      // dtStart -= startdate.getTimezoneOffset() * 60000;
      // console.log(new Date(dtStart));
      // dtEnd -= timeZone * 60000;
      let dataRes =
        this.tempDiagram.type == 'pie' || this.compareNodes ? 'a' : 'd';
      let node = [];
      let vars = [];
      this.tempDatapoint.forEach(element => vars.push(element.name));
      if (this.compareNodes)
        this.treeNode.nodes.forEach(item => node.push(item.node));
      else [...this.selection].forEach(item => node.push(item.node));

      if (this.selectPanelId != null) {
        await this.$store.dispatch('nodes/updatePanelSettingRequest', {
          id: this.$store.state.nodes.panels[this.selectPanelId].id,
          vars: vars,
          title: this.diagramTitle,
          type: this.panelType ? this.panelType : 'spline'
        });
      } else {
        await this.$store.dispatch('nodes/createPanelRequest', {
          name: this.diagramTitle,
          node: node,
          dtstart: dtStart,
          dtend: dtEnd,
          // tzoffs: timeZone,
          res: dataRes,
          vars: vars,
          diagram: this.tempDiagram ? this.tempDiagram.type : 'spline',
          currentPanel: null
        });
      }
      this.resetForm();
    },
    closeDialog() {
      this.resetForm();
    },
    resetForm() {
      // Reset the form
      this.diagramTitle = '';
      this.selection = [];
      this.selectedDiagram = null;
      this.selectedDatapoint = [];
      this.tempDiagram = null;
      this.tempDatapoint = [];
      this.$emit('resetform');

      // FFS: removed bcause of undefied updateInternalValue()
      //this.$refs.dataPointList.updateInternalValue(undefined);
      //this.$refs.diagramList.updateInternalValue(undefined);
      if (this.$refs.wizard)
        this.isHome
          ? this.$refs.wizard.navigateToTab(0)
          : this.$refs.wizard.navigateToTab(1);

      //console.log(this.selection);
    },
    setSelectedNode() {
      this.nodes = [this.$store.state.nodes.treeNode];
      this.datapointItems = this.$store.state.nodes.treeNode.vars;
      this.datapointItems = this.datapointItems.sort(function(a, b) {
        var nameA = a.text.toLowerCase();
        var nameB = b.text.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    },
    compareunits() {
      for (let i = 0; i < this.tempDatapoint.length; i++) {
        if (this.tempDatapoint[0].unit != this.tempDatapoint[i].unit) {
          return false;
        }
      }
      return true;
    },
    loadElements() {
      this.panelVars = this.$store.state.nodes.panels[this.selectPanelId].vars;
      this.panelTitle = this.$store.state.nodes.panels[
        this.selectPanelId
      ].title;
      this.panelType = this.$store.state.nodes.panels[this.selectPanelId].type;

      for (let vars of this.panelVars) {
        let item = this.$store.state.nodes.treeNode.vars.find(
          datapoint => datapoint.name == vars
        );
        this.tempDatapoint.push(item);
        this.selectedDatapoint.push(
          this.$store.state.nodes.treeNode.vars.indexOf(item)
        );
      }

      let item = this.diagramItems.find(
        diagram => diagram.type == this.panelType
      );

      this.selectDiagram(item, this.filteredDiagram.indexOf(item));

      this.diagramTitle = this.panelTitle;
    },
    loadDiagram() {
      if (this.selectedDiagram != null && this.selectPanelId != null) {
        let panelType = this.$store.state.nodes.panels[this.selectPanelId].type;
        let item = this.diagramItems.find(diagram => diagram.type == panelType);

        this.selectDiagram(item, this.diagramItems.indexOf(item));
      }
    }
  },
  async mounted() {
    this.items = [...this.assignDepth(this.initItems)];
  }
};
</script>

<style src="./CreatePanel.scss" lang="scss"></style>
