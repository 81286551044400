<template>
  <v-container fluid class="main-img">
    <div class="content-main my-md-2">
      <div v-if="!this.isNodeEmpty(node) && activeNodeId !== null">
        <div class="row">
          <div class="col-12">
            <h2 v-if="node.title">{{ node.title }}</h2>
            <p v-if="node.description">{{ node.description }}</p>
          </div>
        </div>

        <div v-if="node.tabs && node.tabs.length > 0">
          <div class="v-dashboard-bar mb-6" ref="tabsBarContainer">
            <div class="v-dashboard-bar__content tabBackground">
              <div class="v-left" ref="tabsContainer">
                <div>
                  <v-list
                    v-resize="onResize"
                    transition="slide-x-transition"
                    v-show="isVisibleOnDesktop"
                    ref="tabsListContainer"
                  >
                    <v-list-item-group
                      v-model="tab"
                      active-class="border"
                      class="d-flex tabBackground"
                      mandatory
                      @change="changeEvent()"
                      :dark="
                        $store.getters['layout/GET_THEME_RESSOURCE'](
                          'tabText'
                        ) === 'true'
                      "
                    >
                      <v-list-item
                        :class="[
                          'v-tab-item',
                          tab.id == activeTab.id
                            ? 'tabBackgroundActiv tabTextActiv--text'
                            : ''
                        ]"
                        v-for="(tab, i) in node.tabs"
                        :key="i"
                        :value="tab.id"
                        :style="
                          tab.id == activeTab.id ? 'max-width: 300px' : ''
                        "
                      >
                        <v-list-item-content>
                          <div
                            class="v-tab-item__delete secondColor"
                            @click="deleteTab(tab.id)"
                          >
                            <icon-base :viewBox="`${-4} ${-6} ${26} ${26}`">
                              <template slot-scope="{ strokeColor }">
                                <IconClose
                                  :strokeColor="strokeColor"
                                /> </template
                            ></icon-base>
                          </div>
                          <v-text-field
                            v-if="editedTab == tab.id"
                            v-model="editedTabName"
                            v-on:blur="
                              editTab(tab.id, tab.name);
                              $emit('update');
                            "
                            @keyup.enter="
                              editTab(tab.id, tab.name);
                              $emit('update');
                            "
                            autofocus
                          ></v-text-field>

                          <v-tooltip>
                            <template v-slot:activator="{ on, attrs }">
                              <v-list-item-title
                                class="font-weight-bold"
                                v-on="on"
                                v-bind="attrs"
                                v-show="editedTab != tab.id"
                                @dblclick="editedTab = tab.id"
                                >{{ tab.name }}</v-list-item-title
                              >
                            </template>
                          </v-tooltip>
                        </v-list-item-content>
                      </v-list-item>
                      <div class="d-flex align-center justify-center">
                        <v-dialog v-model="dialog" persistent max-width="420">
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              class="d-flex align-center justify-between"
                            >
                              <icon-base :icon-name="$t('dashboard.add-tab')">
                                <template>
                                  <keep-alive>
                                    <component
                                      :strokeColor="
                                        $vuetify.theme.isDark
                                          ? $vuetify.theme.themes.dark[
                                              'siderbarIcon'
                                            ]
                                          : $vuetify.theme.themes.light[
                                              'siderbarIcon'
                                            ]
                                      "
                                      :is="iconPlus"
                                    ></component>
                                  </keep-alive>
                                </template>
                              </icon-base>
                            </div>
                          </template>
                          <v-card>
                            <v-card-title
                              class="align-center justify-space-between"
                            >
                              <h3 class="componentText--text">
                                {{ $t('dashboard.create-dash-title') }}
                              </h3>
                              <div class="dialog__close" @click="closeDialog()">
                                <v-hover v-slot:default="{ hover }">
                                  <v-icon
                                    :class="
                                      `${
                                        hover
                                          ? 'thirdColorText--text active'
                                          : ''
                                      }`
                                    "
                                    >mdi-close</v-icon
                                  >
                                </v-hover>
                              </div>
                            </v-card-title>
                            <v-card-text>
                              <form class="mt-6">
                                <v-label>
                                  <span class="componentText--text">
                                    {{ $t('dashboard.create-dash-label') }}
                                  </span>
                                </v-label>
                                <v-text-field
                                  v-model="dashboardName"
                                  ref="dashboardName"
                                  class="custom"
                                  @keydown.enter.prevent="createDashboard"
                                  solo
                                ></v-text-field>

                                <div class="d-flex justify-end">
                                  <v-btn
                                    class="custom firstColor firstColorText--text"
                                    @click="createDashboard"
                                    depressed
                                  >
                                    {{ $t('dashboard.create-dash-action') }}
                                  </v-btn>
                                </div>
                              </form>
                            </v-card-text>
                          </v-card>
                        </v-dialog>
                      </div>
                    </v-list-item-group>
                  </v-list>

                  <v-select
                    class="custom"
                    v-show="!isVisibleOnDesktop"
                    v-model="activeTab"
                    label="Select"
                    :items="node.tabs"
                    item-text="name"
                    item-value="id"
                    single-line
                    hide-details
                    return-object
                    @change="changeSelectedTab"
                    filled
                    height="40"
                    item-color="light"
                  >
                    <template v-slot:append>
                      <div
                        style="display: flex; align-items: center; height: 20px;"
                      >
                        <icon-base :width="18" :height="12">
                          <template slot-scope="{ strokeColor }">
                            <IconArrowDown :strokeColor="strokeColor" />
                          </template>
                        </icon-base>
                      </div>
                    </template>
                  </v-select>
                </div>
              </div>

              <div
                v-if="$vuetify.breakpoint.mdAndUp"
                class="v-grid-switcher d-flex align-center justify-center"
              >
                <div
                  v-for="(item, i) in grids"
                  :key="i"
                  :value="item.id"
                  :class="
                    gridClassName === item.className
                      ? `${item.classNameSwitcher} tabBackgroundActiv v-grid-switcher__item gridBackground  ml-4`
                      : `${item.classNameSwitcher} v-grid-switcher__item py-md-2 px-md-2 mt-3 ml-4`
                  "
                  @click="selectGridView(item)"
                >
                  <icon-base :icon-name="$t(item.title)">
                    <template>
                      <keep-alive>
                        <component
                          :strokeColor="
                            $vuetify.theme.isDark
                              ? $vuetify.theme.themes.dark['gridIconActiv']
                              : $vuetify.theme.themes.light['gridIconActiv']
                          "
                          :is="item.icon"
                          v-if="gridClassName === item.className"
                        ></component
                        ><!-- aktiv Grid -->
                        <component
                          :strokeColor="
                            $vuetify.theme.isDark
                              ? $vuetify.theme.themes.dark['siderbarIcon']
                              : $vuetify.theme.themes.light['siderbarIcon']
                          "
                          :is="item.icon"
                          v-if="gridClassName != item.className"
                        ></component
                        ><!-- other Grids -->
                      </keep-alive>
                    </template>
                  </icon-base>
                </div>
              </div>
            </div>
          </div>
          <div class="v-dashboard-content">
            <draggable
              :list="panels"
              @end="changeEvent()"
              class="row"
              v-if="panels.length > 0"
              draggable=".panelitem"
              :force-fallback="true"
              :scroll-sensitivity="200"
              handle=".dragArea"
              v-bind="{ delay: 100, animation: 300 }"
            >
              <div
                :class="['panelitem', gridClassName]"
                v-for="(item, i) in panels"
                :key="i"
              >
                <!--
                <v-card
                  v-if="Object.entries(item.chartData).length === 0"
                  class="v-panel d-flex align-center justify-center"
                  ><p>{{ $t('dashboard.panel-content-not-found') }}</p></v-card
                >
                -->

                <v-card class="v-panel" color="chartBackground">
                  <ChartComponent
                    v-on:update="chart_updated(item)"
                    v-on:reload_all="reloadAllCharts()"
                    :panel="item"
                    :update="updateChart"
                    :key="item.id"
                    :settings="{
                      id: item.id,
                      title: item.title,
                      type: item.type,
                      startDate: item.startDate,
                      endDate: item.endDate,
                      res: item.res
                    }"
                    v-on:wizardDialog="
                      wizardDialog = !wizardDialog;
                      toggleWizard = !toggleWizard;
                      selectPanelId = i;
                    "
                  />
                </v-card>
              </div>
              <div :class="gridClassName" color="chartBackground">
                <CreatePanel
                  :parentDialog="wizardDialog"
                  :toggleWizard="toggleWizard"
                  :selectPanelId="selectPanelId"
                  v-on:wizardDialog="wizardDialog = !wizardDialog"
                  v-on:setDialog="value => (wizardDialog = value)"
                  v-on:toggleWizard="toggleWizard = !toggleWizard"
                  v-on:resetform="
                    wizardDialog = false;
                    toggleWizard = true;
                    selectPanelId = null;
                  "
                />
              </div>
            </draggable>
            <div v-if="panels.length <= 0">
              <CreatePanel
                :parentDialog="wizardDialog"
                :toggleWizard="toggleWizard"
                :selectPanelId="selectPanelId"
                v-on:wizardDialog="wizardDialog = !wizardDialog"
                v-on:setDialog="value => (wizardDialog = value)"
                v-on:toggleWizard="toggleWizard = !toggleWizard"
                v-on:resetform="
                  wizardDialog = false;
                  toggleWizard = true;
                  selectPanelId = null;
                "
              />
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="row">
          <div class="col col-12">
            <div class="mx-1 mb-1 v-card v-sheet v-card__not-found ">
              <div class="v-card__text pa-5">
                <p class="text-h5">{{ $t('dashboard.not-found-title') }}</p>
                <p>{{ $t('dashboard.not-found-subtitle') }}</p>
                <v-btn
                  @click="$store.dispatch('nodes/changeIsBreadcrumbActive')"
                  style="
                    letter-spacing: 0;
                    text-transform: inherit;
                  "
                >
                  {{ $t('dashboard.not-found-button') }}
                </v-btn>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComp />
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ChartComponent from '@/components/Charts/Chart';
import CreatePanel from '@/components/Panel/CreatePanel';
import FooterComp from '@/components/Footer';
import IconBase from '@/components/Icons';
import IconPlus from '@/components/Icons/IconPlus';
import Grid1Col from '@/components/Icons/IconGrid1Col';
import Grid2Col from '@/components/Icons/IconGrid2Cols';
import Grid3Col from '@/components/Icons/IconGrid3Cols';
import IconClose from '@/components/Icons/IconClose';
import IconArrowDown from '@/components/Icons/IconArrowDown';
import Highcharts from 'highcharts';
import draggable from 'vuedraggable';

function createGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}

export default {
  name: 'DashboardPage',
  components: {
    ChartComponent,
    CreatePanel,
    FooterComp,
    IconBase,
    IconClose,
    IconArrowDown,
    draggable
  },
  data() {
    let width = window.innerWidth;
    return {
      iconPlus: IconPlus,
      dashboardName: '',
      dialog: false,
      value: 0,
      width,
      activeTab: {},
      isVisibleOnDesktop: true,
      gridClassName: 'col-lg-4 col-md-6',
      grids: [
        {
          title: 'dashboard.grid-1-col',
          className: 'col-md-12',
          classNameSwitcher: 'grid-1-col-view',
          icon: Grid1Col
        },
        {
          title: 'dashboard.grid-2-cols',
          className: 'col-md-6',
          classNameSwitcher: 'grid-2-col-view',
          icon: Grid2Col
        },
        {
          title: 'dashboard.grid-3-cols',
          className: 'col-lg-4 col-md-6',
          classNameSwitcher: 'grid-3-col-view',
          icon: Grid3Col
        }
      ],
      editedTab: null,
      editedTabName: null,
      updateChart: false,
      wizardDialog: false,
      toggleWizard: true,
      selectPanelId: null,
      dragging: false
    };
  },
  computed: {
    ...mapState('nodes', [
      'node',
      'panels',
      'isReceiving',
      'isUpdating',
      'updateTabRequest',
      'activeNodeId',
      'isBreadcrumbActive'
    ]),
    tab: {
      get: function() {
        let tabs = this.$store.state.nodes.node.tabs;

        let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
        let tabId = localUserConfig.activeTabId;
        let selectedTab = tabs.find(i => i.id === tabId);

        if (selectedTab === undefined || selectedTab === null) {
          selectedTab = tabs[0].id;
        }
        return selectedTab.id;
      },
      set: function(newVal) {
        let selectedTab = this.$store.state.nodes.node.tabs.find(
          tab => tab.id == newVal
        );
        this.activeTab = { ...{ id: selectedTab.id, name: selectedTab.name } };
        this.$store.dispatch('nodes/loadPanelsRequest', {
          tabId: newVal,
          node: '',
          group: ''
        });
      }
    }
  },
  async beforeMount() {
    this.$store.dispatch('layout/getUserConfig');

    let activeGrid = this.grids.find(
      g => g.classNameSwitcher === this.$store.state.layout.grid
    );
    this.gridClassName = activeGrid.className;

    await this.$store.dispatch('nodes/updateStore');

    if (
      !this.isNodeEmpty(this.$store.state.nodes.node) &&
      this.activeNodeId !== null
    ) {
      let tabs = this.$store.state.nodes.node.tabs;

      if (tabs.length > 0) {
        let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
        let tabId = localUserConfig.activeTabId;
        let selectedTab = tabs.find(i => i.id === tabId);
        if (selectedTab === undefined || selectedTab === null)
          selectedTab = tabs[0].id;
        this.activeTab = { ...{ id: selectedTab.id, name: selectedTab.name } };

        //console.log("Acitve TabId: " + selectedTab.id)
        this.$store.dispatch('nodes/loadPanelsRequest', {
          tabId: selectedTab.id,
          activeNodeId: '',
          activeGroupId: ''
        });
      }
    }
    this.$root.$i18n.locale = this.$store.state.layout.language
      ? this.$store.state.layout.language
      : 'de';
  },
  mounted() {
    this.setChartLang(this.$root.$i18n.locale);
    Highcharts.setOptions({
      lang: {
        resetZoom: this.$root._i18n.t('chart.reset-zoom')
      }
    });
  },
  watch: {
    dialog: function(newValue, old) {
      if (newValue) {
        document.body.classList.add('v-dialog--open');

        setTimeout(() => {
          this.$refs.dashboardName.focus();
        }, 200);
      }

      if (!newValue) {
        this.dashboardName = '';
        document.body.classList.remove('v-dialog--open');
      }
    },
    activeNodeId: function(newValue, oldValue) {
      if (newValue != oldValue) {
        this.panels.forEach(panel => {
          this.chart_updated(panel);
        });
      }
    }
  },
  methods: {
    async changeEvent() {
      /* let tabs = this.$store.state.nodes.node.tabs;
      let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
      let tabId = localUserConfig.activeTabId;
      if (tabs.find(i => i.id === tabId) !== undefined) {
        let selectedTab = tabs.find(i => i.id === tabId);
        selectedTab.panels = this.panels;
      } */
      await this.$store.dispatch('nodes/updateUserConfig');
    },
    isNodeEmpty(nodeObj) {
      return (
        nodeObj === undefined ||
        nodeObj === null ||
        !Object.keys(nodeObj).length
      );
    },
    changeSelectedTab(selectObj) {
      this.$store.dispatch('nodes/loadPanelsRequest', {
        tabId: selectObj.id,
        activeNodeId: '',
        activeGroupId: ''
      });
    },
    onResize() {
      let width = window.innerWidth;

      if (width > 1200) {
        this.isVisibleOnDesktop = true;
      } else if (this.node.tabs.length >= 5 || width < 967) {
        this.isVisibleOnDesktop = false;
      } else {
        this.isVisibleOnDesktop = true;
      }
    },
    selectGridView(item) {
      this.gridClassName = item.className;
      this.$store.dispatch('layout/TOGGLE_GRID', item.classNameSwitcher);
      this.updateChart = !this.updateChart;
    },
    editTab(tabId, oldVal) {
      this.editedTab = '';

      if (
        this.editedTabName !== '' &&
        this.editedTabName !== null &&
        oldVal !== ''
      ) {
        this.$store.dispatch('nodes/updateTabRequest', {
          tabId: tabId,
          name: this.editedTabName
        });
      }

      this.editedTabName = '';
    },
    async createDashboard() {
      this.dialog = false;
      await this.$store.dispatch('nodes/createTabRequest', {
        tabName: this.dashboardName
      });

      // Very hacky solution
      // maybe needs further improvement
      this.$refs.tabsListContainer.$children[0].$children[
        this.$refs.tabsListContainer.$children[0].$children.length - 1
      ].$el.click();
    },
    deleteTab(tabId) {
      this.$store.dispatch('nodes/deleteTabsRequest', { id: tabId });

      let tabs = this.$store.state.nodes.node.tabs;
      let selectedTab = tabs.find(i => i.active === true);
      this.activeTab = { ...{ id: selectedTab.id, name: selectedTab.name } };

      if (tabs.length > 0) {
        this.$store.dispatch('nodes/loadPanelsRequest', {
          tabId: selectedTab.id,
          node: '',
          group: ''
        });
      }
    },
    closeDialog() {
      this.dialog = false;
    },
    chart_updated(chart) {
      this.updateChart = !this.updateChart;
      chart.id = createGuid();
    },
    reloadAllCharts() {
      this.updateChart = !this.updateChart;
    },
    setChartLang(lang) {
      let messages = this.$root.$i18n.messages;
      let months = messages[lang].calendar.months;
      let monthsShort = messages[lang].calendar.monthsShort;
      let weekdays = messages[lang].calendar.weekdays;
      Highcharts.setOptions({
        lang: {
          months: months,
          weekdays: weekdays,
          shortMonths: monthsShort
        }
      });
    }
  }
};
</script>

<style src="./Dashboard.scss" lang="scss"></style>
