<template>
  <v-btn
    v-if="isVisibleHandler()"
    icon
    fab
    class="v-node--back breadcrumbBackground"
    width="34px"
    height="34px"
  >
    <v-icon color="firstColor">
      mdi-chevron-left
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'FinderItemBack',
  props: {
    treeModel: {
      type: Object,
      default: () => {}
    },
    parent: {
      type: Object,
      default: () => {}
    }
  },
  created() {
    window.addEventListener('resize', this.isVisibleHandler);
  },
  destroyed() {
    window.removeEventListener('resize', this.isVisibleHandler);
  },
  methods: {
    isVisibleHandler() {
      let props = this.$props;
      let hasParent =
        props.treeModel.expanded[props.treeModel.expanded.length - 1] ===
        props.parent.id;

      if (this.$vuetify.breakpoint.xs) {
        return props.treeModel.expanded.length > 1;
      } else if (
        !this.$vuetify.breakpoint.xs &&
        this.$vuetify.breakpoint.smAndDown
      ) {
        return props.treeModel.expanded.length > 2 && hasParent;
      } else if (
        this.$vuetify.breakpoint.smAndUp &&
        this.$vuetify.breakpoint.mdAndDown
      ) {
        return props.treeModel.expanded.length > 3 && hasParent;
      } else if (
        this.$vuetify.breakpoint.mdAndUp &&
        this.$vuetify.breakpoint.lgAndDown
      ) {
        return props.treeModel.expanded.length > 4 && hasParent;
      } else {
        return props.treeModel.expanded.length > 4 && hasParent;
      }
    }
  }
};
</script>

<style src="./FinderItemBack.scss" lang="scss"></style>
