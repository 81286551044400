import Vue from 'vue';
import { InlineSvgPlugin } from 'vue-inline-svg';
import App from './App.vue';
import router from './router/index';
import store from './store/index';
import vuetify from './plugins/vuetify';
import axios from 'axios';
import config from './config';
import { AuthMixin } from './mixins/auth';
import HighchartsVue from 'highcharts-vue';
import 'vue-tree-halower/dist/halower-tree.min.css'; // 你可以自定义树的样式
import VTree from 'vue-tree-halower';
import Vuebar from 'vuebar';
import i18n from './plugins/i18n';
import Highcharts from 'highcharts';
import HighchartsMapModule from 'highcharts/modules/map';
import 'leaflet/dist/leaflet.css';
import exporting from 'highcharts/modules/exporting';
//import offlineExporting from 'highcharts/modules/offline-exporting';

HighchartsMapModule(Highcharts);
exporting(Highcharts);
//offlineExporting(Highcharts);

axios.defaults.baseURL = config.baseURLApi;
axios.defaults.headers.common['Content-Type'] = 'application/json';
const token = localStorage.getItem('token');
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

Vue.use(VTree);
Vue.use(HighchartsVue);
Vue.use(InlineSvgPlugin);
Vue.use(Vuebar);

Vue.mixin(AuthMixin);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  render: h => h(App),
  i18n,
  store
}).$mount('#app');
