<template>
  <v-container fluid class="main-img">
    <div class="content-main">
      <v-row>
        <v-col class="componentText--text" :cols="6">
          {{ $t('report.description') }}
        </v-col>
        <v-col :cols="6">
          <v-btn
            :disabled="active == null || active[0] == 0"
            class="text-none ml-1 mr-1 mb-1"
            @click="request_data"
          >
            {{ $t('report.loadData') }}
          </v-btn>
          <v-btn
            :disabled="active == null || active[0] == 0"
            class="text-none ml-1 mr-1 mb-1"
            @click="jumpToAsset"
          >
            {{ $t('report.jumpToAsset') }}
          </v-btn>
          <v-btn
            :disabled="active == null || active[0] == 0"
            class="text-none ml-1 mr-1 mb-1"
            @click="togglePageSize"
          >
            Page size
          </v-btn>
        </v-col>
      </v-row>

      <multipane class="custom-resizer" layout="vertical">
        <v-card class="path-treeview">
          <v-treeview
            dense
            activatable
            open-all
            :load-children="loadChildren"
            :items="path_items"
            transition
            @update:active="onUpdate"
          >
            <template v-slot:prepend="{ item, open }">
              <v-icon
                v-if="item.node != undefined && item.node.charAt(0) == 'a'"
              >
                {{ 'mdi-home' }}
              </v-icon>
              <v-icon
                v-else-if="item.node != undefined && item.node.charAt(0) == 'c'"
              >
                {{ 'mdi-gauge' }}
              </v-icon>
              <v-icon v-else>
                {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
              </v-icon>
            </template>
          </v-treeview>
        </v-card>
        <!-- </v-col>
        <v-col :cols="colsPageRight"> -->
        <multipane-resizer></multipane-resizer>
        <v-card :style="{ flexGrow: 1 }" class="path-treeview">
          <div v-if="request_success == false">
            {{ $t('report.noData') }}
          </div>
          <div v-else>
            <v-tabs
              class="tabsNoLeftSpacer"
              v-model="tab"
              align-with-title
              grow
              centered
            >
              <!-- <v-tabs-slider color="blue"></v-tabs-slider> -->

              <v-tab v-for="(item, idx) in tab_items" :key="idx">
                {{ item.name }}
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" class="mt-5">
              <v-tab-item v-for="(item, idx) in tab_items" :key="idx">
                <div v-if="item.idx == 0" class="componentText--text">
                  <h2 class="componentText--text">{{ response_title }}</h2>
                  <div v-if="response_addr" class="mt-3">
                    <h3
                      class="componentText--text"
                      style="margin-top: 0, margin-bottom: 30"
                    >
                      {{ $t('report.address') }}:
                    </h3>
                    <span class="componentText--text">
                      {{ response_addr.street }}, <br />
                      {{ response_addr.zip }}
                      {{ response_addr.city }}
                      <br />
                    </span>
                  </div>
                  <div v-if="response_loc" class="mt-3">
                    <h3
                      class="componentText--text"
                      style="margin-top: 0, margin-bottom: 30"
                    >
                      {{ $t('report.coordinates') }}:
                    </h3>
                    <span class="componentText--text">
                      {{ response_loc.lat }}, {{ response_loc.lon }} <br />
                    </span>
                  </div>
                  <div v-if="response_dateFormatted" class="mt-3">
                    <h3
                      class="componentText--text"
                      style="margin-top: 0, margin-bottom: 30"
                    >
                      {{ $t('report.init_operation') }}:
                    </h3>
                    <span class="componentText--text">
                      {{ response_dateFormatted }} <br />
                    </span>
                  </div>
                  <div v-if="response_props" class="mt-3">
                    <h3 class="componentText--text">
                      {{ $t('report.properties') }}:
                    </h3>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left">
                              {{ $t('report.property') }}
                            </th>
                            <th class="text-left">
                              {{ $t('report.value') }}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, idx) in response_props" :key="idx">
                            <td>{{ item.name }}</td>
                            <td>{{ item.value }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </div>
                </div>
                <div v-else-if="item.idx == 1" class="componentText--text">
                  <div v-if="available_reports">
                    <h3 class="componentText--text">
                      {{ $t('report.docs') }}
                    </h3>
                    <ul
                      style="list-style-type: none;
                  list-style: none;
                  padding-left: 0;"
                    >
                      <li v-for="(report, j) in available_reports" :key="j">
                        <v-btn
                          x-small
                          @click="button_getDocument(report)"
                          class="text-none ma-2"
                        >
                          {{ report.name }}
                        </v-btn>
                      </li>
                    </ul>
                  </div>
                  <div v-else>
                    <span class="componentText--text">
                      {{ $t('report.no_docs') }}
                    </span>
                  </div>
                </div>
                <div v-else class="componentText--text">
                  <div v-if="response_pics.length > 0">
                    <v-row class="mt-1 mb-2 ml-1">
                      <!-- <v-col :cols="1">
                          <v-btn
                            small
                            outlined
                            v-if="colsPageLeft >= 6"
                            @click="togglePageSize"
                          >
                            <v-icon>
                              mdi-arrow-expand-left
                            </v-icon>
                          </v-btn>
                          <v-btn small outlined @click="togglePageSize" v-else>
                            <v-icon>
                              mdi-arrow-expand-right
                            </v-icon>
                          </v-btn>
                        </v-col>
                        <v-col :cols="1">
                          <v-btn outlined small @click="downloadImage">
                            Download
                          </v-btn>
                        </v-col>
                        <v-col :cols="10"></v-col> -->
                      <v-btn
                        small
                        outlined
                        v-if="colsPageLeft >= 6"
                        @click="togglePageSize"
                      >
                        <v-icon>
                          mdi-arrow-expand-left
                        </v-icon>
                      </v-btn>
                      <v-btn small outlined @click="togglePageSize" v-else>
                        <v-icon>
                          mdi-arrow-expand-right
                        </v-icon>
                      </v-btn>
                      <v-btn outlined small @click="downloadImage" class="ml-1">
                        Download
                      </v-btn>
                    </v-row>
                    <v-img
                      :src="response_pics[0].url"
                      @click="maxImgDialog = true"
                    >
                      <template v-slot:placeholder>
                        <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                        >
                          <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                          ></v-progress-circular>
                        </v-row>
                      </template>
                    </v-img>
                    <v-dialog ma-0 v-model="maxImgDialog">
                      <v-card
                        :style="'width: ' + (windowsWidth + imgWidth) + 'px'"
                        class="noMaxWidth"
                      >
                        <div
                          style="position: absolute; width: 90%; height: 100%; display:flex; flex-direction:column; align-content:flex-start;"
                        >
                          <v-btn
                            fab
                            class="breadcrumbBackground closeBtn"
                            width="35px"
                            height="35px"
                            style="position:sticky; top: 5px; left: 5px; z-index: 1;"
                            @click="imgWidth = imgWidth + 250"
                          >
                            <v-icon color="firstColor">
                              mdi-plus
                            </v-icon>
                          </v-btn>
                          <v-btn
                            fab
                            class="breadcrumbBackground closeBtn"
                            width="35px"
                            height="35px"
                            style="position:sticky; top: 45px; left: 5px; z-index: 1;"
                            @click="imgWidth = imgWidth - 250"
                          >
                            <v-icon color="firstColor">
                              mdi-minus
                            </v-icon>
                          </v-btn>
                        </div>
                        <div
                          style="position: absolute; width: 90%; height: 100%; right:0px; display: flex; flex-direction: column; align-items: flex-end;"
                        >
                          <v-btn
                            icon
                            fab
                            class="breadcrumbBackground closeBtn"
                            width="35px"
                            height="35px"
                            style="position:sticky; margin: 5px;right: 5px; z-index: 1; top: 5px;"
                            @click="maxImgDialog = false"
                          >
                            <v-icon color="firstColor">
                              mdi-close
                            </v-icon>
                          </v-btn>
                        </div>
                        <v-img :src="response_pics[0].url">
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-card>
                    </v-dialog>
                  </div>
                  <div v-else>
                    <span class="componentText--text">
                      {{ $t('report.no_digital_twin') }}
                    </span>
                  </div>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-card>
      </multipane>
      <!--       <multipane class="custom-resizer" layout="vertical">
        <div :style="{ width: '50%' }">
          <v-card class="chartBackground result-box">I'm a card </v-card>
        </div>
        <multipane-resizer></multipane-resizer>
        <div :style="{ flexGrow: 1 }">
          <v-card class="chartBackground result-box">Me too </v-card>
        </div>
      </multipane> -->
    </div>
    <FooterComp />
  </v-container>
</template>
<script>
import { mapState } from 'vuex';
import {
  getMasterData,
  getDocument,
  downloadSchematicImage
} from '../../api/services';
import FooterComp from '@/components/Footer';
import { Multipane, MultipaneResizer } from 'vue-multipane';

export default {
  name: 'ReportPage',
  components: { FooterComp, Multipane, MultipaneResizer },
  data() {
    let height = window.innerHeight;
    let width = window.innerWidth;
    return {
      path_items: [],
      available_reports: [],
      active: null,
      open: [],
      selected_node: null,
      request_success: false,
      response_title: null,
      response_addr: null,
      response_loc: null,
      response_dateFormatted: null,
      response_pics: [],
      response_props: [],
      tab: null,
      tab_items: [],
      colsPageLeft: 6,
      colsPageRight: 6,
      test_request: {
        reqName: 'Test Stammdaten EURXXX',
        node: ['a:03:R48'],
        name: true,
        desc: true,
        addr: true,
        loc: true,
        role: '*',
        dtStart: true,
        pic: '*',
        doc: '*',
        prop: '*'
      },
      request_param: {
        reqName: '',
        node: [],
        name: true,
        desc: true,
        addr: true,
        loc: true,
        role: '*',
        dtStart: true,
        pic: 'Anlagenschema',
        doc: '*',
        prop: '*'
      },
      text: {
        description: 'dashboard.not-found-subtitle'
      },
      windowsHeight: height,
      windowsWidth: width,
      imgWidth: 0,
      maxImgDialog: false
    };
  },
  computed: {
    ...mapState('nodes', [
      'node',
      'panels',
      'isReceiving',
      'isUpdating',
      'updateTabRequest',
      'start_favourite_breadcrumbs'
    ])
  },
  beforeMount() {
    this.$root.$i18n.locale = this.$store.state.layout.language
      ? this.$store.state.layout.language
      : 'de';
    this.path_items.push({
      name: this.$root._i18n.t('report.chooseAsset'),
      id: 0,
      children: []
    });
    this.tab_items.push({
      idx: 0,
      name: this.$root._i18n.t('report.master_data')
    });
    this.tab_items.push({ idx: 1, name: this.$root._i18n.t('report.docs') });
    this.tab_items.push({
      idx: 2,
      name: this.$root._i18n.t('report.digital_twin')
    });
    //this.loadChildren(this.path_items[0]);
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroy() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleResize: function() {
      this.windowsHeight = window.innerHeight;
      this.windowsWidth = window.innerWidth;
    },
    async jumpToAsset() {
      this.searchTree(); // find active node
      if (this.selected_node == null) {
        console.log('Error Node not found');
        return;
      }
      let tree_arr = this.$store.state.nodes.nodes.nodes;
      let breadcrumb = [{ id: '0', text: 'Global' }];
      let breadcrumb_ids = this.selected_node.parents.concat([
        this.selected_node.id
      ]);
      for (let i = 1; i < breadcrumb_ids.length; i++) {
        let j;
        for (j = 0; j < tree_arr.length; j++) {
          if (breadcrumb_ids[i] == tree_arr[j].id) {
            breadcrumb.push(tree_arr[j]);
            break;
          }
        }
        tree_arr = tree_arr[j].nodes;
      }
      await this.$store.dispatch(
        'nodes/loadNodeRequest',
        breadcrumb[breadcrumb.length - 1],
        { root: true }
      );
      this.$store.dispatch('nodes/updateBreadcrumbRequest', breadcrumb);
      let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
      let tabId = localUserConfig.activeTabId;
      this.$store.dispatch('nodes/updateUserConfig').then(() => {
        this.$store.dispatch('nodes/loadPanelsRequest', {
          tabId: tabId
        });
      });
      this.$router.push('/dashboard');
    },
    async request_data() {
      this.searchTree(); // find active node
      if (this.selected_node == null) {
        console.log('Error Node not found');
        return;
      }
      this.request_param.reqName = this.selected_node.name;
      this.request_param.node = [this.selected_node.node];
      let o = {};
      o = await getMasterData(this.request_param);
      this.request_success = o.response[0].success;

      let response_data = o.response[0].nodes[0].data[0];
      //console.log('Response', response_data);
      this.response_title = response_data.name;
      this.response_addr = response_data.addr;
      this.response_loc = response_data.loc;
      this.response_props = response_data.props;
      this.response_pics = response_data.pics ? response_data.pics : [];
      /*this.response_pics.push({
        name: 'Schema',
        url:
          'https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Sequoiadendron_giganteum_at_Kenilworth_Castle.jpg/310px-Sequoiadendron_giganteum_at_Kenilworth_Castle.jpg' // TEST TEST TEST
      });*/
      this.available_reports = response_data.docs;
      if (response_data.dtStart > 0) {
        let dtstart = new Date(response_data.dtStart);
        this.response_dateFormatted =
          dtstart.getDate() +
          '.' +
          (dtstart.getMonth() + 1) +
          '.' +
          dtstart.getFullYear();
      }
      this.response_props.forEach(prop => {
        if (prop.type === 'b')
          prop.value = prop.value
            ? this.$root._i18n.t('report.yes')
            : this.$root._i18n.t('report.no');
      });

      //console.log('res data', response_data);
    },
    async button_getDocument(report) {
      let params = { id: report.id, docName: report.name };
      await getDocument(params);
    },
    togglePageSize() {
      if (this.colsPageLeft == 6) {
        this.colsPageLeft = 2;
        this.colsPageRight = 10;
      } else {
        this.colsPageLeft = 6;
        this.colsPageRight = 6;
      }
    },
    async downloadImage() {
      let payload = {
        url: this.response_pics[0].url,
        file_name: this.response_title.replace(/\s/g, '_') + '_schematic.jpg'
      };
      await downloadSchematicImage(payload);
    },
    //not used now but maybe keep for later if algorithm changes
    recursive_loadNodes(tree, depth) {
      if (tree == null) {
        return;
      }
      if (tree[0] != null && tree[0].type == 'C') {
        //stop at component level
        return;
      }
      for (let i = 0; i < tree.length; i++) {
        tree[i].children = tree[i].nodes;
        delete tree[i].nodes;
        tree[i].name = tree[i].text;
        if (tree[i].children != null) {
          if (tree[i].children[0] != null && tree[i].children[0].type == 'C') {
            delete tree[i].children;
          }
        }
        delete tree[i].text;
        delete tree[i].dashboardtype;
        delete tree[i].level;
        delete tree[i].modeltype;
        delete tree[i].node;
        delete tree[i].type;
        delete tree[i].vars;
        this.recursive_loadNodes(tree[i].children, depth + 1);
      }
    },
    loadChildren(item) {
      if (item.id == 0) {
        let tree = this.$store.state.nodes.nodes.nodes;
        for (let i = 0; i < tree.length; i++) {
          let child_obj = {
            id: 0,
            name: '',
            parents: [item.id],
            node: tree[i].node,
            children: null
          };
          child_obj.id = tree[i].id;
          child_obj.name = tree[i].text;
          child_obj.children = tree[i].nodes != null ? [] : null;
          //child_obj.parents.push(item.id);
          item.children.push(child_obj);
        }
      } else {
        item.parents = item.parents.concat([item.id]);
        let tree = this.findTree(item);
        for (let i = 0; i < tree.length; i++) {
          let child_obj = {
            id: 0,
            name: '',
            parents: item.parents,
            node: null,
            children: null
          };
          child_obj.id = tree[i].id;
          child_obj.name = tree[i].text;
          child_obj.node = tree[i].node;
          if (tree[i].nodes != undefined) {
            child_obj.children = [];
          } else {
            delete child_obj.children;
          }
          //child_obj.parents.push(item.id);
          item.children.push(child_obj);
        }
        item.parents = item.parents.slice(0, -1);
      }
    },
    findTree(item) {
      let tree = this.$store.state.nodes.nodes.nodes;
      for (let i = 0; i < item.parents.length; i++) {
        for (let j = 0; j < tree.length; j++) {
          if (tree[j].id == item.parents[i]) {
            tree = tree[j].nodes;
            break;
          }
        }
      }
      return tree;
    },
    onUpdate(s) {
      this.active = s;
    },
    searchTree() {
      this.recursive_searchTree(this.path_items, this.active);
    },
    recursive_searchTree(tree, search_id) {
      if (tree == null) {
        return;
      }
      for (let i = 0; i < tree.length; i++) {
        if (tree[i].id == search_id) {
          this.selected_node = tree[i];
          return;
        } else this.recursive_searchTree(tree[i].children, search_id);
      }
    }
  }
};
</script>
<style src="./Report.scss" lang="scss"></style>
