<script>
import { get } from 'lodash-es';
import { useWindowSize } from 'vue-window-size';
import { ref } from '@vue/runtime-core';
import FinderItem from './FinderItem';
import FinderItemBack from './FinderItemBack';

function renderItems(h, { props, expandedItemIndex }) {
  let { items, options } = props;

  if (options.sortBy) {
    items = [...items].sort(options.sortBy);
  }

  return items.map((item, index) => {
    return [
      ...[],

      <div>
        <FinderItem
          key={`item-${item.id}`}
          node={item}
          treeModel={props.treeModel}
          selectable={props.selectable}
          dragEnabled={props.dragEnabled}
          options={props.options}
          tabindex={index === expandedItemIndex ? '0' : '-1'}
        >
          {item.text}
        </FinderItem>
      </div>
    ];
  });
}

function getPreviousItemElement(element) {
  let sibling = element.previousSibling;

  while (sibling) {
    if (sibling.classList && sibling.classList.contains('item')) {
      return sibling;
    }
    sibling = sibling.previousSibling;
  }
}

function getNextItemElement(element) {
  let sibling = element.nextSibling;

  while (sibling) {
    if (sibling.classList && sibling.classList.contains('item')) {
      return sibling;
    }
    sibling = sibling.nextSibling;
  }
}

export default {
  name: 'FinderList',
  functional: true,
  components: { FinderItemBack },
  setup() {
    let width = ref(useWindowSize().width).value.value;
    return {
      width
    };
  },
  props: {
    parent: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => []
    },
    treeModel: {
      type: Object,
      required: true
    },
    selectable: {
      type: Boolean,
      default: false
    },
    dragEnabled: {
      type: Boolean,
      default: Boolean
    },
    options: {
      type: Object,
      default: () => ({})
    },
    hasExpandedItem: {
      type: Boolean,
      default: false
    }
  },
  render(h, { props, listeners }) {
    const separatorWidth = get(props, 'options.theme.separatorWidth', '');
    // let marginLeft = 0;
    let width = ref(useWindowSize().width).value.value;
    let colWidth = width;

    if (width > 1264) {
      colWidth = width / 4;
      if (props.treeModel.expanded.length > 4) {
        colWidth = (width - 70) / 4;
      }
    } else if (width > 960) {
      colWidth = width / 3;
      if (props.treeModel.expanded.length > 3) {
        colWidth = (width - 70) / 3;
      }
    } else if (width > 600) {
      colWidth = width / 2;
      if (props.treeModel.expanded.length > 2) {
        colWidth = (width - 70) / 2;
      }
    } else {
      colWidth = width / 1;
      if (props.treeModel.expanded.length > 1) {
        colWidth = (width - 70) / 1;
      }
    }

    const style = {
      ...(separatorWidth && { borderWidth: separatorWidth })
    };

    const expandedItemIndex = Math.max(
      0,
      props.items.findIndex(item => props.treeModel.isNodeExpanded(item.id))
    );

    function navigate(event) {
      let sibling;
      if (event.key === 'ArrowDown') {
        sibling = getNextItemElement(event.target);
      } else if (event.key === 'ArrowUp') {
        sibling = getPreviousItemElement(event.target);
      }

      if (sibling) {
        sibling.focus();
      }
    }

    const onClick = () => {
      props.treeModel.expandNode(props.parent.parent, 'click');
    };

    return [
      <div
        class="list breadcrumbBackground"
        vOn:keydown={navigate}
        style={{
          width: `${colWidth}px`,
          zIndex: 2,
          position: 'relative',
          style
        }}
      >
        {
          <div class="v-list-title" vOn:click={onClick}>
            {!props.parent.parent ? 'Global' : props.parent}
            <FinderItemBack treeModel={props.treeModel} parent={props.parent} />
          </div>
        }

        <div v-bar="{preventParentScroll: true, scrollThrottle: 30}">
          <div>
            {[
              ...renderItems(h, { props, listeners, expandedItemIndex }),
              ...[
                props.dragEnabled && (
                  <FinderListDropZone
                    class="last"
                    treeModel={props.treeModel}
                    node={props.parent}
                    dragEnabled={props.dragEnabled}
                    index={props.items.length}
                    options={props.options}
                  />
                )
              ]
            ]}
          </div>
        </div>
      </div>
    ];
  }
};
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: auto;
  flex-shrink: 0;
  padding: 32px 0 16px 0;
  box-shadow: 0px 24px 40px rgba(0, 0, 0, 0.16);
}

.last {
  flex-grow: 1;
}

.v-list-title {
  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  padding: 0 30px 30px 30px;
  letter-spacing: 0.1px;
}
</style>
