var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"main-img",attrs:{"fluid":""}},[_c('div',{staticClass:"content-main my-md-2"},[(!this.isNodeEmpty(_vm.node) && _vm.activeNodeId !== null)?_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.node.title)?_c('h2',[_vm._v(_vm._s(_vm.node.title))]):_vm._e(),(_vm.node.description)?_c('p',[_vm._v(_vm._s(_vm.node.description))]):_vm._e()])]),(_vm.node.tabs && _vm.node.tabs.length > 0)?_c('div',[_c('div',{ref:"tabsBarContainer",staticClass:"v-dashboard-bar mb-6"},[_c('div',{staticClass:"v-dashboard-bar__content tabBackground"},[_c('div',{ref:"tabsContainer",staticClass:"v-left"},[_c('div',[_c('v-list',{directives:[{name:"resize",rawName:"v-resize",value:(_vm.onResize),expression:"onResize"},{name:"show",rawName:"v-show",value:(_vm.isVisibleOnDesktop),expression:"isVisibleOnDesktop"}],ref:"tabsListContainer",attrs:{"transition":"slide-x-transition"}},[_c('v-list-item-group',{staticClass:"d-flex tabBackground",attrs:{"active-class":"border","mandatory":"","dark":_vm.$store.getters['layout/GET_THEME_RESSOURCE'](
                        'tabText'
                      ) === 'true'},on:{"change":function($event){return _vm.changeEvent()}},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.node.tabs),function(tab,i){return _c('v-list-item',{key:i,class:[
                        'v-tab-item',
                        tab.id == _vm.activeTab.id
                          ? 'tabBackgroundActiv tabTextActiv--text'
                          : ''
                      ],style:(tab.id == _vm.activeTab.id ? 'max-width: 300px' : ''),attrs:{"value":tab.id}},[_c('v-list-item-content',[_c('div',{staticClass:"v-tab-item__delete secondColor",on:{"click":function($event){return _vm.deleteTab(tab.id)}}},[_c('icon-base',{attrs:{"viewBox":((-4) + " " + (-6) + " " + (26) + " " + (26))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var strokeColor = ref.strokeColor;
return [_c('IconClose',{attrs:{"strokeColor":strokeColor}})]}}],null,true)})],1),(_vm.editedTab == tab.id)?_c('v-text-field',{attrs:{"autofocus":""},on:{"blur":function($event){_vm.editTab(tab.id, tab.name);
                            _vm.$emit('update');},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.editTab(tab.id, tab.name);
                            _vm.$emit('update');}},model:{value:(_vm.editedTabName),callback:function ($$v) {_vm.editedTabName=$$v},expression:"editedTabName"}}):_vm._e(),_c('v-tooltip',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-list-item-title',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.editedTab != tab.id),expression:"editedTab != tab.id"}],staticClass:"font-weight-bold",on:{"dblclick":function($event){_vm.editedTab = tab.id}}},'v-list-item-title',attrs,false),on),[_vm._v(_vm._s(tab.name))])]}}],null,true)})],1)],1)}),_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-dialog',{attrs:{"persistent":"","max-width":"420"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({staticClass:"d-flex align-center justify-between"},'div',attrs,false),on),[_c('icon-base',{attrs:{"icon-name":_vm.$t('dashboard.add-tab')}},[[_c('keep-alive',[_c(_vm.iconPlus,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                                        ? _vm.$vuetify.theme.themes.dark[
                                            'siderbarIcon'
                                          ]
                                        : _vm.$vuetify.theme.themes.light[
                                            'siderbarIcon'
                                          ]}})],1)]],2)],1)]}}],null,false,615823506),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"align-center justify-space-between"},[_c('h3',{staticClass:"componentText--text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.create-dash-title'))+" ")]),_c('div',{staticClass:"dialog__close",on:{"click":function($event){return _vm.closeDialog()}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                          var hover = ref.hover;
return [_c('v-icon',{class:("" + (hover
                                        ? 'thirdColorText--text active'
                                        : ''))},[_vm._v("mdi-close")])]}}],null,false,1370023723)})],1)]),_c('v-card-text',[_c('form',{staticClass:"mt-6"},[_c('v-label',[_c('span',{staticClass:"componentText--text"},[_vm._v(" "+_vm._s(_vm.$t('dashboard.create-dash-label'))+" ")])]),_c('v-text-field',{ref:"dashboardName",staticClass:"custom",attrs:{"solo":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.createDashboard($event)}},model:{value:(_vm.dashboardName),callback:function ($$v) {_vm.dashboardName=$$v},expression:"dashboardName"}}),_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"custom firstColor firstColorText--text",attrs:{"depressed":""},on:{"click":_vm.createDashboard}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.create-dash-action'))+" ")])],1)],1)])],1)],1)],1)],2)],1),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isVisibleOnDesktop),expression:"!isVisibleOnDesktop"}],staticClass:"custom",attrs:{"label":"Select","items":_vm.node.tabs,"item-text":"name","item-value":"id","single-line":"","hide-details":"","return-object":"","filled":"","height":"40","item-color":"light"},on:{"change":_vm.changeSelectedTab},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticStyle:{"display":"flex","align-items":"center","height":"20px"}},[_c('icon-base',{attrs:{"width":18,"height":12},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                    var strokeColor = ref.strokeColor;
return [_c('IconArrowDown',{attrs:{"strokeColor":strokeColor}})]}}],null,false,45248332)})],1)]},proxy:true}],null,false,2983418039),model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}})],1)]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"v-grid-switcher d-flex align-center justify-center"},_vm._l((_vm.grids),function(item,i){return _c('div',{key:i,class:_vm.gridClassName === item.className
                    ? ((item.classNameSwitcher) + " tabBackgroundActiv v-grid-switcher__item gridBackground  ml-4")
                    : ((item.classNameSwitcher) + " v-grid-switcher__item py-md-2 px-md-2 mt-3 ml-4"),attrs:{"value":item.id},on:{"click":function($event){return _vm.selectGridView(item)}}},[_c('icon-base',{attrs:{"icon-name":_vm.$t(item.title)}},[[_c('keep-alive',[(_vm.gridClassName === item.className)?_c(item.icon,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                            ? _vm.$vuetify.theme.themes.dark['gridIconActiv']
                            : _vm.$vuetify.theme.themes.light['gridIconActiv']}}):_vm._e(),(_vm.gridClassName != item.className)?_c(item.icon,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                            ? _vm.$vuetify.theme.themes.dark['siderbarIcon']
                            : _vm.$vuetify.theme.themes.light['siderbarIcon']}}):_vm._e()],1)]],2)],1)}),0):_vm._e()])]),_c('div',{staticClass:"v-dashboard-content"},[(_vm.panels.length > 0)?_c('draggable',_vm._b({staticClass:"row",attrs:{"list":_vm.panels,"draggable":".panelitem","force-fallback":true,"scroll-sensitivity":200,"handle":".dragArea"},on:{"end":function($event){return _vm.changeEvent()}}},'draggable',{ delay: 100, animation: 300 },false),[_vm._l((_vm.panels),function(item,i){return _c('div',{key:i,class:['panelitem', _vm.gridClassName]},[_c('v-card',{staticClass:"v-panel",attrs:{"color":"chartBackground"}},[_c('ChartComponent',{key:item.id,attrs:{"panel":item,"update":_vm.updateChart,"settings":{
                    id: item.id,
                    title: item.title,
                    type: item.type,
                    startDate: item.startDate,
                    endDate: item.endDate,
                    res: item.res
                  }},on:{"update":function($event){return _vm.chart_updated(item)},"reload_all":function($event){return _vm.reloadAllCharts()},"wizardDialog":function($event){_vm.wizardDialog = !_vm.wizardDialog;
                    _vm.toggleWizard = !_vm.toggleWizard;
                    _vm.selectPanelId = i;}}})],1)],1)}),_c('div',{class:_vm.gridClassName,attrs:{"color":"chartBackground"}},[_c('CreatePanel',{attrs:{"parentDialog":_vm.wizardDialog,"toggleWizard":_vm.toggleWizard,"selectPanelId":_vm.selectPanelId},on:{"wizardDialog":function($event){_vm.wizardDialog = !_vm.wizardDialog},"setDialog":function (value) { return (_vm.wizardDialog = value); },"toggleWizard":function($event){_vm.toggleWizard = !_vm.toggleWizard},"resetform":function($event){_vm.wizardDialog = false;
                  _vm.toggleWizard = true;
                  _vm.selectPanelId = null;}}})],1)],2):_vm._e(),(_vm.panels.length <= 0)?_c('div',[_c('CreatePanel',{attrs:{"parentDialog":_vm.wizardDialog,"toggleWizard":_vm.toggleWizard,"selectPanelId":_vm.selectPanelId},on:{"wizardDialog":function($event){_vm.wizardDialog = !_vm.wizardDialog},"setDialog":function (value) { return (_vm.wizardDialog = value); },"toggleWizard":function($event){_vm.toggleWizard = !_vm.toggleWizard},"resetform":function($event){_vm.wizardDialog = false;
                _vm.toggleWizard = true;
                _vm.selectPanelId = null;}}})],1):_vm._e()],1)]):_vm._e()]):_c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col col-12"},[_c('div',{staticClass:"mx-1 mb-1 v-card v-sheet v-card__not-found "},[_c('div',{staticClass:"v-card__text pa-5"},[_c('p',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.$t('dashboard.not-found-title')))]),_c('p',[_vm._v(_vm._s(_vm.$t('dashboard.not-found-subtitle')))]),_c('v-btn',{staticStyle:{"letter-spacing":"0","text-transform":"inherit"},on:{"click":function($event){return _vm.$store.dispatch('nodes/changeIsBreadcrumbActive')}}},[_vm._v(" "+_vm._s(_vm.$t('dashboard.not-found-button'))+" ")])],1)])])])]),_c('FooterComp')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }