var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pl-5"},[_c('div',{ref:"breadcrumb",staticClass:"v-node v-breadcrumb breadcrumbBurgerBackground"},[(_vm.list.length > 0)?_vm._l((_vm.list),function(item,index){return _c('div',{key:item.id,staticClass:"v-breadcrumb__item",on:{"click":function($event){return _vm.handleBurgerClicked(item.id)}}},[(_vm.windowWidth >= 1400)?_c('div',{staticClass:"d-flex align-center"},[(index === 0)?[_c('icon-base',{staticClass:"mr-3",attrs:{"icon-name":_vm.icons[0].name,"width":_vm.icons[0].width,"height":_vm.icons[0].height}},[[_c('keep-alive',[_c(_vm.icons[0].component,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                        ? _vm.$vuetify.theme.themes.dark[_vm.breadcrumbIconColor]
                        : _vm.$vuetify.theme.themes.light[_vm.breadcrumbIconColor]}})],1)]],2)]:_vm._e(),_c('div',{staticClass:"v-breadcrumb__text"},[_vm._v(_vm._s(item.text))]),(index > -1 && index != _vm.list.length - 1)?[_c('v-icon',{class:_vm.breadcrumbIconColor + '--text custom'},[_vm._v("mdi-chevron-right")])]:_vm._e(),(index == _vm.list.length - 1)?[_c('v-icon',{class:_vm.breadcrumbIconColor + '--text custom'},[_vm._v("mdi-chevron-down")])]:_vm._e()],2):(_vm.windowWidth < 1400 && _vm.windowWidth >= 905)?[(index === 0)?[_c('div',{staticClass:"d-flex align-center"},[_c('icon-base',{staticClass:"mr-3",attrs:{"icon-name":_vm.icons[0].name,"width":_vm.icons[0].width,"height":_vm.icons[0].height}},[[_c('keep-alive',[_c(_vm.icons[0].component,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                          ? _vm.$vuetify.theme.themes.dark[_vm.breadcrumbIconColor]
                          : _vm.$vuetify.theme.themes.light[_vm.breadcrumbIconColor]}})],1)]],2),(_vm.list.length <= 3)?[_c('div',{staticClass:"v-breadcrumb__text"},[_vm._v(" "+_vm._s(item.text)+" ")]),_c('v-icon',{class:_vm.breadcrumbIconColor + '--text custom'},[_vm._v("mdi-chevron-right")])]:[_c('div',[_vm._v("...")])]],2)]:_vm._e(),(index != 0 && _vm.list.length <= 3 && index != _vm.list.length - 1)?[_c('div',{staticClass:"v-breadcrumb__text"},[_vm._v(_vm._s(item.text))]),_c('v-icon',{class:_vm.breadcrumbIconColor + '--text custom'},[_vm._v("mdi-chevron-right")])]:_vm._e(),(index >= _vm.list.length / 2)?[_c('div',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"v-breadcrumb__text"},[_vm._v(_vm._s(item.text))]),(index > -1 && index != _vm.list.length - 1)?[_c('v-icon',{class:_vm.breadcrumbIconColor + '--text custom'},[_vm._v("mdi-chevron-right")])]:_vm._e(),(index == _vm.list.length - 1)?[_c('v-icon',{class:_vm.breadcrumbIconColor + '--text custom'},[_vm._v("mdi-chevron-down")])]:_vm._e()],2)]:_vm._e()]:[(_vm.windowWidth < 905 && _vm.list.length - 1 === index)?_c('div',{staticClass:"d-flex align-center"},[[_c('icon-base',{staticClass:"mr-3",attrs:{"icon-name":_vm.icons[0].name,"width":_vm.icons[0].width,"height":_vm.icons[0].height}},[[_c('keep-alive',[_c(_vm.icons[0].component,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                          ? _vm.$vuetify.theme.themes.dark[_vm.breadcrumbIconColor]
                          : _vm.$vuetify.theme.themes.light[_vm.breadcrumbIconColor]}})],1)]],2)],_c('div',{staticClass:"v-breadcrumb__text"},[_vm._v(_vm._s(item.text))]),(index > -1 && index != _vm.list.length - 1)?[_c('v-icon',{class:_vm.breadcrumbIconColor + '--text custom'},[_vm._v("mdi-chevron-right")])]:_vm._e(),(index == _vm.list.length - 1)?[_c('v-icon',{class:_vm.breadcrumbIconColor + '--text custom'},[_vm._v("mdi-chevron-down")])]:_vm._e()],2):_vm._e()]],2)}):[_c('div',{staticClass:"d-flex align-center",on:{"click":function($event){return _vm.handleBurgerClicked('')}}},[[_c('icon-base',{staticClass:"mr-3",attrs:{"icon-name":_vm.icons[0].name,"width":_vm.icons[0].width,"height":_vm.icons[0].height}},[[_c('keep-alive',[_c(_vm.icons[0].component,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                      ? _vm.$vuetify.theme.themes.dark[_vm.breadcrumbIconColor]
                      : _vm.$vuetify.theme.themes.light[_vm.breadcrumbIconColor]}})],1)]],2)],_c('div',{staticClass:"v-breadcrumb__text secondColorText--text"},[_vm._v(" "+_vm._s(_vm.$t('main.global-tree'))+" ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-icon',{class:_vm.breadcrumbIconColor + '--text custom'},[_vm._v("mdi-chevron-down")])],1)],2)],(!_vm.as_favourite)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.renderTreeNodeNav),expression:"renderTreeNodeNav"}],staticClass:"pin",staticStyle:{"margin-left":"10px","margin-top":"7px"},on:{"click":_vm.pin_as_favourite}},[_c('icon-base',{attrs:{"icon-name":_vm.$t('main.pinNotActiv'),"width":30,"height":30,"viewBox":((0) + " " + (0) + " " + (30) + " " + (30))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var strokeColor = ref.strokeColor;
return [_c('IconPin',{attrs:{"strokeColor":strokeColor}})]}}],null,false,3273604176)})],1):_vm._e(),(_vm.as_favourite)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.renderTreeNodeNav),expression:"renderTreeNodeNav"}],staticClass:"pin",staticStyle:{"margin-left":"10px","margin-top":"7px"},on:{"click":_vm.pin_as_favourite}},[_c('icon-base',{attrs:{"icon-name":_vm.$t('main.pinActiv'),"width":30,"height":30,"viewBox":((0) + " " + (0) + " " + (30) + " " + (30))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var strokeColor = ref.strokeColor;
return [_c('IconPinFilled',{attrs:{"strokeColor":strokeColor}})]}}],null,false,1993487902)})],1):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }