<template>
  <FinderComponent :tree="ROOT_NODES_STATE" ref="nodeTreeFinder" />
</template>

<script>
import { mapActions, mapState } from 'vuex';

import functionalityStyle from './mixins/functionalityStyle.mixin';
import panelControl from './mixins/panelControl.mixin';
import contentControl from './mixins/contentControl.mixin';

import FinderComponent from './Finder.vue';

export default {
  name: 'NodeTreeView',
  mixins: [functionalityStyle, panelControl, contentControl],
  components: {
    FinderComponent
  },
  props: {
    panelWidth: {
      type: Number,
      default: 300
    },
    menuOpenSpeed: {
      type: Number,
      default: 350
    },
    menuSwitchSpeed: {
      type: Number,
      default: 300
    }
  },
  mounted() {
    this.updateNodesRequest({});
  },
  methods: {
    ...mapActions('nodes', ['updateNodesRequest'])
  },
  computed: {
    ...mapState('nodes', {
      nodes: state => state.nodes
    }),
    ROOT_NODES_STATE() {
      return this.nodes;
    }
  }
};
</script>

<style src="./NodeTreeView.scss" lang="scss"></style>
