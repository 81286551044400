<template>
  <div class="closeDiv">
    <v-btn
      icon
      fab
      class="breadcrumbBackground closeBtn"
      width="35px"
      height="35px"
    >
      <v-icon color="firstColor">
        mdi-close
      </v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'TreeViewClose'
};
</script>

<style src="./TreeViewClose.scss" lang="scss"></style>
