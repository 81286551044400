<template>
  <div>
    <v-app-bar
      elevation="0"
      class="main-header hidden-sm-and-down"
      height="70"
      fixed
      color="header"
    >
      <div class="v-left">
        <SidebarToggleBtn />
        <template v-if="!!logo">
          <v-img
            :src="require(`@/assets/logos/${logo}`)"
            contain
            width="222"
            height="15"
            alt="Gasag Solution Plus"
          ></v-img>
        </template>

        <NodeTreeView v-show="renderTreeNodeNav" />
      </div>

      <SearchComponent />
    </v-app-bar>

    <v-app-bar
      elevation="0"
      class="main-headerTwo"
      v-if="windowWidth < 960 && windowWidth >= 580"
      height="70"
      fixed
      color="header"
    >
      <div class="v-left" v-if="showSearch == false">
        <SidebarToggleBtn />
        <template v-if="!!logo">
          <v-img
            :src="require(`@/assets/logos/${logo}`)"
            contain
            width="222"
            height="15"
            alt="Gasag Solution Plus"
          ></v-img>
        </template>

        <NodeTreeView v-show="renderTreeNodeNav" />
        <v-btn
          icon
          fab
          class="secondColor mt-8 ml-4 searchbtn"
          small
          width="40px"
          height="40px"
          @click="toggleShowSearch()"
          v-model="showSearch"
        >
          <template>
            <icon-base
              icon-name="Suche"
              :width="20"
              :height="15"
              :viewBox="`${-3} ${0} ${20} ${15}`"
            >
              <template slot-scope="{ strokeColor }">
                <IconSearch :strokeColor="strokeColor" /> </template
            ></icon-base>
          </template>
        </v-btn>
      </div>
      <div v-else class="v-left">
        <template v-if="!!logo">
          <v-img
            :src="require(`@/assets/logos/${logo}`)"
            contain
            width="222"
            height="15"
            alt="Gasag Solution Plus"
          ></v-img>
        </template>
        <v-btn
          icon
          fab
          class="secondColor mt-7"
          small
          width="40px"
          height="40px"
          @click="toggleShowSearch()"
          v-model="showSearch"
        >
          <template>
            <icon-base
              icon-name="Suche"
              :width="12"
              :height="18"
              :viewBox="`${0} ${0} ${12} ${18}`"
            >
              <template slot-scope="{ strokeColor }">
                <IconArrowLeft :strokeColor="strokeColor" /> </template
            ></icon-base>
          </template>
        </v-btn>
        <SearchComponent />
      </div>
    </v-app-bar>

    <v-app-bar
      elevation="0"
      class="main-header"
      v-else-if="windowWidth < 580"
      height="70"
      fixed
      color="header"
    >
      <div class="v-left" v-if="showSearch == false">
        <SidebarToggleBtn />
        <template v-if="!!logo">
          <v-img
            :src="require(`@/assets/logos/${logo}`)"
            contain
            width="222"
            height="15"
            alt="Gasag Solution Plus"
          ></v-img>
        </template>
        <div class="headerContent">
          <v-btn
            icon
            fab
            class="secondColor ml-3  searchbtn"
            small
            width="40px"
            height="40px"
            @click="toggleShowSearch()"
            v-model="showSearch"
          >
            <template>
              <icon-base
                icon-name="Suche"
                :width="20"
                :height="15"
                :viewBox="`${0} ${0} ${20} ${15}`"
              >
                <template slot-scope="{ strokeColor }">
                  <IconSearch :strokeColor="strokeColor" /> </template
              ></icon-base>
            </template>
          </v-btn>
        </div>
      </div>
      <div v-else class="v-left">
        <v-btn
          icon
          fab
          class="secondColor mt-4"
          small
          width="40px"
          height="40px"
          @click="toggleShowSearch()"
          v-model="showSearch"
        >
          <template>
            <icon-base
              icon-name="Suche"
              :width="12"
              :height="18"
              :viewBox="`${0} ${0} ${12} ${18}`"
            >
              <template slot-scope="{ strokeColor }">
                <IconArrowLeft :strokeColor="strokeColor" />
              </template>
            </icon-base>
          </template>
        </v-btn>
        <SearchComponent />
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import config from '../../config';
import NodeTreeView from '@/components/NodeTreeView/NodeTreeView';
import SidebarToggleBtn from '@/components/Sidebar/SidebarToggleBtn';

import SearchComponent from '@/components/Search/Search';
import '@jledentu/vue-finder/dist/vue-finder.css';
import IconBase from '@/components/Icons';
import IconArrowLeft from '../Icons/IconArrowLeft.vue';
import IconSearch from '../Icons/IconSearch.vue';

export default {
  name: 'HeaderComp',
  components: {
    SearchComponent,
    NodeTreeView,
    SidebarToggleBtn,
    IconBase,
    IconSearch,
    IconArrowLeft
  },
  data: () => ({
    config,
    showSearch: false,
    showBreadcrumb: false,
    logo: null,
    viewsList: ['/dashboard'],
    windowWidth: 0
  }),
  computed: {
    renderTreeNodeNav() {
      if (this.viewsList.includes(this.$route.fullPath)) {
        this.check_if_favourite();
        return true;
      } else {
        return false;
      }
    }
  },

  methods: {
    getLogo: function() {
      return this.$store.getters['layout/GET_THEME_RESSOURCE']('logo');
    },

    check_if_favourite() {
      if (this.$store.state.nodes.breadcrumb.length > 0) {
        let cur_name = this.$store.state.nodes.breadcrumb[
          this.$store.state.nodes.breadcrumb.length - 1
        ].text;
        let ret = this.$store.state.nodes.start_favourite_breadcrumbs.filter(
          e => e[e.length - 1].text == cur_name
        );
        this.as_favourite = ret.length > 0;
      }
    },
    handleResize: function() {
      let width = window.innerWidth;
      this.windowWidth = width;
    },
    toggleShowSearch() {
      this.showSearch = !this.showSearch;
    },
    toggleShowBreadcrumb() {
      this.showBreadcrumb = !this.showBreadcrumb;
    }
  },
  mounted: function() {
    this.logo = this.getLogo();
  },

  watch: {
    logo: function() {
      this.logo = this.getLogo();
      let width = window.innerWidth;
      this.windowWidth = width;
    },
    '$store.state.layout.theme': function() {
      this.logo = this.getLogo();
    }
    /*as_favourite: function(newValue, oldValue) {
      let current_breadcrumb = this.$store.state.nodes.breadcrumb;
      console.log('As fave', current_breadcrumb);
      this.$store.dispatch(
        'nodes/addBreadcrumbToFavourites',
        current_breadcrumb
      );
    }*/
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style src="./Header.scss" lang="scss"></style>
