<template>
  <g>
    <path
      d="M 1.43 6.3 c 0 2.81 0.31 8.72 6.23 12.15 c 5.81 3.36 12.5 4.1 17.27 4.1"
      :stroke="strokeColor"
      stroke-width="3"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <g>
      <rect fill="none" x="10.02" y="1.94" width="11" height="13.75" />
      <path
        d="M 12.6 1.94 h 1.28 v 2.98 l -0.05 1.54 c 0.69 -0.68 1.45 -1.23 2.48 -1.23 c 1.58 0 2.29 1.02 2.29 2.92 v 4.74 h -1.28 V 8.32 c 0 -1.4 -0.43 -1.98 -1.42 -1.98 c -0.75 0 -1.29 0.38 -2.03 1.14 v 5.41 H 12.6 V 1.94 Z"
        :fill="strokeColor"
        :stroke="strokeColor"
        stroke-width="1"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
  </g>
</template>

<script>
export default {
  props: {
    strokeColor: {
      type: String,
      default: '#143857',
      required: false
    }
  }
};
/*
<svg xmlns="http://www.w3.org/2000/svg" id="Ebene_1" style="" viewBox="0 0 24 24" x="0px" y="0px" xmlns:xml="http://www.w3.org/XML/1998/namespace" xml:space="preserve" version="1.1">
<style type="text/css">
	.st0{fill:none;stroke:#000000;stroke-width:1.5;stroke-miterlimit:10;}
	.st1{fill:none;}
</style>
<path class="st0" d="M 0.85 7 c 0 2.81 0.31 8.72 6.23 12.15 c 5.81 3.36 12.5 4.1 17.27 4.1" />
<g>
	<rect class="st1" x="10.02" y="1.94" width="11" height="13.75" />
	<path d="M 12.6 1.94 h 1.28 v 2.98 l -0.05 1.54 c 0.69 -0.68 1.45 -1.23 2.48 -1.23 c 1.58 0 2.29 1.02 2.29 2.92 v 4.74 h -1.28 V 8.32 c 0 -1.4 -0.43 -1.98 -1.42 -1.98 c -0.75 0 -1.29 0.38 -2.03 1.14 v 5.41 H 12.6 V 1.94 Z" />
</g>
</svg>
*/
</script>
