var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item",class:[
    _vm.node.cssClass || '',
    _vm.expanded ? 'breadcrumbActiv breadcrumbActivText--text' : '',
    _vm.activeClass && 'breadcrumbActiv breadcrumbActivText--text'
  ],style:(Object.assign({}, (_vm.expanded &&
      _vm.theme.breadcrumbActiv && { backgroundColor: _vm.theme.breadcrumbActiv }))),attrs:{"role":"button","aria-expanded":_vm.node.isLeaf ? undefined : _vm.expanded},on:{"mousedown":_vm.onMouseDown,"focus":_vm.onFocus}},[(_vm.selectable)?_c('input',{attrs:{"type":"checkbox","disabled":_vm.node.selectable === false,"aria-label":_vm.node.label},domProps:{"checked":_vm.selected},on:{"change":_vm.onSelect}}):_vm._e(),_c(_vm.itemComponent,{tag:"component",class:[
      'inner-item',
      _vm.expanded || _vm.activeClass
        ? 'secondColorText--text'
        : 'componentText--text'
    ],attrs:{"item":_vm.node,"expanded":_vm.expanded},on:{"click":function($event){return _vm.onClickNodeText(_vm.node)}}},[_vm._t("default")],2),((typeof _vm.node.nodes !== 'undefined'))?_c('div',[_c('div',{staticClass:"v-node--icon"},[(!_vm.expanded)?_c('div',{class:_vm.activeClass ? 'v-node--icon-open' : 'v-node--icon-close',on:{"click":function($event){return _vm.plusClick()}}},[_c('icon-base',{attrs:{"icon-name":"","width":30,"height":30,"viewBox":((-3) + " " + (-3) + " " + (30) + " " + (30))}},[[_c('keep-alive',[_c(_vm.IconPlus,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                    ? _vm.$vuetify.theme.themes.dark['thirdColorIcon']
                    : _vm.$vuetify.theme.themes.light['thirdColorIcon']}})],1)]],2)],1):_c('div',{staticClass:"v-node--icon-open",on:{"click":function($event){return _vm.minusClick()}}},[_c('icon-base',{attrs:{"icon-name":"","width":30,"height":30,"viewBox":((-7) + " " + (-14) + " " + (30) + " " + (30))}},[[_c('keep-alive',[_c(_vm.IconMinus,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                    ? _vm.$vuetify.theme.themes.dark['thirdColorIcon']
                    : _vm.$vuetify.theme.themes.light['thirdColorIcon']}})],1)]],2)],1)])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }