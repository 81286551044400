<template>
  <v-container fluid>
    <div class="content-main my-md-2">
      <div class="row">
        <div class="col-12">
          <div>UI testing</div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'UI'
};
</script>

<style src="./index.scss" lang="scss"></style>
