<template>
  <div :style="style">
    <v-btn
      icon
      fab
      class="secondColor mr-3"
      @click.stop="TOGGLE_DRAWER"
      small
      width="40px"
      height="40px"
    >
      <template v-if="DRAWER_STATE">
        <icon-base icon-name="close" :width="20" :height="15">
          <template slot-scope="{ strokeColor }">
            <IconClose :strokeColor="strokeColor" /> </template
        ></icon-base>
      </template>
      <template v-else>
        <icon-base icon-name="burger" :width="21" :height="14">
          <template slot-scope="{ strokeColor }">
            <IconBurger :strokeColor="strokeColor" /> </template
        ></icon-base>
      </template>
    </v-btn>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import IconBase from '@/components/Icons';
import IconClose from '@/components/Icons/IconClose';
import IconBurger from '@/components/Icons/IconBurger';

export default {
  components: { IconBase, IconClose, IconBurger },
  created() {
    window.addEventListener('resize', this.rwdVisibility);
  },
  destroyed() {
    window.removeEventListener('resize', this.rwdVisibility);
  },
  data() {
    let width = window.innerWidth;
    return {
      style: {
        display: width >= 960 ? 'none' : 'table-cell',
        verticalAlign: 'middle',
        width: '10px'
      }
    };
  },

  computed: {
    ...mapState('layout', {
      drawer: state => state.drawer
    }),

    DRAWER_STATE: {
      get() {
        return this.drawer;
      },
      set(newValue) {
        if (newValue === this.drawer) return;
        this.TOGGLE_DRAWER();
      }
    }
  },
  methods: {
    ...mapActions('layout', ['TOGGLE_DRAWER']),
    rwdVisibility: function() {
      let width = window.innerWidth;
      this.style.display = width >= 960 ? 'none' : 'table-cell';
    }
  }
};
</script>
