<template>
  <transition name="vmenu-fade">
    <div v-if="isActive" @click="handleShadowClicked" class="vmenu-shadow">
      <TreeViewClose />
    </div>
  </transition>
</template>

<script>
import TreeViewClose from './TreeViewClose';

export default {
  components: {
    TreeViewClose
  },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    handleShadowClicked: {
      type: Function,
      default: () => {}
    }
  }
};
</script>

<style src="./NodeTreeShadow.scss" lang="scss"></style>
