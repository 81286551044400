<template>
  <v-app>
    <HeaderComp />
    <Sidebar />
    <v-main class="content">
      <router-view />
      <!--<Footer />-->
    </v-main>
  </v-app>
</template>

<script>
import HeaderComp from '@/components/Header/Header';
import Sidebar from '@/components/Sidebar/Sidebar';
//import Footer from '@/components/Footer';
import config from '../../config/index';

export default {
  name: 'LayoutComponent',
  components: { HeaderComp, Sidebar },
  data: () => ({
    themes: config.themes
  }),
  methods: {
    setTheme: function() {
      // set default theme
      let defaultTheme = this.themes.find(
        item => item.name === this.$store.state.layout.theme.name
      );

      const name = defaultTheme.name;
      const dark = defaultTheme.dark;
      const light = defaultTheme.light;

      // set themes
      Object.keys(dark).forEach(i => {
        this.$vuetify.theme.themes.dark[i] = dark[i];
      });
      Object.keys(light).forEach(i => {
        this.$vuetify.theme.themes.light[i] = light[i];
      });
      // also save theme name to disable selection
      this.$vuetify.theme.themes.name = name;

      let payload = {
        name: this.$store.state.layout.theme.name,
        isDark: this.$vuetify.theme.isDark
      };

      //this.$store.dispatch('layout/setTheme', payload);
    }
  },
  mounted() {
    this.setTheme();
    let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
    this.$vuetify.theme.dark = localUserConfig.theme.isDark;
  }
};
</script>

<style src="./Layout.scss" lang="scss" />
