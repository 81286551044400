<template>
  <v-container fluid class="main-img">
    <div class="content-main my-md-2">
      <div class="row">
        <div class="col-12">
          <!-- <h2>{{ $t('sidebar.links.home') }}</h2> -->

          <v-row>
            <v-col
              v-for="(item, i) in start_view_items"
              :key="i"
              md="6"
              sm="12"
              cols="12"
            >
              <v-card height="100%" class="chartBackground">
                <v-card-title class="card-title firstColorIcon--text">
                  {{ item.title }}
                </v-card-title>
                <v-divider class="mx-4"></v-divider>
                <!-- Zuletzt betrachtet -->
                <div v-if="item.id == 1">
                  <div v-if="panels.length > 0">
                    <ChartComponent
                      :panel="panels[panels.length - 1]"
                      :update="false"
                    />
                  </div>
                </div>
                <!-- Kontakt -->
                <div v-if="item.id == 5">
                  <v-dialog v-model="dialog" width="300">
                    <template v-slot:activator="{ on, attrs }">
                      <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                        class="card-form"
                      >
                        <v-text-field
                          v-model="name"
                          :counter="30"
                          :rules="nameRules"
                          label="Name"
                          required
                        ></v-text-field>

                        <!-- <v-text-field
                          v-model="email"
                          :rules="emailRules"
                          label="E-Mail"
                          required
                        ></v-text-field> -->

                        <!-- <v-select
                          v-model="contact_reason"
                          :items="contact_form_items"
                          :rules="[v => !!v || 'Benötigt']"
                          label="Anfragegrund"
                          required
                        ></v-select> -->

                        <v-textarea
                          v-model="message"
                          :rules="messageRules"
                          v-bind:label="$t('home.message')"
                          required
                        ></v-textarea>
                        <v-checkbox v-model="form_checkbox">
                          <template v-slot:label>
                            <span>
                              {{ $t('home.i_agree_1') }}
                              <!-- <a
                                class="componentText--text"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="https://geo-en.de/impressum/"
                                >{{ $t('main.imprint') }}</a
                              >&nbsp;|&nbsp; -->
                              <a
                                class="componentText--text"
                                target="_blank"
                                href="https://geo-en.de/datenschutz/"
                                @click.stop
                              >
                                {{ $t('home.privacy_2') }}</a
                              >
                              {{ $t('home.i_agree_2') }}
                            </span>
                          </template>
                        </v-checkbox>
                        <v-btn
                          :disabled="!valid || !form_checkbox"
                          class="text-none ml-1 mr-1"
                          @click="validate"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ $t('home.send') }}
                        </v-btn>

                        <v-btn class="text-none ml-1 mr-1" @click="reset">
                          {{ $t('home.reset') }}
                        </v-btn>
                      </v-form>
                    </template>
                    <v-card>
                      <div v-if="form_submitting">
                        <!-- <v-card-title class="text-h5 grey lighten-2">
                          {{ $t('home.form_success_title') }}
                        </v-card-title> -->

                        <v-card-text color="black">
                          {{ $t('home.form_sending') }}
                        </v-card-text>
                      </div>
                      <div v-if="form_success && !form_submitting">
                        <!-- <v-card-title class="text-h5 grey lighten-2">
                          {{ $t('home.form_success_title') }}
                        </v-card-title> -->

                        <v-card-text>
                          {{ $t('home.form_success') }}
                        </v-card-text>
                      </div>
                      <div v-if="!form_success && !form_submitting">
                        <!-- <v-card-title class="text-h5 grey lighten-2">
                          {{ $t('home.form_fail_title') }}
                        </v-card-title> -->

                        <v-card-text>
                          {{ $t('home.form_fail') }}
                        </v-card-text>
                      </div>

                      <v-divider></v-divider>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="dialog = false"
                          :disabled="form_submitting"
                        >
                          Ok
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
                <!-- favourite breadcrumbs -->
                <div v-if="item.id == 6">
                  <div
                    v-if="start_favourite_breadcrumbs.length > 0"
                    class="card-form"
                  >
                    <div
                      v-for="(item, i) in start_favourite_breadcrumbs"
                      :key="i"
                    >
                      <v-chip
                        @click="jump(item)"
                        class="ma-2"
                        :style="{
                          'background-color': colors[i % colors.length]
                        }"
                        close
                        @click:close="remove_from_fav_list(item)"
                      >
                        <div v-for="(subitem, j) in item" :key="j">
                          <div v-if="j != 0 && j != item.length - 1">
                            {{ subitem.text }}
                            <v-icon small>mdi-greater-than</v-icon>
                          </div>
                          <div v-if="j == item.length - 1">
                            {{ subitem.text }}
                          </div>
                        </div> </v-chip
                      ><br />
                    </div>
                  </div>
                  <div
                    v-if="start_favourite_breadcrumbs.length == 0"
                    class="card-form"
                  >
                    <p>{{ $t('home.shortcut_alt') }}</p>
                  </div>
                  <!-- <v-btn @click="button_click">Test</v-btn> -->
                </div>
                <!-- pictures  -->
                <div v-if="item.id == 3">
                  <!--<v-img
                    :src="require('@/assets/startpage1.jpg')"
                    max-height="350"
                    contain
                    class="ma-6"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>-->
                  <v-carousel cycle show-arrows-on-hover>
                    <v-carousel-item
                      v-for="(image, j) in images"
                      :key="j"
                      :src="image.src"
                      reverse-transition="fade-transition"
                      transition="fade-transition"
                    ></v-carousel-item>
                  </v-carousel>
                </div>
                <!-- map -->
                <div v-if="item.id == 4">
                  <div style="height: 500px; width: 100%">
                    <l-map
                      v-if="LTestObject.showMap"
                      ref="map"
                      :zoom="LTestObject.zoom"
                      :center="LTestObject.center"
                      :options="LTestObject.mapOptions"
                      @update:center="centerUpdate"
                      @update:zoom="zoomUpdate"
                    >
                      <!-- @ready="getStartView" -->
                      <l-tile-layer
                        :url="LTestObject.url"
                        :attribution="LTestObject.attribution"
                      />
                      <l-marker
                        v-for="(marker, i) in LTestObject.markers"
                        :key="i"
                        :lat-lng="marker.koord"
                      >
                        <l-popup :options="{ maxWidth: 1000 }">
                          <div>
                            <div>
                              {{ marker.text }}
                            </div>
                            <div
                              v-for="(nodes, j) in marker.navigations"
                              :key="j"
                              class="my-1"
                            >
                              <v-chip @click="jump(nodes)" small link>
                                <div v-for="(node, k) in nodes" :key="k">
                                  {{ node.text }}
                                  <v-icon small v-if="k < nodes.length - 1"
                                    >mdi-greater-than</v-icon
                                  >
                                </div>
                              </v-chip>
                            </div>
                          </div>
                        </l-popup>
                      </l-marker>
                    </l-map>
                  </div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </div>
      <FooterComp />
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex';
import ChartComponent from '@/components/Charts/Chart';
import FooterComp from '@/components/Footer';
import L from 'leaflet';
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from 'vue2-leaflet';
import { submitContactForm } from '../../api/services';
import { getMasterData } from '../../api/services';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  name: 'HomePage',
  components: {
    ChartComponent,
    FooterComp,
    LMap,
    LTileLayer,
    LMarker,
    LPopup
    //LTooltip
  },
  data() {
    return {
      start_view_items: [],
      start_view_list: [
        {
          title: this.$root._i18n.t('home.last_viewed'),
          type: 'chart',
          id: 1,
          flex: 6
        },
        //{ title: 'Störungen', type: 'list', id: 2, flex: 6 },
        //{ title: 'Galerie', type: 'gallery', id: 3, flex: 6 },
        //{ title: this.$root._i18n.t('home.map'), type: 'map', id: 4, flex: 6 },
        {
          title: this.$root._i18n.t('home.contact'),
          type: 'form',
          id: 5,
          flex: 6
        },
        { title: 'Shortcuts', type: 'list', id: 6, flex: 12 }
      ],
      images: [],
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name benötigt',
        v => (v && v.length <= 30) || 'Maximal 30 Zeichen'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail benötigt',
        v => /.+@.+\..+/.test(v) || 'Ungültige E-Mail'
      ],
      contact_reason: '',
      form_checkbox: false,
      message: '',
      messageRules: [v => !!v || 'Nachricht benötigt'],
      contact_form_items: ['Anlage', 'Webseite', 'Daten', 'Anderer Grund'],
      colors: ['#02858D', '#8BB059', '#C4B735', '#BB5A01'],
      dialog: false,
      form_success: false,
      form_submitting: false,
      LTestObject: {
        zoom: 11,
        center: L.latLng(52.0, 13.0), //(52.5077865, 13.383553|52.5243700, 13.4105300),
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        markers: [],
        currentZoom: 12.5,
        currentCenter: L.latLng(52.52437, 13.41053),
        mapOptions: {
          zoomSnap: 0.5
        },
        showMap: true
      }
    };
  },
  computed: {
    ...mapState('nodes', [
      'node',
      'nodes',
      'panels',
      'isReceiving',
      'isUpdating',
      'updateTabRequest',
      'start_favourite_breadcrumbs'
    ])
  },
  beforeMount() {
    this.$store.dispatch('layout/getUserConfig');
    this.$store.dispatch('nodes/updateStore');
    this.$root.$i18n.locale = this.$store.state.layout.language
      ? this.$store.state.layout.language
      : 'de';
    let tabs = this.$store.state.nodes.node.tabs;

    let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
    let tabId = localUserConfig.activeTabId;
    let activeTab = tabs.find(i => i.id === tabId);

    if ((activeTab === undefined || activeTab === null) && tabs.length > 0) {
      activeTab = tabs[0].id;
    }
    if (activeTab) {
      this.$store.dispatch('nodes/loadPanelsRequest', {
        tabId: activeTab.id,
        activeNodeId: '',
        activeGroupId: ''
      });
    }
    this.start_view_list.forEach(item => this.start_view_items.push(item));
    if (this.$store.state.layout.dev) {
      this.start_view_items.splice(1, 0, {
        title: this.$root._i18n.t('home.gallery'),
        type: 'gallery',
        id: 3,
        flex: 6
      });
    }

    if (this.$store.state.nodes.treeNode != null) {
      if (this.$store.state.nodes.treeNode.text !== undefined)
        this.start_view_list[0].title +=
          ' (' + this.$store.state.nodes.treeNode.text + ')';
      else
        this.start_view_list[0].title +=
          ' (' + this.$root._i18n.t('home.no_asset_selected') + ')';
    }
  },
  async mounted() {
    await this.getStartView();
    if (this.$store.state.layout.dev) await this.getCarouselPictures();
  },
  methods: {
    async jump(breadcrumb) {
      await this.$store.dispatch(
        'nodes/loadNodeRequest',
        breadcrumb[breadcrumb.length - 1],
        { root: true }
      );
      this.$store.dispatch('nodes/updateBreadcrumbRequest', breadcrumb);
      this.$store.dispatch('nodes/updateUserConfig');
      this.$router.push('/dashboard');
    },
    remove_from_fav_list(breadcrumb) {
      this.$store.dispatch(
        'nodes/removeBreadcrumbFromFavourites',
        breadcrumb[breadcrumb.length - 1]
      );
    },
    async validate() {
      if (this.$refs.form[0].validate()) {
        this.form_submitting = true;
        let params = {
          username: this.$store.state.auth.user.login,
          name: this.name,
          reason: this.contact_reason,
          text: this.message
        };
        let res = await submitContactForm(params);
        if (!res.data.success) {
          console.log(res.data.errmsg);
        }
        this.form_success = res.data.success;
        this.form_submitting = false;
        this.dialog = true;
      }
    },
    async getCarouselPictures() {
      let req = { reqName: 'homepage_pictures', node: ['*'], pic: 'Random5' };
      let o = { response: [] };
      o = await getMasterData(req);
      if (o.response.length > 0) {
        if (o.response[0].success) {
          let response_nodes = o.response[0].nodes;
          for (let i = 0; i < response_nodes.length; i++) {
            for (let j = 0; j < response_nodes[i].data[0].pics.length; j++) {
              let pic_obj = {
                name: response_nodes[i].data[0].pics[j].name,
                src: response_nodes[i].data[0].pics[j].url
              };
              this.images.push(pic_obj);
              //console.log('Image pushed!', pic_obj);
            }
          }
          if (this.images.length > 0) return;
        }
      }
      // bad request or no images found: add standard images
      this.images.push({
        src: require('@/assets/startpage1.jpg')
      });
      this.images.push({
        src: require('@/assets/startpage2.jpg')
      });
      this.images.push({
        src: require('@/assets/startpage3.jpg')
      });
      this.images.push({
        src: require('@/assets/startpage4.jpg')
      });
    },
    reset() {
      this.$refs.form[0].reset();
    },
    zoomUpdate(zoom) {
      this.LTestObject.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.LTestObject.currentCenter = center;
    },
    async getStartView() {
      let attempt = 0;
      let o = {};
      do {
        var nodeList = this.getNodeList(this.nodes.nodes);
        let nodeIDList = [];
        for (let node of nodeList) {
          nodeIDList.push(node[node.length - 1].node);
        }
        nodeIDList = [...new Set(nodeIDList)]; //to remove the duplicates

        let request_param = {
          reqName: 'map-locs',
          node: nodeIDList,
          name: true,
          loc: true
        };
        o = await getMasterData(request_param);
        attempt++;
      } while (!o.response[0].success && attempt < 10);
      if (
        o.response[0] !== undefined &&
        o.response[0].success &&
        this.$refs.map[0] !== undefined
      ) {
        let locs = o.response[0].nodes.filter(arr => {
          if (arr.data[0].loc != null)
            if (arr.data[0].loc['lat'] != 0 && arr.data[0].loc['lon'] != 0)
              return true;
          return false;
        });
        for (let loc of locs) {
          let obj = loc.data[0];
          this.LTestObject.markers.push({
            koord: L.latLng(obj.loc.lat, obj.loc.lon),
            text: obj.name,
            navigations: nodeList.filter(
              arr => arr[arr.length - 1].node == loc.node
            )
          });
        }
        this.$refs.map[0].fitBounds(
          this.LTestObject.markers.map(m => {
            return [m.koord.lat, m.koord.lng];
          }),
          { padding: [20, 20] }
        );
      }
    },
    getNodeList(nodes) {
      let idList = [];
      for (let node of nodes) {
        if (node.type == 'a') {
          idList.push([node]);
        } else {
          let tempList = this.getNodeList(node.nodes);
          for (let arr of tempList) {
            arr.unshift(node);
          }
          idList.push(...tempList);
        }
      }
      return idList;
    }
  }
};
</script>

<style src="./Home.scss" lang="scss"></style>
