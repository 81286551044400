var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{class:{ 'drawer-mini': !_vm.DRAWER_STATE },attrs:{"app":"","clipped":"","mini-variant":!_vm.DRAWER_STATE,"width":_vm.sidebarWidth,"permanent":_vm.$vuetify.breakpoint.mdAndUp,"mini-variant-width":_vm.sidebarMinWidth,"color":"sidebar"},model:{value:(_vm.DRAWER_STATE),callback:function ($$v) {_vm.DRAWER_STATE=$$v},expression:"DRAWER_STATE"}},[_c('v-col',[_c('v-list',[_vm._l((_vm.items),function(item,i){return [(item.heading)?_c('v-row',{key:item.heading},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"subheader sidebarText--text",class:item.heading && _vm.DRAWER_STATE ? 'show ' : 'hide'},[_vm._v(" "+_vm._s(_vm.$t(item.heading))+" ")])]),_c('v-col',{attrs:{"cols":"6"}})],1):(item.divider)?_c('v-divider',{key:i,staticClass:"my-4",attrs:{"dark":""}}):_c('v-list-item',{key:item.text,class:item.link === _vm.$route.path ? 'sidebarActiv' : '',attrs:{"to":item.link === '#' ? '' : item.link,"link":"","color":"sidebarActiv"},on:{"click":function($event){item.action ? item.action() : null}}},[(!_vm.DRAWER_STATE)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item-action',_vm._g(_vm._b({},'v-list-item-action',attrs,false),on),[_c('icon-base',{attrs:{"icon-name":item.icon}},[[_c('keep-alive',[_c(item.iconComponent,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                            ? _vm.$vuetify.theme.themes.dark[_vm.IconColor]
                            : _vm.$vuetify.theme.themes.light[_vm.IconColor]}})],1)]],2)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t(item.title)))])]):_vm._e(),(_vm.DRAWER_STATE)?_c('v-list-item-action',[_c('icon-base',{attrs:{"icon-name":item.icon}},[[_c('keep-alive',[_c(item.iconComponent,{tag:"component",attrs:{"strokeColor":_vm.$vuetify.theme.isDark
                        ? _vm.$vuetify.theme.themes.dark[_vm.IconColor]
                        : _vm.$vuetify.theme.themes.light[_vm.IconColor]}})],1)]],2)],1):_vm._e(),_c('v-list-item-content',{class:item.link === _vm.$route.path
                ? 'text-left sidebarActivText--text'
                : 'text-left sidebarText--text'},[_c('v-list-item-title',{attrs:{"link":""}},[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")])],1)],1)]})],2)],1),_c('div',[_c('v-btn',{staticClass:"v-btn-drawer secondColor",attrs:{"icon":"","fab":"","width":"34px","height":"34px"},on:{"click":function($event){$event.stopPropagation();return _vm.TOGGLE_DRAWER($event)}}},[(_vm.DRAWER_STATE)?_c('v-icon',{attrs:{"color":"firstColorIcon"}},[_vm._v(" mdi-chevron-left ")]):_c('v-icon',{attrs:{"color":"firstColorIcon"}},[_vm._v(" mdi-chevron-right ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }