<template>
  <div>
    <v-footer
      class="custom px-0 mt-11 mb-11 d-flex align-center justify-center"
    >
      <p class="my-0 py-0 componentText--text">
        {{ $t('main.copyrights') }} {{ new Date().getFullYear() }} |&nbsp;
        <!-- <a
          class="componentText--text"
          target="_blank"
          rel="noopener noreferrer"
          href="https://geo-en.de/impressum/"
          >{{ $t('main.imprint') }}</a
        >&nbsp;|&nbsp;-->
        <a
          class="componentText--text"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.gasag-solution.de/datenschutzhinweise"
        >
          {{ $t('main.privacy') }}</a
        >

        &nbsp;|&nbsp;

        <a
          class="componentText--text"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.gasag-solution.de/impressum"
        >
          Impressum</a
        >
        <!--         <v-dialog
          v-model="dialog_impressum"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <a class="componentText--text" href="#" v-bind="attrs" v-on="on">
              Impressum
            </a>
          </template>
          <v-card>
            <v-toolbar color="#215F75" class="firstColorText--text">
              <v-btn icon dark @click="dialog_impressum = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Impressum</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <div class="ma-3">
              <v-row no-gutters>
                <v-col>
                  <v-card class="pa-2 ma-2" outlined height="100%">
                    <v-card-title class="black--text">
                      Adresse
                    </v-card-title>
                    <v-card-text>
                      <span class="componentText--text"
                        >GASAG Solution Plus GmbH <br />
                        EUREF-Campus 23–24 <br />
                        10829 Berlin</span
                      >
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="pa-2 ma-2" outlined height="100%">
                    <v-card-title class="black--text">
                      Verteten durch
                    </v-card-title>
                    <v-card-text>
                      <span class="componentText--text">Paul Otto</span>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="pa-2 ma-2" outlined height="100%">
                    <v-card-title class="black--text">
                      Geschäftsführung
                    </v-card-title>
                    <v-card-text>
                      <span class="componentText--text">Paul Otto</span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters class="mt-3">
                <v-col>
                  <v-card class="pa-2 ma-2" outlined height="100%">
                    <v-card-title class="black--text">
                      Kontakt
                    </v-card-title>
                    <v-card-text>
                      <span class="componentText--text"
                        >+49 30 7872 4444<br />
                        info@geo-en.de</span
                      >
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="pa-2 ma-2" outlined height="100%">
                    <v-card-title class="black--text">
                      Handelsregistereintrag
                    </v-card-title>
                    <v-card-subtitle>REGISTERGERICHT </v-card-subtitle>
                    <v-card-text>
                      <span class="componentText--text"
                        >Amtsgericht Berlin-Charlottenburg</span
                      >
                    </v-card-text>
                    <v-card-subtitle>REGISTERNUMMER </v-card-subtitle>
                    <v-card-text>
                      <span class="componentText--text"
                        >Berlin HRB 108 908 B</span
                      >
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card class="pa-2 ma-2" outlined height="100%">
                    <v-card-title class="black--text">
                      Umsatzsteuer-Identifikationsnummer
                    </v-card-title>
                    <v-card-subtitle
                      >UMSATZSTEUER-IDENTIFIKATIONSNUMMER GEMÄSS §27A
                      UMSATZSTEUERGESETZ
                    </v-card-subtitle>
                    <v-card-text>
                      <span class="componentText--text">DE 255 876 818</span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </v-dialog> -->
        &nbsp;|&nbsp;
        <v-dialog
          v-model="dialog_nutzung"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
        >
          <template v-slot:activator="{ on, attrs }">
            <a class="componentText--text" href="#" v-bind="attrs" v-on="on">
              Nutzungsbedingungen
            </a>
          </template>
          <v-card>
            <v-toolbar color="#215F75" class="firstColorText--text">
              <v-btn icon dark @click="dialog_nutzung = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Nutzungsbedingungen</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <div class="ma-3">
              <!-- <h2 class="componentText--text">Titel 1</h2> -->
              <h3 class="black--text">1. Geltungsbereich</h3>
              <br />
              <span class="black--text">
                1.1. Die nachfolgenden Nutzungsbedingungen gelten für die
                Nutzung der Web-Applikation NEXERION (nachfolgend:
                „Web-Dienste“) und alle von der GASAG Solution Plus GmbH
                (nachfolgend „GASAG Solution Plus“) diesbezüglich angebotenen
                und erbrachten Leistungen. <br />
                Über die Web-Dienste können Zählerstände und Kennzahlen von
                Energieerzeugungsanlagen und ähnlichen Anlagentechnologien
                dargestellt und ausgewertet werden. <br />
                1.2. Diese AGB gelten ausschließlich. Abweichende,
                entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen
                des Kunden werden nur dann und insoweit Vertragsbestandteil, als
                GASAG Solution Plus ihrer Geltung ausdrücklich schriftlich
                zustimmt. Dieses Zustimmungserfordernis gilt in jedem Fall,
                beispielsweise auch dann, wenn GASAG Solution Plus in Kenntnis
                der Allgemeinen Geschäftsbedingungen des Kunden dessen
                Leistungen annimmt.
                <br />
                1.3. Der Nutzung der Web-Dienste liegt ein im Einzelfall
                zwischen GASAG Solution Plus und dem Kunden abgeschlossener
                Einzelvertrag (nachfolgend „Einzelvertrag“) zu Grunde. <br />
                1.4. Durch den Einzelvertrag oder durch andere, im Einzelfall
                getroffene, individuelle Vereinbarungen mit dem Kunden
                (einschließlich Nebenabreden, Ergänzungen und Änderungen) haben
                in jedem Fall Vorrang vor diesen Nutzungsbedingungen. Für die
                Wirksamkeit derartiger Vereinbarungen ist ein schriftlicher
                Vertrag bzw. die schriftliche Bestätigung durch GASAG Solution
                Plus maßgebend. <br />
              </span>
              <h3 class="black--text">
                2. Abrufbarkeit der Inhalte durch den Kunden und die Nutzer
              </h3>
              <br />
              <span class="black--text">
                Bei den Web-Diensten handelt es sich um über das Internet
                abrufbare Inhalte. Abrufbar sind die Inhalte für den Kunden
                sowie für ausgewählte Mitarbeiter des Kunden, die der Kunde
                GASAG Solution Plus namentlich benennt (diese Mitarbeiter des
                Kunden nachfolgend „Nutzer“ bezeichnet) und damit zur Nutzung
                der Inhalte autorisiert. Für Zwecke der Haftung werden die
                Nutzer als Erfüllungsgehilfen des Kunden angesehen. <br />
              </span>
              <h3 class="black--text">
                3. Voraussetzungen für die Nutzung, Registrierung,
                Nutzungsvereinbarung
              </h3>
              <br />
              <span class="black--text">
                3.1. Die Web-Dienste werden von GASAG Solution Plus über
                Internetseiten angeboten. Für die Nutzung des Web-Dienstes wird
                ein Internet-Zugang benötigt. <br />
                3.2. Voraussetzung für die Anmeldung zum Web-Dienst ist das
                Bestehen eines Vertrages mit der GASAG Solution Plus, welcher
                die Nutzung des Web-Dienstes beinhaltet. Der Kunde bzw. dessen
                Mitarbeiter muss über eine E-Mail-Adresse verfügen, diese
                unterhalten und sie der GASAG Solution Plus mitteilen. Über
                Änderungen der von ihm angegeben E-Mail-Adresse(n) ist die GASAG
                Solution Plus unverzüglich zu unterrichten.
                <br />
                3.3. Der Kunde sowie die Nutzer werden vor der erstmaligen
                Nutzung durch GASAG Solution Plus registriert. Die im Zuge der
                Registrierung von GASAG Solution Plus erfragten Daten sind
                zutreffend anzugeben. <br />
                3.4. Voraussetzung für die Registrierung eines Nutzers ist, dass
                der Nutzer volljährig und auch ansonsten voll geschäftsfähig
                ist. <br />
                3.5. Indem der Kunde GASAG Solution Plus Daten für die
                Registrierung überlässt, stellt dies ein Angebot des Kunden auf
                Abschluss der Vereinbarung über den Zugang zum und die Nutzung
                des Web-Dienstes dar. Nach erfolgreicher Registrierung erhält
                der Kunde und der jeweilige Nutzer eine Bestätigungs-E-Mail mit
                den persönlichen Zugangsdaten. Diese Bestätigungs-E-Mail
                verkörpert die Annahme des Angebots durch GASAG Solution Plus.
                Die Nutzungsvereinbarung ist damit zustande gekommen. <br />
                3.6. Direkten Zugang zum Web-Dienst erhält der Kunde über
                https://www.nexerion.de. Gibt der Kunde bzw. dessen Mitarbeiter
                diese Adresse nicht direkt an, sondern wählt den Zugang zum
                Web-Dienst mittelbar über andere Dienste, Anbieter oder Links,
                besteht die Gefahr, dass sein Passwort Unbefugten zugänglich
                wird. Bei Wahl eines indirekten Zugangs, wie soeben beschrieben,
                ist eine Haftung der GASAG Solution Plus für Datenverlust und
                Datenverfälschung auf diesem Übertragungsweg ausgeschlossen.
                <br />
              </span>
              <h3 class="black--text">
                5. Zugangsdaten
              </h3>
              <br />
              <span class="black--text">
                5.1. Die Zugangsdaten für die Website (E-Mail-Adresse und
                Passwort) sind ausschließlich für die Nutzung durch den Kunden
                bzw. den Nutzer persönlich bestimmt. Der Kunde bzw. der Nutzer
                darf die Zugangsdaten nicht an Dritte weitergeben oder sie
                anderweitig offenlegen. Erhält der Kunde Kenntnis von einem
                Missbrauch von Zugangsdaten oder hat er auch nur einen solchen
                Verdacht, so muss der Kunde dies GASAG Solution Plus umgehend
                mitteilen. Der Kunde haftet für alle Folgen der Nutzung durch
                die Nutzer sowie für eine Nutzung durch Dritte, sofern der
                Missbrauch der Zugangsdaten von ihm oder einem Nutzer zu
                vertreten ist. Diese Haftung des Kunden endet erst, wenn er
                GASAG Solution Plus über die unberechtigte Nutzung oder das
                Abhandenkommen der Zugangsdaten informiert und das Passwort,
                falls erforderlich, geändert hat.
                <br />
                5.2. GASAG Solution Plus ist berechtigt, den Zugang des Kunden
                und der Nutzer zu den Web-Diensten bei Verstößen gegen diese
                Nutzungsbedingungen, insbesondere wegen falscher Angaben bei der
                Registrierung oder unbefugter Weitergabe der Zugangsdaten,
                insbesondere des Passwortes, zeitweilig oder dauerhaft zu
                sperren oder dem Kunden und den Nutzern den Zugang mit
                sofortiger Wirkung oder mit im Ermessen von GASAG Solution Plus
                stehender Frist endgültig zu entziehen oder die
                Nutzungsvereinbarung außerordentlich fristlos zu kündigen.
                <br />
              </span>
              <h3 class="black--text">
                6. Preise und Zahlungsbedingungen
              </h3>
              <br />
              <span class="black--text">
                6.1. Die Preise der einzelnen Angebote folgen aus dem jeweiligen
                Einzelvertrag. <br />
                6.2. Soweit der Kunde seinen Zahlungsverpflichtungen aus dem
                Einzelvertrag nicht nachkommt oder geleistete Beträge
                zurückgebucht bzw. zurückbelastet werden, ist GASAG Solution
                Plus, vorbehaltlich weitergehender Ansprüche, berechtigt, den
                Zugang des Kunden und der Nutzer zu sämtlichen Web-Diensten zu
                sperren. Erfolgt die Sperrung wegen offener Zahlungsforderungen
                und gleicht der Kunde diese aus, wird der Zugang wieder
                entsperrt.
                <br />
              </span>
              <h3 class="black--text">
                7. Nutzungsmöglichkeit und Nutzungsrechte für über Web-Dienste
                erworbene Inhalte
              </h3>
              <br />
              <span class="black--text">
                7.1. Die Nutzung der Web-Dienst-Inhalte ist grundsätzlich zu den
                im Einzelvertrag benannten Zwecken zulässig. <br />
                7.2. Den mit dem Kunden im Sinne der §§ 15ff. Aktiengesetz
                verbundenen Unternehmen (so u.a. auch entsprechenden
                Konzerngesellschaften des Kunden) ist die Nutzung der
                Web-Dienst-Inhalte im gleichen Umfang, wie dem Kunden selbst
                gestattet. Eine darüberhinausgehende Nutzungsbefugnis dieser
                Unternehmen besteht nicht.<br />
                7.3. GASAG Solution Plus behält sich vor, den Zugang zu sperren,
                wenn der Kunde oder die Nutzer entgegen der vorbenannten
                Verpflichtungen gehandelt oder Dritten unbefugt die Nutzung oder
                Verwertung von Inhalten ermöglicht hat bzw. haben.

                <br />
              </span>
              <h3 class="black--text">
                8. Beendigung der Nutzungsvereinbarung
              </h3>
              <br />
              <span class="black--text">
                8.1. Die Nutzungsvereinbarung endet ohne weiteres, also auch
                ohne dass es einer Kündigung bedarf, mit der Beendigung des
                Einzelvertrages. <br />
                8.2. Das Recht der Parteien, die Nutzungsvereinbarung aus
                wichtigem Grund außerordentlich kündigen zu können, bleibt
                unberührt. <br />
                8.3. Mit der Beendigung der Nutzungsvereinbarung erlischt das
                Recht zur Nutzung der Web-Dienste.
                <br />
              </span>
              <h3 class="black--text">
                9. Gewährleistung und Haftung
              </h3>
              <br />
              <span class="black--text">
                9.1. Die Vertragspartner haften – vorbehaltlich der Regelung in
                Ziffer 9.2 – für Vorsatz und grobe Fahrlässigkeit. <br />
                9.2 Bei einfacher Fahrlässigkeit haften die Vertragspartner nur:
                <br />
                • für Schäden aus der Verletzung des Lebens, des Körpers oder
                der Gesundheit und <br />
                • für Schäden aus der Verletzung einer wesentlichen
                Vertragspflicht; in diesem Fall ist die Haftung jedoch auf den
                Ersatz des vorhersehbaren, typischerweise eintretenden Schadens
                begrenzt.<br />
                Unter wesentlichen Vertragspflichten werden hier die
                Verpflichtungen verstanden, deren Erfüllung die ordnungsgemäße
                Durchführung des Vertrags überhaupt erst ermöglicht und auf
                deren Einhaltung der Vertragspartner regelmäßig vertraut.<br />
                Vertragstypische, vorhersehbare Schäden sind solche, die der
                Vertragspartner bei Vertragsschluss als mögliche Folge einer
                Vertragsverletzung vorausgesehen hat oder unter Berücksichtigung
                der Umstände, die ihm bekannt waren oder die er hätte erkennen
                müssen, bei Anwendung verkehrsüblicher Sorgfalt hätte
                voraussehen müssen.<br />
                9.3. Die vorstehenden Haftungsbeschränkungen gelten auch bei
                Pflichtverletzungen durch Personen, deren Verschulden die GASAG
                Solution Plus bzw. der Kunde nach gesetzlichen Vorschriften zu
                vertreten hat.<br />
                9.4. GASAG Solution Plus übernimmt keine Gewährleistung für die
                Funktionsfähigkeit der technischen Einrichtungen des Kunden bzw.
                der Nutzer.<br />
                9.5. GASAG Solution Plus übernimmt keine Haftung für die
                ständige Verfügbarkeit der Online-Verbindung und die
                Erreichbarkeit der Inhalte der Web-Dienste. GASAG Solution Plus
                hat eine eingeschränkte oder fehlende Verfügbarkeit aufgrund
                Leistungsstörungen im Internet, in Folge höherer Gewalt oder
                durch Störung des Arbeitsfriedens sowie im Falle einer
                Betriebsunterbrechung bzw. bei einem Systemausfall nicht zu
                vertreten. Vorübergehende Betriebsunterbrechungen aufgrund der
                üblichen Wartungszeiten, Störungen bei fremden Providern oder
                bei fremden Netzbetreibern sowie im Falle höherer Gewalt sind
                möglich. GASAG Solution Plus übernimmt keine Haftung für vom
                Kunden eigenverursachte Kompatibilitätsprobleme.<br />
                9.6. Im Übrigen haftet GASAG Solution Plus für Schäden des
                Kunden, die vorsätzlich oder grob fahrlässig verursacht wurden,
                die Folge des Nichtvorhandenseins einer garantierten
                Beschaffenheit des Leistungsgegenstandes sind, die auf einer
                schuldhaften Verletzung wesentlicher Vertragspflichten (so
                genannte Kardinalpflichten) beruhen, die Folge einer
                schuldhaften Verletzung der Gesundheit, des Körpers oder des
                Lebens sind, oder für die eine Haftung nach dem
                Produkthaftungsgesetz vorgesehen ist, nach den gesetzlichen
                Bestimmungen.<br />
                9.7. Resultieren Schäden des Kunden aus dem Verlust von Daten,
                haftet GASAG Solution Plus dafür in jedem Fall nicht, soweit die
                Schäden durch eine regelmäßige, vollständige und dem Wert der
                Daten angemessen häufige Sicherung aller relevanten Daten durch
                den Kunden bzw. Nutzer vermieden worden wären.

                <br />
              </span>
              <h3 class="black--text">
                11. Datenschutz
              </h3>
              <br />
              <span class="black--text">
                11.1 GASAG Solution Plus beachtet bei der Erhebung, bei der
                Nutzung und bei der Verarbeitung personenbezogener Daten der
                Nutzer die anwendbaren datenschutzrechtlichen Bestimmungen. Die
                Datenschutzhinweise sind unter
                <a
                  class="black--text"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.gasag-solution.de/datenschutzhinweise"
                >
                  DATENSCHUTZ</a
                >
                zu finden.
                <br />
              </span>
              <h3 class="black--text">
                12. Änderungen der Nutzungsbedingungen
              </h3>
              <br />
              <span class="black--text">
                12.1. GASAG Solution Plus behält sich vor, diese
                Nutzungsbedingungen jederzeit und ohne Angabe von Gründen zu
                ändern. Die neuen Nutzungsbedingungen werden auf der
                Internetseite des Web-Dienstes (www.nexerion.de) veröffentlicht.
                Sie gelten als vereinbart, wenn der Kunde ihrer Geltung nicht
                widerspricht. Der Widerspruch bedarf der Textform. GASAG
                Solution Plus wird den Nutzer in einer E-Mail auf die geänderten
                Nutzungsbedingungen und die Widerspruchsmöglichkeit, die Frist
                und die Folgen seiner Untätigkeit gesondert hinweisen.
                Widerspricht der Kunde, hat sowohl GASAG Solution Plus als auch
                der Kunde das Recht, die Nutzungsvereinbarung durch Kündigung
                mit sofortiger Wirkung zu beenden.
                <br />
              </span>
              <h3 class="black--text">
                13. Schlussbestimmungen
              </h3>
              <br />
              <span class="black--text">
                13.1. Für die auf Grundlage dieser Nutzungsbedingungen
                begründeten Rechtsverhältnisse zwischen dem Kunden und GASAG
                Solution Plus sowie auf diese Nutzungsbedingungen selbst
                einschließlich ihrer Auslegung findet ausschließlich das Recht
                der Bundesrepublik Deutschland Anwendung. Die Anwendung des
                Kollisionsrechtes sowie des UN-Kaufrechts ist ausgeschlossen.
                Sofern es sich beim Kunden um einen Kaufmann, eine juristische
                Person des öffentlichen Rechts oder um ein
                öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand
                für alle Streitigkeiten aus Vertragsverhältnissen zwischen dem
                Kunden und der GASAG Solution Plus Berlin.
                <br />
              </span>
              <h3 class="black--text">
                14. Salvatorische Klausel
              </h3>
              <br />
              <span class="black--text">
                14.1. Sollten einzelne Bestimmungen dieser Nutzungsbedingungen
                einschließlich dieser Regelung ganz oder teilweise unwirksam
                sein, bleibt die Wirksamkeit der übrigen Bestimmungen oder Teile
                solcher Bestimmungen unberührt. Anstelle der unwirksamen oder
                fehlenden Bestimmungen treten die jeweiligen gesetzlichen
                Regelungen.
                <br />
              </span>
              <h3 class="black--text">
                15. Cookies
              </h3>
              <br />
              <span class="black--text">
                15.1 Diese Website verwendet Cookies, um Funktionen anbieten und
                Zugriffe auf unsere Website sicherstellen zu können. Weitere
                Informationen finden Sie in den Datenschutzhinweisen.
                <br />
              </span>
            </div>
          </v-card>
        </v-dialog>
      </p>
    </v-footer>
  </div>
</template>

<script>
export default {
  name: 'FooterComp',
  data() {
    return {
      dialog_datenschutz: false,
      dialog_impressum: false,
      dialog_nutzung: false
    };
  }
};
</script>

<style src="./index.scss" lang="scss"></style>
