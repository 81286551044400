<template>
  <path
    :fill="strokeColor"
    :stroke="strokeColor"
    stroke-width="1"
    d="M5,20h14v-2H5V20z M19,9h-4V3H9v6H5l7,7L19,9z"
  />
</template>

<script>
export default {
  props: {
    strokeColor: {
      type: String,
      default: '#143857',
      required: false
    }
  }
};
</script>
