import axios from 'axios';
import router from '../router/index';

const baseURL = process.env.BASE_URL;

// Default config options
const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
    Lang: 'en'
  }
};

// Create instance
let instance = axios.create(defaultOptions);

// Set the AUTH token for any request
instance.interceptors.request.use(function(config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

// OR using session
// axios.interceptors.request.use(function(config) {
//   const token = store.getState().session.token;
//   config.headers.Authorization = token;

//   return config;
// });

/*
 *  GET nodes list per tree root level base on 'uniqueId'
 *  returns nodes[].
 */
export async function getRootNodes(params) {
  let nodes = [];
  const response = await axios
    .post(`portal/getassettree`, { params: { ...params } })
    .then(res => {
      res.data;
      nodes = [...res.data];
    });
  //nodes = [...response.data];
  return nodes;
}

export async function getRootNodes1(params) {
  let nodes = [];
  const response = await axios.post(`portal/getassettree`, params).then(res => {
    res.data;
    nodes = [...res.data];
  });
  //nodes = [...response.data];
  return nodes;
}

/*
 *  GET node content based on 'id' from the selected tree view navigation
 *  returns node Object.
 */
export async function getSingleNode(params) {
  //console.log(params);
  let data = {};
  const response = await axios
    .post(`portal/getassettree`, { node: params.node })
    .then(res => {
      //console.log(res.data);
      if (res.data.length > 0) data = res.data[0];
    })
    .catch(err => console.log(err));
  return data;
}

/*
 *  POST new panel by passing {activeGroupId, activeNodeId, tabId} params
 */

export async function postSinglePanel(params) {
  let payload = (({ diagram, currentPanel, ...o }) => o)(params); // fancy way of excluding keys in object (https://stackoverflow.com/questions/34698905/how-can-i-clone-a-javascript-object-except-for-one-key)
  let panel = {};
  const response = await axios
    .post(`portal/gettimedata`, [payload], { timeout: 10000 })
    .then(res => {
      panel = [...res.data];
    })
    .catch(err => console.log(err));

  return { request: params, response: panel };
}

/*
 *  POST (UPDATE) all panels by passing {activeGroupId, activeNodeId, tabId, startDate, endDate} params
 */

export async function updateAllPanels(params) {
  await axios
    .put(`${baseURL}/`, null, {
      params: { ...params }
    })
    .then(response => response.status)
    .catch(err => console.warn(err));
}

export async function logout_User(params) {
  await axios
    .post('portal/logout')
    .catch(err => console.log(err))
    .finally(() => {
      //router.push('/login');
      router.go('/login');
    });
}

export async function login_User(creds) {
  const userResponse = {
    success: 0,
    response: null
  };
  await axios
    .post('portal/login', creds)
    .then(async res => {
      userResponse['response'] = res.data;
      userResponse['success'] = 1;
    })
    .catch(err => {
      userResponse['response'] = err.response.data;
    });
  return userResponse;
}

export async function get_User_Config(default_config) {
  let conf = {};
  conf = await axios
    .post('portal/getuserconfig')
    .catch(err => console.log(err))
    .then(res => {
      const configResponse = res.data;
      //console.log(res.data);
      let configData = configResponse.config; // config data is string
      if (configResponse.result != 1) {
        // change object to string
        configData = JSON.stringify(default_config);
      }
      localStorage.setItem('userconfig', configData);
    });
  return conf;
}

export async function save_User_Config() {
  await axios
    .post('portal/saveuserconfig', {
      config: localStorage.getItem('userconfig')
    })
    .catch(err => console.log(err));
}

export async function user_validate(user) {
  let a_data = {};
  await axios
    .post('portal/uservalidate', { username: user.login })
    .then(res => res.data)
    .then(
      data => (a_data = data)
      /*{
      if (!data.result) dispatch('logoutUser');
      else dispatch('getUserConfig');
    }*/
    )
    .catch(err => console.log(err));
  //console.log(a_data);
  return a_data;
}

export async function getMasterData(params) {
  let response_data = {};
  const response = await axios
    .post(`portal/getmasterdata`, [params], { timeout: 10000 })
    .then(res => {
      response_data = [...res.data];
    })
    .catch(err => console.log(err));

  return { request: params, response: response_data };
}

export async function getDocument(params) {
  await axios
    .post(
      `portal/getdocument`,
      { id: params.id },
      { timeout: 10000, responseType: 'blob' }
    )
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data])); // from https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', params.docName);
      document.body.appendChild(link);
      link.click();
    })
    .catch(err => console.log(err));
}

export async function downloadSchematicImage(payload) {
  await axios({
    url: payload.url,
    method: 'GET',
    responseType: 'blob'
  }).then(response => {
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', payload.file_name);
    document.body.appendChild(link);
    link.click();
  });
}

export async function submitContactForm(params) {
  let response_data = {};
  await axios
    .post(`portal/sendcontact`, params, { timeout: 5000 })
    .then(response => {
      response_data = response;
    })
    .catch(err => console.log(err));
  return response_data;
}

export async function resetPassword(params) {
  let response_data = {};
  await axios
    .post(`portal/resetpassword`, params, { timeout: 5000 })
    .then(response => {
      response_data = response;
    })
    .catch(err => console.log(err));
  return response_data;
}

export async function changePassword(params) {
  let response_data = {};
  await axios
    .post(`portal/changepassword`, params, { timeout: 5000 })
    .then(response => {
      response_data = response;
    })
    .catch(err => console.log(err));
  return response_data;
}
