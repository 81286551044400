import {
  getRootNodes,
  getSingleNode,
  updateAllPanels,
  postSinglePanel,
  save_User_Config
} from '../api/services';

function createGuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  );
}

function sort_nodeTree(nodeTree, parent) {
  if (nodeTree == null) return;
  if (parent == 'root') {
    let nodeTreeGroups = nodeTree.filter(node => node.type == 'G');
    nodeTreeGroups.sort(function(a, b) {
      var nameA = a.text.toUpperCase(); // Groß-/Kleinschreibung ignorieren
      var nameB = b.text.toUpperCase(); // Groß-/Kleinschreibung ignorieren
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    let nodeTreeAssets = nodeTree.filter(node => node.type == 'a');
    nodeTreeAssets.sort(function(a, b) {
      var nameA = a.text.toUpperCase(); // Groß-/Kleinschreibung ignorieren
      var nameB = b.text.toUpperCase(); // Groß-/Kleinschreibung ignorieren
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    nodeTree = [...nodeTreeGroups, ...nodeTreeAssets];
  } else {
    nodeTree.sort(function(a, b) {
      var nameA = a.text.toUpperCase(); // Groß-/Kleinschreibung ignorieren
      var nameB = b.text.toUpperCase(); // Groß-/Kleinschreibung ignorieren
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }
  for (let i = 0; i < nodeTree.length; i++) {
    nodeTree[i]['parent'] = parent;
    nodeTree[i].nodes = sort_nodeTree(nodeTree[i].nodes, nodeTree[i].id);
  }
  return nodeTree;
}

function regressionLine(data_arr) {
  var x = [];
  var y = [];
  for (let i = 0; i < data_arr.length; i++) {
    x.push(data_arr[i][0]);
    y.push(data_arr[i][1]);
  }
  var lr = {};
  var n = y.length;
  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;
  var sum_yy = 0;

  for (var i = 0; i < y.length; i++) {
    sum_x += x[i];
    sum_y += y[i];
    sum_xy += x[i] * y[i];
    sum_xx += x[i] * x[i];
    sum_yy += y[i] * y[i];
  }
  var slope = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x);
  var intercept = (sum_y - slope * sum_x) / n;
  lr['slope'] = slope;
  lr['intercept'] = intercept;
  lr['r2'] = Math.pow(
    (n * sum_xy - sum_x * sum_y) /
      Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
    2
  );
  lr['startpoint'] = [x[0], slope * x[0] + intercept];
  lr['endpoint'] = [x[x.length - 1], slope * x[x.length - 1] + intercept];

  return lr;
}

export const rootTreeNodeLevel = {
  id: 'root',
  nodes: []
};

export default {
  namespaced: true,
  state: {
    nodes: { ...rootTreeNodeLevel },
    nodeId: '', // Selected node from the tree node navigation
    groupId: '', // Selected node from the tree node navigation of the current group
    node: {
      title: '',
      description: '',
      tabs: [],
      nodeId: '',
      groupId: ''
    },
    treeNode: {},
    panels: [],
    isReceiving: false,
    isUpdating: false,
    isDeleting: false,
    idToDelete: null,
    activeNodeId: '',
    activeGroupId: '',
    activeTabId: '',
    isCreatingPanel: {},
    isBreadcrumbActive: false,
    breadcrumb: [{ id: '0', text: 'Global' }],
    start_favourite_breadcrumbs: []
  },
  getters: {
    ROOT_NODES_STATE(state) {
      return state.nodes;
    },
    SAVED_ANALYSES(state) {
      return state.savedAnalyses;
    }
  },
  mutations: {
    UPDATED_NODES(state, payload) {
      payload = sort_nodeTree(payload, 'root');
      let nodes = Object.assign(state, {
        nodes: payload
      });

      state = Object.assign(state, {
        nodes: { ...{ id: 'root', nodes: nodes.nodes } },
        isUpdating: false
      });

      state.isReceiving = false;
    },
    UPDATING_NODES(state) {
      state.isUpdating = true;
    },
    RECEIVED_NODE(state, payload) {
      state = Object.assign(state, {
        activeGroupId: payload.activeGroupId,
        activeNodeId: payload.activeNodeId,
        node: { ...payload.node },
        isReceiving: false
      });
    },
    RECEIVING_NODE(state) {
      state.isReceiving = true;
    },
    UPDATED_BREADCRUMB(state, payload) {
      // remove long nodes list from breadcrumb 
      let breadcrumb_flat = [];
      payload.forEach( function(node){
        let new_node = (({ nodes, ...o }) => o)(node);
        breadcrumb_flat.push(new_node);
      });
      state = Object.assign(state, {
        breadcrumb: [...breadcrumb_flat],
        isUpdating: false,
        treeNode: payload[payload.length - 1]
      });
    },
    UPDATING_BREADCRUMB(state) {
      state.isUpdating = true;
    },
    ADD_BREADCRUMB_FAV(state, payload) {
      let i = state.start_favourite_breadcrumbs.filter(
        b => b[b.length - 1].id == payload[payload.length - 1].id
      );
      if (i.length == 0) state.start_favourite_breadcrumbs.push(payload);
    },
    REMOVE_BREADCRUMB_FAV(state, payload) {
      //Payload: Last item in breadcrumb
      let index_to_delete = state.start_favourite_breadcrumbs.findIndex(
        b => b[b.length - 1].id === payload.id
      );
      if (index_to_delete > -1) {
        state.start_favourite_breadcrumbs.splice(index_to_delete, 1);
      }
    },
    CHANGE_ISBREADCRUMBACTIVE(state, payload) {
      if (payload !== undefined && (payload || !payload))
        state.isBreadcrumbActive = payload;
      else state.isBreadcrumbActive = !state.isBreadcrumbActive;
    },
    UPDATED_TAB(state, payload) {
      let { tabId, name } = payload;

      state = Object.assign(state, {
        node: {
          ...state.node,
          tabs: state.node.tabs.map(p => {
            if (p.id === tabId) {
              return { ...p, name };
            }
            return p;
          })
        },
        isUpdating: false
      });
    },
    UPDATING_TAB(state) {
      state.isUpdating = true;
    },
    CREATED_TAB(state, payload) {
      let tabs = state.node.tabs.map(function(x) {
        x.active = false;
        return x;
      });

      let tabId = createGuid();

      state = Object.assign(state, {
        node: {
          title: state.node.title,
          description: state.node.description,
          nodeId: state.node.nodeId,
          groupId: state.node.groupId,
          tabs: [
            ...tabs,
            {
              active: true,
              endDate: null,
              id: tabId,
              name: payload.tabName,
              startDate: null,
              panels: []
            }
          ]
        },
        isUpdating: false,
        activeTabId: tabId
      });
    },
    CREATING_TAB(state) {
      state.isUpdating = true;
    },
    DELETED_TAB(state, payload) {
      let indexToDelete = state.node.tabs.findIndex(p => p.id === payload.id);
      let tabs = [...state.node.tabs];
      tabs.splice(indexToDelete, 1);

      let newTabs = tabs.map((x, y) => {
        if (y === 0) {
          x.active = true;
          return x;
        }
        return x;
      });

      state = Object.assign(state, {
        node: {
          title: state.node.title,
          description: state.node.description,
          nodeId: state.node.nodeId,
          groupId: state.node.groupId,
          tabs: [...newTabs]
        },
        isDeleting: false,
        idToDelete: null
      });
    },
    DELETING_TAB(state, payload) {
      state.isDeleting = true;
      state.idToDelete = payload.id;
    },
    RECEIVED_PANELS(state, payload) {
      // find the index of the tab pass by the payload
      var tabInx = state.node.tabs.findIndex(t => t.id === payload.tabId);
      // find active tab as object
      var currentTab = state.node.tabs[tabInx];
      // update the panels in the state
      state = Object.assign(state, {
        panels: currentTab === undefined ? [] : [...currentTab.panels],
        isReceiving: false
      });
    },
    RECEIVING_PANELS(state) {
      state.isReceiving = true;
    },
    async UPDATE_PANEL(state, payload) {
      let req = payload.request;
      let res = payload.response[0];
      let panelInx = state.panels.findIndex(t => t.id === req.currentPanel);
      let currentPanel = state.panels[panelInx];

      if (res == undefined) {
        currentPanel.chartData = [
          {
            type: req.diagram,
            name: req.name,
            axis: 1,
            data: null,
            series: null,
            stacking: null,
            innerSize: null,
            hasData: false,
            isRange: false
          }
        ];
        return;
      }
      for (let i = 0; i < res.columns.length; i++) {
        if (res.columns[i].unit == '') {
          res.columns[i].unit = ' '; // to avoid highcharts error
        }
        if (res.columns[i].type == 'b') {
          // convert bools to ints for chart
          for (let j = 0; j < res.columns[i].data.length; j++) {
            if (res.columns[i].data[j] == null) continue;
            else if (res.columns[i].data[j]) res.columns[i].data[j] = 1;
            else res.columns[i].data[j] = 0;
          }
        }
      }
      //### for Debugging ###
      //console.log('update panel req', req);
      //console.log(state.panels);
      //console.log(panelInx);
      //console.log('update panel res', res);

      let tableHeader = [];
      let tableData = [];
      let chartSeries = [];
      let chartData = [];
      let units = [];

      // fix offset, which was needed for backend-request
      req.dtstart =
        req.dtstart + new Date(req.dtstart).getTimezoneOffset() * 60000;
      req.dtend = new Date(
        req.dtend + new Date(req.dtend).getTimezoneOffset() * 60000
      );
      // the backend know think the endDate is exclusiv, needs to change to inclusiv! Could then deleted
      req.dtend.setDate(req.dtend.getDate() - 1);
      req.dtend = req.dtend.setHours(23, 0, 0, 0);

      if (req.node.length > 1) {
        // tableHeader, tableDate, chartData, chartSeries, units
        // chartData & chartSeries & units & tableHeader
        tableHeader.push({
          text: 'Name',
          value: 'NodeName'
        });
        for (let i = 0; i < req.vars.length; i++) {
          let datapointArr = [];
          for (let j = 0; j < req.node.length; j++) {
            let posColumn = i + j * req.vars.length + 1;
            // chartData part
            let nodeArr = [];
            nodeArr.push(state.treeNode.nodes[j].text);
            let data = res.columns[posColumn].data;
            for (let k = 0; k < data.length; k++) {
              nodeArr.push(data[k]);
            }
            // chartSeries, units & tableHeader part
            if (j == 0) {
              chartSeries.push({
                name:
                  res.columns[posColumn].text +
                  '[' +
                  res.columns[posColumn].unit +
                  ']'
              });
              units.push(res.columns[posColumn].unit);
              tableHeader.push({
                text:
                  res.columns[posColumn].text +
                  '[' +
                  res.columns[posColumn].unit +
                  ']',
                value: res.columns[posColumn].var
              });
            }
            datapointArr.push(nodeArr);
          }
          chartData.push(datapointArr);
        }

        //tableData just for resolution 'a'
        for (let i = 0; i < req.node.length; i++) {
          let obj = {};
          let key = '';
          let value = '';
          for (let j = 0; j < tableHeader.length; j++) {
            key = tableHeader[j].value;
            if (j == 0) value = state.treeNode.nodes[i].text;
            else {
              let posColumn = j + req.vars.length * i;
              value = res.columns[posColumn].data[0];
            }
            obj[key] = value;
          }
          tableData.push(obj);
        }
      } else {
        // table header and chart series

        for (let c = 0; c < res.columns.length; c++) {
          let text = c == 0 ? 'Datum' : res.columns[c].text;
          let unit = res.columns[c].unit;
          let varname = res.columns[c].var;
          if (unit != null) {
            text = text + ' [' + unit + ']';
          }
          tableHeader.push({ text: text, value: varname });
          // skip time column
          if (c > 0) {
            chartSeries.push({ name: text });
          }
        }
        // table data
        let options = {};
        if (req.res == 'h')
          options = {
            hour: 'numeric',
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          };
        else
          options = {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric'
          };
        /*
        if (req.res == 'd') {
          y_count = startdate.getDay(); // day code: 0... sunday, 1... monday, etc, 6... sat
          if (y_count == 0) {
            y_count = 6;
          } else {
            y_count--; // new code: 0... monday, 1... tuesday, ..., 6... sun
          }
        } else if (req.res == 'h') {
          for (let j = 0; j < res.columns[0].data.length; j++) {
            res.columns[0].data[j] -= 36e5; // Time offset hard coded, maybe should be altered in future
          }
        } else if (req.res == 'm') {
          for (let j = 0; j < res.columns[0].data.length; j++) {
            //res.columns[0].data[j] -= 30 * 24 * 36e5; // Time offset hard coded, maybe should be altered in future
          }
        } else if (req.res == 'y') {
          for (let j = 0; j < res.columns[0].data.length; j++) {
            //res.columns[0].data[j] -= 365 * 24 * 36e5; // Time offset hard coded, maybe should be altered in future
          }
        }
  */
        for (let r = 0; r < res.rowcnt; r++) {
          let row = {};
          for (let c = 0; c < res.columns.length; c++) {
            let varname = res.columns[c].var;
            let vartype = res.columns[c].type;
            let value = res.columns[c].data[r];
            if (vartype == 't') {
              // convert timestamp to date string
              value = new Date(value).toLocaleDateString(
                this.getters['layout/GET_LANGUAGE'],
                options
              );
            }
            row[varname] = value;
          }
          tableData.push(row);
        }
        // chart data
        if (req.diagram === 'heatmap') {
          var startdate = new Date();
          startdate.setTime(req.dtstart);
          let y_count = 0;
          if (req.res == 'd') {
            y_count = startdate.getDay(); // day code: 0... sunday, 1... monday, etc, 6... sat
            if (y_count == 0) {
              y_count = 6;
            } else {
              y_count--; // new code: 0... monday, 1... tuesday, ..., 6... sun
            }
          }
          let cur_date = res.columns[0].data[0];
          if (req.res == 'd') {
            cur_date -= (y_count + 1) * 24 * 36e5; // Setze Datum auf Wochenanfang/Montag -> gleich breite Spalten
          }
          for (let i = 0; i < res.columns[0].data.length; i++) {
            if (req.res == 'd') {
              if (y_count % 6 == 0 && y_count != 0) {
                chartData.push([cur_date, y_count, res.columns[1].data[i]]);
                y_count = 0;
                cur_date = res.columns[0].data[i];
              } else {
                chartData.push([cur_date, y_count, res.columns[1].data[i]]);
                y_count++;
              }
            } else if (req.res == 'h') {
              if (y_count % 23 == 0 && y_count != 0) {
                chartData.push([cur_date, y_count, res.columns[1].data[i]]);
                y_count = 0;
                cur_date = res.columns[0].data[i];
              } else {
                chartData.push([cur_date, y_count, res.columns[1].data[i]]);
                y_count++;
              }
            }
          }
        } else {
          for (let c = 1; c < res.columns.length; c++) {
            chartData.push([]);
            for (let i = 0; i < res.columns[0].data.length; i++) {
              chartData[c - 1].push([
                res.columns[0].data[i],
                res.columns[c].data[i]
              ]);
            }
          }
        }

        for (let u = 1; u < res.columns.length; u++) {
          units.push(res.columns[u].unit);
        }
      }
      if (currentPanel !== undefined) {
        //let currTab = state.node.tabs.find(t => t.id === state.activeTabId);

        currentPanel.chartData = [
          {
            type: req.diagram,
            name: req.name,
            axis: 1,
            data: chartData,
            series: chartSeries,
            stacking: null,
            innerSize: null,
            hasData: res.success,
            isRange: false
          }
        ];

        currentPanel.chartYAxis[0].unit = units;
        currentPanel.chartYAxis[0].title = res.columns[1].text;

        // Trendline
        if (
          req.diagram == 'scatter' ||
          req.diagram == 'column' ||
          req.diagram == 'spline'
        ) {
          if (currentPanel.hasTrendLine) {
            let lr = regressionLine(currentPanel.chartData[0].data[0]);
            currentPanel.chartYAxis[0].unit.push(
              currentPanel.chartYAxis[0].unit[0]
            );
            currentPanel.chartData[0].series.push({
              name: 'Trend',
              trend: true
            });
            currentPanel.chartData[0].data.push([lr.startpoint, lr.endpoint]);
          } else {
            for (var i = 0; i < currentPanel.chartData[0].series.length; i++) {
              if (currentPanel.chartData[0].series[i].trend) {
                currentPanel.chartData[0].series.slice(i);
                currentPanel.chartYAxis[0].unit.slice(i);
                currentPanel.chartData[0].data.slice(i);
              }
            }
          }
        }

        currentPanel.tableHeader = tableHeader;
        currentPanel.tableData = tableData;
        currentPanel.type = req.diagram;
        currentPanel.res = req.res;
        currentPanel.endDate = req.dtend;
        currentPanel.startDate = req.dtstart;
        currentPanel['sortBySize'] = currentPanel.sortBySize ? true : false;
        currentPanel['compareNodes'] = currentPanel.compareNodes ? true : false;
        currentPanel.node = req.node;
        if (currentPanel.relativeDate == undefined)
          currentPanel['relativeDate'] = {
            active: false,
            count: 1,
            timespan: 'm'
          };

        if (currentPanel.topFlop == undefined)
          currentPanel['topFlop'] = {
            isActive: 0, // 0=off, 1=Top, 2=Flop
            n: 5 > tableData.length ? 5 : tableData.length
          };
        else
          currentPanel.topFlop = {
            isActive: currentPanel.topFlop.isActive,
            n: currentPanel.topFlop.n
          };
        if (currentPanel.chartColors === undefined)
          currentPanel['chartColors'] = [];
      }
    },
    UPDATE_ALL_PANELS(state, payload) {
      state = Object.assign(state, {
        panels: [...payload],
        isReceiving: false
      });
    },
    CREATED_PANEL(state, payload) {
      let req = payload;
      let newPanel = {
        id: createGuid(),
        chartData: [
          {
            type: req.diagram,
            name: req.name,
            axis: 1,
            data: [], // [Datenpunkt[[nodename,value],[nodename,value]]]
            series: [], // res.columns[i].text + '[' + unit + ']'
            stacking: null,
            innerSize: null,
            hasData: false,
            isRange: false
          }
        ],
        chartYAxis: [
          {
            title: null,
            unit: null, // die einheiten, die gefragt sind
            opposite: true,
            min: 0,
            max: null,
            index: 1
          }
        ],
        type: req.diagram,
        vars: req.vars,
        tableHeader: null, // 'Bezeichnung' + die verschiedenen Einheiten
        tableData: [], // {[{nodename, vars}]}
        title: req.name,
        startDate: req.dtstart,
        endDate: req.dtend,
        res: req.res,
        sortBySize: false,
        compareNodes: req.node.length > 1 ? true : false,
        node: req.node,
        topFlop: {
          isActive: 0, // 0=off,1=Top,2=Flop
          n: 5
        },
        chartColors: [],
        relativeDate: {
          active: false,
          count: 1,
          timespan: 'm'
        }
      };
      // find current tab to synchronize panels from store to tab
      let tabInx = state.node.tabs.findIndex(t => t.id === state.activeTabId);
      let currentTab = state.node.tabs[tabInx];

      state = Object.assign(state, {
        panels: [...state.panels, newPanel],
        isUpdating: false
      });
      currentTab.panels = state.panels;
    },
    CREATING_PANEL(state) {
      state.isUpdating = true;
    },
    DELETED_PANEL(state, payload) {
      let indexToDelete = state.panels.findIndex(p => p.id === payload);
      let panels = [...state.panels];
      panels.splice(indexToDelete, 1);

      var tabInx = state.node.tabs.findIndex(t => t.id === state.activeTabId);
      var currentTab = state.node.tabs[tabInx];
      state = Object.assign(state, {
        panels: panels,
        isDeleting: false,
        idToDelete: null
      });
      currentTab.panels = state.panels;
    },
    /* PAYLOAD:
    { id: Panel-ID, title: Panel Titel, type: Panel Diagrammtyp, startdate, enddate: Zeitbereich} 
    ID mandatory, Rest wird geupdated wenn vorhanden
    */
    UPDATE_PANEL_SETTING(state, payload) {
      let index = state.panels.findIndex(p => p.id === payload['id']);
      let curr_panel = state.panels[index];
      if (curr_panel == undefined) {
        console.log('Current panel undefined');
        return;
      }
      if ('title' in payload) {
        curr_panel.title = payload['title'];
      }
      if ('type' in payload) {
        curr_panel.type = payload['type'];
      }
      if ('vars' in payload) {
        curr_panel.vars = payload['vars'];
      }
      if ('startDate' in payload) {
        curr_panel.startDate = payload['startDate'];
      }
      if ('endDate' in payload) {
        curr_panel.endDate = payload['endDate'];
      }
      if ('res' in payload) {
        curr_panel.res = payload['res'];
      }
      if ('stacking' in payload) {
        curr_panel.stacking = payload['stacking'];
      }
      if ('hasTrendLine' in payload) {
        curr_panel.hasTrendLine = payload['hasTrendLine'];
      }
      if ('upperLimit' in payload) {
        curr_panel.upperLimit = payload['upperLimit'];
      }
      if ('lowerLimit' in payload) {
        curr_panel.lowerLimit = payload['lowerLimit'];
      }
      if ('relativeDate' in payload) {
        curr_panel.relativeDate = payload['relativeDate'];
      }
      if ('chartColors' in payload) {
        curr_panel.chartColors = payload['chartColors'];
      } else if ('color' in payload && 'colorIndex' in payload) {
        curr_panel.chartColors[payload['colorIndex']] = payload['color'];
      }
      if ('sortBySize' in payload) {
        curr_panel.sortBySize = payload['sortBySize'];
      }
      if ('topFlop' in payload) {
        curr_panel.topFlop = payload['topFlop'];
      }
      state.panels[index] = curr_panel;

      var tabInx = state.node.tabs.findIndex(t => t.id === state.activeTabId);
      var currentTab = state.node.tabs[tabInx];
      currentTab.panels = state.panels;
    },
    UPDATE_ALL_PANELS_SETTING(state, payload) {
      let panels = state.panels;

      for (let i = 0; i < panels.length; i++) {
        if ('startDate' in payload) {
          panels[i].startDate = payload['startDate'];
        }
        if ('endDate' in payload) {
          panels[i].endDate = payload['endDate'];
        }
        if ('res' in payload) {
          panels[i].res = payload['res'];
        }
        if ('relativeDate' in payload) {
          panels[i].relativeDate = payload['relativeDate'];
        }
      }
      let tabInx = state.node.tabs.findIndex(t => t.id === state.activeTabId);
      let currentTab = state.node.tabs[tabInx];
      currentTab.panels = state.panels;
    },
    DELETING_PANEL(state) {
      state.isDeleting = true;
    },
    UPDATE_TREENODE(state, payload) {
      state = Object.assign(state, { treeNode: payload });
    },
    SET_ACTIVE_TAB(state, payload) {
      state = Object.assign(state, { activeTabId: payload });
    },
    DEFAULT_TIME_CHARTS(state, payload) {
      state.defaultTime = payload;
    },
    SAVE_ANALYSIS(state, payload) {
      if (state.savedAnalyses == undefined) {
        state.savedAnalyses = [];
      }
      state.savedAnalyses.push(payload);
    },
    DELETE_ANALYSIS(state, index) {
      if (state.savedAnalyses && index < state.savedAnalyses.length) {
        state.savedAnalyses.splice(index, 1);
      }
    },
    UPDATE_ANALYSIS_SESSION(state, payload) {
      state.analysisSession = payload;
    }
  },
  actions: {
    async updateNodesRequest({ dispatch }, payload) {
      dispatch('updatingNodes');
      dispatch('updateNodes', await getRootNodes(payload));
    },
    updateNodes({ commit }, payload) {
      commit('UPDATED_NODES', payload);
    },
    updatingNodes({ commit }) {
      commit('UPDATING_NODES');
    },
    // load Node per selected item from the tree view
    async loadNodeRequest({ dispatch, commit }, params) {
      let activeNode = params;
      let activeNodeId = activeNode.id;

      let dashboard = {
        nodeId: '',
        groupId: '',
        title: '',
        description: '',
        tabs: [
          {
            id: 'tab-1',
            name: 'Dashboard',
            active: true,
            startDate: null,
            endDate: null,
            panels: []
          }
        ]
      };
      let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
      if (
        Object.prototype.hasOwnProperty.call(
          localUserConfig.dashboards,
          activeNode.dashboardtype
        )
      ) {
        // found dashboard with dashboardtype
        dashboard = localUserConfig.dashboards[activeNode.dashboardtype];
      } else if (activeNode.dashboardtype != undefined) {
        localUserConfig.dashboards[activeNode.dashboardtype] = dashboard;
        localStorage.setItem('userconfig', JSON.stringify(localUserConfig));
      }

      if (activeNode.vars === null) {
        // load data point for node from backend if not available
        let res = await getSingleNode(activeNode);
        activeNode.vars = res.vars;
      }
      commit('UPDATE_TREENODE', activeNode);
      dispatch('receiveNode', {
        activeNodeId: activeNodeId,
        node: dashboard
      });
      let activeTabId = dashboard.tabs.findIndex(
        t => t.id === localUserConfig.activeTabId
      );

      let activeTab = activeTabId === -1 ? 0 : activeTabId;
      dispatch('setActiveTab', activeTab);
    },
    receiveNode({ commit }, payload) {
      commit('RECEIVED_NODE', payload);
    },
    receivingNode({ commit }) {
      commit('RECEIVING_NODE');
    },
    saveDefaultTimerange({ commit }, payload) {
      commit('DEFAULT_TIME_CHARTS', payload);
    },
    saveDefaultTimerangeRequest({ dispatch }, payload) {
      dispatch('saveDefaultTimerange', payload);
      dispatch('updateUserConfig');
    },

    // Tabs (Dashboards)
    async createTabRequest({ dispatch, state }, payload) {
      await dispatch('createTab', payload);
      await dispatch('loadPanelsRequest', { tabId: state.activeTabId });
      dispatch('updateUserConfig');
    },
    async createTab({ commit }, payload) {
      commit('CREATED_TAB', payload);
    },
    creatingTab({ commit }) {
      commit('CREATING_TAB');
    },
    deleteTabsRequest({ dispatch }, payload) {
      dispatch('deletingTab', payload);
      dispatch('deleteTab', payload);

      if (this.state.nodes.node.tabs.length <= 0) {
        dispatch('createDefaultTab');
      }
      dispatch('updateUserConfig');
    },
    createDefaultTab({ commit }) {
      commit('CREATED_TAB', { tabName: 'Dashboard' });
    },
    deleteTab({ commit }, payload) {
      commit('DELETED_TAB', payload);
    },
    deletingTab({ commit }, payload) {
      commit('DELETING_TAB', payload);
    },
    updateTabRequest({ dispatch }, payload) {
      dispatch('updatingTab');
      dispatch('updateTab', payload);
      dispatch('updateUserConfig');
    },
    updateTab({ commit }, payload) {
      commit('UPDATED_TAB', payload);
    },
    updatingTab({ commit }) {
      commit('UPDATING_TAB');
    },

    // Breadcrumb
    updateBreadcrumbRequest({ dispatch }, payload) {
      dispatch('updatingBreadcrumb');
      dispatch('updateBreadcrumb', payload);
    },
    updateBreadcrumb({ commit }, payload) {
      commit('UPDATED_BREADCRUMB', payload);
    },
    updatingBreadcrumb({ commit }) {
      commit('UPDATING_BREADCRUMB');
    },
    async addBreadcrumbToFavourites({ dispatch, commit }, payload) {
      commit('ADD_BREADCRUMB_FAV', payload);
      await dispatch('updateUserConfig');
    },
    async removeBreadcrumbFromFavourites({ dispatch, commit }, payload) {
      // Payload: last item in breadcrumb
      commit('REMOVE_BREADCRUMB_FAV', payload);
      await dispatch('updateUserConfig');
    },
    changeIsBreadcrumbActive({ commit }, payload) {
      commit('CHANGE_ISBREADCRUMBACTIVE', payload);
    },

    // Panels
    async loadPanelsRequest({ dispatch }, params) {
      dispatch('setActiveTab', params.tabId);
      dispatch('receivingPanels');
      // GET request
      dispatch('receivePanels', params);
    },
    setActiveTab({ commit }, payload) {
      commit('SET_ACTIVE_TAB', payload);
    },
    receivePanels({ commit }, payload) {
      commit('RECEIVED_PANELS', payload);
    },
    receivingPanels({ commit }) {
      commit('RECEIVING_PANELS');
    },
    async updatePanelRequest({ dispatch }, params) {
      await dispatch('updatePanel', await postSinglePanel(params));
    },
    async updatePanel({ commit }, payload) {
      await commit('UPDATE_PANEL', payload);
    },
    updateAllPanelsRequest({ dispatch }, params) {
      dispatch('updateAllPanels', updateAllPanels(params));
    },
    updateAllPanels({ commit }, payload) {
      commit('UPDATE_ALL_PANELS', payload);
    },
    async createPanelRequest({ dispatch }, payload) {
      dispatch('creatingPanel');
      dispatch('createPanel', payload);
      await dispatch('updateUserConfig');
    },
    createPanel({ commit }, payload) {
      commit('CREATED_PANEL', payload);
    },
    creatingPanel({ commit }) {
      commit('CREATING_PANEL');
    },
    async deletePanelRequest({ dispatch }, payload) {
      await dispatch('deletePanel', payload);
      await dispatch('updateUserConfig');
      await dispatch('updateStore');
    },
    async deletePanel({ commit }, payload) {
      commit('DELETED_PANEL', payload);
    },
    deletingPanel({ commit }) {
      commit('DELETING_PANEL');
    },
    async updateStore({ dispatch }) {
      let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
      let activeNodeId = localUserConfig.activeNodeId;
      let breadcrumb = localUserConfig.breadcrumb;
      let treeNode = localUserConfig.treeNode;
      let node = localUserConfig.node;
      if (localUserConfig.shortcuts != undefined) {
        this.state.nodes.start_favourite_breadcrumbs =
          localUserConfig.shortcuts;
      }
      if (localUserConfig.savedAnalyses != undefined) {
        this.state.nodes.savedAnalyses = localUserConfig.savedAnalyses;
      }
      if (localUserConfig.defaultTimeRange != undefined) {
        this.state.nodes.defaultTime = localUserConfig.defaultTimeRange;
      }

      this.state.nodes.activeNodeId = activeNodeId;
      this.state.nodes.breadcrumb = breadcrumb;
      this.state.nodes.treeNode = treeNode;
      this.state.nodes.node = { ...node };
    },
    async updatePanelSettingRequest({ dispatch }, payload) {
      dispatch('updatePanelSetting', payload);
      await dispatch('updateUserConfig');
      await dispatch('updateStore');
    },
    async updatePanelSetting({ commit }, payload) {
      commit('UPDATE_PANEL_SETTING', payload);
      //await dispatch('updateUserConfig');
    },
    async updateAllPanelSettingRequest({ dispatch }, payload) {
      dispatch('updateAllPanelSetting', payload);
      await dispatch('updateUserConfig');
    },
    updateAllPanelSetting({ commit }, payload) {
      commit('UPDATE_ALL_PANELS_SETTING', payload);
    },
    async updateUserConfig() {
      let copyNode = { ...this.state.nodes.node };
      copyNode.tabs.forEach(element => {
        if (element.panels !== undefined && element.panels !== null) {
          element.panels.forEach((panel, index, array) => {
            array[index] = (({
              chartData,
              //chartSeries,
              tableData,
              tableHeader,
              ...o
            }) => o)(panel); // fancy way of excluding keys in object (https://stackoverflow.com/questions/34698905/how-can-i-clone-a-javascript-object-except-for-one-key)
          });
        } else {
          element.panels = [];
        }
      });

      let dashboardtype = this.state.nodes.treeNode.dashboardtype;

      let localUserConfig = JSON.parse(localStorage.getItem('userconfig'));
      if (
        Object.prototype.hasOwnProperty.call(
          localUserConfig.dashboards,
          dashboardtype
        )
      ) {
        // get dashboard per dashboard type
        localUserConfig.dashboards[dashboardtype].tabs = copyNode.tabs;
      } else {
        // create a new dashboard
        localUserConfig.dashboards[dashboardtype] = {
          nodeId: '',
          groupId: '',
          title: '',
          description: '',
          tabs: [
            {
              id: 'tab-1',
              name: 'Dashboard',
              active: true,
              startDate: null,
              endDate: null,
              panels: []
            }
          ]
        };
      }

      localUserConfig.activeNodeId = this.state.nodes.activeNodeId;
      localUserConfig.activeTabId = this.state.nodes.activeTabId;
      localUserConfig.breadcrumb = this.state.nodes.breadcrumb;
      if (this.state.nodes.savedAnalyses)
        localUserConfig.savedAnalyses = this.state.nodes.savedAnalyses;
      localUserConfig.treeNode = this.state.nodes.treeNode;
      localUserConfig.shortcuts = this.state.nodes.start_favourite_breadcrumbs;
      if (this.state.nodes.defaultTime)
        localUserConfig.defaultTimeRange = this.state.nodes.defaultTime;
      localUserConfig.node = copyNode;

      localStorage.setItem('userconfig', JSON.stringify(localUserConfig));
      save_User_Config();
    },
    saveAnalysis({ commit }, payload) {
      commit('SAVE_ANALYSIS', payload);
    },
    deleteAnalysis({ commit }, index) {
      commit('DELETE_ANALYSIS', index);
    },
    updateAnalysisSession({ commit }, payload) {
      commit('UPDATE_ANALYSIS_SESSION', payload);
    }
  }
};
