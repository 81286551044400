<template>
  <v-app class="login-page">
    <v-container fluid>
      <v-col cols="12" class="main-part d-flex align-center flex-column">
        <div class="login-wrapper pt-sm-0">
          <div grow class="my-8" light>
            <div class="d-flex align-center justify-center mb-6">
              <v-img
                src="../../assets/GASAG_solution_plus.png"
                max-width="300"
                max-height="300"
              />
            </div>

            <div>
              <v-container>
                <v-row class="flex-column">
                  <v-card class="mt-6 px-12 pt-6 pt-b-6">
                    <v-form ref="log" v-model="valid" lazy-validation>
                      <!-- <form action="" role="form" @submit.prevent="onSubmit"> -->

                      <div
                        class="card"
                        @keydown.enter="login"
                        @keydown="checkCaps()"
                        @click="checkCaps()"
                      >
                        <v-text-field
                          id="username"
                          ref="username"
                          v-model="username"
                          label="Benutzername"
                          required
                          value=""
                          autofocus
                          @focus="checkCaps()"
                          :color="capsLock ? 'warning' : ''"
                          :hint="capsLock ? 'Caps Lock' : ''"
                        ></v-text-field>
                        <v-text-field
                          id="password"
                          ref="password"
                          v-model="password"
                          :type="show ? 'text' : 'password'"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :color="capsLock ? 'warning' : ''"
                          :hint="capsLock ? 'Caps Lock' : ''"
                          label="Passwort"
                          required
                          @focus="checkCaps()"
                          @click:append="show = !show"
                        ></v-text-field>
                        <div
                          class="d-flex align-center justify-center flex-column"
                        >
                          <v-dialog v-model="dialog" width="400">
                            <template v-slot:activator="{ on, attrs }">
                              <a href="#" class="mb-3" v-bind="attrs" v-on="on"
                                >Passwort vergessen?</a
                              >
                            </template>

                            <v-card>
                              <v-card-title>
                                Passwort zurücksetzen
                              </v-card-title>
                              <v-divider></v-divider>
                              <v-card-text>
                                Nach Eingabe eines gültigen Benutzernamens und
                                einer E-Mail wird das Passwort zurückgesetzt und
                                ein Neues versendet.
                              </v-card-text>
                              <v-form
                                ref="form"
                                v-model="reset_valid"
                                lazy-validation
                                class="card-form"
                              >
                                <v-text-field
                                  v-model="reset_name"
                                  :counter="30"
                                  :rules="nameRules"
                                  label="Benutzername"
                                  required
                                ></v-text-field>

                                <v-text-field
                                  v-model="reset_email"
                                  :rules="emailRules"
                                  label="E-Mail"
                                  required
                                ></v-text-field>
                              </v-form>
                              <v-divider></v-divider>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  :disabled="!valid"
                                  @click="validate_reset"
                                  color="primary"
                                  text
                                >
                                  OK
                                </v-btn>
                                <v-btn
                                  color="primary"
                                  text
                                  @click="dialog = false"
                                >
                                  Abbrechen
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>

                          <v-btn
                            large
                            class="mb-4"
                            :disabled="
                              username.length === 0 || password.length === 0
                            "
                            :loading="isFetching"
                            @click="login"
                          >
                            Anmelden
                          </v-btn>
                          <!-- <v-checkbox
                            v-model="checkbox"
                            label="Angemeldet bleiben"
                          ></v-checkbox> -->
                        </div>
                      </div>
                    </v-form>
                  </v-card>
                </v-row>
              </v-container>
            </div>
          </div>
        </div>
        <FooterComp />
      </v-col>
    </v-container>
    <v-snackbar v-model="alert" color="error">
      {{ message }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="alert = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import config from '../../config';
import { resetPassword } from '../../api/services';
import FooterComp from '@/components/Footer';

export default {
  name: 'LoginPage',
  components: { FooterComp },

  data() {
    let width = window.innerWidth;

    return {
      valid: true,
      username: '',
      password: '',
      reset_name: '',
      reset_email: '',
      nameRules: [
        v => !!v || 'Name benötigt',
        v => (v && v.length <= 30) || 'Maximal 30 Zeichen'
      ],
      emailRules: [
        v => !!v || 'E-mail benötigt',
        v => /.+@.+\..+/.test(v) || 'Ungültige E-Mail'
      ],
      reset_valid: false,
      //checkbox: true,
      alert: false,
      message: '',
      capsLock: false,
      show: false,
      dialog: false,
      head: width > 960 ? 4 : 12,
      main: width > 960 ? 8 : 12
    };
  },
  methods: {
    ...mapActions('auth', [
      'loginUser',
      'receiveToken',
      'receiveLogin',
      'resetFetching'
    ]),
    checkCaps() {
      if (event.type == 'focus') {
        event.target.click();
      }
      // FFS: checkcaps lock on KeyboardEvent
      // the current available event is from class Event and getModifierState() is unknown
      // this.capsLock = event.getModifierState('CapsLock');
    },
    login() {
      const username = this.username;
      const password = this.password;
      if (username.length === 0 || password.length === 0) return;
      this.loginUser({ username, password });
    },
    validate() {
      if (this.$refs.log.validate()) {
        window.localStorage.setItem('authenticated', true);
        this.$router.push('/');
      }
    },
    handleResize: function() {
      let width = window.innerWidth;
      this.head = width > 960 ? 4 : 12;
      this.main = width > 960 ? 8 : 12;
    },
    async validate_reset() {
      if (this.$refs.form.validate()) {
        let params = { username: this.reset_name, email: this.reset_email };
        let response = await resetPassword(params);
        //console.log(response);
        this.dialog = false;
      }
    }
  },
  computed: {
    ...mapState('auth', {
      isFetching: state => state.isFetching,
      errorMessage: state => state.errorMessage
    })
  },
  watch: {
    errorMessage() {
      this.message = this.errorMessage;
      if (this.message.length > 0) this.alert = true;
    } /*,
    regErrorMessage() {
      this.message = this.regErrorMessage;
      this.alert = true;
    }*/
  },
  created() {
    //console.log('Backend: ' + !!config.isBackend);
    let token = localStorage.getItem('token');
    if (token) {
      this.receiveToken(token);
    } else {
      if (this.isAuthenticated(localStorage.getItem('token'))) {
        this.receiveLogin();
      }
    }
    window.addEventListener('resize', this.handleResize);
  },
  mounted() {
    this.resetFetching();
    const creds = config.auth;
    this.username = creds.username;
    this.password = creds.password;
  },
  destroy() {
    window.removeEventListener('resize', this.handleResize);
  }
};
</script>

<style src="./Login.scss" lang="scss" />
