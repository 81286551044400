var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drop-zone",class:{
    'drag-over': _vm.dragOver,
    dragging: _vm.treeModel.isDragging(),
    'no-drop': _vm.treeModel.isDragging() && !_vm.canDrop
  },style:(Object.assign({}, (_vm.dragOver &&
      _vm.theme.breadcrumbBackground && {
        borderColor: _vm.theme.breadcrumbBackground
      }),
    (_vm.dragOver &&
      _vm.theme.dropZoneBgColor && { backgroundColor: _vm.theme.dropZoneBgColor }))),on:{"dragenter":function($event){$event.preventDefault();return _vm.onDragEnter($event)},"dragleave":function($event){$event.preventDefault();return _vm.onDragLeave($event)},"dragover":function($event){$event.preventDefault();},"drop":function($event){$event.preventDefault();return _vm.onDrop($event)}}},[(_vm.options.dropZoneComponent)?_c(_vm.options.dropZoneComponent,{tag:"component",staticStyle:{"flex-grow":"1"},attrs:{"dragging":_vm.treeModel.isDragging(),"drag-over":_vm.dragOver}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }